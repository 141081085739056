import { useAuth0 } from '@auth0/auth0-react';
import { Link, Text } from '@chakra-ui/react';
import { resetLastSuccessfulLoginOrganizationId } from 'features/common/auth/auth0Storage';
import { GenericErrorTemplate } from 'features/common/components/GenericErrorTemplate';
import { FallbackProps } from 'react-error-boundary';

export interface IErrorPageProps {
  resetErrorBoundary?: FallbackProps['resetErrorBoundary'];
}

export const ErrorPage = ({ resetErrorBoundary }: IErrorPageProps) => {
  const { logout } = useAuth0();

  const onLogout = () => {
    resetLastSuccessfulLoginOrganizationId();
    logout({
      returnTo: window.location.origin,
    });
  };

  return (
    <GenericErrorTemplate
      title='Something went wrong'
      text={
        <Text>
          Please try again. If the issue persists, try visiting our{' '}
          <Link mb={4} href='https://support.sitecore.com/kb' isExternal>
            Visit Knowledge Base
          </Link>{' '}
          for assistance.
        </Text>
      }
      variant='error'
      shortcuts={
        <>
          {resetErrorBoundary && (
            <Link mb={4} onClick={resetErrorBoundary}>
              Retry
            </Link>
          )}
          <Link
            onClick={() => (window.location.href = window.location.origin)}
            mb={4}
          >
            Go to Homepage
          </Link>
          <Link onClick={onLogout} mb={4}>
            Logout
          </Link>
        </>
      }
    />
  );
};
