import {
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Button,
  ModalContent,
  ModalOverlay,
  Flex,
  Box,
} from '@chakra-ui/react';

interface IPersonalizeCookiePolicyModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const PersonalizeCookiePolicyModal = ({
  isOpen,
  onClose,
}: IPersonalizeCookiePolicyModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size='2xl' autoFocus={false}>
      <ModalOverlay />
      <ModalContent data-testid='personalize-cookie-policy-modal'>
        <ModalHeader>Updates in cookie policy</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex alignItems={'center'} flexDirection={'column-reverse'}>
            <Box>
              We want to let you know that we have an update in our cookie
              policy. On Cloud Portal, we now use Sitecore Personalize cookies
              to enhance your experience and deliver personalized
              recommendations. These cookies are strictly necessary to ensure
              proper site functionality without collecting personal information.
            </Box>
          </Flex>
        </ModalBody>
        <ModalFooter justifyContent={'flex-start'}>
          <ButtonGroup>
            <Button
              data-testid='learn-more-btn'
              variant='solid'
              onClick={() => {
                window.open(
                  'https://www.sitecore.com/legal/cookie-policy',
                  '_blank',
                );
                onClose();
              }}
            >
              Learn more about cookies
            </Button>
            <Button
              variant='outline'
              onClick={onClose}
              data-testid='dismiss-btn'
            >
              Dismiss
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
