export const MARKETPLACE_ROUTES = {
  ROOT: '/marketplace',
  BROWSE: '/', // /marketplace/
  APP_DETAILS: '/app/:id', // /marketplace/app/:id
  INSTALLATION: '/app/:id/install', // /marketplace/app/:id/install
} as const;

// Application
export const getAppPath = (id: string) => {
  return `${MARKETPLACE_ROUTES.ROOT}${MARKETPLACE_ROUTES.APP_DETAILS.replace(
    ':id',
    id,
  )}`;
};
