import { Flex, Heading } from '@chakra-ui/react';

const NoSubscriptions = () => (
  <Flex
    height='100%'
    justifyContent='center'
    alignItems='center'
    mb={40}
    data-testid='no-subscriptions-container'
  >
    <Flex justifyContent='center'>
      <Heading size='md'>No Subscriptions available</Heading>
    </Flex>
  </Flex>
);

export default NoSubscriptions;
