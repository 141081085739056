import React, { useMemo } from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  VStack,
  Heading,
  Button,
  Text,
  Icon,
  Tag,
  HStack,
} from '@chakra-ui/react';
import { useDrawerStateDispatch, useMainState } from 'features/main/context';
import {
  Media,
  MediaBody,
  MediaDescription,
  MediaFigure,
  MediaHover,
  MediaTitle,
} from 'features/common/components/MediaObject';
import { mdiOpenInNew } from '@mdi/js';
import groupBy from 'lodash/groupBy';
import { History } from 'history';
import { useHistory } from 'react-router-dom';
import { redirect } from '../../../../common/utils/utils';
import { closeDrawer } from '../../../../main/context/drawer/actionCreators';
import { tenantsFallbackIcon } from 'features/common/utils/icons';
import {
  validateURL,
  isNonProdEnvironment,
} from '@sitecore-ui/portal-singular';
import { quickActionAdapter } from 'features/domains/portal/content/utils';
import { Action } from 'gql/graphql';

export const onLinkClick = (
  url: string,
  history?: string[] | History<unknown>,
) => {
  redirect(url, history);
};

export const TenantsDrawer = () => {
  const state = useMainState();
  const [{ tenantDrawerVisible }, drawerDispatch] = useDrawerStateDispatch();

  const history = useHistory();
  const { app } = state;

  const product = useMemo(
    () =>
      app?.appearance?.web?.actions?.nodes?.filter(
        (node: Action) => node.category === 'Direct Links',
      )[0],
    [app],
  );

  const onCloseDrawer = () => drawerDispatch(closeDrawer());

  const nodes = useMemo(
    () =>
      app?.appearance?.web?.actions?.nodes?.filter(
        (node: Action) => node.category !== 'Direct Links',
      ),
    [app],
  );

  const quickActions = useMemo(
    () =>
      nodes?.map((node) =>
        quickActionAdapter(node, { dispatch: drawerDispatch, history }),
      ) ?? [],
    [drawerDispatch, history, nodes],
  );

  const quickActionsGrouped = groupBy(quickActions, 'category');

  const getTagCustomerEnvironmentValue = app?.labels?.filter(
    (label) =>
      label.key === 'CustomerEnvironmentType' &&
      !isNonProdEnvironment(label.value),
  )[0]?.value;

  return (
    <Drawer
      onClose={onCloseDrawer}
      isOpen={tenantDrawerVisible}
      size={'md'}
      data-testid={'tenantsDrawer'}
      id={'tenants-drawer'}
      autoFocus={false}
    >
      <DrawerOverlay />
      <DrawerContent
        data-behavior-analytics-feature={`tenantDrawer-${product?.displayName}`}
      >
        <HStack pt={7} pb={6}>
          <DrawerCloseButton />
        </HStack>
        <DrawerBody>
          <VStack align={'stretch'} pb={10} gap='8'>
            <VStack align={'start'} gap='5'>
              <Media
                p={0}
                as={'div'}
                size={'2xlarge'}
                _hover={{ backgroundColor: 'transparent', cursor: 'initial' }}
                _focus={{ backgroundColor: 'transparent', cursor: 'initial' }}
              >
                <MediaFigure
                  icon={{ path: tenantsFallbackIcon }}
                  image={{
                    url: product?.icon.src,
                    alt: '',
                  }}
                  color={'transparent'}
                />
                <MediaBody
                  alignSelf={'center'}
                  display={'flex'}
                  flexDirection={'column'}
                >
                  <MediaTitle as={'div'}>
                    <HStack>
                      <Heading fontSize='2xl'>{product?.name}</Heading>
                      {getTagCustomerEnvironmentValue && (
                        <Tag
                          style={{
                            fontSizeAdjust: 'from-font',
                          }}
                          colorScheme='neutral'
                          size='sm'
                        >
                          {getTagCustomerEnvironmentValue}
                        </Tag>
                      )}
                    </HStack>
                  </MediaTitle>
                  <MediaDescription>
                    {product?.displayName ?? ''}
                  </MediaDescription>
                </MediaBody>
              </Media>
              {product?.description && (
                <Text variant='subtle' data-testid='tenantDrawerDescription'>
                  {product?.description}
                </Text>
              )}
            </VStack>
            <Button
              rightIcon={
                <Icon>
                  <path d={mdiOpenInNew} />
                </Icon>
              }
              alignSelf='start'
              variant='solid'
              onClick={() => {
                redirect(product?.link.to as string, history);
              }}
              data-behavior-analytics-id={`app-button-open_${product?.name}`}
              data-testid={`Open-${product?.displayName}`}
              data-url={product?.link.to}
            >
              Open app
            </Button>
            {Object.keys(quickActionsGrouped).map((category, idx) => {
              return (
                <VStack align='stretch' key={idx}>
                  {category.toLowerCase() !== 'quick actions' && (
                    <>
                      <Heading variant='section'>{category}</Heading>
                    </>
                  )}
                  <VStack align='stretch' gap='1'>
                    {quickActionsGrouped[category].map((quickAction: any) => {
                      return (
                        <Media
                          key={quickAction.title}
                          size='large'
                          variant={
                            validateURL(quickAction.link) ? 'link' : undefined
                          }
                          onClick={() =>
                            onLinkClick(quickAction.link as string, history)
                          }
                          data-testid={`app drawer - ${quickAction.title}`}
                          data-behavior-analytics-id={`${product?.name}_${quickAction.title}_${product?.displayName}`}
                          data-url={quickAction['data-url']}
                          alignItems='center'
                        >
                          <MediaFigure
                            icon={{
                              path: quickAction?.iconPath || '',
                            }}
                            image={{
                              url: quickAction.svgIcon,
                              alt: quickAction.title,
                            }}
                            color={quickAction.iconColor}
                          />
                          <MediaBody
                            alignSelf={'center'}
                            display={'flex'}
                            flexDirection={'column'}
                          >
                            <MediaTitle>{quickAction.title}</MediaTitle>
                          </MediaBody>
                          <MediaHover
                            icon={{
                              path: mdiOpenInNew,
                            }}
                          />
                        </Media>
                      );
                    })}
                  </VStack>
                </VStack>
              );
            })}
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
