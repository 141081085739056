import { ComponentType } from 'react';
import { TokenCustomClaimOrgAccountIdKey } from 'features/domains/gainsight/gainsight';
import { TokenCustomClaimKeysEnum } from '@sitecore-ui/portal-singular';
import { ORG_ROLES } from 'features/common/components/OrganizationAppAccess/resource';
import { useAuth0 } from '@auth0/auth0-react';
import { useFeature } from 'lib/featureFlags/features';
import { useOrgEnvironmentDeps } from 'features/common/utils/orgEnvironments';
import { Redirect } from 'react-router';

const withSubscriptionGuard =
  (Component: ComponentType<any>) => (props: any) => {
    const isEnabled = useFeature('SUBSCRIPTIONS');

    const { isAuthenticated } = useAuth0();
    const { claims, isLoading } = useOrgEnvironmentDeps();

    const roles = claims?.[TokenCustomClaimKeysEnum.ROLES] ?? [];
    const isOwner =
      roles.filter((item: any) =>
        [ORG_ROLES.ORG_OWNER].includes(item.split('\\')[1]),
      ).length > 0;

    const salesforceId = claims?.[TokenCustomClaimOrgAccountIdKey];

    if ((isEnabled && isAuthenticated && isOwner && salesforceId) || isLoading)
      return <Component {...props} />;
    return <Redirect to='/no-access' />;
  };

export default withSubscriptionGuard;
