import React from 'react';
import { Dispatch, MainProviderProps, MainState } from './model';
import { initialMainState, mainReducer } from './reducers';
import { drawerReducer } from './drawer/reducers';
import { DrawerId } from './drawer/model';

const StateContext = React.createContext<MainState | undefined>(undefined);
const DispatchContext = React.createContext<Dispatch | undefined>(undefined);

const combineReducers = (reducers: any) => {
  return (state: any, action: any) => {
    return Object.keys(reducers).reduce((acc, prop) => {
      return {
        ...acc,
        ...reducers[prop]({ [prop]: acc[prop] }, action),
      };
    }, state);
  };
};

const appReducers = combineReducers({
  main: mainReducer,
  drawer: drawerReducer,
});

function MainProvider({ children, initialState }: MainProviderProps) {
  const [state, dispatch] = React.useReducer(appReducers, {
    ...initialMainState,
    ...initialState,
  });

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
}

function useMainState() {
  const context = React.useContext(StateContext);

  if (!context) {
    throw new Error('useMainState must be used within the MainProvider');
  }

  const withXmCloudContext = context.main?.env?.WITH_XM_CLOUD_CONTEXT;

  return {
    withXmCloudContext,
    ...context.main,
  };
}

function useDrawerState() {
  const context = React.useContext(StateContext);

  if (!context) {
    throw new Error('useDrawerState must be used within the MainProvider');
  }

  // Drawer selectors
  const drawerIsVisible = (drawerId: DrawerId) => {
    return (
      context.drawer?.drawerId === drawerId && context.drawer?.drawerVisible
    );
  };

  return {
    ...context.drawer,
    tenantDrawerVisible: drawerIsVisible('tenant-drawer'),
    helpDrawerVisible: drawerIsVisible('help-drawer'),
    inviteDrawerVisible: drawerIsVisible('invite-drawer'),
    inviteDetailsDrawerVisible: drawerIsVisible('invite-details-drawer'),
    tryConnectDrawerVisible: drawerIsVisible('try-connect'),
  };
}

function useMainDispatch() {
  const context = React.useContext(DispatchContext);

  if (!context) {
    throw new Error('useMainDispatch must be used within the MainProvider');
  }

  return context;
}

function useMainStateDispatch() {
  return [useMainState(), useMainDispatch()] as const;
}

function useDrawerStateDispatch() {
  return [useDrawerState(), useMainDispatch()] as const;
}

export {
  MainProvider,
  useMainState,
  useMainDispatch,
  useMainStateDispatch,
  useDrawerState,
  useDrawerStateDispatch,
};
