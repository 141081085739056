import React from 'react';
import { Text, Box, BoxProps, Image } from '@chakra-ui/react';
import {
  MediaType,
  MediaFigureProps,
  MediaConfig,
  MediaIconProps,
} from './model';
import { PortalIcon } from '../PortalIcon';

const MediaContext = React.createContext<
  Omit<MediaType, 'children' | 'minWidth'>
>({
  size: 'large',
});

function Media({
  children,
  size = 'large',
  variant,
  onClick,
  minWidth,
  maxWidth,
  ...props
}: MediaType) {
  const ref = React.useRef<null | HTMLDivElement>(null);

  const onContainerClick = () => {
    if (!onClick) return;
    onClick();
  };

  return (
    <MediaContext.Provider value={{ size, variant }}>
      <Box
        as='button'
        className='media'
        data-testid='mediaObject'
        display='flex'
        ref={ref}
        justifyContent='flex-start'
        p={2}
        minWidth={minWidth}
        maxWidth={maxWidth}
        width='100%'
        borderRadius='md'
        bg='transparent'
        textAlign='left'
        height='fit-content'
        style={{
          transition: 'background-color .1s ease-in',
        }}
        _focus={{
          boxShadow: 'var(--chakra-shadows-outline)',
        }}
        _focusVisible={{
          outline: 'none',
        }}
        _hover={{
          backgroundColor: 'neutral.100',
          cursor: 'pointer',
          '&>.media__mediaHover': { opacity: 1 },
          ...props._hover,
        }}
        onClick={onContainerClick}
        {...props}
      >
        {children}
      </Box>
    </MediaContext.Provider>
  );
}

function MediaBody({ children, ...props }: BoxProps) {
  return <Box {...props}>{children}</Box>;
}

function MediaTitle({
  children,
  as = 'h3',
}: Pick<MediaType, 'children' | 'as'>) {
  const { size } = React.useContext(MediaContext);
  const mb: MediaConfig = {
    option: 0,
    large: 0,
    xlarge: 1,
    '2xlarge': 2,
  };

  return (
    <Box mb={mb[size]} noOfLines={2}>
      <Text
        noOfLines={2}
        lineHeight='shorter'
        as={as}
        data-testid={`media-title-${
          children && typeof children !== 'object' ? children : ''
        }`}
      >
        {children}
      </Text>
    </Box>
  );
}

function MediaDescription({ children }: Pick<MediaType, 'children'>) {
  return (
    <Box
      as='p'
      color={'chakra-subtle-text'}
      fontSize={'sm'}
      noOfLines={2}
      wordBreak='break-word'
    >
      {children}
    </Box>
  );
}

function MediaFigure({
  icon,
  image,
  color = 'neutral',
  logoColor,
  ...props
}: MediaFigureProps) {
  const { size } = React.useContext(MediaContext);
  const boxSize: MediaConfig = {
    option: '6',
    large: 'icon.lg',
    xlarge: 'icon.xl',
    '2xlarge': 'icon.2xl',
  };

  // TODO: this ovverides the gray that we get from singular
  const transformedColor = color === 'gray' ? 'neutral' : color;
  const stylesForBoxOption = transformedColor &&
    size !== 'option' && {
      borderRadius: 'md',
      background: `${transformedColor}.100`,
    };
  const stylesForGrayImages = logoColor === 'gray' && {
    filter: 'grayscale(1)',
    boxSize: '6',
  };
  const stylesForColorImages = logoColor !== 'gray' &&
    size === 'large' && {
      padding: '1.5',
    };

  return (
    <Box mr={4} {...props}>
      <Box role='figure' alignItems='center' justifyContent='center' {...props}>
        {props.children ? (
          props.children
        ) : (
          <>
            {icon?.path && !image?.url && (
              <PortalIcon
                layerStyle='icon.subtle'
                path={icon.path}
                boxSize={boxSize[size]}
                color={transformedColor}
              />
            )}
            {image?.url && (
              <Box
                boxSize={boxSize[size]}
                display='flex'
                alignItems='center'
                justifyContent='center'
                {...stylesForBoxOption}
              >
                <Image
                  src={image.url}
                  boxSize={boxSize[size]}
                  alt={image.alt}
                  {...stylesForColorImages}
                  {...stylesForGrayImages}
                />
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
}

export function MediaHover({ icon }: MediaIconProps) {
  const { size, variant } = React.useContext(MediaContext);

  const padding: MediaConfig = {
    option: 0,
    large: 0,
    xlarge: 2,
    '2xlarge': 2,
  };

  if (variant !== 'link') return null;

  return (
    <Box
      ml={'auto'}
      pl={4}
      data-testid={'mediaHover'}
      className={'media__mediaHover'}
      opacity={0}
      style={{
        transition: 'opacity .225s ease-out',
      }}
    >
      <Box
        role='button'
        borderRadius={'base'}
        alignItems={'center'}
        justifyContent={'center'}
        padding={padding[size]}
        color={'chakra-placeholder-color'}
      >
        <PortalIcon path={icon?.path} boxSize='icon.lg' />
      </Box>
    </Box>
  );
}

export { Media, MediaFigure, MediaBody, MediaDescription, MediaTitle };
