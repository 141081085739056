import React from 'react';
import { Heading, VStack, Box } from '@chakra-ui/react';

import OrganizationAccess from './OrganizationAccess';
import AppAccess from './AppAccess';

function OrgAppAccess() {
  return (
    <VStack spacing='10' align='start'>
      <Box flex={1} width={'100%'}>
        <Heading size='md' mb={4}>
          Organization access
        </Heading>
        <Box pl={4}>
          <OrganizationAccess />
        </Box>
      </Box>
      <Box flex={1} width={'100%'}>
        <Heading size='md' mb={4}>
          App access
        </Heading>
        <AppAccess />
      </Box>
    </VStack>
  );
}

export default OrgAppAccess;
