/* eslint-disable no-console */
import React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  ButtonGroup,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Text,
  Button,
  useToast,
} from '@chakra-ui/react';
import { T } from '@transifex/react';
import { t } from '@transifex/native';
import { RouteComponentProps, useHistory, useParams } from 'react-router';
import { useRevokeMembershipMutation } from 'gql/graphql';
import { GET_MEMBERS } from '../members-queries';
import { removedMemberIdVar } from 'lib/@apollo/client/ApolloProvider';

export interface IMemberRemoveProps
  extends Partial<RouteComponentProps<RouteParamProps>> {
  isOpen: boolean;
  userId?: string;
  name?: string;
  onClose?: () => void;
  onCompleted?: () => void;
  page?: string;
}

interface RouteParamProps {
  id: string;
  page: string;
}

export const MemberRemoveConfirmation = ({
  isOpen,
  userId,
  name,
  onClose,
  onCompleted = onClose,
  match,
}: IMemberRemoveProps) => {
  const [revokeMembershipMutation, { loading: isLoading }] =
    useRevokeMembershipMutation();
  const toast = useToast();
  const lessDestructiveRef = React.useRef(null);
  const { goBack, location } = useHistory();
  const searchParams = new URLSearchParams(location.search);

  const { page } = useParams<{ page: string }>();

  const simpleQueryPagingConfig = {
    page: parseInt(page ?? 1),
    pageSize: 10,
  };

  const id = userId ?? match?.params.id;

  const onError = (error: Error) => {
    toast({
      description: error?.message,
      title: t('Error'),
      status: 'error',
    });
  };

  const onSuccess = () => {
    toast({
      status: 'success',
      description: t('Member removed successfully'),
    });
  };

  const onDeleteMembers = async () => {
    try {
      const { data } = await revokeMembershipMutation({
        variables: {
          input: { members: [id || ''] },
        },
        refetchQueries: [
          {
            query: GET_MEMBERS,
            variables: {
              skip:
                (simpleQueryPagingConfig.page - 1) *
                simpleQueryPagingConfig.pageSize,
              take: simpleQueryPagingConfig.pageSize,
            },
          },
        ],

        update: (cache, result) => {
          if (result.data?.revokeMembership) {
            const organizationId = searchParams.get('organization');

            cache.modify({
              id: cache.identify({
                __typename: 'Organization',
                id: organizationId,
              }),
              fields: {
                members(existingMembers = { items: [] }) {
                  if (
                    result.data?.revokeMembership &&
                    result.data.revokeMembership.members &&
                    result.data.revokeMembership.members.length > 0
                  ) {
                    const newMembers = existingMembers.items.filter(
                      (memberRef: { __ref: string | undefined }) => {
                        const memberId = `OrganizationMember:${
                          result.data?.revokeMembership?.members?.[0] ?? ''
                        }`;

                        removedMemberIdVar(memberId);

                        return memberRef.__ref !== memberId;
                      },
                    );

                    return {
                      ...existingMembers,
                      items: newMembers,
                      totalCount: existingMembers.totalCount - 1,
                    };
                  }

                  // If no membership was revoked, return existingMembers without modifying it
                  return existingMembers;
                },
              },
            });
          }
        },
        onCompleted: onCompleted ?? goBack,
      });

      if (!data) {
        return;
      }

      const errors = data?.revokeMembership?.errors ?? [];
      if (errors.length) {
        return errors.map((error) => onError(error as Error));
      }

      onSuccess();
    } catch (error: any) {
      const { networkError } = error ?? {};

      if (networkError) {
        toast({
          description: networkError.message,
          title: `Error ${networkError.statusCode}`,
          status: 'error',
        });
      }
    }
  };

  if (!id) return null;

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose ?? goBack}
      leastDestructiveRef={lessDestructiveRef}
      data-testid='remove-member-modal'
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>
            <T _str='Remove access' />
          </AlertDialogHeader>
          <AlertDialogBody>
            <Text>
              <T
                _str='You are about to remove {FirstName} from the organization. This action cannot be undone.'
                FirstName={name ?? 'the member'}
              />
            </Text>
          </AlertDialogBody>
          <AlertDialogFooter>
            <ButtonGroup>
              <Button
                variant='ghost'
                onClick={onClose ?? goBack}
                isDisabled={isLoading}
                ref={lessDestructiveRef}
                data-testid='removeModal-cancelButton'
              >
                <T _str='Cancel' />
              </Button>
              <Button
                colorScheme='danger'
                variant='solid'
                onClick={onDeleteMembers}
                isLoading={isLoading}
                data-testid='removeModal-removeButton'
              >
                <T _str='Remove' />
              </Button>
            </ButtonGroup>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
