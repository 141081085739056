import { useAuthQuery } from 'features/common/apiUtils/queryHelper';
import {
  xmCloudApi,
  getXMCloudDeployBaseUrl,
  XMCloudOrganizationContext,
} from '@sitecore-ui/portal-singular';
import { env } from 'features/common/config/envConfig';
import { AxiosResponse } from 'axios';
import { UseQueryOptions } from 'react-query';

/**
 * @description Get XM Cloud organization
 * Returns the organization in context of the current authorization (Auth policies: ValidateOrganization, xmclouddeploy.deployments:manage, xmclouddeploy.environments:manage, xmclouddeploy.projects:manage)
 */
export function useGetXMCloudOrganization(
  queryOptions?:
    | Omit<
        UseQueryOptions<
          AxiosResponse<XMCloudOrganizationContext>,
          unknown,
          AxiosResponse<XMCloudOrganizationContext>,
          string[]
        >,
        'queryKey' | 'queryFn'
      >
    | undefined,
) {
  return useAuthQuery(
    ['xmCloudOrganization'],
    async (axiosInstance) => {
      return axiosInstance?.get<XMCloudOrganizationContext>(
        `${getXMCloudDeployBaseUrl(env)}${xmCloudApi.get_organization.url}`,
      );
    },
    { ...queryOptions },
    xmCloudApi.get_organization.scope,
  );
}
