import { useGetTenantsQuery, useGetUserOrgDepsQuery } from 'gql/graphql';
import { useAuth0TokenClaims } from '../auth/claims';
import { useEffect, useMemo } from 'react';
import { env as envConfig } from 'features/common/config/envConfig';
import { setEnvironmentData, useMainDispatch } from '../../main/context';
import {
  OrgEnvironment,
  TokenCustomClaimKeysEnum,
} from '@sitecore-ui/portal-singular';
import { useGetXMCloudOrganization } from 'features/common/services/xmCloud/xmCloud';
import { adaptGraphQLDataToUserEnvironmentObject } from 'features/common/utils/dataAdapters';

export function useOrgEnvironmentDeps() {
  const dispatch = useMainDispatch();
  const { claims } = useAuth0TokenClaims();

  const { data: tenantsQueryData, loading: areTenantsLoading } =
    useGetTenantsQuery({
      variables: {
        first: 100,
        env: envConfig,
      },
    });

  const roles = (claims && claims[TokenCustomClaimKeysEnum.ROLES]) || [];
  // XM Cloud
  const { isSuccess: isXMCloudExist, isLoading: isXMCloudExistLoading } =
    useGetXMCloudOrganization({
      enabled: checkIfOrgAdmin(roles),
    });

  const { data: userOrgDepsData, loading: areOrgDepsLoading } =
    useGetUserOrgDepsQuery() || {};

  const transformedData = useMemo(
    () =>
      adaptGraphQLDataToUserEnvironmentObject({
        //@ts-ignore
        tenantsQueryData,
        //@ts-ignore
        userOrgDepsData,
        claims,
        isXMCloudExist,
      }),
    [claims, isXMCloudExist, tenantsQueryData, userOrgDepsData],
  );

  const isLoading = useMemo(
    () => areOrgDepsLoading || areTenantsLoading || isXMCloudExistLoading,
    [areOrgDepsLoading, areTenantsLoading, isXMCloudExistLoading],
  );
  const env = useMemo(() => {
    const { values } = new OrgEnvironment({
      userOrgData: transformedData.userOrgData || [],
      userRoleData: transformedData.userRoleData || [],
      tenantsData: transformedData.tenantsData || [],
      xmCloudContext: isXMCloudExist,
    });

    return {
      ...values(),
      isLoading,
    };
  }, [
    isXMCloudExist,
    transformedData.tenantsData,
    transformedData.userOrgData,
    transformedData.userRoleData,
    isLoading,
  ]);

  useEffect(() => {
    dispatch(setEnvironmentData(env || null));
  }, [env, dispatch]);

  const { tenantsData } = transformedData;

  // We make tenants and tenantsLoading public due to UX requirements
  return {
    claims,
    tenantsData,
    organization: userOrgDepsData?.organization,
    isLoading,
  };
}

/**
 * Check if user is Organization Admin or Organization Owner, before calling XM Cloud API
 * @param roles
 */
const checkIfOrgAdmin = (roles: string[]) => {
  const organizationRoles = roles.filter((role) =>
    role.includes('[Organization]'),
  );

  const isOrganizationOwner = organizationRoles.includes(
    '[Organization]\\Organization Owner',
  );
  const isOrganizationAdmin = organizationRoles.includes(
    '[Organization]\\Organization Admin',
  );

  return isOrganizationOwner || isOrganizationAdmin;
};
