import { MainState, MainAction } from './model';
import * as actions from './actions';
import { initialDrawerManagerState } from './drawer/reducers';

const initialMainState: MainState = {
  main: {
    drawerVisible: false,
    helpDrawerVisible: false,
    tenant: null,
    tenantId: null,
    app: null,
    env: null,
  },
  drawer: {
    ...initialDrawerManagerState,
  },
};

function mainReducer(state: MainState, action: MainAction): MainState {
  switch (action.type) {
    case actions.TOGGLE_DRAWER:
      return {
        ...state,
        main: {
          ...state.main,
          drawerVisible: !state.main.drawerVisible,
          tenant: action.payload.tenant,
        },
      };

    case actions.SELECT_APP:
      return {
        ...state,
        main: {
          ...state.main,
          app: action.payload.app,
        },
      };

    case actions.SET_ENV_DATA:
      return {
        ...state,
        main: {
          ...state.main,
          env: action.payload,
        },
      };

    case actions.TOGGLE_HELP_DRAWER:
      return {
        ...state,
        main: {
          ...state.main,
          helpDrawerVisible: action.payload.helpDrawerVisible,
        },
      };

    default:
      return state;
  }
}

export { mainReducer, initialMainState };
