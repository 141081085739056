import { ApmRoute } from '@elastic/apm-rum-react';

import * as React from 'react';
import { Redirect, match } from 'react-router';
import { useFeatures } from 'lib/featureFlags/features';

interface Props {
  component: React.ComponentType<any>;
  path: string;
  exact?: boolean;
  match?: match<{ [p: string]: string | undefined }>;
}
export const SSORoute = ({ component: Component, ...rest }: Props) => {
  const { SAML: isSamlEnabled, OPENID: isOpenIdEnabled } = useFeatures();
  return (
    <ApmRoute
      {...rest}
      render={({ match, ...restProps }) => {
        const { ssoProvider } = match.params;
        const shouldRedirect =
          (ssoProvider === 'samlp' && !isSamlEnabled) ||
          (ssoProvider === 'oidc' && !isOpenIdEnabled);

        return shouldRedirect ? (
          <div>
            <Redirect to='/no-access' />
          </div>
        ) : (
          <Component match={match} {...restProps} />
        );
      }}
    />
  );
};

export const ssoRoute = '/admin/sso';
export const ssoCreateRoute = `${ssoRoute}/:ssoProvider/create`;
export const ssoEditRoute = `${ssoRoute}/:ssoProvider/:id/edit`;
export const ssoTestRoute = `${ssoRoute}/:ssoProvider/:id/test-connection`;
export const ssoRestartTestRoute = `${ssoRoute}/:ssoProvider/:id/restart-test-connection`;
export const ssoVerifyRoute = `${ssoRoute}/:ssoProvider/:id/verify-connection`;
export const ssoEnableRoute = `${ssoRoute}/:ssoProvider/:id/enable-connection`;
export const ssoDeleteRoute = `${ssoRoute}/:ssoProvider/:id/delete-connection`;
