import { Image, VStack, Text, Tag } from '@chakra-ui/react';
import { tenantsFallbackIcon } from 'features/common/utils/icons';
import { PortalIcon } from 'features/common/components/PortalIcon';
import TruncatedText from 'features/common/components/TruncatedText';

interface IAppTileProps {
  appName: string;
  tenantName: string;
  appLogo?: string;
  onClick: () => void;
  color?: string;
  'data-testid'?: string;
  image?: string;
  ['data-behavior-analytics-id']?: string;
  customerEnvironmentType?: string;
}

export const AppTile = ({
  appName,
  tenantName,
  appLogo,
  onClick,
  color = 'neutral',
  'data-testid': dataTestId,
  image,
  'data-behavior-analytics-id': dataBehaviorAnalyticsId,
  customerEnvironmentType,
}: IAppTileProps) => (
  <VStack
    p='4'
    onClick={onClick}
    cursor='pointer'
    _hover={{ backgroundColor: 'neutral.100' }}
    _active={{ backgroundColor: 'neutral.200' }}
    data-behavior-analytics-id={dataBehaviorAnalyticsId}
    data-testid={dataTestId}
    data-appname={tenantName}
    borderRadius='md'
    transitionProperty='common'
    transitionDuration='normal'
  >
    {image ? (
      <Image boxSize='10' src={image} />
    ) : (
      <PortalIcon
        data-testid='app-tile-default-icon'
        boxSize='icon.6xl'
        m='2'
        path={appLogo || tenantsFallbackIcon}
        color={`${color}.600`}
      />
    )}
    <VStack spacing='0'>
      <Text
        fontWeight='medium'
        textAlign='center'
        w='full'
        noOfLines={1}
        wordBreak='break-all'
      >
        {appName}
      </Text>
      <TruncatedText text={tenantName} />
    </VStack>
    {customerEnvironmentType && (
      <Tag colorScheme='neutral' size='sm'>
        {customerEnvironmentType}
      </Tag>
    )}
  </VStack>
);
