/* eslint-disable no-console */
import { env } from 'features/common/config/envConfig';
import {
  getStoredGuestId,
  storeGuestId,
} from 'lib/personalize/personalize-storage';

async function fetchBrowserId() {
  const browserId = await window.engage.getBrowserId();
  return browserId;
}

async function fetchCurrentGuestId() {
  const currentGuestId = await window.engage.getGuestId();
  return currentGuestId;
}

function isGuestIdChanged(storedGuestId: unknown, currentGuestId: any) {
  return currentGuestId !== storedGuestId;
}

async function dispatchIdentityEvent(
  event: typeof identityEvent & {
    page: string;
    browser_id: string;
    identifiers: { provider: string; id: string }[];
  },
) {
  const identityEventResponse = await window.engage.identity(event);

  return identityEventResponse;
}

const isNonProdEnvironment = ['dev', 'qa', 'staging'].includes(
  env.toLowerCase(),
);

function handleIdentityEventResponse(identityEventResponse: {
  status: string;
}) {
  if (identityEventResponse.status === 'OK') {
    isNonProdEnvironment &&
      console.log('Identity event dispatched successfully.');
  } else {
    isNonProdEnvironment && console.error('Failed to dispatch identity event.');
  }
}

const identityEvent = {
  channel: 'WEB',
  type: 'IDENTITY',
  language: 'EN',
  currency: 'EUR',
  pos: 'default',
} as const;

let hasRun = false;

async function handleGuestIdVerificationAndIdentityDispatch({
  page,
}: {
  page: string;
}) {
  if (hasRun) return;
  hasRun = true;

  const storedGuestId = getStoredGuestId();
  const currentGuestId = await fetchCurrentGuestId();
  const browserId = await fetchBrowserId();

  if (isGuestIdChanged(storedGuestId, currentGuestId)) {
    const identityEventResponse = await dispatchIdentityEvent({
      ...identityEvent,
      page,
      browser_id: browserId,
      identifiers: [{ provider: 'userId', id: currentGuestId }],
    });
    storeGuestId(currentGuestId);
    handleIdentityEventResponse(identityEventResponse);
  } else {
    isNonProdEnvironment &&
      console.log(
        'Guest ID matches the stored value. No need to dispatch identity event.',
      );
  }
}

export { handleGuestIdVerificationAndIdentityDispatch };
