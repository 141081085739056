/* istanbul ignore file */
import { T } from '@transifex/react';
import { MediaObjectLink } from 'features/common/components/MediaObjectLink';
import { Box, Heading, VStack } from '@chakra-ui/react';
import useGetGeneralActionsContentFromSchema from 'features/domains/portal/content/generalActions/useGetGeneralActions';
import { useSupportCase } from 'features/snow/hooks/useSupportCase';
import { useMainState } from 'features/main/context';

const QuickLinksPanelNoOrg: React.FC = () => {
  const state = useMainState();
  const { generalQuickActions } = useGetGeneralActionsContentFromSchema();

  useSupportCase({
    componentName: 'create-support-case-drawer',
    buttonSelector: '[data-support-case="create-support-case-drawer"]',
  });

  return (
    <Box
      w='full'
      data-testid='quick-links-panel'
      data-behavior-analytics-feature='quick-links - home page'
    >
      <Heading variant='section' pb='4'>
        <T _str='Quick Links' />
      </Heading>
      <VStack>
        {generalQuickActions.slice(0, 6).map((quickAction) => (
          <MediaObjectLink
            {...quickAction}
            onClick={() => {
              if (quickAction.title == 'Create support case') {
                return () => void 0;
              }
              return quickAction.action();
            }}
            title={quickAction.title}
            link={quickAction.link}
            key={quickAction.title}
            data-testid={`help-item-${quickAction.title}`}
            data-behavior-analytics-id={quickAction['analytics-id']}
            iconPath={quickAction.iconPath}
            iconColor={quickAction.iconColor}
            {...(quickAction.title === 'Create support case'
              ? { 'data-support-case': 'create-support-case-drawer' }
              : {})}
          />
        ))}
      </VStack>
      {state.env?.ORG_ADMIN_OR_OWNER && <create-support-case-drawer />}
    </Box>
  );
};

export default QuickLinksPanelNoOrg;
