import {
  APP_ROLES,
  ORG_ROLES,
} from 'features/common/components/OrganizationAppAccess/resource';
import { IGetRoleItem } from 'features/members/services/identity/rolesModel';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import mapValues from 'lodash/mapValues';
import { ITenantSelectOption } from 'features/members/components/table/utils/memberDetailsModel';

class NoAppRolesError extends Error {
  name = 'NoAppRolesError';
  message: string;

  constructor(message: string) {
    super();
    this.message = message;
  }
}

/**
 * Gets a list of role and a product code and returns a list of access options
 * @param roles
 * @param productCode
 */
const getAccessOptions = (
  roles: IGetRoleItem[] | undefined,
  productCode: string,
): ITenantSelectOption[] => {
  try {
    if (!roles || roles.length === 0) return [];

    const sorter = (role: IGetRoleItem) =>
      role.name === APP_ROLES.USER || role.name === ORG_ROLES.ORG_USER;

    // Groups per scope and sorts the roles with user first and then the rest
    const orderedOptionList = mapValues(groupBy(roles, 'scope'), (r) =>
      orderBy(r, [sorter], ['desc']),
    );

    if (!orderedOptionList[productCode]) {
      throw new NoAppRolesError(`${productCode} is not included in roles list`);
    }

    const accessOptions =
      orderedOptionList && productCode
        ? orderedOptionList[productCode].map((r) => ({
            value: r.name,
            label: r.name,
          }))
        : [];

    return accessOptions;
  } catch (e) {
    // Case found: product code does not match any role scope due to premature onboarding with identity
    console.error(e);
    return [];
  }
};

export default getAccessOptions;
