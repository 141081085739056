import { memo } from 'react';
import {
  Flex,
  Input,
  InputGroup,
  Link,
  Text,
  InputRightElement,
  Stack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormErrorIcon,
} from '@chakra-ui/react';
import { T } from '@transifex/react';
import { Verification } from '../api';
import { CopyClipboardIconTooltip } from 'features/common/components/CopyClipboardIconTooltip';

const VerifyConnectionSteps = ({
  verified,
  verification,
}: {
  verified: boolean;
  verification?: Verification;
}) => {
  const txtMethods =
    verification?.methods.filter(({ name }) => name.toLowerCase() === 'txt') ??
    [];

  return verified ? (
    <Text data-testid='verifyConnectionModal-alreadyVerified'>
      <T _str='Now you can test or enable your connection.' />
    </Text>
  ) : (
    <Flex flexDirection='column' gap={6}>
      <Text data-testid='verifyConnectionModal-noVerified'>
        Copy the following TXT records and paste them in to{' '}
        <Link
          href='https://doc.sitecore.com/portal/en/developers/sitecore-cloud-portal/introduction-to-the-sitecore-cloud-portal.html?contextId=verify-domain-openid'
          target='_blank'
        >
          your DNS records
        </Link>
        , then return to this page and click Verify domains.
      </Text>
      <Stack spacing='6'>
        {txtMethods.map(({ domain, record, error }) => (
          <FormControl key={`${domain}_${record}`} isInvalid={!!error}>
            <FormLabel>{`TXT record for ${domain}`}</FormLabel>
            <InputGroup>
              <Input isReadOnly value={record} />
              <InputRightElement>
                <CopyClipboardIconTooltip
                  inputValue={record}
                  dataTestId={`verifyConnectionModal-${domain}-recordValue`}
                />
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage data-testid='domain-txt-record-error-message'>
              <FormErrorIcon />
              The domain host does not contain this TXT record
            </FormErrorMessage>
          </FormControl>
        ))}
      </Stack>
    </Flex>
  );
};

export default memo(VerifyConnectionSteps);
