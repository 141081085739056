import { hostnames } from '../common/config/envConfig';

export function getContextApiUrl() {
  const contextApiBaseUrl = {
    [hostnames.localhost]:
      'https://edge-platform-context-api-staging.sitecore-staging.cloud/api/context/user/v1',
    [hostnames.internal]:
      'https://edge-platform-context-api-staging.sitecore-staging.cloud/api/context/user/v1',
    [hostnames.dev]:
      'https://edge-platform-context-api-staging.sitecore-staging.cloud/api/context/user/v1',
    [hostnames.qa]:
      'https://edge-platform-context-api-staging.sitecore-staging.cloud/api/context/user/v1',
    [hostnames.staging]:
      'https://edge-platform-context-api-staging.sitecore-staging.cloud/api/context/user/v1',
    [hostnames.beta]:
      'https://edge-platform-context-api-beta.sitecorecloud.io/api/context/user/v1',
    [hostnames['pre-production']]:
      'https://edge-platform-context-api-beta.sitecorecloud.io/api/context/user/v1',
    [hostnames.production]:
      'https://edge-platform-context-api.sitecorecloud.io/api/context/user/v1',
  }[window.location.hostname];

  return contextApiBaseUrl || '';
}

export const PRODUCT_CODES = {
  XM_CLOUD: 'xmcloud',
  EDGE: 'edge',
  CDP: 'cdp',
  PERSONALIZE: 'personalize',
  PERSONALIZE_EMBEDDED: 'personalizeembedded',
  SEARCH: 'search',
};

export const CUSTOMER_ENV_TYPES = {
  PROD: 'prod',
  NON_PROD: 'nonprod',
};

export const EDGE_ENDPOINT_TYPES = {
  LIVE: 'Live',
  PREVIEW: 'Preview',
};
