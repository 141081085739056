import { ApmRoute } from '@elastic/apm-rum-react';
import { PageNotFound } from 'features/main/components/CustomErrorPages';
import { Switch } from 'react-router';
import { CustomHostnames } from './CustomHostnames';
import {
  customHostnamesRoute,
  customHostnamesCreateRoute,
  customHostnamesDeleteRoute,
  customHostnamesUpdateRoute,
  customHostnamesRecordsRoute,
} from './routes';
import { HostnameCreationModal } from './HostnameCreationModal';
import { HostnameDeletionModal } from './HostnameDeletionModal';
import { HostnameUpdateModal } from './HostnameUpdateModal';
import { HostnameRecordsModal } from './HostnameRecordsModal';

export const CustomHostnamesContainer = () => {
  return (
    <Switch>
      <ApmRoute
        path={customHostnamesRoute}
        render={() => (
          <>
            <CustomHostnames />
            <ApmRoute
              exact
              path={customHostnamesCreateRoute}
              component={HostnameCreationModal}
            />
            <ApmRoute
              exact
              path={customHostnamesDeleteRoute}
              component={HostnameDeletionModal}
            />
            <ApmRoute
              exact
              path={customHostnamesUpdateRoute}
              component={HostnameUpdateModal}
            />
            <ApmRoute
              exact
              path={customHostnamesRecordsRoute}
              component={HostnameRecordsModal}
            />
          </>
        )}
      />
      <ApmRoute component={PageNotFound} />
    </Switch>
  );
};
