import { useQueryClient } from 'react-query';
import {
  useAuthMutation,
  useAuthQuery,
} from '../../../common/apiUtils/queryHelper';
import {
  IGetOrganizationResponse,
  IUpdateOrganization,
} from './organizationModel';
import { identity as identityConfig } from '@sitecore-ui/portal-singular';
import { env } from 'features/common/config/envConfig';

const identity = identityConfig(env);

export const useGetCurrentOrg = () => {
  return useAuthQuery(
    'organization',
    async (axiosInstance) => {
      return axiosInstance.get<IGetOrganizationResponse>(identity.get_org.url);
    },
    {},
    identity.get_org.scope,
  );
};

export const useUpdateOrganizationMutation = () => {
  const queryClient = useQueryClient();

  return useAuthMutation(
    (axiosInstance) => (payload: IUpdateOrganization) => {
      return axiosInstance.patch(identity.patch_org.url, payload);
    },
    {
      onSuccess: (data, variables) => {
        variables.successCb && variables.successCb();
        queryClient.invalidateQueries('organization');
      },
      onError: (data, variables) => {
        variables.errorCb && variables.errorCb();
        // queryClient.invalidateQueries('organization');
      },
    },
  );
};
