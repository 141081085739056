import React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  ButtonGroup,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Text,
  Button,
} from '@chakra-ui/react';

const MappingRemovalConfirmationModal = ({
  isOpen,
  onClose,
  onRemove,
}: {
  isOpen: boolean;
  onClose: () => void;
  onRemove: () => void;
}) => {
  const lessDestructiveRef = React.useRef(null);

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
      leastDestructiveRef={lessDestructiveRef}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>Remove mapping</AlertDialogHeader>
          <AlertDialogBody>
            <Text>
              This action will remove this mapping along with all of the source
              and target claims.
            </Text>
          </AlertDialogBody>
          <AlertDialogFooter>
            <ButtonGroup>
              <Button
                ref={lessDestructiveRef}
                variant='ghost'
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button colorScheme='danger' variant='solid' onClick={onRemove}>
                Remove
              </Button>
            </ButtonGroup>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default MappingRemovalConfirmationModal;
