import { MARKETPLACE_KEYS } from 'features/marketplace/config/query-keys';
import { Application } from 'features/marketplace/shared/types';
import { useAuthQuery } from '../../../core/auth/hooks/useAuthQuery';
import { marketplaceApi } from '../api/endpoints';

export const useGetApplication = (appId: string) => {
  const {
    get_application: { method, url },
  } = marketplaceApi;
  return useAuthQuery<Application>({
    queryKey: MARKETPLACE_KEYS.apps.details(appId),
    url: url(appId),
    method,
    // onError: () => console.log('err'),
  });
};
//TODO pass queryOptions
