/* istanbul ignore file */
import { QuickAction } from '@sitecore-ui/portal-singular';
import { useAppAccess } from 'features/common/components/OrganizationAppAccess';
import { env } from 'features/common/config/envConfig';
import { useMainStateDispatch } from 'features/main/context';
import { openDrawer } from 'features/main/context/drawer/actionCreators';
import { DrawerId } from 'features/main/context/drawer/model';
import { useGetActiveProducts } from 'lib/portal-singular';
import { useCallback, useMemo } from 'react';
import { helpLinksMainSchemas, helpLinksSecondarySchemas } from '.';
import { quickActionsMapper } from '../utils';
import { useFeatures } from 'lib/featureFlags/features';

/**
 * This function is used to check if the feature flag is enabled or not.
 * If the feature flag is not enabled, the quick action will be disabled.
 * This is done to avoid the user to click on the quick action while it is not enabled.
 * @param json
 * @param features
 */
function isFeatureFlagEnabled(
  json: { link: { to: string } },
  features: { SNOW: any },
) {
  const featureFlagMapping = {
    SNOW: 'create-ticket-drawer',
  };
  // TODO: we should probably add a feature flag id to the quick action schema for better azure feature flag mapping,
  //  but for now we can use the drawer id and the feature flag id to be the same
  // SNOW quick action condition
  if (json.link.to === featureFlagMapping.SNOW) {
    return features.SNOW;
  }

  return true;
}

const useGetHelpContentFromSchema = () => {
  const features = useFeatures();
  const [, dispatch] = useMainStateDispatch();

  const onOpenDrawer = useCallback(
    (drawerId: DrawerId) => dispatch(openDrawer(drawerId)),
    [dispatch],
  );
  const { userRoles } = useAppAccess();
  const { activeProducts } = useGetActiveProducts();

  const helpMainQuickActions = useMemo(
    () =>
      helpLinksMainSchemas
        .map(
          (json) =>
            new QuickAction(
              {
                jsonData: json,
                hostCta: onOpenDrawer,
                userRoles,
                activeProducts,
                env,
              },
              isFeatureFlagEnabled(json, features),
            ),
        )
        .filter(({ isDisabled }) => !isDisabled)
        .map(quickActionsMapper),
    [onOpenDrawer, userRoles, activeProducts, features],
  );

  const helpSecondaryQuickActions = useMemo(
    () =>
      helpLinksSecondarySchemas
        .map(
          (json) =>
            new QuickAction(
              {
                jsonData: json,
                hostCta: onOpenDrawer,
                userRoles,
                activeProducts,
                env,
              },
              isFeatureFlagEnabled(json, features),
            ),
        )
        .filter(({ isDisabled }) => !isDisabled)
        .map(quickActionsMapper),
    [onOpenDrawer, userRoles, activeProducts, features],
  );

  return { helpMainQuickActions, helpSecondaryQuickActions };
};

export { useGetHelpContentFromSchema };
