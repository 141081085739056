import React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  ButtonGroup,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Text,
  Button,
  useToast,
} from '@chakra-ui/react';
import { T } from '@transifex/react';
import { IMFAModalProps } from './types';
import { useUpdateOrganizationMutation } from 'features/home/services/identity/organization';
import { t } from '@transifex/native';

const MFAActivationModal = (props: IMFAModalProps) => {
  const toast = useToast();
  const lessDestructiveRef = React.useRef(null);

  const { mutate: updateOrganizationMutation, isLoading } =
    useUpdateOrganizationMutation();

  const activateMFA = () => {
    updateOrganizationMutation({
      mfa: { required: true },
      successCb() {
        props.onClose();
        toast({
          description: t('MFA enabled for all users'),
          status: 'success',
        });
      },
      errorCb() {
        props.onClose();
        toast({
          title: t('Something went wrong'),
          description: t('Try again'),
          status: 'error',
        });
      },
    });
  };

  return (
    <AlertDialog {...props} leastDestructiveRef={lessDestructiveRef}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader data-testid='MFAActivationModal-header'>
            <T _str='Multi-factor authentication' />
          </AlertDialogHeader>
          <AlertDialogBody>
            <Text>
              Are you sure you want to enable MFA&nbsp;
              <strong>for all users in your organization?</strong>
            </Text>
          </AlertDialogBody>
          <AlertDialogFooter>
            <ButtonGroup>
              <Button
                variant='ghost'
                onClick={props.onClose}
                isDisabled={isLoading}
                data-testid='cancel-button'
              >
                <T _str='Cancel' />
              </Button>
              <Button
                variant='solid'
                colorScheme='success'
                onClick={activateMFA}
                isLoading={isLoading}
                ref={lessDestructiveRef}
                data-testid='activate-button'
              >
                Enable MFA
              </Button>
            </ButtonGroup>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default MFAActivationModal;
