import { UseToastOptions } from '@chakra-ui/react';
import { History } from 'history';
import { QueryClient } from 'react-query';
import { ssoRoute } from '../sso-routes';

export const handleSuccess = (
  history: History,
  toast: (options: UseToastOptions) => void,
  queryClient: QueryClient,
) => {
  history.replace({
    pathname: ssoRoute,
    search: history.location.search,
  });
  toast({
    description: 'Connection updated',
    title: 'Success',
    status: 'success',
  });
  queryClient.removeQueries('claims-mappings');
};

export const handleCreateSuccess = (
  id: string,
  history: History,
  toast: (options: UseToastOptions) => void,
  queryClient: QueryClient,
) => {
  queryClient.invalidateQueries('connections/list');
  history.replace({
    pathname: `${ssoRoute}/oidc/${id}/verify-connection`,
    search: history.location.search,
  });
  toast({
    description: 'Connection saved',
    title: 'Success',
    status: 'success',
  });
  queryClient.removeQueries('claims-mappings');
};

export const handleUpdateSuccess = (
  history: History,
  toast: (options: UseToastOptions) => void,
  queryClient: QueryClient,
) => {
  history.replace({
    pathname: ssoRoute,
    search: history.location.search,
  });
  toast({
    description: 'Connection updated',
    title: 'Success',
    status: 'success',
  });
  queryClient.removeQueries('claims-mappings');
};

export const handleError = (
  error: any,
  toast: (options: UseToastOptions) => void,
) => {
  toast({
    description: 'An error occurred while saving the connection',
    status: 'error',
  });
  console.error('Connection save error:', error);
};
