import {
  Breadcrumb,
  BreadcrumbItem,
  Divider,
  Flex,
  Skeleton,
} from '@chakra-ui/react';
import { HeaderSkeleton, BodySkeleton, SidebarSkeleton } from './components';

export const AppDetailsSkeleton = () => (
  <Flex flexDir='column' mx={40} mt={6} gap={8}>
    <Breadcrumb data-testid='app-details-breadcrumb'>
      <BreadcrumbItem>
        <Skeleton height='20px' width='100px' />
      </BreadcrumbItem>
      <BreadcrumbItem isCurrentPage>
        <Skeleton height='20px' width='100px' />
      </BreadcrumbItem>
    </Breadcrumb>
    <HeaderSkeleton />
    <Divider px={4} />
    <Flex data-testid='app-details-body' gap={16}>
      <BodySkeleton />
      <SidebarSkeleton />
    </Flex>
  </Flex>
);
