import { Badge } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { isDatePassed } from './helpers';

export const BadgeByDates = ({
  expirationDate,
}: {
  expirationDate: string;
}) => {
  const datePassed = isDatePassed(expirationDate);

  // invalid date
  if (datePassed === null) return <T _str='N/A' />;

  const colorScheme = datePassed ? 'warning' : 'neutral';
  const status = datePassed ? <T _str='Expired' /> : <T _str='Pending' />;

  return <Badge colorScheme={colorScheme}>{status}</Badge>;
};
