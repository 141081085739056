import { Box, BoxProps } from '@chakra-ui/react';

export const LayoutWrapper = ({ children, ...props }: BoxProps) => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      w={'100vw'}
      h={'100vh'}
      overflow='hidden'
      {...props}
    >
      {children}
    </Box>
  );
};
