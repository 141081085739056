import { Button, Heading, Flex, ButtonGroup, useToast } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { useMemo, useCallback, ReactNode } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { EmptyTablePlaceholder } from './components/EmptyTablePlaceHolder';
import { mdiAccountPlusOutline } from '@mdi/js';
import { useDrawerStateDispatch } from '../../../main/context';
import { openDrawer } from '../../../main/context/drawer/actionCreators';
import { useQueryErrorResetBoundary } from 'react-query';
import { ErrorBoundary } from 'react-error-boundary';
import { MembersErrorPage } from 'features/main/components/CustomErrorPages/MembersErrorPage';
import styled from '@emotion/styled';
import { Feature } from 'lib/featureFlags/features';
import {
  DataTable,
  DataTableListOptions,
  DataTableSortingRule,
} from 'features/common/components/DataTable';
import { PortalIcon } from 'features/common/components/PortalIcon';
import {
  GetInvitationsDocument,
  Invitation,
  useDeleteInvitationMutation,
  useGetInvitationsQuery,
  useInviteOrganizationMemberMutation,
} from 'gql/graphql';
import { adminPath, invitesPath } from 'features/admin/components/admin-paths';
import { getTableOptions } from './invitationsTableHelpers';

const TableDiv = styled.div`
  table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
`;

export type IInvitationsTableProps = RouteComponentProps<{ page: string }>;

const Header = ({ children }: { children: ReactNode }) => (
  <Flex
    flexDirection={['column', 'column', 'row']}
    justifyContent='space-between'
    marginBottom={12}
    gap={[6, 6, 0]}
  >
    {children}
  </Flex>
);

export const InvitationsTableWithErrorHandling = (
  props: IInvitationsTableProps,
) => {
  const { reset } = useQueryErrorResetBoundary();
  return (
    <ErrorBoundary FallbackComponent={MembersErrorPage} onReset={reset}>
      <InvitationsTable {...props} />
    </ErrorBoundary>
  );
};

const sortBy: DataTableSortingRule[] | undefined = [];

export const InvitationsTable = (props: IInvitationsTableProps) => {
  const history = useHistory();
  const toast = useToast();
  const simpleQueryPagingConfig = {
    page: parseInt(props.match.params.page ?? 1),
    pageSize: 10,
  };

  const { page, pageSize } = simpleQueryPagingConfig;

  const [, drawerDispatch] = useDrawerStateDispatch();

  const { data, loading } = useGetInvitationsQuery({
    variables: {
      skip: pageSize * (page - 1),
      take: pageSize,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-and-network',
  });
  const [inviteOrganizationMemberMutation] =
    useInviteOrganizationMemberMutation();
  const [deleteInviteMutation] = useDeleteInvitationMutation();

  const { items, totalCount } = useMemo(
    () => data?.organization?.invitations || { items: [], totalCount: 0 },
    [data?.organization?.invitations],
  );

  const onSuccess = () =>
    toast({
      description: 'The invitation has been resent.',
      status: 'success',
    });
  const onError = (error: Omit<Error, 'name'>) => {
    toast({
      description: error?.message,
      title: 'Error',
      status: 'error',
    });
  };

  const resendInvite = useCallback(
    async (invitation: Invitation) => {
      const {
        inviteeEmail,
        inviterName,
        organizationRoles,
        applicationRoles,
        id,
      } = invitation;
      const newInvitation = {
        inviteeEmail,
        inviterName,
        applicationRoles: applicationRoles.map(
          ({ role, applicationId, productCode }) => ({
            roleName: role,
            applicationId,
            productCode,
          }),
        ),
        organizationRoles: organizationRoles.map(({ name }) => ({
          roleName: name,
        })),
      };

      try {
        const { data: deleteInvitationResponse } = await deleteInviteMutation({
          variables: {
            invitationId: id,
          },
        });

        const deleteInvitationError =
          deleteInvitationResponse?.deleteInvitation.errors?.[0];
        if (deleteInvitationError) return onError(deleteInvitationError);

        const { data: inviteOrganizationMemberResponse } =
          await inviteOrganizationMemberMutation({
            variables: newInvitation,
            refetchQueries: [GetInvitationsDocument],
          });

        const inviteOrganizationMemberError =
          inviteOrganizationMemberResponse?.inviteOrganizationMember
            .errors?.[0];
        if (inviteOrganizationMemberError)
          return onError(inviteOrganizationMemberError);

        onSuccess();
      } catch (error: any) {
        const { networkError } = error ?? {};

        toast({
          description: networkError.message,
          title: `Error ${networkError.statusCode}`,
          status: 'error',
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deleteInviteMutation, inviteOrganizationMemberMutation],
  );

  const options = useMemo(
    () => getTableOptions({ page, drawerDispatch, resendInvite }),
    [page, drawerDispatch, resendInvite],
  );

  const onChange = useCallback(
    (options: DataTableListOptions) => {
      history.push({
        pathname: `${adminPath}${invitesPath}/page/${options.page}`,
        search: history.location.search,
      });
    },
    [history],
  );

  const pagination = totalCount < pageSize ? 'hidden' : 'complex';

  return (
    <Flex direction='column'>
      <Header>
        <Heading as='h1'>
          <T _str='Invitations' />
        </Heading>
        <ButtonGroup>
          <Feature name='INVITATIONS'>
            <Button
              variant='solid'
              onClick={() => drawerDispatch(openDrawer('invite-drawer'))}
              leftIcon={<PortalIcon path={mdiAccountPlusOutline} />}
              data-testid='create_invite_toggle'
              data-behavior-analytics-id='Invite members button - Invites page'
            >
              <T _str='Invite' />
            </Button>
          </Feature>
        </ButtonGroup>
      </Header>

      {items?.length === 0 && !loading ? (
        <EmptyTablePlaceholder title='No invitations found' />
      ) : (
        <TableDiv>
          <DataTable
            columns={options.columns}
            //@ts-ignore
            rowActions={options.rowActions}
            pagination={pagination}
            totalItems={totalCount}
            data={items ?? []}
            onChange={onChange}
            page={page}
            pageSize={pageSize}
            isLoading={loading}
            sortBy={sortBy}
            theaderProps={{
              'data-behavior-analytics-id': 'invitations sorting',
            }}
            paginationProps={{
              'data-behavior-analytics-feature': ' invitations pagination',
            }}
            data-behavior-analytics-feature='invitations table'
          />
        </TableDiv>
      )}
    </Flex>
  );
};
