import { hostnames } from 'features/common/config/envConfig';

const baseMarketplaceUrl = {
  [hostnames.internal]:
    'https://marketplace-api-staging.sitecore-staging.cloud',
  [hostnames.localhost]:
    'https://marketplace-api-staging.sitecore-staging.cloud',
  [hostnames.dev]: 'https://marketplace-api-dev.sitecore-staging.cloud',
  [hostnames.qa]: 'https://marketplace-api-qa.sitecore-staging.cloud',
  [hostnames.staging]: 'https://marketplace-api-staging.sitecore-staging.cloud',
  [hostnames.beta]: 'https://marketplace-api-beta.sitecorecloud.io',
  [hostnames['pre-production']]:
    'https://marketplace-api-beta.sitecorecloud.io',
  [hostnames.production]: 'https://marketplace-api.sitecorecloud.io',
};

export const marketplaceApi = {
  get_application: {
    url: (appId: string) =>
      `${
        baseMarketplaceUrl[window.location.hostname]
      }/api/v1/Applications/${appId}`,
    scope: 'mkp.apps:r',
    method: 'GET' as const,
  },
};
