import React from 'react';

import { useDrawerState } from '../../context';
import { DrawerId, DrawerMap } from '../../context/drawer/model';
import { RouteComponentProps, useRouteMatch } from 'react-router-dom';
import {
  IInviteDetailsProps,
  InviteDetailsDrawer,
} from '../../../members/components/table/InviteDetailsDrawer';
import { ApmRoute } from '@elastic/apm-rum-react';
import { Feature } from 'lib/featureFlags/features';

export const invitationDetailsDrawerPath =
  '/admin/invites/page/:page/details/:id';

function DrawerManager(
  props: JSX.IntrinsicAttributes & RouteComponentProps<any>,
) {
  const { drawerId } = useDrawerState();
  const invitationDetailsMatch = useRouteMatch({
    path: invitationDetailsDrawerPath,
    exact: true,
  });

  // This side effect is used in case you are in invite-details-drawer, and you want to move to another drawer
  // in that case we pop doc the window.history manually
  React.useEffect(() => {
    if (invitationDetailsMatch && drawerId !== 'invite-details-drawer') {
      window.history.back();
    }
  }, [drawerId, invitationDetailsMatch]);

  const getDrawerId = (drawerId: DrawerId) => {
    if (invitationDetailsMatch && drawerId === 'invite-details-drawer') {
      return 'invite-details-drawer';
    }

    return drawerId;
  };

  const drawerIdentifier = getDrawerId(drawerId as DrawerId);
  // Invite-details drawer uses react-router params as a dependency,
  // this only works if you render the component within a route with explicit path
  if (drawerId === 'invite-details-drawer') {
    return (
      <ApmRoute
        path={invitationDetailsDrawerPath}
        component={FeaturedComponent}
      />
    );
  }

  const Drawer = DrawerMap[drawerIdentifier] || DrawerMap['default'];

  return (
    <>
      <Drawer {...props} />
      <create-help-case-drawer
        drawer-is-open={drawerIdentifier === 'create-support-case-drawer'}
      />
    </>
  );
}

const FeaturedComponent = (
  props: JSX.IntrinsicAttributes &
    IInviteDetailsProps & { children?: React.ReactNode },
) => (
  <Feature name='INVITATIONS'>
    <InviteDetailsDrawer {...props} />
  </Feature>
);

export { DrawerManager };
