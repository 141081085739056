import React from 'react';
import { Grid, GridItem, Box } from '@chakra-ui/react';
import { ErrorBoundary } from 'features/marketplace/shared/components';

interface BrowseLayoutProps {
  title: React.ReactNode;
  content: React.ReactNode;
  errorMessage?: string;
}

export const BrowseLayout: React.FC<BrowseLayoutProps> = ({
  title,
  content,
  errorMessage,
}) => {
  return (
    <Grid
      data-testid='browse-layout'
      templateAreas={`
        "title"
        "content"
      `}
      gridTemplateRows={'auto 1fr'}
      h='100vh'
      w='100%'
      maxW='container.2xl'
      mx='auto'
      px={{ base: 4, md: 6, lg: 8 }}
    >
      <GridItem
        data-testid='browse-layout-title'
        area='title'
        pt='20'
        pr='1'
        pb='20'
      >
        {title}
      </GridItem>
      <GridItem
        data-testid='browse-layout-content'
        area='content'
        position='relative'
        overflow='hidden'
      >
        <ErrorBoundary customErrorMessage={errorMessage}>
          <Box h='100%'>{content}</Box>
        </ErrorBoundary>
      </GridItem>
    </Grid>
  );
};
