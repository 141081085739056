import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  Text,
  AlertDialogBody,
  AlertDialogFooter,
  ButtonGroup,
  Button,
  ModalCloseButton,
  useToast,
} from '@chakra-ui/react';
import { T } from '@transifex/react';
import { history } from 'features/common/history/history';
import { useRef } from 'react';
import { useParams } from 'react-router';
import { useQueryClient } from 'react-query';
import { t } from '@transifex/native';
import { getLoginUrl } from 'features/sso/utils';
import { redirect } from 'features/common/utils/utils';
import { ssoRoute } from '../sso-routes';
import withConnectionIdGuard from '../with-connection-id-guard';

import { useActivateConnection, useGetConnection } from '../api';

const EnableSSOConnectionModal = () => {
  const lessDestructiveRef = useRef(null);
  const { id, ssoProvider } = useParams<{ id: string; ssoProvider: string }>();
  const toast = useToast();
  const queryClient = useQueryClient();
  const { mutate: postConnection, isLoading: isLoadingActiveRequest } =
    useActivateConnection();

  const { data: connection, isLoading } = useGetConnection(id, {
    enabled: !!id,
  });
  const { name } = connection?.data ?? {};
  const testUrl = `${getLoginUrl()}/TestConnection?connectionName=${name}`;

  const onClose = () => {
    history.replace(`${ssoRoute}${history.location.search}`);
  };

  const onTest = () => {
    redirect(testUrl);
    history.replace(
      `${ssoRoute}/${ssoProvider}/${id}/test-connection/${history.location.search}`,
    );
  };

  const onEnable = () => {
    postConnection(
      { id },
      {
        onSuccess: () => {
          queryClient.invalidateQueries('connections/list');
          onClose();
          toast({
            description: t(
              'Enabling SSO connection. This may take a few minutes.',
            ),
            status: 'info',
          });
        },
        onError: () => {
          toast({
            description: t('Couldn’t enable SSO connection'),
            status: 'error',
          });
        },
      },
    );
  };

  return (
    <AlertDialog
      isOpen={true}
      onClose={onClose}
      leastDestructiveRef={lessDestructiveRef}
      size='xl'
    >
      <AlertDialogOverlay>
        <AlertDialogContent data-testid='enable-sso-connection-modal' gap={2}>
          <AlertDialogHeader>
            <Text data-testid='enableConnectionModal-title'>
              <T _str='Enable SSO connection?' />
            </Text>
          </AlertDialogHeader>
          <ModalCloseButton />
          <AlertDialogBody>
            <Text data-testid='enableConnectionModal-description'>
              <T _str='Before you enable your SSO connection, test it to make sure it works.' />
            </Text>
          </AlertDialogBody>
          <AlertDialogFooter>
            <ButtonGroup>
              <Button
                onClick={onClose}
                isDisabled={isLoading}
                variant='ghost'
                data-testid='enableConnectionModal-cancelButton'
              >
                Cancel
              </Button>
              <Button
                onClick={onTest}
                isDisabled={isLoading}
                variant='ghost'
                data-testid='enableConnectionModal-testButton'
              >
                Test first
              </Button>
              <Button
                onClick={onEnable}
                isDisabled={isLoadingActiveRequest || isLoading}
                variant='solid'
                data-testid='enableConnectionModal-enableButton'
              >
                Enable now
              </Button>
            </ButtonGroup>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default withConnectionIdGuard(EnableSSOConnectionModal);
