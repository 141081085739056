import { useAppAccess } from 'features/common/components/OrganizationAppAccess';
import { useMainStateDispatch } from 'features/main/context';
import { openDrawer } from 'features/main/context/drawer/actionCreators';
import { DrawerId } from 'features/main/context/drawer/model';
import { useCallback, useMemo } from 'react';
import { generalQuickActionsSchemas } from '.';
import { env } from 'features/common/config/envConfig';
import { quickActionsMapper } from '../utils';
import { useAuth0TokenClaims } from 'features/common/auth/claims';
import { QuickAction } from '@sitecore-ui/portal-singular';
import { useGetActiveProducts } from 'lib/portal-singular';

const useGetGeneralActionsContentFromSchema = () => {
  const [state, dispatch] = useMainStateDispatch();
  const { claims: tokenClaims } = useAuth0TokenClaims();

  const onOpenDrawer = useCallback(
    (drawerId: DrawerId) => dispatch(openDrawer(drawerId)),
    [dispatch],
  );
  const { userRoles } = useAppAccess();

  const { activeProducts } = useGetActiveProducts();

  const generalQuickActions = useMemo(() => {
    const generalQuickActions = generalQuickActionsSchemas
      .filter((json) => {
        /**
         * This is a special case for the "XM Cloud Deploy" action. It should only be enabled for users
         * if XM Cloud is defined for the organization and are either an org admin or org owner.
         */
        if (json.name === 'XM Cloud Deploy') {
          return (
            state?.env?.WITH_XM_CLOUD_CONTEXT &&
            (state?.env.ORG_ADMIN || state?.env.ORG_OWNER)
          );
        }
        return true;
      })
      .map((json) => {
        return new QuickAction({
          jsonData: json,
          hostCta:
            json.name === 'Create support case' ? () => void 0 : onOpenDrawer,
          activeProducts,
          userRoles,
          env,
          tokenClaims,
        });
      });

    return generalQuickActions
      .filter(({ isDisabled }) => !isDisabled)
      .map(quickActionsMapper);
  }, [activeProducts, onOpenDrawer, userRoles, tokenClaims, state]);

  return { generalQuickActions };
};

export default useGetGeneralActionsContentFromSchema;
