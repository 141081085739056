import * as Yup from 'yup';
import { t } from '@transifex/native';
import {
  nameDoesNotStartWithSpecialCharRegex,
  isValidPhoneRegex,
} from '../../common/validations/matchers';
import { nameValidation } from '../../common/validations/schemas';

const namesValidationMessage = t(
  'This field can not start with a special character',
);

export const profileSchema = Yup.object({
  givenName: nameValidation
    .label(t('Given name'))
    .matches(nameDoesNotStartWithSpecialCharRegex, namesValidationMessage)
    .required(t('This field is required')),
  familyName: nameValidation
    .label(t('Family name'))
    .matches(nameDoesNotStartWithSpecialCharRegex, namesValidationMessage)
    .required(t('This field is required')),
  phoneNumber: Yup.string()
    .label(t('Phone number'))
    .test('validPhone', t('Not a valid phone number'), (value: any) => {
      if (!value) return true;
      return isValidPhoneRegex.test(value);
    }),
  jobRole: Yup.string().label(t('Job role')),
});
