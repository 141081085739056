import {
  Box,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Link,
  Skeleton as ChakraSkeleton,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useDrawerState, useMainStateDispatch } from 'features/main/context';
import { T } from '@transifex/react';
import { MediaObjectLink } from 'features/common/components/MediaObjectLink';
import { closeDrawer, openDrawer } from '../context/drawer/actionCreators';
import { useGetHelpContent } from 'features/domains/portal/content/helpLinks';
import { useSupportCase } from 'features/snow/hooks/useSupportCase';
import { quickActionAdapter } from 'features/domains/portal/content/utils';
import { useHistory } from 'react-router';
import { useEffect } from 'react';

export const HelpDrawer = () => {
  const [, dispatch] = useMainStateDispatch();
  const drawerState = useDrawerState();
  const onCloseDrawer = () => dispatch(closeDrawer());

  return (
    <>
      <Drawer
        onClose={onCloseDrawer}
        isOpen={drawerState.drawerVisible}
        size='md'
      >
        <DrawerOverlay />
        <DrawerContent
          data-behavior-analytics-feature='Help drawer'
          data-testid='help-drawer'
        >
          <DrawerCloseButton
            data-testid='help-panel-close'
            _focus={{ border: 'none' }}
          />
          <DrawerHeader display='flex' alignItems='center'>
            <T _str='Help' />
          </DrawerHeader>
          <DrawerBody>
            {drawerState.helpDrawerVisible && <DrawerBodyContent />}
          </DrawerBody>
          <DrawerFooter pb='6' justifyContent='center'>
            <Text variant='subtle'>
              <Link
                data-testid='help-panel-legal'
                variant='ghost'
                href='https://www.sitecore.com/trust'
                target='_blank'
              >
                Legal
              </Link>
              &emsp;&middot;&emsp;
              <Link
                data-testid='help-panel-privacy-policy'
                variant='ghost'
                href=' https://www.sitecore.com/trust/privacy-policy'
                target='_blank'
              >
                Privacy policy
              </Link>
            </Text>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export const DrawerBodyContent = () => {
  const { mainHelpLinks, secondaryHelpLinks, isLoading } = useGetHelpContent();
  const [, dispatch] = useMainStateDispatch();
  const history = useHistory();

  const openCreateSupportCaseDrawer = () =>
    dispatch(openDrawer('create-support-case-drawer'));
  const rerun = useSupportCase({
    componentName: 'create-help-case-drawer',
    buttonSelector: '[data-support-case="create-help-case-drawer"]',
  });

  useEffect(() => {
    if (!isLoading) rerun();
  }, [isLoading, rerun]);

  const hasTwoSections = mainHelpLinks.length && secondaryHelpLinks.length;
  return isLoading ? (
    <Skeleton />
  ) : (
    <>
      <VStack>
        {mainHelpLinks.map((quickAction) => {
          const {
            title,
            action,
            description,
            link,
            iconColor,
            iconPath,
            ...rest
          } = quickActionAdapter(quickAction, { dispatch, history });
          return (
            <MediaObjectLink
              key={title}
              onClick={action}
              title={title}
              description={description as string | undefined}
              link={link}
              data-testid={`help-item-${title}`}
              data-behavior-analytics-id={rest['analytics-id']}
              iconPath={iconPath}
              iconColor={iconColor}
            />
          );
        })}
      </VStack>
      {hasTwoSections ? (
        <Box w='full' py='4'>
          <Divider />
        </Box>
      ) : null}
      <VStack>
        {secondaryHelpLinks.map((quickAction) => {
          const {
            title,
            action,
            description,
            link,
            iconColor,
            iconPath,
            ...rest
          } = quickActionAdapter(quickAction, { dispatch, history });

          return (
            <MediaObjectLink
              key={title}
              onClick={
                link === 'create-ticket-drawer'
                  ? openCreateSupportCaseDrawer
                  : action
              }
              title={title}
              description={description as string | undefined}
              link={link}
              iconPath={iconPath}
              iconColor={iconColor}
              data-testid={`help-item-${title}`}
              data-behavior-analytics-id={rest['analytics-id']}
              data-url={rest['data-url']}
              {...(link === 'create-ticket-drawer'
                ? { 'data-support-case': 'create-help-case-drawer' }
                : {})}
            />
          );
        })}
      </VStack>
    </>
  );
};

const Skeleton = () => (
  <VStack>
    {Array(6)
      .fill(null)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map((_, index) => (
        <ChakraSkeleton
          key={`main-help-links-${index}`}
          w='md'
          h='10'
          my='1'
          data-testid='loading-skeleton'
        />
      ))}
    <Box w='full' py='4'>
      <Divider />
    </Box>
    {Array(2)
      .fill(null)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map((_, index) => (
        <ChakraSkeleton
          key={`secondary-help-links-${index}`}
          w='md'
          h='10'
          my='1'
          data-testid='loading-skeleton'
        />
      ))}
  </VStack>
);
