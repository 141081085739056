import { List, ListIcon, ListItem, Skeleton } from '@chakra-ui/react';
import {
  mdiAccountOutline,
  mdiBriefcaseOutline,
  mdiPhoneOutline,
} from '@mdi/js';
import { IUser } from '@sitecore-ui/portal-singular';

interface IDetailsList extends IUser {
  isLoading: boolean;
}

interface IDetailsListItemProps {
  isExternal: boolean | undefined;
  isLoading: boolean;
  listItemLabel: string;
  listItemVal: string;
  listItemIcon: string;
  listItemTestId: string;
}

function ProfileDetailsListItem({
  isExternal,
  isLoading,
  listItemLabel,
  listItemVal,
  listItemIcon,
  listItemTestId,
}: IDetailsListItemProps) {
  const itemData =
    listItemVal && listItemVal.length > 0
      ? listItemVal
      : `No ${listItemLabel} set`;
  if (isExternal && !listItemVal) return null;

  return (
    <Skeleton isLoaded={!isLoading}>
      <ListItem data-testid={listItemTestId}>
        <ListIcon boxSize='6'>
          <path d={listItemIcon} />
        </ListIcon>
        {itemData}
      </ListItem>
    </Skeleton>
  );
}

export const ProfileDetailsList = (props: IDetailsList) => {
  const name =
    props.givenName || props.familyName
      ? `${props.givenName} ${props.familyName}`
      : '';

  return (
    <List spacing={2}>
      <ProfileDetailsListItem
        isExternal={props.isExternal}
        isLoading={props.isLoading}
        listItemLabel='name'
        listItemVal={name}
        listItemIcon={mdiAccountOutline}
        listItemTestId='myaccount-detailsCard_givenName'
      />
      <ProfileDetailsListItem
        isExternal={props.isExternal}
        isLoading={props.isLoading}
        listItemLabel='job role'
        listItemVal={props.jobRole}
        listItemIcon={mdiBriefcaseOutline}
        listItemTestId='myaccount-detailsCard_jobRole'
      />

      <ProfileDetailsListItem
        isExternal={props.isExternal}
        isLoading={props.isLoading}
        listItemLabel='phone number'
        listItemVal={props.phoneNumber}
        listItemIcon={mdiPhoneOutline}
        listItemTestId='myaccount-detailsCard_phoneNumber'
      />
    </List>
  );
};
