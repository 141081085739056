import { useAuthQuery } from 'features/common/apiUtils/queryHelper';
import { connectionsApi } from './endpoints';
import { getIdentityBaseUrl, IResponse } from '@sitecore-ui/portal-singular';
import { env } from 'features/common/config/envConfig';
import { AxiosResponse } from 'axios';
import { ConnectionType } from '../types';
import { UseQueryOptions } from 'react-query';

const baseUrl = getIdentityBaseUrl(env.toLowerCase());

const shouldEnablePolling = (
  connectionsResponse?: AxiosResponse<
    AxiosResponse<IResponse<ConnectionType[]>>
  >,
) => {
  const connections = connectionsResponse?.data?.data;
  const isConnectionActivating =
    Array.isArray(connections) &&
    connections.find(({ state }: { state: string }) => state === 'Activating');

  return isConnectionActivating;
};

export const useGetConnections = (
  useQueryOptions?:
    | Omit<
        UseQueryOptions<
          AxiosResponse<AxiosResponse<IResponse<ConnectionType[]>>>,
          unknown,
          AxiosResponse<AxiosResponse<IResponse<ConnectionType[]>>>,
          string[]
        >,
        'queryKey' | 'queryFn'
      >
    | undefined,
) => {
  const { list_connections } = connectionsApi;
  const url = `${baseUrl}${list_connections.url()}?pagenumber=1`;

  return useAuthQuery(
    ['connections/list'],
    async (axiosInstance) => {
      return axiosInstance?.get<AxiosResponse<IResponse<ConnectionType[]>>>(
        url,
      );
    },
    {
      refetchInterval: (response) =>
        shouldEnablePolling(response) ? 30000 : false,
      ...useQueryOptions,
    },
    list_connections.scope,
  );
};
