import './features/try-apps/polyfill';
import {
  Extension,
  ExtensionLifeCycleParams,
  windowEventExtensionRegistry,
} from '@sitecore/page-composer-sdk';
import React from 'react';
import App from './App';
import { FedUiProvider } from './features/common/fedui/FedUiContext';
import reportWebVitals from './reportWebVitals';
import { freezeRiskyPrototypes } from './securityMeasures';
import { setupApm } from 'features/domains/elastic/apm/setupApm';
import { createRoot } from 'react-dom/client';

if (
  ['development', 'test'].includes(process.env.NODE_ENV) ||
  window.location.hostname === 'portal.internal'
) {
  (window as any).ENABLED_PRODUCTS = [
    'XMCloud',
    'HeadlessCMS',
    'Connect',
    'OrderCloud',
    'CDP',
    'Personalize',
    'Search',
    'Discover',
    'Send',
    'ContentOperations',
    'DAM',
    'ai',
  ];
}

if (process.env.REACT_APP_MOCK === 'enabled') {
  const { worker } = require('./mocks/browser');
  worker.start();
} else {
  // temporary solution, proper checks need to be made since msw modifies the xmlhttprequest object
  freezeRiskyPrototypes();
}

setupApm();

class PortalExtension implements Extension {
  async mount(context: ExtensionLifeCycleParams): Promise<void> {
    const root = createRoot(context.rootElement);

    root.render(
      <React.StrictMode>
        <FedUiProvider fedUiContext={context}>
          <App />
        </FedUiProvider>
      </React.StrictMode>,
    );

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
  }

  // eslint-disable-next-line no-empty-function
  async unmount(): Promise<void> {}
}

windowEventExtensionRegistry.define({
  name: 'sc-portal-ui:app',
  extension: PortalExtension,
});
