/* eslint-disable no-empty-function */
import { Button } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { history } from 'features/common/history/history';
import { redirect } from 'features/common/utils/utils';
import { useGetConnection } from 'features/sso/api';
import { ssoRoute } from 'features/sso/sso-routes';
import { getLoginUrl } from 'features/sso/utils';
import withConnectionIdGuard from 'features/sso/with-connection-id-guard';
import { useRef } from 'react';
import { useParams } from 'react-router';
import TestConnectionModal from '../test-connection-modal';

export const getDescription = (state: string) => {
  const map = {
    notVerified:
      'In the tab that opened, sign in to your identity provider. If your connection test succeeded, you can verify the domain and then enable the SSO connection.',
    enabled: 'In the tab that opened, sign in to your identity provider.',
    notEnabled:
      'In the tab that opened, sign in to your identity provider. If your connection test succeeded and you’ve verified the domain, you can enable the SSO connection.',
  }[state];

  return map || '';
};

export const RestartTest = withConnectionIdGuard(() => {
  const lessDestructiveRef = useRef(null);

  const { id, ssoProvider } = useParams<{ id: string; ssoProvider: string }>();
  const { data: connection, isLoading } = useGetConnection(id, {
    enabled: !!id,
  });
  const { name, state, verified } = connection?.data ?? {};
  const testUrl = `${getLoginUrl()}/TestConnection?connectionName=${name}`;
  const isConnectionEnabled = state === 'Active';
  const isConnectionVerified = verified === true;

  return (
    <TestConnectionModal
      ref={lessDestructiveRef}
      defaultIsOpen={true}
      title='Test SSO connection'
      description={getDescription(
        !isConnectionVerified
          ? 'notVerified'
          : isConnectionEnabled
          ? 'enabled'
          : 'notEnabled',
      )}
      testId='restartTest'
    >
      <Button
        variant='ghost'
        onClick={() => history.replace(`${ssoRoute}${history.location.search}`)}
        ref={lessDestructiveRef}
        isDisabled={isLoading}
        data-testid='testConnectionModal-closeButton'
      >
        <T _str='Close' />
      </Button>
      <Button
        variant={isConnectionEnabled ? 'solid' : 'ghost'}
        onClick={() => redirect(testUrl)}
        isLoading={isLoading}
        data-testid='testConnectionModal-restartTestButton'
      >
        <T _str='Restart test' />
      </Button>
      {!isConnectionEnabled && isConnectionVerified ? (
        <Button
          variant='solid'
          isLoading={isLoading}
          onClick={() =>
            history.replace(
              `${ssoRoute}/${ssoProvider}/${id}/enable-connection${history.location.search}`,
            )
          }
          data-testid='testConnectionModal-enableSsoConnectionButton'
        >
          <T _str='Enable SSO connection' />
        </Button>
      ) : null}
      {!isConnectionEnabled && !isConnectionVerified ? (
        <Button
          variant='solid'
          isLoading={isLoading}
          onClick={() =>
            history.replace(
              `${ssoRoute}/${ssoProvider}/${id}/verify-connection${history.location.search}`,
            )
          }
          data-testid='testConnectionModal-verifySsoConnectionButton'
        >
          <T _str='Verify domains' />
        </Button>
      ) : null}
    </TestConnectionModal>
  );
});
