import { useGetConnections } from 'features/sso/api/use-get-connections';
import { useFeatures } from 'lib/featureFlags/features';
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useRouteMatch } from 'react-router';
import { ConnectionType } from './types';
import { adminPath } from 'features/admin/components/admin-paths';

type SSOContextType = {
  connections: ConnectionType[];
  setConnections?: Dispatch<SetStateAction<ConnectionType[]>>;
  isLoading: boolean;
};

const initialState = {
  connections: [],
  setConnections: undefined,
  isLoading: false,
};
const SSOContext = createContext<SSOContextType>(initialState);

export const useSSOContext = () => useContext<SSOContextType>(SSOContext);

/**
 * @description SSOProvider is a React Context Provider that provides SSO connections data to its children
 */
export const SSOProvider = ({ children }: { children: ReactNode }) => {
  const features = useFeatures();

  // enable connections request under member route
  const match = useRouteMatch(adminPath);
  const isAdminRoute = match?.path?.startsWith(adminPath);

  const connectionsResponse = useGetConnections({
    enabled: features.SSO && !!isAdminRoute,
  });
  const { data, isLoading } = connectionsResponse;
  // Memoize connections data
  const connectionsData = useMemo(
    () => data?.data.data || [],
    [data?.data.data],
  );
  // Set connections state
  const [connections, setConnections] = useState<ConnectionType[]>(
    connectionsData as ConnectionType[],
  );

  // Update connections when data changes
  useEffect(() => {
    setConnections(connectionsData as ConnectionType[]);
  }, [connectionsData]);

  // Memoize context value
  const value = useMemo(
    () => ({ connections, isLoading, setConnections }),
    [connections, isLoading, setConnections],
  );

  return <SSOContext.Provider value={value}>{children}</SSOContext.Provider>;
};

/**
 * @description useConnectionsState is a React Hook that returns connections data from the SSOProvider,
 * as well as a function to update the connections state, and a selector for active and verified SSO domains
 * @returns {connections, setConnections, activeVerifiedSSODomainsSelector}
 */
export function useConnectionsState(): {
  connections: ConnectionType[];
  setConnections?: Dispatch<SetStateAction<ConnectionType[]>>;
  isLoading: boolean;
} {
  const context = useContext(SSOContext);

  if (context === undefined) {
    throw new Error('useConnectionsState must be used within a SSOProvider');
  }

  return context;
}
