import { useAuthMutation } from 'features/common/apiUtils/queryHelper';
import { connectionsApi } from './endpoints';
import { getIdentityBaseUrl } from '@sitecore-ui/portal-singular';
import { env } from 'features/common/config/envConfig';

type useVerifyConnectionProps = { connectionId: string };
const baseUrl = getIdentityBaseUrl(env.toLowerCase());

export const useVerifyConnection = ({
  connectionId = '',
}: useVerifyConnectionProps) => {
  const { verify_connection } = connectionsApi;
  const url = `${baseUrl}${verify_connection.url(connectionId)}`;

  return useAuthMutation(
    (axiosInstance) => () => axiosInstance?.post(url),
    {},
    verify_connection.scope,
  );
};
