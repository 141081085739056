import React from 'react';
import { PaginationProps } from './model';
import { Button, ButtonGroup, Skeleton, Stack, Text } from '@chakra-ui/react';
import usePagination from '@mui/material/usePagination';
import { PreviousNextButton } from './PreviousNextButton';

export const Pagination = ({
  page = 1,
  pageSize = 20,
  totalItems,
  onChange,
  'data-behavior-analytics-feature': dataBehaviorAnalyticsFeature,
}: PaginationProps) => {
  const totalPages = React.useMemo(() => {
    if (!totalItems) return 1;
    return Math.ceil(totalItems / pageSize);
  }, [totalItems, pageSize]);

  const { items } = usePagination({
    count: totalPages,
    page,
    onChange: (_e, page) => onChange(page),
  });

  return (
    <Skeleton
      data-testid='pagination'
      isLoaded={typeof totalItems === 'number'}
      data-behavior-analytics-feature={dataBehaviorAnalyticsFeature}
    >
      <Stack direction='row' align='center'>
        {totalItems && totalItems > 0 && (
          <Text variant='subtle' minW='20'>
            {page * pageSize - (pageSize - 1)}&ndash;
            {page * pageSize < totalItems ? page * pageSize : totalItems}
            &nbsp;of&nbsp;{Math.ceil(totalItems)}
          </Text>
        )}
        <ButtonGroup as='nav' variant='pagination' size='sm' spacing='1'>
          {items.map(({ page, type, selected, ...item }, index) => {
            if (type === 'start-ellipsis' || type === 'end-ellipsis') {
              return (
                <Button isDisabled key={index}>
                  …
                </Button>
              );
            } else if (type === 'page') {
              return (
                <Button
                  key={index}
                  type='button'
                  isActive={selected}
                  data-testid={
                    selected
                      ? 'pagination_currentPage'
                      : 'pagination_pageSelect'
                  }
                  {...item}
                >
                  {page}
                </Button>
              );
            } else if (type === 'previous' || type === 'next') {
              return <PreviousNextButton key={index} type={type} {...item} />;
            }
          })}
        </ButtonGroup>
      </Stack>
    </Skeleton>
  );
};

Pagination.displayName = 'Pagination';
