import { Box, BoxProps } from '@chakra-ui/react';

export const LayoutHeader = ({ children, ...rest }: BoxProps) => {
  return (
    <Box
      layerStyle='section.topbar'
      as='header'
      overflow='hidden'
      py='3'
      flexShrink={0}
      zIndex={3}
      {...rest}
    >
      {children}
    </Box>
  );
};
