import React, { useRef, useState, useEffect } from 'react';
import { Box, BoxProps, useColorModeValue } from '@chakra-ui/react';

interface ScrollableProps extends BoxProps {
  children: React.ReactNode;
  paddingBottom?: string;
}

export const Scrollable: React.FC<ScrollableProps> = ({
  children,
  paddingBottom = '0rem',
  ...props
}) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [showTopShadow, setShowTopShadow] = useState(false);
  const [showBottomShadow, setShowBottomShadow] = useState(false);

  const gradientStart = useColorModeValue(
    'rgba(255, 255, 255, 0.8)',
    'rgba(0, 0, 0, 0.8)',
  );
  const gradientEnd = useColorModeValue(
    'rgba(255, 255, 255, 0)',
    'rgba(0, 0, 0, 0)',
  );

  const updateShadows = () => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      setShowTopShadow(scrollTop > 0);
      setShowBottomShadow(scrollTop < scrollHeight - clientHeight - 1);
    }
  };

  useEffect(() => {
    updateShadows();
    window.addEventListener('resize', updateShadows);
    return () => window.removeEventListener('resize', updateShadows);
  }, []);

  return (
    <Box
      data-testid='scrollable-container'
      position='relative'
      height='100%'
      overflow='hidden'
      {...props}
    >
      <Box
        data-testid='scrollable-content'
        ref={scrollRef}
        height='100%'
        overflowY='auto'
        onScroll={updateShadows}
        paddingLeft={'2rem'}
        paddingRight={'2rem'}
        marginRight={'-2rem'}
        marginLeft={'-2rem'}
        pb={paddingBottom}
        css={{
          '&::-webkit-scrollbar': {
            width: '4px',
          },
          '&::-webkit-scrollbar-track': {
            width: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'rgba(0, 0, 0, 0.1)',
            borderRadius: '24px',
          },
          // Ensure content is scrollable in Safari
          WebkitOverflowScrolling: 'touch',
          // Force hardware acceleration
          transform: 'translateZ(0)',
        }}
      >
        {children}
      </Box>
      {showTopShadow && (
        <Box
          data-testid='top-shadow'
          position='absolute'
          top={0}
          left={0}
          right={0}
          height='1.25rem'
          bgGradient={`linear(to-b, ${gradientStart}, ${gradientEnd})`}
          pointerEvents='none'
          zIndex={1}
        />
      )}
      {showBottomShadow && (
        <Box
          data-testid='bottom-shadow'
          position='absolute'
          bottom='3.5rem'
          left={0}
          right={0}
          height='1.25rem'
          bgGradient={`linear(to-t, ${gradientStart}, ${gradientEnd})`}
          pointerEvents='none'
          zIndex={1}
        />
      )}
    </Box>
  );
};
