import { mdiDrawing } from '@mdi/js';

export const IconLinks = {
  sitecoreLogo:
    'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/logo-sitecore',
  sitcoreLogoIconOnly:
    'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/mark-sitecore',
  xmDeployLogo:
    'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/mark-xm_cloud',
  headlessCmsLogo:
    'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/mark-content_hub_one',
  pagesLogo:
    'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/mark-content_hub_one',
  portalLogo: '',
  dashboardLogo: '',
  xmCloudLogo:
    'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/mark-xm_cloud',
  explorerIcon:
    'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/mark-explorer',
  pagesIcon:
    'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/mark-pages',
  noConnectionsIcon:
    'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-cloud-key-neutral',
} as const;

export const tenantsFallbackIcon = mdiDrawing;
