import { useMemo } from 'react';
import { t } from '@transifex/native';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Button,
  Input,
  FormControl,
  FormLabel,
  ModalFooter,
  FormErrorMessage,
  useToast,
  ButtonGroup,
  FormErrorIcon,
} from '@chakra-ui/react';
import { T } from '@transifex/react';
import { Formik, Form } from 'formik';
import { Select as ReactSelect, SingleValue } from 'chakra-react-select';
import {
  ContextUser,
  Error,
  useUpdateContextUserDetailsMutation,
} from 'gql/graphql';
import { GET_CURRENT_USER } from 'features/profile/profile-queries';
import { IUpdateUserData } from '../../services/identity/usersModel';
import { jobRolesOptions } from '../../../common/static/jobRoles';
import { profileSchema } from '../../validations/profileSchema';

interface IProfileEditDetailsProps {
  isOpen: boolean;
  onClose: () => void;
  userData?: ContextUser | null;
}

export const ProfileEditDetails = (props: IProfileEditDetailsProps) => {
  const { userData, isOpen, onClose } = props;
  const toast = useToast();
  const [updateContextUserDetailsMutation, { loading }] =
    useUpdateContextUserDetailsMutation();

  const initialValues = useMemo(
    () => ({
      givenName: userData?.givenName || '',
      familyName: userData?.familyName || '',
      phoneNumber: userData?.phoneNumber || '',
      jobRole: userData?.jobRole || '',
    }),
    [userData],
  );

  const onSuccess = () => {
    onClose();
    toast({
      description: t('Profile updated!'),
      title: t('Success'),
      status: 'success',
    });
  };
  const onError = (error: Error) => {
    toast({
      description: error?.message,
      title: t('Error'),
      status: 'error',
    });
  };
  return (
    <Modal
      size='sm'
      isOpen={isOpen}
      onClose={onClose}
      data-testid='edit-details-modal'
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Edit details</ModalHeader>
        <ModalBody>
          <Formik
            initialValues={initialValues}
            validationSchema={profileSchema}
            onSubmit={async (values) => {
              try {
                const { data } = await updateContextUserDetailsMutation({
                  variables: {
                    input: profileSchema.cast(values) as IUpdateUserData,
                  },
                  refetchQueries: [{ query: GET_CURRENT_USER }],
                });

                const error = data?.updateContextUserDetails.errors?.[0];

                if (error) return onError(error);
                onSuccess();
              } catch (error: any) {
                const { networkError } = error ?? {};

                toast({
                  description: networkError.message,
                  title: `Error ${networkError.statusCode}`,
                  status: 'error',
                });
              }
            }}
          >
            {({ errors, touched, values, setFieldValue, handleChange }) => (
              <Form>
                <FormControl
                  isInvalid={!!(errors.givenName && touched.givenName)}
                  data-testid='myaccount-editDetailsForm_givenName'
                >
                  <FormLabel>
                    <T _str='Given name' />
                  </FormLabel>
                  <Input
                    name='givenName'
                    value={values.givenName}
                    onChange={handleChange}
                  />
                  <FormErrorMessage>
                    <FormErrorIcon />
                    {errors.givenName}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  mt='5'
                  isInvalid={!!(errors.familyName && touched.familyName)}
                  data-testid='myaccount-editDetailsForm_familyName'
                >
                  <FormLabel>
                    <T _str='Family name' />
                  </FormLabel>
                  <Input
                    name='familyName'
                    value={values.familyName}
                    onChange={handleChange}
                  />
                  <FormErrorMessage>
                    <FormErrorIcon />
                    {errors.familyName}
                  </FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={!!(errors.phoneNumber && touched.phoneNumber)}
                  mt='5'
                  data-testid='myaccount-editDetailsForm_phoneNumber'
                >
                  <FormLabel>
                    <T _str='Phone number' />
                  </FormLabel>
                  <Input
                    value={values.phoneNumber}
                    isInvalid={!!(errors.phoneNumber && touched.phoneNumber)}
                    onChange={handleChange}
                    name='phoneNumber'
                    placeholder='Enter a phone number'
                  />
                  <FormErrorMessage>
                    <FormErrorIcon />
                    {errors.phoneNumber}
                  </FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={!!(errors.jobRole && touched.jobRole)}
                  mt='5'
                  data-testid='myaccount-editDetailsForm_jobRole'
                >
                  <FormLabel>
                    <T _str='Job role' />
                  </FormLabel>
                  <ReactSelect
                    selectedOptionColorScheme='primary'
                    useBasicStyles
                    name='jobRole'
                    options={jobRolesOptions}
                    value={jobRolesOptions.find(
                      (option) => option.value === values.jobRole,
                    )}
                    onChange={(
                      data: SingleValue<{ label: string; value: string }>,
                    ) => setFieldValue('jobRole', data?.value || '')}
                    placeholder='Select Role'
                    menuPlacement='auto'
                    menuPosition='fixed'
                  />
                  <FormErrorMessage>
                    <FormErrorIcon />
                    {errors.jobRole}
                  </FormErrorMessage>
                </FormControl>
                <ModalFooter mt='8' p='0'>
                  <ButtonGroup>
                    <Button
                      data-testid='cancel-btn'
                      variant='ghost'
                      onClick={onClose}
                      isDisabled={loading}
                    >
                      Cancel
                    </Button>
                    <Button
                      data-testid='submit-btn'
                      type='submit'
                      variant='solid'
                      isLoading={loading}
                    >
                      Save
                    </Button>
                  </ButtonGroup>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
