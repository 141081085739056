/* eslint-disable no-useless-escape */
export const isValidPhoneRegex = /^\+?[1-9]\d{1,14}$/;
export const allowedSearchCharactersRegex = /^[a-zA-Z0-9._@\-]*$/;
export const nameDoesNotStartWithSpecialCharRegex = /^(?![=\+\-@\"\(\)\t\r]).*/; // checks if it starts with =, +, -, @, ", (, ), 0x09 and 0x0D
export const restrictedEmails = /(@sitecore.net)|(@boxever.com)/;
export const restrictedSitecoreEmails =
  /(@sitecore.net)|(@sitecore.com)|(@moosend.com)/;
/* 
    Matches a domain that:

    Starts with a letter or number (not a hyphen)
    Contains only letters, numbers, and hyphens
    Ends with a dot and a valid top-level domain name (e.g. .com, .net, .org)
*/
export const isValidDomain =
  /^(?!-)(?!.*--)[A-Za-z0-9-]{1,63}(?<!-)\.[A-Za-z0-9-]{2,63}(?:\.[A-Za-z]{2,})*$/;

/* 
    Matches a URL that:

    Starts with an optional "http://" or "https://" or "ftp://" protocol identifier
    Contains one or more alphanumeric characters or special characters like "/", "-", "?", "=", "%", and "."
    Ends with a valid domain name
*/
export const isValidUrl =
  /^(?:(?:https?|ftp):\/\/)?[\w/\-?=%.]+\.[\w/\-?=%.]+$/i;
