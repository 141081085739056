import { useCallback, useState } from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerFooter,
  Button,
  Skeleton,
  List,
  useToast,
  ButtonGroup,
  CircularProgress,
  Text,
  Box,
} from '@chakra-ui/react';
import { T } from '@transifex/react';
import { RouteComponentProps } from 'react-router-dom';
import { history } from '../../../common/history/history';
import { BadgeByDates } from './shared/BadgeByDates';
import { t } from '@transifex/native';
import { InvitationRemoveConfirmation } from './components/InvitationDeleteConfirmation';
import OrganizationAccessCard from 'features/common/components/Access/UserAccessCard';
import { useDrawerStateDispatch } from '../../../main/context';
import { closeDrawer } from '../../../main/context/drawer/actionCreators';
import { adminPath, invitesPath } from 'features/admin/components/admin-paths';
import {
  GetInvitationsDocument,
  Invitation,
  OrganizationApplication,
  useDeleteInvitationMutation,
  useGetInvitationQuery,
  useInviteOrganizationMemberMutation,
} from 'gql/graphql';
import { EOrganizationAccessRole } from 'features/members/services/identity/membersModel';
import InviteDetailsProductAccess from './components/InviteDetailsProductAccess';

interface RouteParamProps {
  id: string;
  page: string;
}

export interface IInviteDetailsProps
  extends RouteComponentProps<RouteParamProps> {}

export const InviteDetailsDrawer = (props: IInviteDetailsProps) => {
  const [{ inviteDetailsDrawerVisible }, drawerDispatch] =
    useDrawerStateDispatch();
  const [showRemoveInvitationDialog, setShowRemoveInvitationDialog] =
    useState<boolean>(false);
  const toast = useToast();
  const { id: invitationId, page } = props.match.params;

  const { data, loading } = useGetInvitationQuery({
    variables: {
      id: invitationId,
    },
  });

  const applications = data?.organization?.applications?.nodes;
  const invitation = data?.organization?.invitations?.items?.[0] as Invitation;
  const orgRole = invitation?.organizationRoles?.[0];

  const [inviteOrganizationMemberMutation, { loading: deleteInviteLoading }] =
    useInviteOrganizationMemberMutation();
  const [deleteInviteMutation, { loading: createInviteLoading }] =
    useDeleteInvitationMutation();

  const actionPending = deleteInviteLoading || createInviteLoading;

  const onClose = () => {
    drawerDispatch(closeDrawer());
    setTimeout(() => {
      history.push(
        `${adminPath}${invitesPath}/page/${page}${history.location.search}`,
      );
    }, 300);
  };

  const onCloseInvitationDrawer = () => {
    setShowRemoveInvitationDialog(false);
  };

  const onDeleteInvite = () => {
    setShowRemoveInvitationDialog(true);
  };

  const onSuccess = () =>
    toast({
      description: 'The invitation has been resent.',
      status: 'success',
    });
  const onError = (error: Omit<Error, 'name'>) => {
    toast({
      description: error?.message,
      title: 'Error',
      status: 'error',
    });
  };

  const resendInvite = useCallback(async () => {
    const {
      inviteeEmail,
      inviterName,
      organizationRoles,
      applicationRoles,
      id,
    } = invitation ?? {};
    const newInvitation = {
      inviteeEmail,
      inviterName,
      applicationRoles: applicationRoles?.map(
        ({ role, applicationId, productCode }) => ({
          roleName: role,
          applicationId,
          productCode,
        }),
      ),
      organizationRoles: organizationRoles?.map(({ name }) => ({
        roleName: name,
      })),
    };

    try {
      const { data: deleteInvitationResponse } = await deleteInviteMutation({
        variables: {
          invitationId: id,
        },
      });
      const deleteInvitationError =
        deleteInvitationResponse?.deleteInvitation.errors?.[0];

      if (deleteInvitationError) return onError(deleteInvitationError);

      const { data: inviteOrganizationMemberResponse } =
        await inviteOrganizationMemberMutation({
          variables: newInvitation,
          refetchQueries: [GetInvitationsDocument],
        });

      const inviteOrganizationMemberError =
        inviteOrganizationMemberResponse?.inviteOrganizationMember.errors?.[0];
      if (inviteOrganizationMemberError)
        return onError(inviteOrganizationMemberError);

      onClose();
      onSuccess();
    } catch (error: any) {
      const { networkError } = error ?? {};

      toast({
        description: networkError.message,
        title: `Error ${networkError.statusCode}`,
        status: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitation, deleteInviteMutation, inviteOrganizationMemberMutation]);

  const onSuccessCb = () => {
    toast({
      description: t('The invitation has been deleted.'),
      status: 'success',
    });
    onClose();
  };

  return (
    <Drawer
      onClose={onClose}
      isOpen={inviteDetailsDrawerVisible}
      size='lg'
      autoFocus={false}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton
          _focus={{ border: 'none' }}
          data-testid='invite-details-drawer-close-btn'
        />
        <DrawerHeader display='flex' alignItems='center' w='lg'>
          <T _str='Invitation' />
          <Skeleton mt='1' mr='0' mb='0' ml='4' isLoaded={!loading}>
            {invitation?.expires && (
              <BadgeByDates expirationDate={invitation.expires} />
            )}
          </Skeleton>
        </DrawerHeader>
        <DrawerBody>
          {actionPending ? (
            <Box
              alignItems='center'
              justifyContent='center'
              display='flex'
              w='100%'
              h='100%'
            >
              <CircularProgress isIndeterminate size={12} />
            </Box>
          ) : (
            <>
              <List spacing='6'>
                <Skeleton isLoaded={!loading}>
                  <Text mb='2' fontSize='md' fontWeight='medium'>
                    Email Address
                  </Text>
                  <Box pl='4' pt='0.5' py='0.5' lineHeight='10'>
                    {invitation?.inviteeEmail}
                  </Box>
                </Skeleton>
                <Skeleton isLoaded={!loading}>
                  <Box mb='4'>
                    <Text mb='2' fontSize='md' fontWeight='medium'>
                      Organization access
                    </Text>
                    <Box pl='4' pt='0.5' py='0.5' lineHeight='10'>
                      <OrganizationAccessCard
                        role={
                          organizationRoleMap[
                            orgRole?.name as keyof typeof organizationRoleMap
                          ]?.role ?? ''
                        }
                        description={
                          organizationRoleMap[
                            orgRole?.name as keyof typeof organizationRoleMap
                          ]?.description ?? ''
                        }
                      />
                    </Box>
                  </Box>
                  {orgRole?.name ===
                    EOrganizationAccessRole['Organization Owner'] && (
                    <Text variant='subtle' data-testid='owner-access-wrapper'>
                      You are granting ownership rights to this organization.
                      This includes access to all apps, the admin section and
                      management of all owners.
                    </Text>
                  )}
                  {orgRole?.name ===
                    EOrganizationAccessRole['Organization Admin'] && (
                    <Text variant='subtle' data-testid='admin-access-wrapper'>
                      You are granting admin rights to this organization. <br />
                      This includes access to all apps and the admin section.
                    </Text>
                  )}
                  {orgRole?.name ===
                    EOrganizationAccessRole['Organization User'] && (
                    <InviteDetailsProductAccess
                      roles={invitation?.applicationRoles}
                      applications={applications as OrganizationApplication[]}
                    />
                  )}
                </Skeleton>
              </List>
            </>
          )}
        </DrawerBody>
        <DrawerFooter>
          <Skeleton isLoaded={!loading}>
            <ButtonGroup>
              <Button
                isDisabled={actionPending}
                variant='outline'
                onClick={resendInvite}
                data-testid='resend-invitation-button'
              >
                <T _str={'Resend'} />
              </Button>
              <Button
                isDisabled={actionPending}
                variant='outline'
                colorScheme='danger'
                onClick={onDeleteInvite}
                data-testid='delete-invitation-button'
              >
                <T _str='Delete invitation' />
              </Button>
            </ButtonGroup>
          </Skeleton>
        </DrawerFooter>
      </DrawerContent>

      {showRemoveInvitationDialog && (
        <InvitationRemoveConfirmation
          isOpen={showRemoveInvitationDialog}
          onClose={onCloseInvitationDrawer}
          onSuccess={onSuccessCb}
          invitationId={invitationId}
        />
      )}
    </Drawer>
  );
};

// TODO - When PORTAL Web Api changes the text, remove this and revert the props of OrganizationAccessCard
const organizationRoleMap = {
  'Organization Owner': { role: 'Owner', description: 'Can access everything' },
  'Organization Admin': { role: 'Admin', description: 'Can access all apps' },
  'Organization User': {
    role: 'User',
    description: 'Can only access the apps you specify',
  },
} as const;
