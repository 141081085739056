import { useAuthenticatedAxios } from 'features/common/apiUtils/AxiosProvider';
import { AxiosInstance } from 'axios';

import { useCallback, useState } from 'react';
import {
  generateCSV,
  generateCSVRowObject,
  getAllMembers,
} from './utils/utils';
import { useToast } from '@chakra-ui/react';
import { t } from '@transifex/native';
import { useGetTenants } from 'features/common/services/inventory/tenants';
import { useGetOrganization } from 'features/common/hooks/auth/useGetOrganization';
import { format } from 'date-fns';

const pageSize = 100;

const useExportMembers = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast();

  const axiosInstance: AxiosInstance = useAuthenticatedAxios();
  const { organizationName } = useGetOrganization();
  const { data: tenantsData } = useGetTenants({
    pageNumber: 1,
    pageSize: 100,
  });

  const exportMembers = useCallback(async () => {
    const tenants = tenantsData?.data.data ?? [];
    setIsLoading(true);
    const fileName = organizationName + ' ' + format(new Date(), 'dd-MM-yyyy');

    try {
      const members = await getAllMembers(axiosInstance, pageSize);
      const memberCSVData = members.map(generateCSVRowObject(tenants));
      await generateCSV(fileName, memberCSVData);

      toast({
        status: 'success',
        description: t('Team members exported successfully.'),
      });
    } catch (error) {
      toast({
        status: 'error',
        description: t('Export failed. Please try again.'),
      });
    }

    setIsLoading(false);
  }, [axiosInstance, organizationName, tenantsData?.data.data, toast]);

  return { exportMembers, isLoading };
};

export default useExportMembers;
