import * as Yup from 'yup';

interface IValidateClienSecret {
  value: string | undefined;
  isEditMode?: boolean;
  type: string;
  clientSecretInteractionMode?: boolean;
  typeInitial?: string;
}

export function validateClienSecret({
  value,
  isEditMode,
  type,
  clientSecretInteractionMode,
  typeInitial,
}: IValidateClienSecret) {
  const isRequired = false;
  const isNotRequired = true;

  // If type is BackChannel and is in edit mode and user has interacted with while value is empty or undefined then the field is required
  if (
    type === 'BackChannel' &&
    isEditMode &&
    clientSecretInteractionMode === true &&
    !value
  ) {
    return isRequired;
  }

  // If type is BackChannel and is not in edit mode and there is no value then the field is required
  if (type === 'BackChannel' && !isEditMode && !value) {
    return isRequired;
  }

  // If type is BackChannel and type initially is BackChannel and value is empty or undefined while user has interacted with is required
  if (
    type === 'BackChannel' &&
    typeInitial === 'BackChannel' &&
    !value &&
    clientSecretInteractionMode === true
  ) {
    return isRequired;
  }

  // If type is BackChannel and type initially is FrontChannel and value is empty then field is required
  if (type === 'BackChannel' && typeInitial === 'FrontChannel' && !value) {
    return isRequired;
  }

  return isNotRequired;
}

export const createConectionSchema = {
  oidc: Yup.object({
    options: Yup.object().shape({
      oidc: Yup.object().shape({
        isEditMode: Yup.boolean(),
        type: Yup.mixed()
          .oneOf(['FrontChannel', 'BackChannel'])
          .required()
          .label('Connection type'),
        typeInitial: Yup.string(),
        clientSecret: Yup.string()
          .label('Client secret')
          .test(
            'options.oidc.clientSecret',
            'Client secret is a required field',
            function (value) {
              const {
                isEditMode,
                type,
                clientSecretInteractionMode,
                typeInitial,
              } = this.parent;

              return validateClienSecret({
                value,
                isEditMode,
                type,
                clientSecretInteractionMode,
                typeInitial,
              });
            },
          ),

        scope: Yup.string()
          .matches(
            /(?:.*\\s)?openid(?:\\s.*)?/,
            'Scope must be space separated and contain "openid".',
          )
          .required()
          .label('Scope'),
        issuer: Yup.string().url().label('Issuer URL'),
        jwksurl: Yup.string().url().label('JWKS URL'),
        clientId: Yup.string().required().label('Client ID'),
        discoveryUrl: Yup.string().url().required().label('Issuer URL'),
        tokenEndpoint: Yup.string().url().label('Token endpoint'),
        userinfoEndpoint: Yup.string().url().label('Userinfo endpoint'),
        authorizationEndpoint: Yup.string()
          .url()
          .label('Authorization endpoint'),
      }),
    }),
    strategy: Yup.string().label('Strategy'),
    emailDomains: Yup.array()
      .of(
        Yup.string()
          .matches(
            /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/,
            'Domain requires a valid domain',
          )
          .required(),
      )
      .label('Domain')
      .min(1, 'At least one domain is required')
      .max(50, 'Domain limit reached')
      .required(),
  }),
  samlp: Yup.object({
    emailDomains: Yup.array()
      .of(
        Yup.string()
          .matches(
            /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/,
            'Domain requires a valid domain',
          )
          .required(),
      )
      .label('Domain')
      .min(1, 'At least one domain is required')
      .max(50, 'Domain limit reached')
      .required(),
  }),
};
