import { ApmRoute } from '@elastic/apm-rum-react';
import { PageNotFound } from 'features/main/components/CustomErrorPages';
import React from 'react';
import { Switch } from 'react-router-dom';
import { ProfileManage } from './manage/ProfileManage';
import { Box } from '@chakra-ui/react';

const ProfileContainer = () => {
  return (
    <Box h='full' overflow='auto'>
      <Switch>
        <ApmRoute exact path='/profile' component={ProfileManage} />
        <ApmRoute component={PageNotFound} />
      </Switch>
    </Box>
  );
};

export default ProfileContainer;
