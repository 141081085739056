export const APP_XM = 'XMCloud';
export const APP_CDP = 'CDP';
export const APP_ORGANIZATION = 'Organization';

export const ORG_ROLES = {
  ORG_OWNER: 'Organization Owner',
  ORG_ADMIN: 'Organization Admin',
  ORG_USER: 'Organization User',
};

export const APP_ROLES = {
  ADMIN: 'Admin',
  USER: 'User',
};
