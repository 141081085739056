import { useToast } from '@chakra-ui/react';
import { AxiosError, AxiosResponse } from 'axios';
import { useFormik } from 'formik';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { ContextErrors, createHostnameSchema } from '../utils';
import { useCreateHostname } from './useCreateHostname';
import { EdgeApiError, Hostname } from '../types';
import { customHostnamesRoute } from '../routes';

export type CreateValues = {
  hostname: string;
  defaultSiteId: string;
  defaultContextId: string;
};

const defaultValues: CreateValues = {
  hostname: '',
  defaultSiteId: '',
  defaultContextId: '',
};

export const useHostnameCreationForm = () => {
  const toast = useToast();
  const history = useHistory();
  const queryClient = useQueryClient();

  const { mutate: createHostname, isLoading: isCreateHostnameLoading } =
    useCreateHostname();
  const {
    touched,
    values,
    errors,
    handleChange,
    handleSubmit,
    handleBlur,
    setSubmitting,
    isValid,
    isValidating,
    isSubmitting,
    dirty,
    initialValues,
  } = useFormik({
    initialValues: defaultValues,
    onSubmit: (values, { setFieldError }) => {
      const options = {
        onSuccess: (res: AxiosResponse<Hostname>) => {
          queryClient.invalidateQueries('hostnames/list');
          toast({
            description: 'Hostname has been registered',
            status: 'success',
          });
          setSubmitting(false);
          history.push({
            pathname: `${customHostnamesRoute}/${res?.data?.hostname}/records`,
            search: history.location.search,
          });
        },
        onError: (error: AxiosError<EdgeApiError>) => {
          const fieldError = ContextErrors.includes(
            error?.response?.data?.detail ?? '',
          )
            ? 'defaultContextId'
            : 'hostname';
          setFieldError(fieldError, error?.response?.data?.detail);
          toast({
            description: 'Something went wrong',
            title: 'Error',
            status: 'error',
          });
          setSubmitting(false);
        },
      };
      //@ts-ignore
      createHostname(values, options);
    },
    validationSchema: createHostnameSchema,
  });

  return {
    touched,
    values,
    errors,
    handleChange,
    handleSubmit,
    handleBlur,
    setSubmitting,
    isValid,
    isValidating,
    isSubmitting,
    isLoading: isValidating || isSubmitting || isCreateHostnameLoading,
    dirty,
    initialValues,
  };
};
