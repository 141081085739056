import { useGetTenantsQuery } from 'gql/graphql';
import { useMemo } from 'react';
import { env as envConfig } from 'features/common/config/envConfig';
import { adaptGraphQLDataToUserEnvironmentObject } from '../../common/utils/dataAdapters';

export const useFetchTenantsData = () => {
  const { data: tenantsQueryData, loading: areTenantsLoading } =
    useGetTenantsQuery({
      variables: {
        first: 100,
        env: envConfig,
      },
    });

  const transformedData = useMemo(
    () =>
      adaptGraphQLDataToUserEnvironmentObject({
        //@ts-ignore
        tenantsQueryData,
      }),
    [tenantsQueryData],
  );

  const tenantsData = useMemo(
    () => transformedData.tenantsData || [],
    [transformedData.tenantsData],
  );
  const isLoading = useMemo(() => areTenantsLoading, [areTenantsLoading]);

  return {
    tenantsData,
    isLoading,
  };
};
