import { forwardRef } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  ButtonGroup,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  ModalCloseButton,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { T } from '@transifex/react';
import { history } from 'features/common/history/history';
import { ssoRoute } from '../sso-routes';

type TestConnectionModalProps = {
  defaultIsOpen: boolean;
  description: string;
  title: string;
  children?: React.ReactNode;
  testId: string;
};

const TestConnectionModal = forwardRef(
  (
    {
      defaultIsOpen,
      title,
      description,
      children,
      testId,
    }: TestConnectionModalProps,
    ref: any,
  ) => {
    const { isOpen } = useDisclosure({ defaultIsOpen });
    return (
      <AlertDialog
        isOpen={isOpen}
        onClose={() => history.replace(`${ssoRoute}${history.location.search}`)}
        leastDestructiveRef={ref}
        size='xl'
        data-testid={`testConnectionModal-${testId}`}
        autoFocus={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent gap={2}>
            <AlertDialogHeader>
              <Text data-testid={`testConnectionModal-${testId}-title`}>
                <T _str={title} />
              </Text>
            </AlertDialogHeader>
            <ModalCloseButton
              data-testid={`testConnectionModal-${testId}-close-btn`}
            />
            <AlertDialogBody>
              <Text data-testid={`testConnectionModal-${testId}-description`}>
                <T _str={description} />
              </Text>
            </AlertDialogBody>
            <AlertDialogFooter>
              <ButtonGroup>{children}</ButtonGroup>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    );
  },
);

export default TestConnectionModal;
