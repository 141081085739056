import { gql } from '@apollo/client';

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    user {
      id
      email
      emailVerified
      created
      updated
      lastLogin
      signUpCompleted
      givenName
      familyName
      nickName
      phoneNumber
      company
      jobRole
      preferredLanguage
      isExternal
      emailVerified
    }
  }
`;
