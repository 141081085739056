import inviteTeamMembers from './invite-team-members.json';
import xmCloudDeploy from './xm-cloud-deploy.json';
import help from './help.json';
import createSupportCase from './create-support-case.json';
import { QuickActionSchema } from '@sitecore-ui/portal-singular';

const generalQuickActionsSchemas = [
  xmCloudDeploy,
  inviteTeamMembers,
  help,
  createSupportCase,
] as QuickActionSchema[];

export { generalQuickActionsSchemas };
