import * as React from 'react';
import { GenericErrorTemplateProps } from './model';
import { variants } from './resources';
import { Box, Container, Flex, Image, Text, Heading } from '@chakra-ui/react';

const GenericErrorTemplate = ({
  title,
  subtext,
  text,
  variant,
  shortcuts,
}: GenericErrorTemplateProps) => {
  const activeVariant = variants[variant];

  return (
    <Container pt={9} py={8} pb={12} height='100%' width='100%'>
      <Container maxW='sm' width='sm' pt={24}>
        <Flex justify='center' flexDirection='column' alignItems='center'>
          <Box
            borderRadius='2xl'
            display='flex'
            alignItems='center'
            justifyContent='center'
          >
            <Image
              boxSize='32'
              src={activeVariant.illustration.colored}
              alt={activeVariant.label}
            />
          </Box>
          <Heading size='xl' textAlign='center' pt='8'>
            {title || activeVariant.label}
          </Heading>
          <Text textAlign='center' variant='subtle' pt='2'>
            {subtext || activeVariant.error}
          </Text>
          <Text textAlign='center' py='6' size='md' color='neutral.800'>
            {text}
          </Text>
          {shortcuts && (
            <Box display='flex' flexDirection='column' alignItems='center'>
              {shortcuts}
            </Box>
          )}
        </Flex>
      </Container>
    </Container>
  );
};

export { GenericErrorTemplate };
