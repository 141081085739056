import { Box, HStack, Text } from '@chakra-ui/react';
import MediaCard from 'features/common/components/MediaCard/MediaCard';
import { OrganizationApplication, UserApplicationRole } from 'gql/graphql';
import { iconByProduct } from 'features/subscriptions/utils';

const InviteDetailsProductAccess = ({
  roles,
  applications,
}: {
  roles: UserApplicationRole[];
  applications?: OrganizationApplication[];
}) => (
  <Box data-testid='invitation-details-product-access'>
    {!!roles?.length && (
      <>
        <Text mb='2' fontSize='md' fontWeight='medium'>
          App Access
        </Text>
        {roles.map(({ applicationId, role, productCode }) => {
          const { displayName } =
            applications?.find(({ id }) => id === applicationId) ?? {};
          const { name, iconUrl } =
            iconByProduct[productCode.toLowerCase()] ?? {};
          return (
            <Box
              key={applicationId}
              pl='4'
              pt='0.5'
              py='0.5'
              lineHeight='10'
              mb='4'
              w='100%'
              data-testid='invitation-details-product-access-roles'
            >
              <HStack w='100%' justifyContent='space-between'>
                <Box mr='4'>
                  <MediaCard
                    size='option'
                    title={name}
                    label={displayName}
                    imageUrl={iconUrl}
                  />
                </Box>
                <Text noOfLines={1} w={44}>
                  {role}
                </Text>
              </HStack>
            </Box>
          );
        })}
      </>
    )}
  </Box>
);

export default InviteDetailsProductAccess;
