import { useRef } from 'react';
import {
  ButtonGroup,
  Text,
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from '@chakra-ui/react';
import { T } from '@transifex/react';

interface IDiscardChangesProps {
  isOpen: boolean;
  onClose: () => void;
  onDiscard: () => void;
}

const DiscardChangesModal = ({
  isOpen,
  onClose,
  onDiscard,
  ...rest
}: IDiscardChangesProps) => {
  const lessDestructiveRef = useRef(null);
  return (
    <AlertDialog
      {...rest}
      isOpen={isOpen}
      onClose={onClose}
      leastDestructiveRef={lessDestructiveRef}
      data-testid='discardChangesModal'
      aria-label='Discard changes modal'
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>
            <Text as='span' data-testid='discardChangesModal-title'>
              <T _str='Discard changes?' />
            </Text>
          </AlertDialogHeader>
          <AlertDialogBody>
            <Text>
              <T _str='Discard unsaved changes and close?' />
            </Text>
          </AlertDialogBody>
          <AlertDialogFooter>
            <ButtonGroup>
              <Button
                variant='ghost'
                onClick={onClose}
                ref={lessDestructiveRef}
                data-testid='discardChangesModal-keepEditingButton'
              >
                <T _str='Keep editing' />
              </Button>
              <Button
                colorScheme='danger'
                variant='solid'
                onClick={onDiscard}
                data-testid='discardChangesModal-discardButton'
              >
                <T _str='Discard' />
              </Button>
            </ButtonGroup>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default DiscardChangesModal;
