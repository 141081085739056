import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
} from '@chakra-ui/react';
import { getOverageEntitlements } from './utils';
import { Entitlement } from 'gql/graphql';

const SubscriptionAlerts = (entitlements: { entitlements: Entitlement[] }) => {
  const overageEntitlements = getOverageEntitlements(entitlements);

  return (
    <>
      {overageEntitlements?.map((entitlement) => (
        <Alert
          key={entitlement.name}
          status={entitlement.alertStatus}
          data-testid={`${entitlement.quotaStatus}-usage-alert-${entitlement.name}`}
        >
          <AlertIcon />
          <Box>
            <AlertTitle
              data-testid={`${entitlement.quotaStatus}-usage-alert-title-${entitlement.name}`}
            >
              {entitlement.name} usage {entitlement.quotaStatus} the limit
            </AlertTitle>
            <AlertDescription>
              To upgrade your entitlements, contact your Customer Success
              Manager.
            </AlertDescription>
          </Box>
        </Alert>
      ))}
    </>
  );
};

export default SubscriptionAlerts;
