import createReducer from '../../../common/utils/createReducer';
import { MainState } from '../model';
import { DrawerId } from './model';

const initialDrawerManagerState = {
  drawerVisible: false,
  drawerId: 'default',
  data: null,
};

const drawerReducerMap = {
  OPEN_DRAWER: (
    state: MainState,
    payload: { drawerId: DrawerId; data?: any },
  ) => {
    return {
      ...state,
      drawer: {
        ...state.drawer,
        data: payload.data,
        drawerId: payload.drawerId,
        drawerVisible: true,
      },
    };
  },
  CLOSE_DRAWER: (state: MainState) => {
    return {
      ...state,
      drawer: {
        ...state.drawer,
        drawerVisible: false,
      },
    };
  },
};

const drawerReducer = createReducer(
  initialDrawerManagerState,
  drawerReducerMap,
);

export { drawerReducer, initialDrawerManagerState };
