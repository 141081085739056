import { useAuthQuery } from 'features/common/apiUtils/queryHelper';
import { connectionsApi } from './endpoints';
import { getIdentityBaseUrl } from '@sitecore-ui/portal-singular';
import { env } from 'features/common/config/envConfig';
import { AxiosResponse } from 'axios';
import { UseQueryOptions } from 'react-query';
import { IConnectionSaml, IOidcConnection } from 'features/sso/api/types';

const baseUrl = getIdentityBaseUrl(env.toLowerCase());

export const useGetConnection = (
  id: string,
  useQueryOptions?:
    | Omit<
        UseQueryOptions<
          AxiosResponse<IOidcConnection | IConnectionSaml>,
          unknown,
          AxiosResponse<IOidcConnection | IConnectionSaml>,
          string[]
        >,
        'queryKey' | 'queryFn'
      >
    | undefined,
) => {
  const { get_connection } = connectionsApi;
  const url = `${baseUrl}${get_connection.url(id)}`;

  return useAuthQuery(
    ['connections/connection', id],
    async (axiosInstance) => {
      const response = await axiosInstance?.get<
        IOidcConnection | IConnectionSaml
      >(url);
      if ('oidc' in response.data.options) {
        return response as AxiosResponse<IOidcConnection>;
      } else if ('samlp' in response.data.options) {
        return response as AxiosResponse<IConnectionSaml>;
      } else {
        throw new Error('Unexpected response type');
      }
    },
    { ...useQueryOptions, cacheTime: 0 },
    get_connection.scope,
  );
};
