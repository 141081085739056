import React from 'react';
import { ExtensionLifeCycleParams } from '@sitecore/page-composer-sdk';

const FedUiContext = React.createContext<{
  fedUiContext?: ExtensionLifeCycleParams;
}>({});

export const FedUiProvider = (props: {
  fedUiContext: ExtensionLifeCycleParams;
  children?: React.ReactNode;
}) => (
  <FedUiContext.Provider value={{ fedUiContext: props.fedUiContext }}>
    {props.children}
  </FedUiContext.Provider>
);

export const useFedUiContext = () => {
  return React.useContext(FedUiContext);
};
