import { Button } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { history } from 'features/common/history/history';
import { redirect } from 'features/common/utils/utils';
import { useGetConnection } from 'features/sso/api';
import { ssoRoute } from 'features/sso/sso-routes';
import { getLoginUrl } from 'features/sso/utils';
import withConnectionIdGuard from 'features/sso/with-connection-id-guard';
import { useRef } from 'react';
import { useParams } from 'react-router';
import TestConnectionModal from '../test-connection-modal';

const description =
  'To see if the SSO connection works, click Start test. In the tab that opens, sign in to your identity provider. After you sign in, return to this page to continue.';

export const StartTest = withConnectionIdGuard(() => {
  const lessDestructiveRef = useRef(null);
  const { id, ssoProvider } = useParams<{ id: string; ssoProvider: string }>();
  const { data: connection, isLoading } = useGetConnection(id, {
    enabled: !!id,
  });
  const { name } = connection?.data ?? {};
  const testUrl = `${getLoginUrl()}/TestConnection?connectionName=${name}`;

  return (
    <TestConnectionModal
      ref={lessDestructiveRef}
      defaultIsOpen={true}
      title='Test SSO connection'
      description={description}
      testId='startTest'
    >
      <Button
        variant='ghost'
        onClick={() => history.replace(`${ssoRoute}${history.location.search}`)}
        ref={lessDestructiveRef}
        isDisabled={isLoading}
        data-testid='testConnectionModal-closeButton'
      >
        <T _str='Cancel' />
      </Button>
      <Button
        variant='solid'
        isLoading={isLoading}
        onClick={() => {
          redirect(testUrl);
          history.replace(
            `${ssoRoute}/${ssoProvider}/${id}/restart-test-connection${history.location.search}`,
          );
        }}
        data-testid='testConnectionModal-startTestButton'
      >
        <T _str='Start test' />
      </Button>
    </TestConnectionModal>
  );
});
