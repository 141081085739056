import React from 'react';
import { Box, Heading, VStack } from '@chakra-ui/react';
import { TopPicks } from '../components/TopPicks';
import { GradientBackground, Scrollable } from '../../../shared/components';
import { BrowseLayout } from '../layouts/BrowseLayout';
import { t } from '../../../shared/utils/i18n';

export const BrowseRoute: React.FC = () => {
  const title = (
    <Heading as='h1' size='3xl' lineHeight={'120%'}>
      {t('Explore apps and plugins')}
      <br />
      {t('for Sitecore DXP')}
    </Heading>
  );

  const content = (
    <VStack spacing='0' align='stretch' height='100%'>
      <Heading size='lg' mb='5'>
        {t('Start here to find the perfect fit for your needs')}
      </Heading>
      <Box flex='1' minHeight='0' height='100%'>
        <Scrollable paddingBottom='4.5rem'>
          <TopPicks />
        </Scrollable>
      </Box>
    </VStack>
  );

  const customErrorMessage = t(
    "We're having trouble loading your top picks. Please try again later.",
  );

  return (
    <GradientBackground>
      <BrowseLayout
        title={title}
        content={content}
        errorMessage={customErrorMessage}
      />
    </GradientBackground>
  );
};
