import { hostnames } from 'features/common/config/envConfig';

export const baseEdgePlatfromAdminApiUrl = {
  [hostnames.internal]:
    'https://edge-platform-admin-api-staging.sitecore-staging.cloud',
  [hostnames.localhost]:
    'https://edge-platform-admin-api-staging.sitecore-staging.cloud',
  [hostnames.dev]: 'https://edge-platform-admin-api-dev.sitecore-staging.cloud',
  [hostnames.qa]: 'https://edge-platform-admin-api-qa.sitecore-staging.cloud',
  [hostnames.staging]:
    'https://edge-platform-admin-api-staging.sitecore-staging.cloud',
  [hostnames.beta]: 'https://edge-platform-admin-api-preprod.sitecorecloud.io',
  [hostnames['pre-production']]:
    'https://edge-platform-admin-api-preprod.sitecorecloud.io',
  [hostnames.production]: 'https://edge-platform-admin-api.sitecorecloud.io',
};

export const edgePlatformAdminApi = {
  get_hostnames: {
    url: () =>
      `${
        baseEdgePlatfromAdminApiUrl[window.location.hostname]
      }/api/admin/v1/hostnames`,
    scope: 'ep.admn.orgs.hstnme:r',
  },
  get_hostname: {
    url: (hostname: string) =>
      `${
        baseEdgePlatfromAdminApiUrl[window.location.hostname]
      }/api/admin/v1/hostnames/${hostname}`,
    scope: 'ep.admn.orgs.hstnme:r',
  },
  create_hostname: {
    url: () =>
      `${
        baseEdgePlatfromAdminApiUrl[window.location.hostname]
      }/api/admin/v1/hostnames`,
    scope: 'ep.admn.orgs.hstnme:w',
  },
  update_hostname: {
    url: (hostname: string) =>
      `${
        baseEdgePlatfromAdminApiUrl[window.location.hostname]
      }/api/admin/v1/hostnames/${hostname}`,
    scope: 'ep.admn.orgs.hstnme:w',
  },
  delete_hostname: {
    url: (hostname: string) =>
      `${
        baseEdgePlatfromAdminApiUrl[window.location.hostname]
      }/api/admin/v1/hostnames/${hostname}`,
    scope: 'ep.admn.orgs.hstnme:d',
  },
};
