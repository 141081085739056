import { useEffect, useRef, useState } from 'react';
import {
  Text,
  FormControl,
  Box,
  ButtonGroup,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
} from '@chakra-ui/react';
import { T } from '@transifex/react';
import { useAuth0 } from '@auth0/auth0-react';
import { TokenCustomClaimKeysEnum } from '@sitecore-ui/portal-singular';
import { CopyClipboardInput } from 'features/common/components/CopyClipboardInput';
interface IAPITokenModal {
  isOpen: boolean;
  onClose: () => void;
}
const APITokenModal = ({ isOpen, onClose, ...rest }: IAPITokenModal) => {
  const btnLeastDestructiveRef = useRef(null);
  const { getAccessTokenSilently, user } = useAuth0();
  const organizationId = user?.[TokenCustomClaimKeysEnum.ORG_ID];
  const [accessToken, setAccessToken] = useState('');

  useEffect(() => {
    (async () => {
      const token = await getAccessTokenSilently({
        organization_id: organizationId,
      });
      setAccessToken(token);
    })();
  }, [getAccessTokenSilently, organizationId]);

  return (
    <div data-testid='apiTokenModal'>
      <Modal
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
        size='lg'
        autoFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text data-testid='apiTokenModal-title'>
              <T _str='API tokens' />
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box maxW='lg'>
              <FormControl>
                <CopyClipboardInput
                  inputLabel='Bearer token'
                  isReadOnly
                  inputValueProp={accessToken}
                  dataTestId='apiTokenInput'
                />
              </FormControl>
            </Box>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button
                onClick={onClose}
                ref={btnLeastDestructiveRef}
                data-testid='apiTokenModal-doneButton'
              >
                <T _str='Done' />
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default APITokenModal;
