import {
  Card,
  CardBody,
  HStack,
  Heading,
  Button,
  Image,
  Text,
  Badge,
} from '@chakra-ui/react';

const TryAppCard = ({
  icon,
  name,
  description,
  onClick,
}: {
  icon: string;
  name: string;
  description: string;
  onClick: () => void;
}) => (
  <Card
    variant='flat'
    size='md'
    data-testid={`discover-apps-${name}`}
    maxW='sm'
  >
    <CardBody>
      <HStack spacing='3' alignItems='center'>
        <Image width='10' height='10' src={icon} alt='connect icon' />
        <Heading size='sm'>{name}</Heading>
        <Badge>FREE</Badge>
      </HStack>
      <Text mt='4' mb='8' variant='subtle'>
        {description}
      </Text>
      <Button
        data-testId={`try-app-${name}-enable`}
        variant='outline'
        onClick={onClick}
        aria-label={`enable ${name}`}
      >
        Enable
      </Button>
    </CardBody>
  </Card>
);

export default TryAppCard;
