/* eslint-disable @typescript-eslint/no-unused-vars */
import { t } from '@transifex/native';
import { AxiosResponse } from 'axios';
import {
  convertFieldsMapToViewState,
  getSavedMetadataTypeSelection,
} from '../saml/saml-utils';
import { ssoRoute } from '../sso-routes';
import {
  IOidcConnection,
  IConnectionSaml,
  IConnectionSamlViewModel,
  IConnectionViewModel,
} from './types';
import { UseToastOptions } from '@chakra-ui/react';

/**
 * Convert IOidcConnection to IConnectionViewModel
 */
export const convertToIConnectionViewModel = (
  connection: IOidcConnection,
  isEditMode?: boolean,
): IConnectionViewModel => {
  const { id, created, name, strategy, ...rest } = connection;

  return {
    ...rest,
    ...(rest.options && {
      options: {
        ...rest.options,
        oidc: {
          ...rest.options.oidc,
          isEditMode: isEditMode,
          clientSecretInteractionMode: false,
          typeInitial: rest.options.oidc.type,
        },
      },
    }),
  };
};

export const convertToIConnectionSamlViewModel = (
  connection: IConnectionSaml,
  isEditMode?: boolean,
): IConnectionSamlViewModel => {
  const { options, displayName, emailDomains } = connection;
  const { metadataXml, metadataUrl, fieldsMap, signInEndpoint } = options.samlp;

  return {
    ...options.samlp,
    emailDomains: emailDomains || [],
    displayName: displayName || '',
    metadataSelection: getSavedMetadataTypeSelection(
      signInEndpoint,
      metadataUrl,
    ),
    metadataUrl: metadataUrl || '',
    metadataXml: metadataXml || '',
    fieldsMap: convertFieldsMapToViewState(fieldsMap),
    signInEndpoint: signInEndpoint,
  };
};

interface IConnectionPatchDTO
  extends Omit<IConnectionViewModel, 'displayName'> {}

/**
 * Convert IConnectionViewModel to IOidcConnection for Patch
 */
export const convertToIConnectionPatchDTO = (
  connection: IConnectionViewModel,
  initialValues: any,
): IConnectionPatchDTO | object => {
  const {
    emailDomains,
    organizationId,
    accountId,
    state,
    verified,
    verification,
    ...rest
  } = connection;

  return getModifiedProperties(connection, initialValues);
};

export const getModifiedProperties = (
  newObj: { [key: string]: any },
  oldObj: { [key: string]: any },
): IConnectionPatchDTO | object => {
  return Object.keys(newObj).reduce((result: { [key: string]: any }, key) => {
    if (typeof newObj[key] === 'object' && !Array.isArray(newObj[key])) {
      // If the property is an object (excluding arrays), recursively check for changes
      const nestedChanges = getModifiedProperties(
        newObj[key] ?? {},
        oldObj[key],
      );
      if (Object.keys(nestedChanges).length > 0) {
        result[key] = nestedChanges;
      }
    } else if (
      Array.isArray(newObj[key]) &&
      JSON.stringify(oldObj[key]) !== JSON.stringify(newObj[key])
    ) {
      // If the property has changed, add it to the result
      result[key] = newObj[key];
    } else if (oldObj[key] !== newObj[key]) {
      result[key] = newObj[key];
    }
    return result;
  }, {});
};

export const displayConnectionErrorAlert = (
  toast: (arg0: UseToastOptions) => void,
  claimsData: { error: any; title: any },
) => {
  if (claimsData.error) {
    toast({
      description: claimsData.title,
      status: 'error',
    });
  } else {
    toast({
      description: 'Something went wrong',
      status: 'error',
    });
  }
};
