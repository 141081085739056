import { Box, Skeleton, VStack } from '@chakra-ui/react';

export const SkeletonCard: React.FC = () => (
  <Box
    data-testid='skeleton-card'
    w='100%'
    borderWidth='1px'
    borderRadius='lg'
    overflow='hidden'
    p={4}
  >
    <VStack align='start' spacing={3}>
      <Skeleton height='50px' width='50px' />
      <Skeleton height='24px' width='80%' />
      <Skeleton height='36px' width='100%' />
      <Skeleton height='20px' width='60%' />
      <Skeleton height='20px' width='40%' />
    </VStack>
  </Box>
);
