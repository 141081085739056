import { getContextApiUrl } from '../utils';
import { useAuthMutation } from '../../common/apiUtils/queryHelper';
import { CreateSitecoreContextType } from '../types/types';

export const useCreateSitecoreContext = () => {
  const url = `${getContextApiUrl()}/contexts`;

  return useAuthMutation(
    (axiosInstance) => (payload: CreateSitecoreContextType) =>
      axiosInstance?.post(url, payload),
    {},
  );
};
