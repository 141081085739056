import { useGetTenants } from 'features/common/services/inventory/tenants';
import { useMemo } from 'react';
import {
  ITenant,
  IUserRole,
  getTenantQuickActionsPerProduct,
  filterTenantsByExistingRole,
} from '@sitecore-ui/portal-singular';
import { useAppAccess } from 'features/common/components/OrganizationAppAccess';
import { useMainState } from 'features/main/context';
import { quickActionsMapper } from 'features/domains/portal/content/utils';
import { env } from 'features/common/config/envConfig';

export const useGetActiveProducts = () => {
  const state = useMainState();
  const { data } = useGetTenants({
    pageNumber: 1,
    pageSize: 100,
  });
  const { userRoles } = useAppAccess();

  const activeProducts = useMemo(() => {
    const tenants = data?.data.data ?? [];
    const products = new Set(
      filterTenantsByExistingRole({
        tenants: tenants ?? [],
        roles: userRoles,
        isOrgUser: !!state.env?.ORG_USER,
      }).map((tenant: ITenant) => tenant.labels.ProductCode),
    );

    return [...products];
  }, [data?.data.data, state.env?.ORG_USER, userRoles]);

  return { activeProducts };
};

export const getTenantQuickActions = (
  tenant: ITenant,
  userRoles: IUserRole[],
  activeProducts: string[],
) =>
  getTenantQuickActionsPerProduct(tenant, userRoles, env, activeProducts).map(
    quickActionsMapper,
  );
