import { Flex, SkeletonText, Skeleton } from '@chakra-ui/react';

export const BodySkeleton = () => (
  <Flex data-testid='app-details-body-main-skeleton' flexDir='column' gap={10}>
    <Flex data-testid='app-details-body-text-skeleton'>
      <SkeletonText noOfLines={4} spacing='4' w='300px' />
    </Flex>
    <Flex
      data-testid='app-details-body-screenshots-container-skeleton'
      flexDir='column'
      gap={4}
    >
      <Skeleton height='20px' width='150px' />
      <Flex
        data-testid='app-details-body-screenshots-skeleton'
        gap={4}
        flexWrap='wrap'
        maxW='5xl'
      >
        <Skeleton width='320px' height='200px' borderRadius='lg' />
        <Skeleton width='320px' height='200px' borderRadius='lg' />
        <Skeleton width='320px' height='200px' borderRadius='lg' />
      </Flex>
    </Flex>
  </Flex>
);
