import { useAuth0 } from '@auth0/auth0-react';
import { Icon, IconButton, Tooltip, useMediaQuery } from '@chakra-ui/react';
import {
  mdiAccountCircleOutline,
  mdiDotsGrid,
  mdiHelpCircleOutline,
  mdiInformationOutline,
  mdiLogout,
} from '@mdi/js';
import React, { useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { StaticContext } from 'react-router';
import { t } from '@transifex/native';
import { history } from '../../common/history/history';
import { useGetCurrentUser } from '../../profile/services/identity/user';
import { useAppAccess } from 'features/common/components/OrganizationAppAccess';
import {
  useDrawerState,
  useMainStateDispatch,
} from 'features/main/context/main-context';
import OrgName from './OrgName';
import { IconLinks } from 'features/common/utils/icons';
import * as auth0Storage from 'features/common/auth/auth0Storage';
import { openDrawer } from '../../main/context/drawer/actionCreators';
import { ApmRoute } from '@elastic/apm-rum-react';
import { NavigationHorizontal } from 'features/common/components/Navigation';
import sitecoreTheme from '@sitecore/blok-theme';
import { PortalIcon } from 'features/common/components/PortalIcon';
import { AppSwitcher } from 'features/app-switcher/AppSwitcher';
import APITokenModal from './APITokenModal';
import MobileMenu from './MobileMenu';
import { useGetMainMenuItems } from 'features/common/hooks/navigationMenu/useGetNavigationMenuItems';

type THistory<T = unknown> = ReturnType<typeof useHistory<T>>;

type NavigationItems = {
  history: THistory;
  props?: RouteComponentProps<any, StaticContext, unknown>;
  omit?: boolean;
};

type NavigationMenuItems = NavigationItems & {
  hasOrgAdminAccess?: boolean;
  hasOrgOwnerAccess?: boolean;
  hasOrg?: boolean;
};

const TopBarComponent = (
  // eslint-disable-next-line @typescript-eslint/ban-types
  props: RouteComponentProps<{}, StaticContext, unknown>,
) => {
  const [, dispatch] = useMainStateDispatch();
  const drawerState = useDrawerState();
  const { logout } = useAuth0();
  const { hasOrgAdminAccess, hasOrgOwnerAccess, hasOrg } = useAppAccess();
  const { data } = useGetCurrentUser();
  const { mainMenuItems } = useGetMainMenuItems();
  const { helpDrawerVisible } = drawerState;
  const currentUser = data?.data;

  const navigationActions = [
    {
      active: helpDrawerVisible,
      ariaLabel: t('Help'),
      icon: <PortalIcon path={mdiHelpCircleOutline} />,
      onClick: () => dispatch(openDrawer('help-drawer')),
      'data-behavior-analytics-id': 'Help drawer button - Topmenu',
      'data-testid': 'help-drawer-button',
    },
  ];

  const onLogout = () => {
    auth0Storage.resetLastSuccessfulLoginOrganizationId();
    logout({
      returnTo: window.location.origin,
    });
  };

  const memoizedItems = React.useMemo(() => {
    return [(key: string | number) => <OrgName key={key} />, ...mainMenuItems];
  }, [mainMenuItems]);

  const [isBiggerThanMd] = useMediaQuery(
    `(min-width: ${sitecoreTheme.breakpoints.md})`,
  );

  const [isApiTokenModalOpen, setApiTokenModalOpen] = useState(false);

  const navigationMenuItemsGetter = ({
    history,
    hasOrg,
    hasOrgAdminAccess,
    hasOrgOwnerAccess,
  }: NavigationMenuItems) => [
    {
      label: 'Manage account',
      onClick: () => history.push(`/profile${history.location.search}`),
      icon: (
        <Icon>
          <path d={mdiAccountCircleOutline} />
        </Icon>
      ),
      valid: true,
    },
    {
      label: 'API tokens',
      onClick: () => setApiTokenModalOpen(true),
      icon: (
        <Icon>
          <path d={mdiInformationOutline} />
        </Icon>
      ),
      valid: hasOrg || hasOrgOwnerAccess || hasOrgAdminAccess,
    },
    {
      label: 'Log out',
      onClick: () => onLogout(),
      icon: (
        <Icon>
          <path d={mdiLogout} />
        </Icon>
      ),
      valid: true,
    },
  ];

  const memoizedNavMenuItems = React.useMemo(
    () =>
      Object.assign([
        ...navigationMenuItemsGetter({
          props,
          history,
          hasOrg,
          hasOrgAdminAccess,
          hasOrgOwnerAccess,
        }).filter(({ valid }) => valid),
      ]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasOrg, hasOrgAdminAccess, hasOrgOwnerAccess, props],
  );

  const handleDialogClose = (): void => {
    setApiTokenModalOpen(false);
  };

  return (
    <>
      <AppSwitcher />
      <NavigationHorizontal
        icon={
          <Tooltip label='Switch to…'>
            <IconButton
              className='js-app-switcher-button'
              variant='ghost'
              size='sm'
              icon={
                <Icon>
                  <path d={mdiDotsGrid} />
                </Icon>
              }
              aria-label={'Switch…'}
            />
          </Tooltip>
        }
        data-testid='nav-bar'
        items={memoizedItems}
        mobileItems={<MobileMenu />}
        image={{
          h: 8,
          width: 'auto',
          p: 1,
          cursor: 'pointer',
          src: isBiggerThanMd
            ? IconLinks.sitecoreLogo
            : IconLinks.sitcoreLogoIconOnly,
          onClick: () => history.push(`/${history.location.search}`),
        }}
        menu={{
          name:
            currentUser?.givenName && currentUser?.familyName
              ? `${currentUser.givenName} ${currentUser.familyName}`
              : '',
          email: currentUser?.email ?? '',
          items: memoizedNavMenuItems,
        }}
        actions={navigationActions}
      />
      <APITokenModal isOpen={isApiTokenModalOpen} onClose={handleDialogClose} />
    </>
  );
};

export const TopBar = React.memo(() => (
  <ApmRoute path='*' component={TopBarComponent} />
));
