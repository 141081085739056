import { Box } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  generateConnectionsTableData,
  getTableOptions,
} from './connections-table-helpers';
import orderBy from 'lodash/orderBy';
import { useGetConnections } from 'features/sso/api';
import { ConnectionType } from '../types';
import { DataTable } from 'features/common/components/DataTable';

type ConnectionsTableTypes = {
  connections: ConnectionType[] | undefined;
};

export const ConnectionsTable = ({ connections }: ConnectionsTableTypes) => {
  const { isLoading, isFetching } = useGetConnections();
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [sortBy, setSortBy] = useState([{ id: 'name', desc: false }]);
  const { columns } = getTableOptions();
  const tableData = useMemo(
    () => (connections ? generateConnectionsTableData(connections) : []),
    [connections],
  );
  const { id, desc } = sortBy[0] || { id: 'name', desc: false };

  const handleChange = useCallback((opts: any) => {
    setIsTableLoading(true);
    setSortBy(opts.sortBy);
    setIsTableLoading(false);
  }, []);

  useEffect(() => {
    setIsTableLoading(isLoading);
  }, [isLoading]);

  const sortedData = useMemo(() => {
    return orderBy(tableData, [id], [desc ? 'desc' : 'asc']);
  }, [tableData, id, desc]);

  return (
    <Box w='full'>
      <DataTable
        page={1}
        pageSize={5}
        isLoading={isTableLoading || isFetching}
        columns={columns}
        data={sortedData}
        sortBy={useMemo(() => [], [])}
        pagination={'hidden'}
        onChange={handleChange}
      />
    </Box>
  );
};
