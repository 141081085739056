import { Heading, Button, Box, Container, Flex, Image } from '@chakra-ui/react';
import { IErrorPageProps } from './ErrorPage';

export const MembersErrorPage = ({ resetErrorBoundary }: IErrorPageProps) => (
  <Container pt={9} py={8} pb={12} height='100%' width='100%'>
    <Container maxW='sm' width='sm' pt={24}>
      <Flex justify='center' flexDirection='column' alignItems='center'>
        <Image
          boxSize='24'
          src='https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-alert'
          alt='Alert icon'
        />
        <Heading size='xl' textAlign='center' pt='12' fontSize='xl'>
          Something went wrong
        </Heading>

        <Box display='flex' flexDirection='column' alignItems='center'>
          <Button
            variant='ghost'
            colorScheme='primary'
            size='md'
            mt={4}
            onClick={resetErrorBoundary}
          >
            Try again
          </Button>
        </Box>
      </Flex>
    </Container>
  </Container>
);
