import { mdiOpenInNew } from '@mdi/js';
import { T } from '@transifex/react';
import {
  Media,
  MediaFigure,
  MediaBody,
  MediaTitle,
  MediaDescription,
  MediaHover,
} from '../MediaObject';
import type { IMediaObjectLink } from './model';
import { history } from 'features/common/history/history';
import { redirect } from 'features/common/utils/utils';
import { validateURL } from '@sitecore-ui/portal-singular';

const MediaObjectLink = ({
  link,
  title,
  description,
  iconPath,
  iconColor,
  svgIcon,
  onClick,
  'data-testid': dataTestId,
  'data-behavior-analytics-id': dataBehaviorAnalyticsId,
  'data-support-case': dataSupportCase,
}: IMediaObjectLink) => {
  return (
    <Media
      size='large'
      variant={validateURL(link) ? 'link' : undefined}
      onClick={() => {
        return onClick ? onClick() : redirect(link, history);
      }}
      data-testid={dataTestId}
      data-behavior-analytics-id={dataBehaviorAnalyticsId}
      data-support-case={dataSupportCase}
    >
      <MediaFigure
        icon={{
          path: iconPath || undefined,
        }}
        image={{
          url: svgIcon,
          alt: title,
        }}
        color={iconColor}
        logoColor={iconColor}
      />
      <MediaBody alignSelf='center' display='flex' flexDirection='column'>
        <MediaTitle>{title}</MediaTitle>
        {description && (
          <MediaDescription>
            <T _str={description} />
          </MediaDescription>
        )}
      </MediaBody>
      <MediaHover
        icon={{
          path: mdiOpenInNew,
        }}
      />
    </Media>
  );
};

export default MediaObjectLink;
