import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  HStack,
  Heading,
  Text,
  Image,
  Skeleton,
  TableContainer,
  Table,
  Thead,
  Th,
  Tr,
  Tbody,
  Td,
  Progress,
  TableCaption,
  Container,
  VStack,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/react';
import { Entitlement, useGetSubscriptionQuery } from 'gql/graphql';
import withSubscriptionGuard from './withSubscriptionGuard';
import SubscriptionAlerts from './SubscriptionAlerts';
import { getEntitlementData, getRemainingTime, iconByProduct } from './utils';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { subscriptionsRoute } from './routes';
import { format } from 'date-fns';

const dateFormat = 'MMMM d, y';

const SubscriptionDetails = () => {
  const { subscriptionId } = useParams<{ subscriptionId: string }>();
  const { loading, data: query } = useGetSubscriptionQuery({
    variables: {
      subscriptionId: subscriptionId,
    },
  });

  const {
    contractStart,
    contractEnd,
    editionType,
    sellableProductCode,
    entitlements,
  } = query?.subscriptions?.nodes?.[0] ?? {};

  const productInfo = {
    title: sellableProductCode ? iconByProduct[sellableProductCode].name : '',
    image: sellableProductCode
      ? iconByProduct[sellableProductCode].iconUrl
      : '',
  };

  const history = useHistory();

  const formattedStartDate = contractStart
    ? format(new Date(contractStart), dateFormat)
    : '';
  const formattedEndDate = contractEnd
    ? format(new Date(contractEnd), dateFormat)
    : '';

  return (
    <Box
      h={'full'}
      overflow='auto'
      layerStyle='section.main'
      bg='chakra-subtle-bg'
      data-testid={'subscription-details-page'}
    >
      <Container maxW='container.xl'>
        <VStack alignItems='flex-start' gap={4}>
          {loading && (
            <>
              <Flex alignItems={'center'}>
                <Skeleton h={8} w={'100'} />
              </Flex>
              <Skeleton h={'xs'} w={'full'} />
              <Skeleton h={'xs'} w={'full'} />
            </>
          )}
          {!loading && (
            <>
              <Flex>
                <Breadcrumb>
                  <BreadcrumbItem>
                    <BreadcrumbLink
                      as={Link}
                      to={`${subscriptionsRoute}${history?.location.search}`}
                    >
                      Subscriptions
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Flex>
              <Flex alignItems={'center'} mb={4}>
                <Image
                  mr={3}
                  src={productInfo.image}
                  alt='product icon'
                  boxSize='10'
                />
                <Heading as='h1'>{productInfo.title}</Heading>
              </Flex>
              <SubscriptionAlerts
                entitlements={entitlements?.nodes as Entitlement[]}
              />
              <Card w='full' size='lg' variant='flat'>
                <CardHeader>
                  <Heading size='md'>Subscription details</Heading>
                </CardHeader>
                <CardBody>
                  <HStack spacing={26} mb={2}>
                    <Text variant='strong' color='chakra-subtle-text' w={48}>
                      Subscription name
                    </Text>
                    <Text>{editionType}</Text>
                  </HStack>
                  <HStack spacing={26}>
                    <Text variant='strong' color='chakra-subtle-text' w={48}>
                      Duration
                    </Text>
                    <Text>
                      {formattedStartDate} - {formattedEndDate}
                    </Text>
                  </HStack>
                </CardBody>
              </Card>
              <Card w='full' size='lg' variant='flat'>
                <CardHeader>
                  <Heading size='md'>Entitlements and usage</Heading>
                </CardHeader>
                <CardBody>
                  <TableContainer whiteSpace='normal'>
                    <Table variant='simple'>
                      <Thead>
                        <Tr>
                          <Th>Entitlement</Th>
                          <Th>Used</Th>
                          <Th>Used %</Th>
                          <Th>Time left</Th>
                          <Th>Last updated</Th>
                        </Tr>
                      </Thead>
                      {entitlements?.nodes && (
                        <Tbody>
                          {entitlements.nodes.map((entitlement) => {
                            const {
                              name,
                              used,
                              percentUsed,
                              colorScheme,
                              textColor,
                            } = (getEntitlementData(
                              entitlement as Entitlement,
                            ) as {
                              name: string;
                              used: number;
                              percentUsed: number;
                              textColor: string;
                              colorScheme: string;
                            }) || {
                              name: '',
                              used: 0,
                              percentUsed: 0,
                              textColor: '',
                              colorScheme: 'neutral',
                            };

                            const timeLeft = getRemainingTime(
                              new Date(entitlement.endDate),
                              new Date(),
                            );

                            return (
                              <Tr key={`entitlement-row-${name}`}>
                                <Td>
                                  <strong>{name}</strong>{' '}
                                  {`(${entitlement.periodType.toLowerCase()})`}
                                </Td>
                                <Td>
                                  <Text color={textColor}>
                                    <strong>{used}</strong> of{' '}
                                    {entitlement.quantity}
                                  </Text>
                                </Td>
                                <Td>
                                  <HStack>
                                    {isFinite(percentUsed || 0) ? (
                                      <>
                                        <Progress
                                          w='16'
                                          size='sm'
                                          value={percentUsed}
                                          colorScheme={colorScheme}
                                        />
                                        <Text
                                          variant='strong'
                                          color={textColor}
                                        >
                                          {percentUsed}%
                                        </Text>
                                      </>
                                    ) : (
                                      <Text variant='subtle'>Unlimited</Text>
                                    )}
                                  </HStack>
                                </Td>
                                <Td>{timeLeft}</Td>
                                <Td>
                                  <Text>{entitlement.lastReportedDate}</Text>
                                </Td>
                              </Tr>
                            );
                          })}
                        </Tbody>
                      )}
                      <TableCaption>
                        Showing totals for all organizations using this
                        subscription.
                      </TableCaption>
                    </Table>
                  </TableContainer>
                </CardBody>
              </Card>
            </>
          )}
        </VStack>
      </Container>
    </Box>
  );
};

export default withSubscriptionGuard(SubscriptionDetails);
