import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  HStack,
  Heading,
  VStack,
  Text,
  Link,
  Card,
  Icon,
  Button,
  ButtonGroup,
  DrawerFooter,
  FormControl,
  Box,
  FormLabel,
  FormErrorMessage,
  FormErrorIcon,
} from '@chakra-ui/react';
import {
  mdiGiftOutline,
  mdiButtonPointer,
  mdiDatabaseOutline,
  mdiSwapHorizontal,
  mdiBookOpenPageVariantOutline,
  mdiAlarmCheck,
} from '@mdi/js';
import {
  Media,
  MediaFigure,
  MediaBody,
  MediaTitle,
} from 'features/common/components/MediaObject';
import useModal from 'features/common/hooks/useModal/useModal';
import { tenantsFallbackIcon } from 'features/common/utils/icons';
import { useDrawerStateDispatch } from 'features/main/context';
import { closeDrawer } from 'features/main/context/drawer/actionCreators';
import { iconByProduct } from 'features/subscriptions/utils';
import { useEffect, useRef, useState } from 'react';
import { Select as ReactSelect } from 'chakra-react-select';
import { useGetDeploymentRegionsQuery } from 'gql/graphql';
import { ConnectTermsModal } from './ConnectTermsModal';
import DiscardChangesModal from 'features/common/components/DiscardModal/DiscardChangesModal';

const sellingPoints: { icon: string; text: string }[] = [
  {
    icon: mdiGiftOutline,
    text: 'Absolutely free for non-production purposes',
  },
  {
    icon: mdiAlarmCheck,
    text: 'Limited connect task usage',
  },
  {
    icon: mdiBookOpenPageVariantOutline,
    text: 'Full use of time-bound recipes',
  },
  {
    icon: mdiButtonPointer,
    text: 'Drag-and-drop visual canvas',
  },
  {
    icon: mdiDatabaseOutline,
    text: 'Smart AP-Signature Data Mapping',
  },
  {
    icon: mdiSwapHorizontal,
    text: 'Flexible Data Transfer, with triggers, schedules, or webhooks',
  },
];

export const TryDiscoverDrawer = () => {
  const [isValid, setIsValid] = useState(true);
  const [region, setRegion] = useState<{ value: string; label: string } | null>(
    null,
  );
  const connect = iconByProduct.connect;
  const { data } = useGetDeploymentRegionsQuery();
  const initialFocusRef = useRef(null);
  const { isOpen, setIsOpen, onCloseModal } = useModal();
  const [isTermsOpen, setTermsOpen] = useState(false);
  const [{ tryConnectDrawerVisible }, drawerDispatch] =
    useDrawerStateDispatch();

  const connectDeploymentRegions = data?.products?.find(
    (product) => product.code === 'Connect',
  );

  const regionOptions = connectDeploymentRegions?.deploymentRegions?.nodes
    ? connectDeploymentRegions.deploymentRegions.nodes.map((region) => ({
        value: region.id,
        label: region.description,
      }))
    : [];

  const ref = useRef<HTMLElement>(document.body);
  useEffect(() => {
    ref.current.style.overflow = 'hidden';
    ref.current.style.touchAction = 'none';
  }, []);

  const clearForm = () => {
    setIsOpen(false);
    setRegion(null);
    drawerDispatch(closeDrawer());
  };

  const onCloseDrawer = () => {
    if (region) return setIsOpen(true);
    setIsOpen(false);
    setRegion(null);
    drawerDispatch(closeDrawer());
  };

  const handleSubmit = () => {
    if (!region) {
      setIsValid(false);
      return;
    }
    setTermsOpen(true);
  };

  return (
    <>
      <ConnectTermsModal
        isOpen={isTermsOpen}
        onCloseHandler={setTermsOpen}
        regionId={region ? region.value : ''}
      />
      <Drawer
        onClose={onCloseDrawer}
        isOpen={tryConnectDrawerVisible}
        initialFocusRef={initialFocusRef}
        blockScrollOnMount={false}
        size='lg'
        aria-label={
          tryConnectDrawerVisible ? 'trial drawer open' : 'trial drawer closed'
        }
      >
        <DrawerOverlay />
        <DrawerContent aria-label='trial drawer'>
          <HStack pt={7} pb={6}>
            <DrawerCloseButton />
          </HStack>
          <DrawerBody>
            <VStack align={'stretch'} pb={10} gap='8'>
              <VStack align={'start'} gap='8'>
                <Media
                  p={0}
                  as={'div'}
                  size={'2xlarge'}
                  _hover={{
                    backgroundColor: 'transparent',
                    cursor: 'initial',
                  }}
                  _focus={{
                    backgroundColor: 'transparent',
                    cursor: 'initial',
                  }}
                >
                  <MediaFigure
                    icon={{ path: tenantsFallbackIcon }}
                    image={{
                      url: connect.iconUrl,
                      alt: '',
                    }}
                    color={'transparent'}
                  />
                  <MediaBody
                    alignSelf={'center'}
                    display={'flex'}
                    flexDirection={'column'}
                  >
                    <MediaTitle as={'div'}>
                      <HStack>
                        <Heading fontSize='2xl'>{connect.name}</Heading>
                      </HStack>
                    </MediaTitle>
                  </MediaBody>
                </Media>
              </VStack>
              <Card variant='outline' size='md' p='4'>
                <VStack align='flex-start' gap='4'>
                  <Text>
                    Try out how easily you can integrate Sitecore products with
                    thousands of applications.
                  </Text>
                  <Link
                    data-testid=''
                    variant='ghost'
                    href='https://doc.sitecore.com/connect/en/users/sitecore-connect/introduction-to-sitecore-connect.html'
                    target='_blank'
                  >
                    Read documentation
                  </Link>
                </VStack>
              </Card>
              <VStack
                data-testId='trial-connect-selling-points'
                align='flex-start'
              >
                {sellingPoints.map((point, key) => (
                  <HStack width='full' key={key} gap='4' p='2'>
                    <Icon
                      layerStyle='icon.subtle'
                      boxSize='icon.lg'
                      color='red'
                    >
                      <path d={point.icon} />
                    </Icon>
                    <Text>{point.text}</Text>
                  </HStack>
                ))}
              </VStack>
              <FormControl isRequired={true} isInvalid={!isValid}>
                <FormLabel htmlFor='try-connect-regions-input-id'>
                  Region
                </FormLabel>
                <Box data-testid='select-region'>
                  <ReactSelect
                    id='select-region'
                    selectedOptionColorScheme='primary'
                    useBasicStyles
                    inputId='try-connect-regions-input-id'
                    aria-label='select region'
                    options={regionOptions}
                    onChange={(data) => setRegion(data)}
                    value={region}
                    formatOptionLabel={(data) => (
                      <span data-testid={`option-${data.value}`}>
                        {data.label}
                      </span>
                    )}
                  />
                  <input
                    id='select-region-hidden-input'
                    type='hidden'
                    value={region?.value}
                    data-label={region?.label}
                  />
                </Box>
                {!isValid && (
                  <FormErrorMessage>
                    <FormErrorIcon />
                    Region is required
                  </FormErrorMessage>
                )}
              </FormControl>
            </VStack>
          </DrawerBody>
          <DrawerFooter justifyContent='flex-end'>
            <ButtonGroup>
              <Button
                data-testid='cancel-btn'
                type='button'
                variant='ghost'
                onClick={onCloseDrawer}
                aria-label='cancel trial'
              >
                Cancel
              </Button>
              <Button
                data-testid='submit-btn'
                type='submit'
                variant='solid'
                aria-label='enable trial'
                onClick={handleSubmit}
                isDisabled={!region}
              >
                Enable for free
              </Button>
            </ButtonGroup>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <DiscardChangesModal
        isOpen={isOpen}
        onClose={onCloseModal()}
        onDiscard={onCloseModal({ cb: clearForm })}
      />
    </>
  );
};
