import { Box, Text } from '@chakra-ui/react';

interface IUserCard {
  role: string;
  description: string;
}

const OrganizationAccessCard = ({ role, description }: IUserCard) => (
  <Text fontSize='md'>
    <Box as='span' fontWeight='semibold'>
      {role}
    </Box>{' '}
    - {description}
  </Text>
);

export default OrganizationAccessCard;
