import { format } from 'date-fns';
import { Log, Logs } from './types';

export const getTableData = (logs?: Logs) =>
  logs?.map(({ time, entity: { id }, extensions: { reason, clientId } }) => ({
    time,
    email: id,
    id,
    clientId,
    description: reason,
  })) ?? [];

export const tableOptions = {
  columns: [
    {
      id: 'email',
      width: '30%',
      Header: 'Product support engineer',
      accessor: 'email',
      disableSortBy: true,
    },
    {
      id: 'loginTime',
      width: '30%',
      Header: 'Login time',
      accessor: ({ time }: Partial<Log>) => {
        if (!time) return 'Invalid Date';

        const formattedDate = format(
          new Date(time).getTime(),
          'MMM d, yyyy, h:mm a',
        );

        const tz = getTimeZoneCode(time);
        return `${formattedDate} ${tz}`;
      },
      disableSortBy: true,
    },
    {
      id: 'description',
      width: 'auto',
      Header: 'Description',
      accessor: 'description',
      disableSortBy: true,
    },
  ],
};

export const getTimeZoneCode = (isoString: Date) => {
  const date = new Date(isoString);

  if (isNaN(date.getTime())) {
    console.error(`Invalid date string: ${isoString}`);
    return;
  }

  const timeZoneCode = new Intl.DateTimeFormat('en-US', {
    timeZoneName: 'short',
  })
    .formatToParts(date)
    .find((part) => part.type === 'timeZoneName')?.value;

  return timeZoneCode;
};
