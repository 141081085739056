import { Heading, Wrap, WrapItem } from '@chakra-ui/react';
import { useMainStateDispatch } from 'features/main/context';
import { openDrawer } from 'features/main/context/drawer/actionCreators';
import { iconByProduct } from 'features/subscriptions/utils';
import { useGetCountOfProductQuery } from 'gql/graphql';
import TryAppCard from './TryAppCard';

const TryApps = () => {
  const connect = iconByProduct.connect;
  const [, dispatch] = useMainStateDispatch();
  const { data, loading, error } = useGetCountOfProductQuery({
    variables: {
      productCode: 'Connect',
    },
  });

  const hasConnect =
    data?.organization?.applications?.totalCount &&
    data.organization.applications.totalCount > 0;

  const showDiscoverAppsSection = !loading && !error && !hasConnect;

  if (!showDiscoverAppsSection) return null;

  return (
    <>
      <Heading size='md' noOfLines={1}>
        Discover apps
      </Heading>
      <Wrap>
        {/* Connect */}
        <WrapItem>
          <TryAppCard
            icon={connect.iconUrl}
            name={connect.name}
            description='Try out how easily you can integrate Sitecore products with thousands of applications.'
            onClick={() => dispatch(openDrawer('try-connect'))}
          />
        </WrapItem>
      </Wrap>
    </>
  );
};

export default TryApps;
