import { useAuthMutation } from 'features/common/apiUtils/queryHelper';
import { edgePlatformAdminApi } from '../api/endpoints';

export const useDeleteHostname = (hostname: string) => {
  const { delete_hostname } = edgePlatformAdminApi;
  const url = delete_hostname.url(hostname);

  return useAuthMutation(
    (axiosInstance) => () => axiosInstance?.delete(url),
    {},
    delete_hostname.scope,
  );
};
