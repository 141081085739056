import { faker } from '@faker-js/faker';
import { Application, ApplicationListResponse } from '../../../shared/types';

const sitecoreCategories = [
  'Content Management',
  'Digital Asset Management',
  'Customer Data Platform',
  'Analytics',
  'Personalization',
  'E-commerce',
  'Marketing Automation',
  'Search',
  'Integration',
  'Development Tools',
];

const sitecoreServices = [
  'xm-cloud',
  'ordercloud',
  'cdp',
  'send',
  'search',
  'personalize',
];
const deploymentTypes = ['container', 'serverless'];
const statuses = ['active', 'pending', 'updating'];

const sitecoreAppIcons = [
  'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/mark-sitecore',
  'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/mark-blok',
  'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/mark-cdp',
  'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/mark-content_hub',
  'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/mark-xm',
];

const pickRandom = <T>(arr: T[]): T =>
  arr[Math.floor(Math.random() * arr.length)];

const generateMockApplications = (count: number = 10): Application[] => {
  return Array.from(
    { length: count },
    (): Application => ({
      id: faker.string.uuid(),
      organizationId: faker.string.uuid(),
      name: `Sitecore ${faker.company.buzzNoun()} ${faker.company.buzzAdjective()}`,
      appVersion: faker.system.semver(),
      visibility: {
        // @ts-ignore
        allowedOrganizations: faker.helpers.maybe(
          () =>
            Array.from(
              { length: faker.number.int({ min: 1, max: 3 }) },
              faker.string.uuid,
            ),
          { probability: 0.6 },
        ),
      },
      deployment: {
        type: pickRandom(deploymentTypes),
        service: pickRandom(sitecoreServices),
      },
      presentation: {
        description: `${faker.commerce.productDescription()} Seamlessly integrate with Sitecore's digital experience platform.`,
        iconUrl: pickRandom(sitecoreAppIcons),
        categories: Array.from(
          { length: faker.number.int({ min: 1, max: 3 }) },
          () => pickRandom(sitecoreCategories),
        ),
        // @ts-ignore
        screenshots: faker.helpers.maybe(() =>
          Array.from({ length: faker.number.int({ min: 2, max: 4 }) }, () => ({
            url: faker.image.url(),
            caption: `${faker.commerce.productAdjective()} ${faker.commerce.productName()} Interface`,
          })),
        ),
        websiteUrl: `https://${faker.internet.domainName()}/sitecore-integration`,
        // @ts-ignore
        supportUrl: faker.helpers.maybe(
          () => `https://support.${faker.internet.domainName()}`,
        ),
        privacyPolicyUrl: `https://${faker.internet.domainName()}/privacy`,
        termsOfServiceUrl: `https://${faker.internet.domainName()}/terms`,
        organizationName: faker.company.name(),
      },
      provision: {
        url: `https://api.${faker.internet.domainName()}/provision`,
      },
      system: {
        updatedBy: faker.string.uuid(),
        updatedAt: faker.date.recent().toISOString(),
        state: {
          overall: pickRandom([0, 1, 2]),
          // @ts-ignore
          message: faker.helpers.maybe(() =>
            pickRandom([
              'Integration running normally',
              'Updating Sitecore components',
              'Syncing with XM Cloud',
              'Maintenance in progress',
            ]),
          ),
        },
        deployment: {
          id: faker.string.uuid(),
          status: pickRandom(statuses),
          url: `https://${faker.internet.domainName()}/sitecore`,
        },
        createdBy: faker.string.uuid(),
        createdAt: faker.date.past().toISOString(),
        status: 0,
      },
    }),
  );
};

const generateMockApplicationListResponse = (
  count: number = 10,
): ApplicationListResponse => ({
  totalCount: count,
  pageSize: 10,
  pageNumber: 1,
  data: generateMockApplications(count),
});

export { generateMockApplications, generateMockApplicationListResponse };
