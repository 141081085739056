import { isEmpty, pickBy } from 'lodash';
import { FieldsMapType, IConnectionSamlViewModel } from '../api';

export const userAttributeOptions = ['email', 'given_name', 'family_name'];

/**
 *
 * @param domain
 * @param displayName
 * @returns
 */
export const getMetadata = (domain: string, displayName: string) => {
  return {
    metadataUrl: `${domain}/samlp/metadata?connection=${displayName}`,
    entityId: `urn:sitecore:${displayName}`,
    assertionConsumerService: `${domain}/login/callback?connection=${displayName}`,
  };
};

/**
 * Adds a new line in the attribute view data
 * @param attributes
 * @returns {FieldsMapType[]}
 */
export const addNewAttributeLine = (attributes: FieldsMapType[]) => [
  ...attributes,
  { mapping: userAttributeOptions[0], value: '' },
];

/**
 * Sets new values on an existings attribute entry
 * @param attributes
 * @param newMapping
 * @param newValue
 * @param key
 * @returns { FieldsMapType[] }
 */
export const editAttributeLine = (
  attributes: FieldsMapType[],
  newMapping: string | undefined,
  newValue: string | undefined,
  key: number,
) =>
  attributes.map((oldValue, k) =>
    k === key
      ? {
          mapping: newMapping || oldValue.mapping,
          value: newValue !== undefined ? newValue : oldValue.value,
        }
      : oldValue,
  );

/**
 * Removes attribute
 * @param attributes
 * @param position
 * @returns { FieldsMapType[] }
 */
export const removeAttributeLine = (
  attributes: FieldsMapType[],
  position: number,
) => attributes.filter((value, key) => position !== key);

/**
 * Converts fields maps response data to view state
 * @param fieldsMap
 * @returns { FieldsMapType[] }
 */
export const convertFieldsMapToViewState = (
  fieldsMap:
    | {
        [key: string]: string[];
      }
    | undefined,
) => {
  if (!fieldsMap || isEmpty(fieldsMap)) return [];
  const keys = Object.keys(fieldsMap);
  const rows = [] as FieldsMapType[];

  keys.forEach((key) =>
    fieldsMap[key].forEach((fieldMap) =>
      rows.push({
        mapping: key,
        value: fieldMap,
      }),
    ),
  );

  return rows;
};

/**
 * Takes the fields map data view we have on the view model and constructs the fields map payload for the saml request
 * @param attributes
 * @returns
 */
export const constructFieldsMapPayload = (attributes: FieldsMapType[]) => {
  const keys: { [key: string]: string[] } = {};
  userAttributeOptions.forEach((option) => {
    keys[option] = [];
  });
  attributes.forEach((attr) => {
    if (keys[attr.mapping] && attr.value.length > 0) {
      keys[attr.mapping].push(attr.value);
    }
  });

  userAttributeOptions.forEach((attr) => {
    if (keys[attr].length === 0) {
      delete keys[attr];
    }
  });

  return keys;
};

/**
 * constructs the samlp payload data
 * @param values
 * @returns
 */
export const constructSamlPatchPayload = (values: IConnectionSamlViewModel) => {
  const compactedValues = pickBy(values);
  const { displayName, metadataSelection, metadataUrl, metadataXml } =
    compactedValues;

  return {
    displayName: displayName,
    options: {
      samlp: {
        ...(metadataSelection === 'url' && {
          metadataUrl,
        }),
        ...(metadataSelection === 'xml' &&
          !!metadataXml && {
            metadataXml,
          }),
        fieldsMap:
          !values.fieldsMap || isEmpty(values.fieldsMap)
            ? {}
            : constructFieldsMapPayload(values.fieldsMap),
      },
    },
  };
};

/**
 * Returns the type of the metadata selection
 * @param signInEndpoint
 * @param metadataUrl
 * @returns { 'xml' | 'url'}
 */
export const getSavedMetadataTypeSelection = (
  signInEndpoint: string | undefined | null,
  metadataUrl: string | undefined | null,
) => {
  if (signInEndpoint && metadataUrl) {
    return 'url';
  }
  if (signInEndpoint && !metadataUrl) {
    return 'xml';
  }

  return 'url';
};
