const errorCodeMessages = {
  400: 'Invalid request',
  401: 'Invalid access token',
  403: 'Insufficient scope',
  404: 'Something went wrong',
};

export const errorsMap = [
  {
    scope: 'identity.user:read',
    code: 401,
    throwError: true,
  },
  {
    scope: 'identity.user:read',
    code: 403,
    throwError: true,
  },
  {
    scope: 'identity.user:read',
    code: 404,
    throwError: true,
  },
  {
    scope: 'identity.user:update',
    code: 400,
    message: <>{errorCodeMessages[400]}</>,
  },
  {
    scope: 'identity.user:update',
    code: 401,
    message: <>{errorCodeMessages[401]}</>,
  },
  {
    scope: 'identity.user:update',
    code: 403,
    message: <>{errorCodeMessages[403]}</>,
  },
  {
    scope: 'identity.user:update',
    code: 404,
    message: <>{errorCodeMessages[404]}</>,
  },
  {
    scope: 'identity.user_organizations:read',
    code: 400,
    throwError: true,
  },
  {
    scope: 'identity.user_organizations:read',
    code: 401,
    throwError: true,
  },
  {
    scope: 'identity.user_organizations:read',
    code: 403,
    throwError: true,
  },
  {
    scope: 'identity.user_organizations:read',
    code: 404,
    throwError: true,
  },
  {
    scope: 'identity.user_roles:read',
    code: 400,
    throwError: true,
  },
  {
    scope: 'identity.user_roles:read',
    code: 401,
    throwError: true,
  },
  {
    scope: 'identity.user_roles:read',
    code: 403,
    throwError: true,
  },
  {
    scope: 'identity.user_roles:read',
    code: 404,
    throwError: true,
  },
  {
    scope: 'identity.roles:list',
    code: 400,
    message: <>{errorCodeMessages[400]}</>,
  },
  {
    scope: 'identity.roles:list',
    code: 401,
    message: <>{errorCodeMessages[401]}</>,
  },
  {
    scope: 'identity.roles:list',
    code: 403,
    message: <>{errorCodeMessages[403]}</>,
  },
  {
    scope: 'identity.roles:read',
    code: 400,
    message: <>{errorCodeMessages[400]}</>,
  },
  {
    scope: 'identity.roles:read',
    code: 401,
    message: <>{errorCodeMessages[401]}</>,
  },
  {
    scope: 'identity.roles:read',
    code: 403,
    message: <>{errorCodeMessages[403]}</>,
  },
  {
    scope: 'identity.roles:read',
    code: 404,
    message: <>{errorCodeMessages[404]}</>,
  },
  {
    scope: 'identity.organization:read',
    code: 400,
    message: <>{errorCodeMessages[400]}</>,
  },
  {
    scope: 'identity.organization:read',
    code: 401,
    message: <>{errorCodeMessages[401]}</>,
  },
  {
    scope: 'identity.organization:read',
    code: 403,
    message: <>{errorCodeMessages[403]}</>,
  },
  {
    scope: 'identity.organization:read',
    code: 404,
    message: <>{errorCodeMessages[404]}</>,
  },
  {
    scope: 'identity.organization:update',
    code: 400,
    message: <>{errorCodeMessages[400]}</>,
  },
  {
    scope: 'identity.organization:update',
    code: 401,
    message: <>{errorCodeMessages[401]}</>,
  },
  {
    scope: 'identity.organization:update',
    code: 403,
    message: <>{errorCodeMessages[403]}</>,
  },
  {
    scope: 'identity.organization_invitations:list',
    code: 400,
    message: <>{errorCodeMessages[400]}</>,
  },
  {
    scope: 'identity.organization_invitations:list',
    code: 401,
    message: <>{errorCodeMessages[401]}</>,
  },
  {
    scope: 'identity.organization_invitations:list',
    code: 403,
    message: <>{errorCodeMessages[403]}</>,
  },
  {
    scope: 'identity.organization_invitations:create',
    code: 400,
    message: <>{errorCodeMessages[400]}</>,
  },
  {
    scope: 'identity.organization_invitations:create',
    code: 401,
    message: <>{errorCodeMessages[401]}</>,
  },
  {
    scope: 'identity.organization_invitations:create',
    code: 403,
    message: <>{errorCodeMessages[403]}</>,
  },
  {
    scope: 'identity.organization_invitations:read',
    code: 400,
    message: <>{errorCodeMessages[400]}</>,
  },
  {
    scope: 'identity.organization_invitations:read',
    code: 401,
    message: <>{errorCodeMessages[401]}</>,
  },
  {
    scope: 'identity.organization_invitations:read',
    code: 403,
    message: <>{errorCodeMessages[403]}</>,
  },
  {
    scope: 'identity.organization_invitations:read',
    code: 404,
    message: <>{errorCodeMessages[404]}.</>,
  },
  {
    scope: 'identity.organization_invitations:delete',
    code: 400,
    message: <>{errorCodeMessages[400]}</>,
  },
  {
    scope: 'identity.organization_invitations:delete',
    code: 401,
    message: <>{errorCodeMessages[401]}</>,
  },
  {
    scope: 'identity.organization_invitations:delete',
    code: 403,
    message: <>{errorCodeMessages[403]}</>,
  },
  {
    scope: 'identity.organization_invitations:delete',
    code: 404,
    message: <>{errorCodeMessages[404]}</>,
  },
  {
    scope: 'identity.organization_members:list',
    code: 400,
    message: <>{errorCodeMessages[400]}</>,
  },
  {
    scope: 'identity.organization_members:list',
    code: 401,
    message: <>{errorCodeMessages[401]}</>,
  },
  {
    scope: 'identity.organization_members:list',
    code: 403,
    message: <>{errorCodeMessages[403]}</>,
  },
  {
    scope: 'identity.organization_members:list',
    code: 503,
    throwError: true,
  },
  {
    scope: 'identity.organization_members:read',
    code: 400,
    message: <>{errorCodeMessages[400]}</>,
  },
  {
    scope: 'identity.organization_members:read',
    code: 401,
    message: <>{errorCodeMessages[401]}</>,
  },
  {
    scope: 'identity.organization_members:read',
    code: 403,
    message: <>{errorCodeMessages[403]}</>,
  },
  {
    scope: 'identity.organization_members:read',
    code: 404,
    message: <>{errorCodeMessages[404]}</>,
  },
  {
    scope: 'identity.organization_members:delete',
    code: 400,
    message: <>{errorCodeMessages[400]}</>,
  },
  {
    scope: 'identity.organization_members:delete',
    code: 401,
    message: <>{errorCodeMessages[401]}</>,
  },
  {
    scope: 'identity.organization_members:delete',
    code: 403,
    message: <>{errorCodeMessages[403]}</>,
  },
  {
    scope: 'identity.organization_members_roles:create',
    code: 400,
    message: <>{errorCodeMessages[400]}</>,
  },
  {
    scope: 'identity.organization_members_roles:create',
    code: 401,
    message: <>{errorCodeMessages[401]}</>,
  },
  {
    scope: 'identity.organization_members_roles:create',
    code: 403,
    message: <>{errorCodeMessages[403]}</>,
  },
  {
    scope: 'identity.organization_members_roles:create',
    code: 404,
    message: <>{errorCodeMessages[404]}</>,
  },
  {
    scope: 'identity.organization_members_roles:delete',
    code: 400,
    message: <>{errorCodeMessages[400]}</>,
  },
  {
    scope: 'identity.organization_members_roles:delete',
    code: 401,
    message: <>{errorCodeMessages[401]}</>,
  },
  {
    scope: 'identity.organization_members_roles:delete',
    code: 403,
    message: <>{errorCodeMessages[403]}</>,
  },
  {
    scope: 'identity.organization_members_roles:delete',
    code: 404,
    message: <>{errorCodeMessages[404]}</>,
  },
  {
    scope: 'platform.tenants:list',
    code: 400,
    message: <>{errorCodeMessages[400]}</>,
  },
  {
    scope: 'platform.tenants:list',
    code: 401,
    message: <>{errorCodeMessages[401]}</>,
  },
  {
    scope: 'platform.tenants:list',
    code: 403,
    message: <>{errorCodeMessages[403]}</>,
  },
  {
    scope: 'platform.tenants:list',
    code: 404,
    message: <>{errorCodeMessages[404]}</>,
  },
  {
    scope: 'identity.org_connections:write',
    code: 400,
    message: <>{errorCodeMessages[400]}</>,
  },
  {
    scope: 'identity.org_connections:write',
    code: 401,
    message: <>{errorCodeMessages[401]}</>,
  },
  {
    scope: 'identity.org_connections:write',
    code: 403,
    message: <>{errorCodeMessages[403]}</>,
  },
];
