import { OmitCommonProps, As } from '@chakra-ui/system';
import { useEffect, useState } from 'react';
import { mdiPencilOutline, mdiRestore } from '@mdi/js';
import {
  ButtonGroup,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Tooltip,
} from '@chakra-ui/react';
import { PortalIcon } from 'features/common/components/PortalIcon';

const PasswordInput = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<any, never> &
    object & { as?: As | undefined },
) => {
  const { isInEditMode, setFieldValue, setFieldTouched } = props;
  const [show, setShow] = useState(false);

  const handleClick = (type: 'edit' | 'revert') => {
    if (type === 'edit') {
      setFieldValue('options.oidc.clientSecret', '');
      setFieldTouched('options.oidc.clientSecret', true);

      setShow(true);
    }

    if (type === 'revert') {
      setFieldValue('options.oidc.clientSecret', undefined);
      setFieldTouched('options.oidc.clientSecret', false);

      setShow(false);
    }
  };

  useEffect(() => {
    const clientSecretInteractionMode = show;
    setFieldValue(
      'options.oidc.clientSecretInteractionMode',
      clientSecretInteractionMode,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const getInputType = () => {
    if (!isInEditMode) {
      return 'text';
    }

    if (isInEditMode) {
      return show ? 'text' : 'password';
    }

    return 'text';
  };

  return (
    <InputGroup size='md'>
      <Input
        type={getInputType()}
        data-testid='password-input'
        {...props}
        isReadOnly={!show && isInEditMode}
      />
      <InputRightElement>
        <ButtonGroup>
          {!show && isInEditMode && (
            <Tooltip label='Edit'>
              <IconButton
                size='sm'
                data-testid='edit-icon-button'
                variant='ghost'
                onClick={() => handleClick('edit')}
                icon={<PortalIcon path={mdiPencilOutline} />}
                aria-label='Edit'
              />
            </Tooltip>
          )}
          {show && isInEditMode && (
            <Tooltip label='Revert'>
              <IconButton
                size='sm'
                data-testid='revert-icon-button'
                variant='ghost'
                onClick={() => handleClick('revert')}
                icon={<PortalIcon path={mdiRestore} />}
                aria-label='Revert'
              />
            </Tooltip>
          )}
        </ButtonGroup>
      </InputRightElement>
    </InputGroup>
  );
};

export default PasswordInput;
