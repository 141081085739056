import { useAuth0 } from '@auth0/auth0-react';
import { setContext } from '@apollo/client/link/context';
import {
  ApolloClient,
  ApolloProvider as RCApolloProvider,
  InMemoryCache,
  HttpLink,
} from '@apollo/client';
import { useEffect, useMemo } from 'react';
import {
  TokenCustomClaimKeysEnum,
  storeLastSuccessfulLoginOrganizationId,
  getPortalApiBaseUrl,
  Environment,
} from '@sitecore-ui/portal-singular';
import { useHistory } from 'react-router-dom';
import { relayStylePagination } from '@apollo/client/utilities';
import { isNonProd, env } from 'features/common/config/envConfig';
import { resolveUserAgent } from 'features/common/apiUtils/user-agent';
import { makeVar } from '@apollo/client/cache';
import { MEMBER_FRAGMENT } from 'features/members/components/table/members-queries';
import { Action } from 'gql/graphql';

export const removedMemberIdVar = makeVar<string | null>(null);
export const xmCloudDeployActionVar = makeVar<Action | null>(null);

const API_URL =
  getPortalApiBaseUrl(env.toLowerCase() as Environment) + 'api/portal/graphql';

const httpLink = new HttpLink({
  uri: (operation) => `${API_URL}?${operation.operationName}`,
});

interface ApolloAuthProps {
  children: React.ReactNode;
}

/**
 * Provides an Apollo client with authentication headers using Auth0 and sets the organization ID in the URL search params.
 * @param children The child components to render.
 * @returns The ApolloProvider component.
 */

function ApolloProvider({ children }: ApolloAuthProps) {
  const { getAccessTokenSilently, user } = useAuth0();
  const history = useHistory();
  const organizationId = user?.[TokenCustomClaimKeysEnum.ORG_ID];

  useEffect(() => {
    if (!organizationId) return;

    storeLastSuccessfulLoginOrganizationId(organizationId);

    const searchParams = new URLSearchParams(history.location.search);
    searchParams.set('organization', organizationId);

    history.replace({
      pathname: history.location.pathname,
      search: `?${searchParams.toString()}`,
    });
  }, [history, organizationId]);

  const client = useMemo(() => {
    // Set the Authorization header for every request.
    const authLink = setContext(async (_, { headers }) => {
      const token = await getAccessTokenSilently({
        organization_id: organizationId,
      });

      const { userAgent } = resolveUserAgent();

      return {
        headers: {
          ...headers,
          'Content-Type': 'application/json',
          Authorization: token ? `Bearer ${token}` : '',
          ...userAgent,
        },
      };
    });

    return new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache({
        typePolicies: {
          Query: {
            fields: {
              removedMemberId: {
                read() {
                  return removedMemberIdVar();
                },
              },
              xmCloudDeployAction: {
                read() {
                  return xmCloudDeployActionVar();
                },
              },
            },
          },
          user: {
            fields: {
              applications: relayStylePagination(),
            },
          },
          Organization: {
            fields: {
              members: {
                read(existing, { args, cache }) {
                  // If there's no data or no args, return undefined to fetch from network
                  if (!existing || !args) return undefined;

                  // Check if existing.items is defined before trying to access it
                  if (!existing.items) return undefined;

                  // Calculate start and end index based on skip and take
                  const start = args.skip;
                  const end = start + args.take;

                  // Check if there's enough data in the cache
                  const notEnoughDataInCase = end > existing.items.length;
                  if (notEnoughDataInCase) return undefined;

                  const sortedItems = existing.items.map(
                    (ref: { __ref: any }) => {
                      // Use readFragment to get the full object
                      const item = cache.readFragment({
                        id: ref.__ref,
                        fragment: MEMBER_FRAGMENT,
                      });

                      return item;
                    },
                  );

                  // If the nameOrEmail query is empty, resort the items by lastLogin
                  if (args.query && args.query.nameOrEmail === '') {
                    sortedItems.sort(
                      (
                        a: { lastLogin: string | number | Date },
                        b: { lastLogin: string | number | Date },
                      ) =>
                        (new Date(b.lastLogin) as any) -
                        (new Date(a.lastLogin) as any),
                    );
                  }

                  // If the nameOrEmail query is not empty, filter the items by nameOrEmail
                  if (args.query && args.query.nameOrEmail !== '') {
                    sortedItems.sort(
                      (
                        a: {
                          familyName: string | null;
                          givenName: string | null;
                          email: string | null;
                          lastLogin: string | number | Date;
                        },
                        b: {
                          familyName: any;
                          givenName: any;
                          email: any;
                          lastLogin: string | number | Date;
                        },
                      ) => {
                        // Sort by familyName
                        const familyNameComparison =
                          a.familyName?.localeCompare(b.familyName);
                        if (familyNameComparison !== 0)
                          return familyNameComparison;

                        // If familyName is the same, sort by givenName
                        const givenNameComparison = a.givenName?.localeCompare(
                          b.givenName,
                        );
                        if (givenNameComparison !== 0)
                          return givenNameComparison;

                        // If givenName is the same, sort by email
                        const emailComparison = a.email?.localeCompare(b.email);
                        if (emailComparison !== 0) return emailComparison;

                        // If email is the same, sort by lastLogin
                        return (
                          new Date(b.lastLogin).getTime() -
                          new Date(a.lastLogin).getTime()
                        );
                      },
                    );
                  }

                  // Return a slice of the sorted data
                  return {
                    ...existing,
                    items: sortedItems.slice(start, end),
                  };
                },

                merge(existing = { items: [] }, incoming, { args }) {
                  let skip = 0;
                  if (args && 'skip' in args) {
                    skip = args.skip;
                  }
                  let merged = existing ? existing : {};
                  if (incoming) {
                    let mergedItems;
                    if (skip === 0 || skip < existing.items.length) {
                      // If we're on the first page or a previous page, replace the existing items
                      mergedItems = [...incoming.items];
                    } else {
                      // Otherwise, append the incoming items to the existing items
                      mergedItems = [...existing.items, ...incoming.items];
                    }

                    merged = {
                      ...incoming,
                      items: mergedItems,
                    };
                  }
                  return merged;
                },
              },
            },
          },
        },
      }),
      connectToDevTools: isNonProd,
    });
  }, [getAccessTokenSilently, organizationId]);

  return <RCApolloProvider client={client}>{children}</RCApolloProvider>;
}

export { ApolloProvider };
