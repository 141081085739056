import { TokenCustomClaimKeysEnum } from '@sitecore-ui/portal-singular';
import { useAuth0TokenClaims } from '../../auth/claims';

export const useGetOrganization = () => {
  const { claims } = useAuth0TokenClaims();

  const organizationId =
    (claims && claims[TokenCustomClaimKeysEnum.ORG_ID]) || '';

  const organizationName =
    (claims && claims[TokenCustomClaimKeysEnum.ORG_DISPLAY_NAME]) || '';

  return { organizationId, organizationName };
};
