import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Image,
  Heading,
  Text,
  Wrap,
  WrapItem,
  VStack,
  Link,
  Tag,
} from '@chakra-ui/react';
import { Application, Presentation } from '../../shared/types';
import { getAppPath } from 'features/marketplace/config/routes';

interface AppCardProps {
  app: Application;
}

export const AppCard: React.FC<AppCardProps> = ({ app }) => {
  const presentation = app.presentation as Presentation;

  return (
    <Box
      data-testid='app-card'
      borderWidth='1px'
      borderRadius='lg'
      p='5'
      display='flex'
      flexDirection='column'
      gap='2'
      backgroundColor='chakra-body-bg'
      width='100%'
    >
      <VStack
        data-testid='app-card-content'
        align='start'
        spacing='2'
        width='100%'
      >
        <Image
          data-testid='app-icon'
          src={presentation.iconUrl!}
          alt={`${app.name} icon`}
          width='14'
          height='14'
          objectFit='cover'
          fallbackSrc='https://via.placeholder.com/56'
        />

        <Heading as='h3' size='md' fontWeight='semibold'>
          <RouterLink
            to={getAppPath(app.id!)}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {app.name}
          </RouterLink>
        </Heading>
        <VStack align='start' gap='0.5rem'>
          <Text fontSize='sm' color='gray.600' noOfLines={2} lineHeight='1.5'>
            {presentation.description}
          </Text>

          <Wrap spacing='2'>
            {presentation?.categories?.map((category) => (
              <WrapItem key={category}>
                <Tag>{category}</Tag>
              </WrapItem>
            ))}
          </Wrap>

          <Text fontSize='md' color='gray.600'>
            <Wrap alignItems='center' gap='0.5rem'>
              <Text fontSize='sm'>by</Text>
              <Link color='purple.600' _hover={{ textDecoration: 'underline' }}>
                {/* NOTE: not defined in schema yet */}
                {presentation?.organizationName ?? 'Sitecore'}
              </Link>
            </Wrap>
          </Text>
        </VStack>
      </VStack>
    </Box>
  );
};
