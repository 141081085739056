import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

interface GradientBackgroundProps extends BoxProps {
  children: React.ReactNode;
}

export const GradientBackground: React.FC<GradientBackgroundProps> = ({
  children,
  ...props
}) => {
  return (
    <Box
      position='relative'
      minH='100vh'
      overflow='hidden'
      _before={{
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        bgGradient: 'linear(to-b, rgba(249, 249, 249, 0.00) 0%, #FFF 33%)',
        zIndex: 1,
      }}
      _after={{
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        bgGradient: 'linear(87deg, #DEDBFF 0%, #F9F9F9 50%, #FFCFCF 100%)',
        zIndex: 0,
      }}
      {...props}
    >
      <Box position='relative' zIndex={2} minH='100vh'>
        {children}
      </Box>
    </Box>
  );
};
