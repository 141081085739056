import React from 'react';
import { useTopPicks } from '../hooks/useTopPicks';
import { TopPicksGrid } from 'features/marketplace/features/browse/layouts/TopPicksGrid';
import {
  StatusView,
  SkeletonCard,
  AppCard,
} from 'features/marketplace/shared/components';
import { t } from 'features/marketplace/shared/utils/i18n';

export const TopPicks: React.FC = () => {
  const { data, isLoading, error, refetch } = useTopPicks();

  if (isLoading) {
    return (
      <TopPicksGrid>
        {[...Array(6)].map((_, index) => (
          <SkeletonCard key={index} />
        ))}
      </TopPicksGrid>
    );
  }

  if (error) {
    return (
      <StatusView
        icon='https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-alert-circle'
        iconAlt='alert icon'
        title={t('Something went wrong')}
        action={{
          label: t('Try again'),
          onClick: refetch,
        }}
      />
    );
  }

  if (!data?.length) {
    return (
      <StatusView
        icon='https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-cactus-neutral'
        iconAlt='desert icon'
        title={t('No applications yet')}
      />
    );
  }

  return (
    <TopPicksGrid>
      {data.map((app) => (
        <AppCard key={app.id} app={app} />
      ))}
    </TopPicksGrid>
  );
};
