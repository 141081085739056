import { Text } from '@chakra-ui/react';
import { getSingleOrgDetails, useAppAccess } from './utils';
import { TokenCustomClaimKeysEnum } from '@sitecore-ui/portal-singular';
import { APP_ORGANIZATION } from './resource';

function OrganizationAccess() {
  const { hasOrgAdminOwnerAccess, userRoles, claims } = useAppAccess();
  const organizationId = claims && claims[TokenCustomClaimKeysEnum.ORG_ID];

  if (!userRoles || !organizationId) return null;

  const singleOrgDetails = getSingleOrgDetails({
    userRoles,
    hasOrgAdminOwnerAccess,
    organizationId,
  });

  if (!singleOrgDetails) return null;

  return (
    <Text
      data-testid='org-access'
      size='md'
      lineHeight='6'
      color='chakra-subtle-text'
    >
      {singleOrgDetails.role.replace(APP_ORGANIZATION, '')}
    </Text>
  );
}

export default OrganizationAccess;
