import compareAsc from 'date-fns/compareAsc';
import parseISO from 'date-fns/parseISO';
import isValid from 'date-fns/isValid';

export const isDatePassed = (
  date: string | Date | undefined,
): boolean | null => {
  if (!date) return null;

  const current = new Date();
  const parsedDate: string | Date =
    date instanceof Date ? date : parseISO(date);

  if (!isValid(parsedDate)) {
    return null;
  }

  return !(compareAsc(parsedDate, current) >= 0);
};
