import { mdiCircleSmall } from '@mdi/js';
import {
  Box,
  Flex,
  Heading,
  Text,
  SkeletonCircle,
  Skeleton,
  Avatar,
} from '@chakra-ui/react';
import {
  formatDate,
  generateMemberDetailsRow,
} from '../utils/memberDetailsUtils';
import { PortalIcon } from 'features/common/components/PortalIcon';

interface IMemberDetailsHeader {
  isLoading: boolean;
  memberName: string;
  lastLogin?: string;
  memberDetails: {
    jobRole?: string | null;
    email?: string;
    phoneNumber?: string | null;
    picture?: string | null;
  };
}
export const MemberDetailsHeader = ({
  memberName,
  memberDetails,
  lastLogin,
  isLoading,
}: IMemberDetailsHeader) => {
  const memberDetailsRow = generateMemberDetailsRow(memberDetails);

  return (
    <Box
      mt='5'
      pb='6'
      mb='8'
      borderBottom='1px solid'
      borderColor='chakra-border-color'
    >
      <Flex>
        {isLoading ? (
          <SkeletonCircle mr='10' boxSize='24' />
        ) : (
          <Avatar
            name={memberName}
            src={memberDetails.picture || undefined}
            size='xl'
            mr='6'
          />
        )}
        <Box>
          <Box alignSelf='center'>
            <Skeleton isLoaded={!isLoading}>
              <Heading size='xl' mb='2'>
                {memberName}
              </Heading>
            </Skeleton>
            <Skeleton isLoaded={!isLoading}>
              {memberDetailsRow.length && (
                <Box mb='2'>
                  {memberDetailsRow.map((memberDetail, index) => (
                    <Box key={index} as='span'>
                      <Text as='span'>{memberDetail}</Text>
                      {index < memberDetailsRow.length - 1 && (
                        <PortalIcon
                          path={mdiCircleSmall}
                          mr='4'
                          ml='4'
                          color='neutral'
                        />
                      )}
                    </Box>
                  ))}
                </Box>
              )}
            </Skeleton>
            <Skeleton isLoaded={!isLoading}>
              {lastLogin && (
                <Text variant='subtle'>
                  Last login: {formatDate(lastLogin)}
                </Text>
              )}
            </Skeleton>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};
