import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@chakra-ui/react';
import { GenericErrorTemplate } from 'features/common/components/GenericErrorTemplate';
import { history } from 'features/common/history/history';
import { useEffect } from 'react';

const clearStorage = () => {
  sessionStorage.clear();
  localStorage.clear();
};

const removeSearchParams = () => {
  const currentUrl = new URL(location.pathname, window.location.origin);
  history.replace(currentUrl.pathname);
};

const text =
  'Authorization failed. Perhaps your invitation expired or was deleted. Contact your organization admin.';
const title = 'Something went wrong';

export const InvalidInvitationPage = () => {
  const { loginWithRedirect, logout } = useAuth0();

  useEffect(() => {
    removeSearchParams();
    clearStorage();
  }, []);

  return (
    <GenericErrorTemplate
      title={title}
      text={text}
      variant='error'
      shortcuts={
        <>
          <Button
            variant='link'
            onClick={() => loginWithRedirect({ organization_id: undefined })}
            mb={4}
          >
            Go to Login
          </Button>
          <Button variant='link' onClick={() => logout()}>
            Log out
          </Button>
        </>
      }
    />
  );
};
