import { Organization } from 'gql/graphql';

type Condition = { name: string; parameters?: Record<string, string> };
type Data = { organization?: Organization };

type Params = { condition: Condition; data?: Data };

const conditions = {
  MicrosoftTimeWindow: 'Microsoft.TimeWindow',
  PortalOrganizations: 'Portal.Organizations',
};

const toUnixTime = (date: Date) => Math.floor(date.getTime() / 1000);

const checkIfEnabledOrganization = ({ condition, data }: Params) => {
  const { id } = data?.organization ?? {};
  const selectedOrganizations = condition?.parameters ?? {};

  return id ? Object.hasOwn(selectedOrganizations, id) : false;
};

const checkIfValidTimeWindow = ({ condition }: Params) => {
  const start = toUnixTime(new Date(condition.parameters?.Start ?? ''));
  const end = toUnixTime(new Date(condition.parameters?.End ?? ''));
  const now = toUnixTime(new Date());

  if (!start) return now <= end;
  if (!end) return now >= start;
  return now >= start && now <= end;
};

const validationCallbackPerCondition = {
  [conditions.MicrosoftTimeWindow]: checkIfValidTimeWindow,
  [conditions.PortalOrganizations]: checkIfEnabledOrganization,
};

export { conditions, validationCallbackPerCondition };
