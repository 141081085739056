import { useAuth0 } from '@auth0/auth0-react';
import {
  Avatar,
  Box,
  Button,
  ButtonProps,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  useMediaQuery,
} from '@chakra-ui/react';
import { mdiChevronDown } from '@mdi/js';
import * as React from 'react';
import { PortalIcon } from '../PortalIcon';
import type {
  NavigationAction,
  NavigationHorizontalProps,
  NavigationItem,
  NavigationVerticalProps,
} from './model';
import { Link as ReactRouterLink } from 'react-router-dom';
import sitecoreTheme from '@sitecore/blok-theme';

/**
 * determineNavItemTargetId
 * Apply data-targetid to each navigation menu option,
 * with the format of "${name}_{index}" or "nav-menu-btn_${index}"
 * @param navItem function | object
 * @param index number
 * @returns "${name}_{index}" or "nav-menu-btn_${index}"
 */
const determineNavItemTargetId = (navItem: NavigationItem, index: number) => {
  if (typeof navItem === 'function' || typeof navItem.label !== 'string') {
    return `nav-menu-btn_${index}`;
  }

  return `${navItem.label.replace(/\s/g, '').toLocaleLowerCase()}_${index}`;
};

const OverflowMenu = ({
  visibilityMap,
  navItems,
}: {
  visibilityMap: {
    [key: string]: boolean;
  };
  navItems: NavigationItem[];
}) => {
  const allOptionsHidden = React.useMemo(() => {
    return Object.values(visibilityMap).every((v) => v === false);
  }, [visibilityMap]);

  return (
    <Menu placement='bottom-end' closeOnBlur id='nav-menu-more'>
      <MenuButton
        as={Button}
        variant='ghost'
        rightIcon={
          <PortalIcon path={mdiChevronDown} layerStyle='menuButtonIcon' />
        }
        borderRadius='md'
        flexShrink={0}
        px={2}
        height='100%'
        data-testid='nav-btn-menu-more'
      >
        {allOptionsHidden ? 'Menu' : 'More'}
      </MenuButton>
      <MenuList>
        {navItems.map((item: any, i: number) => {
          if (!visibilityMap[determineNavItemTargetId(item, i)]) {
            return (
              <MenuItem
                key={i}
                onClick={item.onClick}
                // color={item.active ? 'primary.500' : 'neutral.600'}
                // fontWeight={item.active ? 600 : 'inherit'}
                // _hover={{
                //   bgColor: 'neutral.100',
                // }}
              >
                {item.label}
              </MenuItem>
            );
          }
          return null;
        })}
      </MenuList>
    </Menu>
  );
};

const IntersectionOberverWrapper = ({
  children,
  navItems,
}: {
  children: any;
  navItems: NavigationItem[];
}) => {
  const containerRef = React.useRef<any>(null);
  const previousItems = React.useRef<any>(navItems);

  const [visibilityMap, setVisibilityMap] = React.useState<{
    [key: string]: boolean;
  }>({});

  React.useEffect(() => {
    if (!containerRef.current) return;

    const navMenuBtnsObserver = new IntersectionObserver(
      handleMenuOptionsIntersection,
      {
        root: containerRef.current,
        threshold: 1,
        // 'more' menu option is around 82px
        // we take into acount its space to the intersection
        rootMargin: '0px -85px 0px 0px',
      },
    );

    // We are adding observers to child elements of the container div
    // with ref as navRef. Notice that we are adding observers
    // only if we have the data attribute targetid on the child element
    Array.from(containerRef.current.children).forEach((item: any) => {
      if (item.dataset.targetid) {
        navMenuBtnsObserver.observe(item);
      }
    });

    if (previousItems.current !== navItems) {
      previousItems.current = navItems;
      // reset visibility map in case of new items
      setVisibilityMap({});
    }
    // clean up on unmount
    return () => {
      navMenuBtnsObserver.disconnect();
    };
  }, [navItems]);

  /**
   * handleMenuOptionsIntersection
   * The function to execute when a navigation menu option is intersected.
   * We update the visibility map by whether the option
   * is partially hidden or fully visible
   * @param entries
   */
  const handleMenuOptionsIntersection = (entries: { [key: string]: any }) => {
    const updatedEntries: { [key: string]: boolean } = {};

    entries.forEach((entry: any) => {
      const targetid = entry.target.dataset.targetid;

      // Check if element is visibile within container
      if (entry.isIntersecting) {
        updatedEntries[targetid] = true;
      } else {
        updatedEntries[targetid] = false;
      }
    });
    // Overwrite previous state values with current state
    setVisibilityMap((prev: any) => ({
      ...prev,
      ...updatedEntries,
    }));
  };

  const showOverflowMenu = React.useMemo(() => {
    return !Object.values(visibilityMap).every((v) => v === true);
  }, [visibilityMap]);

  return (
    <React.Fragment>
      <Box
        ref={containerRef}
        sx={{
          '.visible': {
            order: 0,
            opacity: 1,
            visibility: 'visible',
          },
          '.inVisible': {
            visibility: 'hidden',
            order: 100,
            opacity: 0,
            mx: '2',
            pointerEvents: 'none',
          },
        }}
        display={'flex'}
        height='100%'
        alignItems='center'
        overflow='hidden'
        p={1}
        boxSizing='content-box'
        flexBasis='auto'
        flexGrow={1}
        flexShrink={1}
        id='navigation-horizontal__menu'
      >
        {React.Children.map(children, (child: any, index: any) => {
          return React.cloneElement(child, {
            className: visibilityMap[child.props['data-targetid'] as string]
              ? 'visible'
              : 'inVisible',
            ['data-testid']: `nav-item-btn_${index}`,
          });
        })}

        {showOverflowMenu && (
          <OverflowMenu visibilityMap={visibilityMap} navItems={navItems} />
        )}
      </Box>
    </React.Fragment>
  );
};

/**
 * Sitecore UI Navigation components
 */
export const NavigationHorizontal = (props: NavigationHorizontalProps) => {
  const { user } = useAuth0();
  const [isBiggerThanMd] = useMediaQuery(
    `(min-width: ${sitecoreTheme.breakpoints.md})`,
  );

  return (
    <Box
      h='100%'
      w='100%'
      alignItems='center'
      display='flex'
      data-testid='navigationHorizontal'
      id='navigation-horizontal'
      {...props.boxProps}
    >
      {props.icon}
      {props.image && <Image mx='2' {...props.image} />}
      {/* if bigger than md use the old representation */}
      {isBiggerThanMd && (
        <IntersectionOberverWrapper navItems={props.items}>
          {renderItems(props.items, {
            style: { height: '100%', marginRight: '0.5rem', flexShrink: 0 },
          })}
        </IntersectionOberverWrapper>
      )}
      {/* if it's smaller than md then render the mobile items menu */}
      {!isBiggerThanMd && props.mobileItems}
      <Box
        ml='auto'
        display='flex'
        alignItems='center'
        justifyContent='flex-end'
      >
        {props.actions && RenderActions(props.actions)}
        {props.menu && (
          <Menu data-testid='navigationHorizontal_accountMenu'>
            <MenuButton data-testid='navigationHorizontal_accountMenuButton'>
              <Tooltip label={props.menu.name}>
                <Avatar
                  size='sm'
                  name={props.menu.name}
                  layerStyle='interactive.fill'
                  src={user?.picture}
                />
              </Tooltip>
            </MenuButton>
            <MenuList>
              <Box px='3.5' py='2'>
                {props.menu.name && (
                  <Text variant='strong'>{props.menu.name}</Text>
                )}
                {props.menu.email && (
                  <Text variant='small'>{props.menu.email}</Text>
                )}
              </Box>
              {props.menu.items.map((item, i) => {
                return (
                  <React.Fragment key={i}>
                    <MenuItem icon={item.icon} onClick={item.onClick}>
                      {item.label}
                    </MenuItem>
                    {props.menu?.items?.length &&
                      i < props.menu?.items?.length - 1 && <MenuDivider />}
                  </React.Fragment>
                );
              })}
            </MenuList>
          </Menu>
        )}
      </Box>
    </Box>
  );
};

export const NavigationVertical = (props: NavigationVerticalProps) => {
  return (
    <Box
      mx='-2'
      display='flex'
      flexDirection='column'
      gap='1'
      data-testid='navigationVertical'
      id='navigation-vertical'
      {...props.boxProps}
    >
      {renderItems(props.items)}
    </Box>
  );
};

/**
 * Sitecore UI Navigation components display names
 */
NavigationHorizontal.displayName = 'NavigationHorizontal';
NavigationVertical.displayName = 'NavigationVertical';

const renderItems = (items: NavigationItem[], props?: ButtonProps) => {
  return items.map((item, i) => {
    if (typeof item === 'function') {
      return item(i, props);
    }

    return (
      <Button
        data-targetid={determineNavItemTargetId(item, i)}
        key={i}
        variant='navigation'
        {...props}
        isActive={!!item.active}
        leftIcon={item.icon}
        to={item.href}
        as={ReactRouterLink}
        cursor='pointer'
      >
        {item.label}
      </Button>
    );
  });
};

const RenderActions = (items: NavigationAction[]) => {
  const buttonRef = React.useRef(null);

  return items.map((item, i) => {
    const onClick = () => {
      // @ts-ignore
      buttonRef?.current?.blur();
      item.onClick && item.onClick();
    };

    return (
      <Tooltip key={i} label={item.ariaLabel || ''}>
        <IconButton
          ref={buttonRef}
          size='sm'
          key={i}
          variant='ghost'
          aria-label={item.ariaLabel || ''}
          onClick={onClick}
          icon={item.icon}
          title={item.ariaLabel || ''}
          mr='4'
          data-behavior-analytics-id={item['data-behavior-analytics-id']}
          data-testid={item['data-testid']}
        />
      </Tooltip>
    );
  });
};
