import axios from 'axios';
import { getAuthSettings } from '../../../common/config/envConfig';

type ResetUserResponse = {
  client_id: string;
  email: string;
  connection: string;
};

async function resetPassword(email: string) {
  const { domain, clientId } = getAuthSettings();

  try {
    const response = await axios.post<ResetUserResponse>(
      `${domain}/dbconnections/change_password`,
      {
        client_id: clientId,
        email,
        connection: 'Content-Hub-Shared-Database',
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      },
    );
    return response.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      return Promise.reject(new Error(e.message));
    }
    return Promise.reject(new Error('Something went wrong, please try again'));
  }
}

export { resetPassword };
