/* eslint-disable no-empty-function */
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Container,
  Heading,
  VStack,
} from '@chakra-ui/react';
import {
  DataTable,
  DataTableSortingRule,
} from 'features/common/components/DataTable';
import {
  ContextUserApplication,
  Entitlement,
  useGetSubscriptionsQuery,
} from 'gql/graphql';
import { useMemo } from 'react';
import { useHistory } from 'react-router';
import NoSubscriptions from './NοSubscriptions';
import { calculateUsage, getTableSchema } from './utils';
import withSubscriptionGuard from './withSubscriptionGuard';
import { subscriptionsRoute } from './routes';

// When graphQL endpoint is updated we will get the icons from this query
// const { data: iconsData, loading: iconsLoading } = useGetIconsQuery();
// const productIcons = iconsData?.products?.applications?.nodes;
const productIcons: unknown = [];

const sortBy: DataTableSortingRule[] = [];
const onChange = () => {};

export const Subscriptions = () => {
  const history = useHistory();
  const { data: subscriptionsData, loading: subscriptionsLoading } =
    useGetSubscriptionsQuery({
      variables: { first: 100 },
    });

  const hasSubscriptions = !!subscriptionsData?.subscriptions?.totalCount;
  const subscriptions = subscriptionsData?.subscriptions?.nodes ?? [];

  const subscriptionsWithExceededLimits = subscriptions.map((subscription) => {
    const hasExceededLimit = subscription?.entitlements?.nodes?.some(
      (entitlement) =>
        // @ts-ignore - limitType is not defined yet in the entitlement type
        entitlement.limitType !== 'UNLIMITED' &&
        entitlement.quantity !== null &&
        entitlement.quantity <
          calculateUsage({
            ...entitlement,
            // @ts-ignore - limitType is not defined yet in the entitlement type
            limitType: '',
            metric: '',
            // @ts-ignore - improve typing
            periodType: '',
          }),
    );

    return {
      subscriptionId: subscription.subscriptionId,
      hasExceededLimit,
    };
  });

  const entitlementUsageLimitExceeded = subscriptions
    .flatMap(({ entitlements }) => entitlements?.nodes as Entitlement[])
    .find(
      (entitlement) =>
        entitlement.limitType !== 'UNLIMITED' &&
        entitlement.quantity !== null &&
        entitlement.quantity < calculateUsage(entitlement),
    );

  const tableSchema = useMemo(
    () =>
      getTableSchema(
        (productIcons as ContextUserApplication[]) ?? [],
        subscriptionsWithExceededLimits,
      ),
    [subscriptionsWithExceededLimits],
  );

  const onClickRow = ({ subscriptionId }: { subscriptionId: string }) =>
    history.push({
      pathname: `${subscriptionsRoute}/${subscriptionId}`,
      search: history.location.search,
    });

  return !hasSubscriptions && !subscriptionsLoading ? (
    <NoSubscriptions />
  ) : (
    <Box
      h={'full'}
      overflow='auto'
      layerStyle='section.main'
      bg='chakra-subtle-bg'
    >
      <Container maxW='container.xl'>
        <VStack alignItems='flex-start' gap={8}>
          <Box>
            <Heading as='h1'>Subscriptions</Heading>
          </Box>
          {!!entitlementUsageLimitExceeded && (
            <Alert status='warning' data-testid='subscriptionsUsageLimitAlert'>
              <AlertIcon />
              <AlertDescription aria-label='Alert'>
                Some of your subscriptions have exceeded their usage limits.
              </AlertDescription>
            </Alert>
          )}
          <Box
            w='full'
            bg='white'
            borderRadius='md'
            boxShadow='0px 1px 2px 0px rgba(0, 0, 0, 0.05)'
          >
            <DataTable
              page={1}
              pageSize={5}
              isLoading={subscriptionsLoading}
              columns={tableSchema}
              data={subscriptions}
              pagination='hidden'
              sortBy={sortBy}
              onChange={onChange}
              onClickRow={onClickRow}
              gutterBottom={false}
              data-testid='subscriptionsTable'
              data-behavior-analytics-feature='subscriptions table'
            />
          </Box>
        </VStack>
      </Container>
    </Box>
  );
};

export default withSubscriptionGuard(Subscriptions);
