import { useRouteMatch } from 'react-router';
import { Switch } from 'react-router-dom';
import { ApmRoute } from '@elastic/apm-rum-react';
import { ContextMappingTable } from './table/ContextMappingTable';

const ContextMappingContainer = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Switch>
        <ApmRoute exact path={path} component={ContextMappingTable} />
        <ApmRoute component={ContextMappingTable} />
      </Switch>
    </>
  );
};

export default ContextMappingContainer;
