import React from 'react';
import { Grid, GridProps } from '@chakra-ui/react';

interface TopPicksGridProps extends GridProps {
  children: React.ReactNode;
}
export const TopPicksGrid: React.FC<TopPicksGridProps> = ({
  children,
  ...props
}) => {
  return (
    <Grid
      {...props}
      templateColumns={{
        base: 'repeat(auto-fill, minmax(min(100%, 340px), 1fr))',
        md: 'repeat(auto-fill, minmax(min(calc(50% - 8px), 340px), 1fr))',
        lg: 'repeat(auto-fill, minmax(min(calc(33.333% - 11px), 340px), 1fr))',
      }}
      gap='4'
      justifyItems='center'
      data-testid='top-picks-grid'
    >
      {children}
    </Grid>
  );
};
