import * as Yup from 'yup';

const claimsMappingSchema = Yup.object().shape({
  mappings: Yup.array()
    .of(
      Yup.object().shape({
        sourceClaims: Yup.array()
          .of(
            Yup.object().shape({
              name: Yup.string()
                .required('Name is required')
                .max(250, "Name can't exceed 250 characters"),
              value: Yup.string().max(500, "Value can't exceed 500 characters"),
            }),
          )
          .default([]),
        targetClaims: Yup.array()
          .of(
            Yup.object().shape({
              name: Yup.string()
                .required('Name is required')
                .max(50, "Name can't exceed 50 characters")
                .test(
                  'same-names',
                  'Target claim names must match',
                  function (value, context) {
                    const { path, options } = context;
                    const { mappings } = options?.context || {
                      targetClaims: [],
                    };
                    // Extract the mapping index from the path
                    const matched = path.match(/\d+/g);
                    if (!matched) {
                      return false;
                    }
                    const mappingIndex = matched[0];
                    const names = mappings[mappingIndex].targetClaims.map(
                      (v: any) => v.name,
                    );
                    const isValid = names.every(
                      (name: string) => name === names[0],
                    );
                    return (
                      isValid ||
                      this.createError({
                        path,
                        message: 'Target claim names must match',
                      })
                    );
                  },
                ),
              value: Yup.string()
                .required('Value is required')
                .max(100, "Value can't exceed 100 characters"),
            }),
          )
          .required(),
      }),
    )
    .max(20, "Number of mappings can't exceed 20"),
});

export { claimsMappingSchema };
