import { getContextApiUrl } from '../utils';
import { useAuthMutation } from '../../common/apiUtils/queryHelper';
import { SitecoreContextResourceType } from '../types/types';

export const useCreateSitecoreContextResource = (sitecoreContextId: string) => {
  const url = `${getContextApiUrl()}/contexts/${sitecoreContextId}/resources`;

  return useAuthMutation(
    (axiosInstance) => (payload: SitecoreContextResourceType) =>
      axiosInstance?.post(url, payload),
    {},
  );
};
