import * as actions from './actions';
import { MainAction, MainState } from './model';

function selectApp(payload?: Partial<MainState['main']>): MainAction {
  return {
    type: actions.SELECT_APP,
    payload: {
      app: payload?.app ?? null,
    },
  };
}

function setEnvironmentData(payload?: any): MainAction {
  return {
    type: actions.SET_ENV_DATA,
    payload,
  };
}

export { selectApp, setEnvironmentData };
