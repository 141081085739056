import { Box, useToast } from '@chakra-ui/react';
import { IResponse } from '@sitecore-ui/portal-singular';
import { useMemo } from 'react';
import {
  DataTable,
  DataTableSortingRule,
} from 'features/common/components/DataTable';
import EmptyState from 'features/common/components/EmptyState';
import { tableOptions, getTableData } from './utils';
import { useGetHostnames } from './hooks/useGetHostnames';
import { Hostname } from './types';

const sortBy: DataTableSortingRule[] = [];

export const CustomHostnamesTable = () => {
  const toast = useToast();
  // todo when api error handling is ready, check for error message
  const onError = () => {
    toast({
      description: 'Something went wrong',
      title: 'Error',
      status: 'error',
    });
  };

  const { data, isLoading } = useGetHostnames({ onError });
  const responseData = data as IResponse<Hostname> | undefined;
  const hostnames = useMemo(
    () => responseData?.data ?? [],
    [responseData?.data],
  );
  const totalCount = responseData?.data?.length ?? 0;
  const tableData = useMemo(() => getTableData(hostnames), [hostnames]);
  return hostnames?.length || isLoading ? (
    <Box w='full'>
      <DataTable
        columns={tableOptions.columns}
        totalItems={totalCount}
        data={tableData}
        isLoading={isLoading}
        sortBy={sortBy}
        pagination='hidden'
        onChange={() => void 0}
        data-testid='custom-hostnames-table'
        paginationProps={{
          'data-behavior-analytics-feature': 'custom hostnames pagination',
        }}
        data-behavior-analytics-feature='custom hostnames table'
      />
    </Box>
  ) : (
    <EmptyState text='No hostnames' dataTestId='no-hostnames-container' />
  );
};
