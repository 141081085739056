/* eslint-disable no-console */
import { Router } from 'react-router-dom';
import {
  QueryClient,
  QueryClientProvider,
  useQueryErrorResetBoundary,
} from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ChakraProvider } from '@chakra-ui/react';
import { MainContainer } from './features/main/components/MainContainer';
import { history } from './features/common/history/history';
import { AuthenticatedAxiosProvider } from './features/common/apiUtils/AxiosProvider';
import { Auth0ProviderWithHistory } from './features/common/auth/Auth0ProviderWithHistory';
import { MainProvider } from 'features/main/context/main-context';
import { ErrorBoundary } from 'react-error-boundary';
import { GeneralErrorFallback } from 'features/common/utils/errorBoundaries';
import { fetchLastSuccessfulLoginOrganizationId } from 'features/common/auth/auth0Storage';
import sitecoreTheme, { toastOptions } from '@sitecore/blok-theme';
import ErrorController from 'features/common/errorController/ErrorController';
import './app.css';

const organizationIdFromLastSuccessfulLogin =
  fetchLastSuccessfulLoginOrganizationId();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
    mutations: {
      retry: false,
    },
  },
});

const App = () => {
  const { reset } = useQueryErrorResetBoundary();
  const params = new URLSearchParams(window.location.search);
  const requestedOrganization = params.get('organization');

  return (
    <Router history={history}>
      <Auth0ProviderWithHistory
        initOrganizationId={
          requestedOrganization || organizationIdFromLastSuccessfulLogin
        }
      >
        <ErrorController>
          <ChakraProvider theme={sitecoreTheme} toastOptions={toastOptions}>
            <ErrorBoundary
              onReset={reset}
              FallbackComponent={GeneralErrorFallback}
            >
              <QueryClientProvider client={queryClient}>
                <AuthenticatedAxiosProvider>
                  <MainProvider>
                    <MainContainer />
                  </MainProvider>
                </AuthenticatedAxiosProvider>
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </ErrorBoundary>
          </ChakraProvider>
        </ErrorController>
      </Auth0ProviderWithHistory>
      <div id='apt-widget-controller'></div>
    </Router>
  );
};

export default App;
