import { format } from 'date-fns';
import { IMemberRole } from 'features/members/services/identity/membersModel';
import {
  EOrganizationAccessLevel,
  IMemberDetails,
  ITenantsAccessLevel,
  ISelectOption,
} from './memberDetailsModel';
import { env } from 'features/common/config/envConfig';
import { getProductInstances, ITenant } from '@sitecore-ui/portal-singular';

export const generateMemberDetailsRow: (input: IMemberDetails) => string[] = ({
  jobRole,
  email,
  phoneNumber,
}: IMemberDetails) => {
  const memberDetailsAll = [jobRole, email, phoneNumber];
  return memberDetailsAll.filter(Boolean) as string[];
};

export const selectAccessGlobalDropdownOptions: {
  [key: string]: ISelectOption;
} = {
  ['Organization Owner']: {
    value: 'owner',
    role: 'Owner',
    description: 'Can access everything',
  },
  ['Organization Admin']: {
    value: 'admin',
    role: 'Admin',
    description: 'Can access all apps',
  },
  ['Organization User']: {
    value: 'user',
    role: 'User',
    description: 'Can only access the apps you specify',
  },
};

export const formatDate = (dataString: string) =>
  format(new Date(dataString).getTime(), 'MMM d, yyyy');

/**
 *
 * @param tenants tenants List that the current organization has
 * @param rolesPerTenant list of access level per tenant for a user
 * @returns an object representing the access level per tenant [admin | user | no-access]
 */

export const generateTenantsAccessMap: (
  tenants: ITenant[],
  rolesPerTenant: ITenantsAccessLevel,
) => ITenantsAccessLevel = (tenants, rolesPerTenant) =>
  getProductInstances(tenants, env).reduce(
    (acc, { viewModel }) => ({
      ...acc,
      [viewModel.id]: rolesPerTenant[viewModel.id] || 'no-access', // TODO: should this be uppercase?
    }),
    {},
  );

const getOnlyTenantRoles: (roles: IMemberRole[]) => IMemberRole[] = (roles) =>
  roles.filter(({ scope }) => scope !== 'Organization');

/**
 * @description returns a list of tenants that the user has access to
 * @param roles
 */
export const getRolesPerTenants: (
  roles: IMemberRole[],
) => ITenantsAccessLevel = (roles) => {
  const filteredRoles = getOnlyTenantRoles(roles).reduce(
    (acc, { tenantId, role }) => {
      const tenantEntry = tenantId ? { [tenantId]: role } : {};

      return { ...acc, ...tenantEntry };
    },
    {},
  );
  return filteredRoles as ITenantsAccessLevel;
};

export const filterRolesForOrganization: (
  organization: string,
  roles: IMemberRole[],
) => IMemberRole[] = (organization, roles) =>
  roles.filter(({ organizationId }) => organizationId === organization);

export const getUserRoleForOrganization: (
  organization: string,
  roles: IMemberRole[],
) => EOrganizationAccessLevel = (organization, roles) => {
  const organizationRole =
    filterRolesForOrganization(organization, roles)
      .filter(({ role }) => role.includes('Organization'))
      .map(({ role }) => {
        //to avoid case sensitive comparisons
        const lowercaseRole = role.toLowerCase();

        switch (lowercaseRole) {
          case 'organization admin':
            return EOrganizationAccessLevel.admin;
          case 'organization user':
            return EOrganizationAccessLevel.user;
          case 'organization owner':
            return EOrganizationAccessLevel.owner;

          default:
            return EOrganizationAccessLevel.user;
        }
      })[0] || EOrganizationAccessLevel.user;

  return organizationRole;
};
