import {
  Media,
  MediaBody,
  MediaDescription,
  MediaFigure,
  MediaSizes,
  MediaTitle,
} from '../MediaObject';
import { T } from '@transifex/react';
import { tenantsFallbackIcon } from 'features/common/utils/icons';

interface IMediaCard {
  title: string;
  label?: string;
  imageUrl?: string;
  iconColor?: string;
  iconPath?: string;
  svgIcon?: string;
  size?: MediaSizes;
}

const MediaCard = ({
  title,
  label,
  imageUrl,
  iconColor,
  iconPath = tenantsFallbackIcon,
  svgIcon,
  size = 'large',
}: IMediaCard) => {
  const renderMediaFigure = () => {
    if (imageUrl || svgIcon) {
      return (
        <MediaFigure
          image={{ url: imageUrl || svgIcon, alt: title }}
          color={iconColor}
          style={{ display: 'flex' }}
        />
      );
    }

    return (
      <MediaFigure
        icon={{ path: iconPath }}
        color={iconColor}
        style={{ display: 'flex' }}
      />
    );
  };

  return (
    <Media
      size={size}
      as='div'
      p={0}
      style={{ display: 'flex', alignItems: 'center', cursor: 'initial' }}
      _hover={{ background: 'initial' }}
      _focus={{ background: 'initial' }}
    >
      {renderMediaFigure()}
      <MediaBody alignSelf='center' display='flex' flexDirection='column'>
        <MediaTitle>{title}</MediaTitle>
        <MediaDescription>{label && <T _str={label} />}</MediaDescription>
      </MediaBody>
    </Media>
  );
};

export default MediaCard;
