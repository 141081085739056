import {
  useAuthMutation,
  useAuthQuery,
} from 'features/common/apiUtils/queryHelper';
import { connectionsApi } from '../api/endpoints';
import { env } from 'features/common/config/envConfig';
import { getIdentityBaseUrl } from '@sitecore-ui/portal-singular';
import {
  ClaimsMappingPayload,
  MappingConnectionId,
} from './claim-mapping-types';
import { AxiosResponse } from 'axios';

const baseUrl = getIdentityBaseUrl(env.toLowerCase());

type ClaimsMappingResponse =
  | AxiosResponse<ClaimsMappingPayload>
  | AxiosResponse<{ configuration: never[] }>;

export const useGetClaimsMapping = ({
  connectionId,
}: MappingConnectionId | { connectionId: undefined }) => {
  return useAuthQuery<ClaimsMappingResponse>(
    ['claims-mappings'],
    async (axiosInstance) => {
      if (!connectionId) {
        return {
          data: { configuration: [] },
          status: 200,
          statusText: 'OK',
          headers: {},
          config: {},
        } as AxiosResponse<{ configuration: never[] }>;
      }

      const url = `${baseUrl}${connectionsApi.get_claims_mappings.url(
        connectionId,
      )}`;

      return axiosInstance?.get<ClaimsMappingPayload>(url);
    },
    {
      enabled: Boolean(connectionId !== undefined),
    },
    connectionsApi.get_claims_mappings.scope,
  );
};

export const usePutClaimsMapping = () => {
  return useAuthMutation(
    (axiosInstance) =>
      ({
        payload,
        connectionId,
      }: {
        payload: ClaimsMappingPayload;
        connectionId: string;
      }) => {
        const url = `${baseUrl}${connectionsApi.put_claims_mappings.url(
          connectionId,
        )}`;

        return axiosInstance?.put(url, payload);
      },
    {
      onError: () => {
        return;
      },
      onSuccess: () => {
        return;
      },
    },
    connectionsApi.put_claims_mappings.scope,
  );
};
