import {
  Flex,
  Wrap,
  Heading,
  Skeleton,
  Badge,
  Stack,
  Button,
  Text,
  useToast,
} from '@chakra-ui/react';
import {
  useGetCurrentOrg,
  useUpdateOrganizationMutation,
} from 'features/home/services/identity/organization';
import SupportAccessTable from './SupportAccessTable';

const SupportAccess = () => {
  const { isLoading: isGetCurrentOrganizationLoading, data } =
    useGetCurrentOrg();
  const { mutate: updateOrganizationMutation, isLoading } =
    useUpdateOrganizationMutation();
  const toast = useToast();

  const isSupportAccessEnabled = data?.data.supportAccess;
  const toggleSupportAccess = () => {
    updateOrganizationMutation({
      supportAccess: !isSupportAccessEnabled,
      successCb() {
        toast({
          description: `Support Access ${
            isSupportAccessEnabled ? 'disabled' : 'enabled'
          }`,
          status: 'success',
        });
      },
      errorCb() {
        toast({
          title: 'Something went wrong',
          description: 'Try again',
          status: 'error',
        });
      },
    });
  };

  return (
    <Flex flexDir='column' height='100%'>
      <Flex mb='8' justifyContent='space-between' gap='6'>
        <Wrap align='center' spacing='4'>
          <Heading as='h1' data-testid='supportAccess-heading'>
            Sitecore Support access
            <Skeleton
              isLoaded={!isGetCurrentOrganizationLoading}
              display='inline-block'
            ></Skeleton>
          </Heading>
          <Badge
            mt='1.5'
            colorScheme={isSupportAccessEnabled ? 'success' : 'neutral'}
            data-testid={
              isSupportAccessEnabled
                ? 'support-access-badge-enabled'
                : 'support-access-badge-not-enabled'
            }
          >
            {isSupportAccessEnabled ? 'Enabled' : 'Not enabled'}
          </Badge>
        </Wrap>
        <Skeleton isLoaded={!isGetCurrentOrganizationLoading}>
          {isSupportAccessEnabled ? (
            <Button
              variant='solid'
              colorScheme='danger'
              onClick={toggleSupportAccess}
              isLoading={isLoading}
              data-testid='deactivateButton'
              data-behavior-analytics-id='disable support access'
            >
              Disable Access
            </Button>
          ) : (
            <Button
              variant='solid'
              colorScheme='success'
              onClick={toggleSupportAccess}
              isLoading={isLoading}
              data-testid='activateButton'
              data-behavior-analytics-id='enable support access'
            >
              Enable Access
            </Button>
          )}
        </Skeleton>
      </Flex>
      <Stack spacing='10'>
        <Text maxW='prose' mb='8' data-testid='supportAccess-description'>
          Grant Sitecore support engineers access to your organization and all
          its apps to resolve technical issues. To revoke the access, click
          disable access.
        </Text>
      </Stack>
      <SupportAccessTable />
    </Flex>
  );
};

export default SupportAccess;
