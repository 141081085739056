import { useRouteMatch } from 'react-router';
import { Switch } from 'react-router-dom';
import { PageNotFound } from 'features/main/components/CustomErrorPages';
import { ApmRoute } from '@elastic/apm-rum-react';
import {
  IInvitationsTableProps,
  InvitationsTableWithErrorHandling,
} from './table/InvitationsTable';
import { Feature } from 'lib/featureFlags/features';
import { JSX } from 'react/jsx-runtime';
import { InvitationRemoveConfirmation } from './table/components/InvitationDeleteConfirmation';

const InvitesContainer = () => {
  const { path } = useRouteMatch();
  return (
    <>
      <Switch>
        <ApmRoute
          path={`${path}/page/:page`}
          component={(
            props: JSX.IntrinsicAttributes & IInvitationsTableProps,
          ) => (
            <Feature name='INVITATIONS'>
              {(isEnabled: boolean) =>
                isEnabled ? (
                  <InvitationsTableWithErrorHandling {...props} />
                ) : (
                  <PageNotFound />
                )
              }
            </Feature>
          )}
        />
        <ApmRoute component={PageNotFound} />
      </Switch>
      <ApmRoute
        exact
        path={`${path}/page/:page/delete/:id`}
        render={(props) => (
          <InvitationRemoveConfirmation {...props} isOpen={true} />
        )}
      />
    </>
  );
};

export default InvitesContainer;
