import { LocalStorageValue } from 'features/common/utils/localStorage';

const FEATURE_STORAGE_KEY = 'GUESTID_RECOMMENDATIONS';
const guestIdStorage = LocalStorageValue.getInstance(FEATURE_STORAGE_KEY);

function storeGuestId(currentGuestId: unknown) {
  guestIdStorage.set(FEATURE_STORAGE_KEY, currentGuestId);
}

function getStoredGuestId() {
  return guestIdStorage.get(FEATURE_STORAGE_KEY);
}

export { FEATURE_STORAGE_KEY, guestIdStorage, storeGuestId, getStoredGuestId };
