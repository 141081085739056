import { DrawerAction, DrawerId } from './model';

function openDrawer(drawerId: DrawerId, data?: any): DrawerAction {
  return {
    type: 'OPEN_DRAWER',
    payload: {
      drawerId,
      data,
    },
  };
}

function closeDrawer(): DrawerAction {
  return {
    type: 'CLOSE_DRAWER',
  };
}

export { openDrawer, closeDrawer };
