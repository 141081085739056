import { AxiosInstance } from 'axios';

let authAxios: AxiosInstance | null = null;

const createAuthAxios = (instance: AxiosInstance) => {
  authAxios = instance;
  return authAxios;
};

export { authAxios, createAuthAxios };

/*
    authAxios instance is modified as a side effect by the AxiosProvider, careful when using it outside
*/
