import { AxiosResponse } from 'axios';
import { yupToFormErrors } from 'formik';
import { t } from '@transifex/native';
import * as Yup from 'yup';
import toLower from 'lodash/toLower';
import intersection from 'lodash/intersection';
import { restrictedEmails } from 'features/common/validations/matchers';
import { IMemberInviteValues } from '../useInviteMember';

const validationSchema = Yup.object({
  emails: Yup.array()
    .of(Yup.string().email())
    .min(1, t('You must enter an email address')),
  orgRole: Yup.string().required(t('Required')),
});

export const hasRestrictedEmail = (email: string) =>
  restrictedEmails.test(email.toLowerCase());

export const getRestrictedEmails = (emails: string[]) => {
  const restrictedEmails = emails
    .filter((email) => hasRestrictedEmail(email))
    .map((email) => email.substring(email.indexOf('@') + 1).toLowerCase());

  return [...new Set(restrictedEmails)];
};

/**
 * @description This function is used to validate the email addresses entered by the user.
 * @param values - The values entered by the user.This is the formik values object. It contains the emails array.
 * @returns {string} - The error message to be displayed to the user.
 */
export const getRestrictedEmailsValidationError = (values: {
  emails: string[];
}) => {
  const { emails } = values;
  const restrictedEmails = getRestrictedEmails(emails);

  return restrictedEmails.length
    ? `Invitations to ${restrictedEmails.join(
        ', ',
      )} domains aren't allowed. Use the sitecore.com email to invite Sitecore employees.`
    : '';
};

/**
 * @description This function is used to validate the email addresses entered by the user.
 * It checks if the email addresses entered by the user already exist in the organization.
 * @param values - The values entered by the user.This is the formik values object. It contains the emails array.
 * @param membersEmailsPool - The list of emails of the members in the organization.
 * @param serverInvitationErrors - The list of server errors returned by the API.
 * @returns {string} - The error message to be displayed to the user.
 */
export const getMemberValidationError = (
  values: { emails: string[] },
  membersEmailsPool: (string | undefined)[],
  serverInvitationErrors: { email: string; message: string }[],
) => {
  const { emails } = values;

  const orgEmailLowercaseList = membersEmailsPool.map(toLower);
  const emailLowercaseList = emails.map(toLower);

  const commonEmails = intersection(orgEmailLowercaseList, emailLowercaseList);

  if (commonEmails.length) {
    let errorMessage =
      commonEmails.length <= 1
        ? 'The highlighted email address already exists in this organization.'
        : 'The highlighted email addresses already exist in this organization.';

    if (serverInvitationErrors.length > 0) {
      errorMessage = `${commonEmails.join(', ')} already ${
        commonEmails.length <= 1 ? 'exist' : 'exists'
      } in this organization`;
    }

    return errorMessage;
  }

  return '';
};

type ValidateSSOConnectionEmailsParams = {
  userEmails: string[];
  allowedDomains?: string[];
};
export const getSSOConnectionValidationError = (allowedDomains: string[]) => {
  return `You can only invite email addresses with domains ${allowedDomains
    .map((domain) => `${domain}`)
    .join(', ')} or sitecore.com`;
};
/**
 * @description This function is used to validate the email addresses entered by the user.
 * It checks if the email addresses entered by the user are allowed by the SSO connection.
 */
export const validateSSOConnectionEmails = ({
  userEmails,
  allowedDomains,
}: ValidateSSOConnectionEmailsParams) => {
  // If there are no sso domains enabled, there is nothing to validate
  if (!allowedDomains?.length) {
    return {
      error: '',
      invalidSSOEmails: [],
    };
  }
  // Business side effect(PORTAL-1836): if there are sso domains, add sitecore.com to the list of allowed domains
  const sideEffectAllowedDomains = allowedDomains
    ? [...allowedDomains, 'sitecore.com']
    : ['sitecore.com'];

  const invalidUserEmails = userEmails.map(toLower).filter((userEmail) => {
    const userEmailDomain = userEmail.substring(userEmail.indexOf('@') + 1);

    return !sideEffectAllowedDomains.map(toLower).includes(userEmailDomain);
  });

  let error = '';

  if (invalidUserEmails.length) {
    error = getSSOConnectionValidationError(allowedDomains);
  }

  return {
    error,
    invalidSSOEmails: invalidUserEmails,
  };
};

// Invitations outside of the enabled SSO domains are not allowed. Enabled domains: domain, domain.
export const handleValidate = async (values: IMemberInviteValues) => {
  try {
    await validationSchema.validate(values);

    return {};
  } catch (err) {
    const errors = yupToFormErrors<IMemberInviteValues>(err);

    return errors;
  }
};

export const getServerInvitationErrors = (
  data:
    | (
        | { email: string; status: 'rejected'; reason: any }
        | {
            email: string;
            status: 'fulfilled';
            value: AxiosResponse<any>;
          }
      )[]
    | undefined,
) => {
  if (Array.isArray(data)) {
    return (
      data
        .filter((d) => d?.status === 'rejected')
        //@ts-ignore We already filter by 'rejected' status. No need to add an extra check for reason
        .flatMap(({ reason, email }) => {
          const { status = '', data = {} } = reason?.response ?? {};
          const { InviteeEmail } = data?.errors ?? {};

          const message = InviteeEmail?.length
            ? InviteeEmail[0]
            : `Error code: ${
                status ?? 'Uknown'
              }. You cannot send an invitation to ${email}. Please contact Sitecore Support.`;
          return { email, message };
        })
    );
  }

  return [];
};

export const getEmailsPerMessage = (
  emails: { email: string; message: string }[],
) =>
  emails.reduce(
    (acc: { emails: string[]; message: string }[], { email, message }) => {
      const existingMessage = acc.find((item) => item.message === message);

      if (existingMessage) {
        existingMessage.emails.push(email);
      } else {
        acc.push({ message, emails: [email] });
      }

      return acc;
    },
    [],
  );
