import React from 'react';
import { env } from 'features/common/config/envConfig';
import { useAuth0 } from '@auth0/auth0-react';
import { fetchLastSuccessfulLoginOrganizationId } from 'features/common/auth/auth0Storage';
import { useLocation } from 'react-router-dom';
import { behindFeature } from 'lib/featureFlags/features';
import { AppSwitcherInterface } from './types';

function RCAppSwitcher() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const organization = params.get('organization');
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const organizationId =
    organization ||
    fetchLastSuccessfulLoginOrganizationId() ||
    // (user && user[TokenCustomClaimKeysEnum.ORG_ID]) ||
    '';

  React.useEffect(() => {
    const initialize = async () => {
      const appSwitcher = document.querySelector(
        'app-switcher',
      ) as unknown as AppSwitcherInterface;

      if (!appSwitcher || !appSwitcher.componentOnReady) return;

      appSwitcher?.componentOnReady().then(() => {
        appSwitcher?.init({
          getAccessToken: getAccessTokenSilently,
          loginWithRedirect,
          environment: env,
          organizationId: organizationId,
          buttonSelector: '.js-app-switcher-button',
        });
      });
    };

    initialize();
  }, [getAccessTokenSilently, loginWithRedirect, organizationId]);

  return <app-switcher data-testid='app-switcher'></app-switcher>;
}

const AppSwitcher = behindFeature('APP_SWITCHER')(RCAppSwitcher);

export { AppSwitcher };
