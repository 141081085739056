import { connectionsApi } from './endpoints';
import { getIdentityBaseUrl } from '@sitecore-ui/portal-singular';
import { env } from 'features/common/config/envConfig';
import { useAuthMutation } from 'features/common/apiUtils/queryHelper';
import { useQueryClient } from 'react-query';

const baseUrl = getIdentityBaseUrl(env.toLowerCase());

export const useDeleteConnection = () => {
  const { delete_connection } = connectionsApi;
  const url = (id: string) => `${baseUrl}${delete_connection.url(id)}`;
  const queryClient = useQueryClient();

  return useAuthMutation(
    (axiosInstance) =>
      ({ id }: { id: string }) => {
        return axiosInstance?.delete(url(id));
      },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('connections/list');
      },
    },
    delete_connection.scope,
  );
};
