import {
  Box,
  Button,
  ButtonGroup,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormHelperText,
  FormLabel,
  Text,
} from '@chakra-ui/react';
import { useCallback, useRef } from 'react';
import { t } from '@transifex/native';
import { T } from '@transifex/react';
import { ProductAccessOptions } from '../../../common/components/ProductAccessOptions';
import { ORG_ROLES } from '../../../common/components/OrganizationAppAccess/resource';
import { useInviteMember } from '../../hooks';
import { useDrawerStateDispatch } from '../../../main/context';
import { closeDrawer } from '../../../main/context/drawer/actionCreators';
import useModal from 'features/common/hooks/useModal/useModal';
import ErrorMessage from './components/ErrorMessage';
import { useAppAccess } from '../../../../features/common/components/OrganizationAppAccess';
import { ORG_ROLE_OPTIONS } from './resources/invitationResources';
import DiscardChangesModal from 'features/common/components/DiscardModal/DiscardChangesModal';
import { InputTag } from 'features/common/components/InputTag';
import { SingleValue, Select as ReactSelect } from 'chakra-react-select';
import { isEmail } from 'features/common/components/InputTag/utils';

export const InviteDrawer = () => {
  const [{ inviteDrawerVisible }, drawerDispatch] = useDrawerStateDispatch();
  const { isOpen, setIsOpen, onCloseModal } = useModal();
  const { hasOrgAdminAccess } = useAppAccess();

  const initialFocusRef = useRef(null);

  const {
    getValidEmails,
    formik,
    memberValidationError,
    hasTenants,
    tenantsLoading,
    setProductAccessStateCb,
    getAvailableOptions,
    productAccessState,
    invalidEmails,
    loading,
    restrictedEmailsValidationError,
    restrictedSSOEmailsError,
  } = useInviteMember({
    onClose: () => drawerDispatch(closeDrawer()),
  });

  const {
    resetForm,
    dirty,
    errors,
    handleSubmit,
    values,
    setErrors,
    setFieldValue,
    isSubmitting,
  } = formik;

  const clearForm = () => {
    resetForm();
    setProductAccessStateCb([]);
    drawerDispatch(closeDrawer());
  };

  const onCloseDrawer = () => {
    const isFormDirty = dirty || productAccessState.length;
    if (isFormDirty) return setIsOpen(true);
    clearForm();
  };

  const hasValidationErrors = Boolean(
    restrictedSSOEmailsError ||
      memberValidationError ||
      restrictedEmailsValidationError ||
      errors.emails,
  );

  const appAccessOptions = hasOrgAdminAccess
    ? ORG_ROLE_OPTIONS.filter((r) => r.value !== ORG_ROLES.ORG_OWNER)
    : ORG_ROLE_OPTIONS;

  const onChange = useCallback(
    (emails: string[]) => {
      setFieldValue('emails', emails);
      setErrors({});
    },
    [setErrors, setFieldValue],
  );

  return (
    <>
      <Drawer
        onClose={onCloseDrawer}
        isOpen={inviteDrawerVisible}
        initialFocusRef={initialFocusRef}
        size='lg'
      >
        <form onSubmit={handleSubmit} data-testid='invite-form'>
          <DrawerOverlay />
          <DrawerContent
            data-testid='create_invite_drawer'
            data-behavior-analytics-feature='Invitations drawer'
          >
            <DrawerHeader display='flex' alignItems='center'>
              <T _str='Invite team members' />
            </DrawerHeader>
            <DrawerBody>
              <FormControl isRequired py={2} isInvalid={hasValidationErrors}>
                <FormLabel>
                  <T _str='Email addresses' />
                </FormLabel>
                <InputTag
                  name='inviteMembers'
                  tags={values.emails}
                  invalidTags={invalidEmails}
                  onChange={onChange}
                  validateTag={isEmail}
                  placeholder='example@email.com'
                  ref={initialFocusRef}
                  isInvalid={hasValidationErrors}
                  dataTestId='multiEmail'
                />
                <>
                  <ErrorMessage
                    msg={memberValidationError}
                    whiteSpace='break-spaces'
                    testId='memberValidationError'
                  />
                  <ErrorMessage
                    msg={restrictedEmailsValidationError}
                    whiteSpace='break-spaces'
                    testId='restrictedEmailsValidationError'
                  />
                  <ErrorMessage
                    msg={restrictedSSOEmailsError}
                    whiteSpace='break-spaces'
                    testId='restrictedSSOEmailsError'
                  />
                  <ErrorMessage
                    msg={errors?.emails ?? []}
                    whiteSpace='break-spaces'
                    testId='errorEmails'
                  />
                  {!hasValidationErrors && values.emails.length === 0 && (
                    <FormHelperText>
                      Enter multiple emails or paste a list. We'll do our best
                      to detect the email addresses.
                    </FormHelperText>
                  )}
                </>
              </FormControl>
              <FormControl py={2}>
                <FormLabel htmlFor='organization-access-select-input-id'>
                  <T _str='Organization access' />
                </FormLabel>
                <Box data-testid='invite-drawer-org-access'>
                  <ReactSelect
                    selectedOptionColorScheme='primary'
                    useBasicStyles
                    inputId='organization-access-select-input-id'
                    value={appAccessOptions.find(
                      (o) => o.value === values.orgRole,
                    )}
                    onChange={(
                      data: SingleValue<{ label: string; value: string }>,
                    ) => {
                      setFieldValue('orgRole', data?.value);
                    }}
                    options={appAccessOptions}
                    formatOptionLabel={({
                      label,
                      desc,
                    }: {
                      label: string;
                      desc: string;
                      value: string;
                    }) => (
                      <Text fontSize='md' data-testid={`option-${label}`}>
                        <span style={{ fontWeight: 'semibold' }}>{label}</span>{' '}
                        - {desc}
                      </Text>
                    )}
                  />
                </Box>
              </FormControl>
              {values.orgRole === ORG_ROLES.ORG_USER && (
                <FormControl py={2}>
                  <FormLabel>
                    <T _str='App access' />
                  </FormLabel>
                  {hasTenants ? (
                    <ProductAccessOptions
                      isLoading={tenantsLoading}
                      //@ts-ignore
                      productAccessOptions={getAvailableOptions()}
                      productAccessRowState={productAccessState}
                      setproductAccessRowState={setProductAccessStateCb}
                    />
                  ) : (
                    <Text
                      fontSize='md'
                      color='chakra-subtle-text'
                      data-testid='invite-drawer-no-apps-text'
                    >
                      No apps are set up yet. When an app is available, you must
                      update the access rights of the team members.
                    </Text>
                  )}
                </FormControl>
              )}
              {values.orgRole === ORG_ROLES.ORG_OWNER && (
                <FormControl py={2}>
                  <FormLabel>
                    <T _str='App access' />
                  </FormLabel>
                  <Text
                    fontSize='md'
                    color='chakra-subtle-text'
                    data-testid='invite-drawer-owner-text'
                  >
                    You are granting ownership rights to this organization. This
                    includes access to all apps, the admin section and
                    management of all owners.
                  </Text>
                </FormControl>
              )}
              {values.orgRole === ORG_ROLES.ORG_ADMIN && (
                <FormControl py={2}>
                  <FormLabel>
                    <T _str='App access' />
                  </FormLabel>
                  <Text
                    fontSize='md'
                    color='chakra-subtle-text'
                    data-testid='invite-drawer-admin-text'
                  >
                    You are granting admin rights to this organization. This
                    includes access to all apps and the admin section.
                  </Text>
                </FormControl>
              )}
            </DrawerBody>
            <DrawerFooter justifyContent='flex-end'>
              <ButtonGroup>
                <Button
                  data-testid='cancel-btn'
                  type='button'
                  variant='ghost'
                  onClick={onCloseDrawer}
                  isDisabled={isSubmitting || loading}
                >
                  Cancel
                </Button>
                <Button
                  type='submit'
                  variant='solid'
                  data-testid='submit-btn'
                  isLoading={isSubmitting || loading}
                >
                  {`${t('Send')} ${
                    getValidEmails(values.emails).length > 0
                      ? `${getValidEmails(values.emails).length} `
                      : ''
                  }${
                    getValidEmails(values.emails).length !== 1
                      ? `${t('invites')}`
                      : `${t('invite')}`
                  }`}
                </Button>
              </ButtonGroup>
            </DrawerFooter>
          </DrawerContent>
        </form>
      </Drawer>
      <DiscardChangesModal
        isOpen={isOpen}
        onClose={onCloseModal()}
        onDiscard={onCloseModal({ cb: clearForm })}
      />
    </>
  );
};
