import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  TokenCustomClaimKeysEnum,
  ITokenClaims,
} from '@sitecore-ui/portal-singular';

export { TokenCustomClaimKeysEnum };

export const useAuth0TokenClaims = () => {
  const { getIdTokenClaims, user, getAccessTokenSilently } = useAuth0();
  const organizationId = user?.[TokenCustomClaimKeysEnum.ORG_ID];

  const [claims, setClaims] = React.useState<ITokenClaims>();
  React.useEffect(() => {
    const getUserMetadata = async () => {
      try {
        await getAccessTokenSilently({
          organization_id: organizationId,
          tenant_id: undefined,
        });
        const tokenClaims = await getIdTokenClaims();
        setClaims(tokenClaims as ITokenClaims);
      } catch (e) {
        console.error(e);
      }
    };

    getUserMetadata();
  }, [getIdTokenClaims, getAccessTokenSilently, organizationId]);
  return { claims };
};
