import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import {
  useEffect,
  createContext,
  useContext,
  useCallback,
  ReactNode,
} from 'react';
import { createAuthAxios } from './customAxios';
import {
  fetchLastSuccessfulLoginOrganizationId,
  resetLastSuccessfulLoginOrganizationId,
  storeLastSuccessfulLoginOrganizationId,
} from '../auth/auth0Storage';
import { useHistory, useLocation } from 'react-router-dom';
import { resolveUserAgent } from './user-agent';
import { TokenCustomClaimKeysEnum } from '@sitecore-ui/portal-singular';

const publicRoutes = [
  '/no-organization-access',
  '/no-valid-invitation',
  '/something-went-wrong',
];

export const AxiosContext = createContext(axios.create());

// Needs to be used inside Auth0Context
export const AuthenticatedAxiosProvider = ({
  children,
}: {
  children?: ReactNode;
}) => {
  const { getAccessTokenSilently, user, loginWithRedirect } = useAuth0();
  const { replace, location } = useHistory();
  const organizationId = user?.[TokenCustomClaimKeysEnum.ORG_ID];

  const { userAgent } = resolveUserAgent();

  // TODO: need to revisit when organization won't be in the token
  useEffect(() => {
    const isPublicRoute = publicRoutes.includes(location.pathname);
    if (!organizationId || isPublicRoute) return;

    storeLastSuccessfulLoginOrganizationId(organizationId);

    const searchParams = new URLSearchParams(location.search);
    searchParams.set('organization', organizationId);

    replace({
      pathname: location.pathname,
      search: `?${searchParams.toString()}`,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  const authAxios = createAuthAxios(
    axios.create({
      headers: {
        ...userAgent,
      },
    }),
  );
  usePortalLocationStorage();

  authAxios.interceptors.request.use(async (request) => {
    try {
      const token = await getAccessTokenSilently({
        organization_id: organizationId,
        tenant_id: undefined,
      });
      request &&
        request.headers &&
        (request.headers.Authorization = 'Bearer ' + token);

      return request;
    } catch (e) {
      resetLastSuccessfulLoginOrganizationId();
      await loginWithRedirect({
        prompt: 'login',
        tenant_id: undefined,
      });
    }
  });

  return (
    <AxiosContext.Provider value={authAxios}>{children}</AxiosContext.Provider>
  );
};

export const useAuthenticatedAxios = () => {
  const axiosInstance = useContext(AxiosContext);
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!axiosInstance)
    throw new Error(
      'useAuthenticatedAxios can not be used outside AuthenticatedAxiosProvider context',
    );
  return axiosInstance;
};

export function usePortalLocationStorage() {
  const history = useHistory();
  const { search, pathname } = useLocation();
  const params = new URLSearchParams(search);
  const organization = params.get('organization');
  const error = params.get('error');
  const code = params.get('code');
  const invitation = params.get('invitation');

  const { loginWithRedirect } = useAuth0();

  const switchOrg = useCallback(
    (organization: string | undefined | null) => {
      return loginWithRedirect({
        organization_id: organization || undefined,
        appState: {
          returnTo: `${window.location.pathname}${window.location.search}`,
        },
      }).then(
        () =>
          organization &&
          storeLastSuccessfulLoginOrganizationId(organization as string),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // @ts-ignore
  useEffect(() => {
    const cachedLocationSearch = fetchLastSuccessfulLoginOrganizationId();
    const isPublicRoute = publicRoutes.includes(pathname);
    // switch org while there is no code
    if (!code && !invitation && !error && !isPublicRoute) {
      switchOrg(organization || cachedLocationSearch);
    }

    // Reset everything, storage, location,
    // if (error) {
    //   const baseUrl = window.location.href.split('?')[0];
    //   window.history.pushState('name', '', baseUrl);
    //   resetLastSuccessfulLoginOrganizationId();

    //   // Redirect to identity's organization switcher
    //   Promise.resolve(
    //     loginWithRedirect({
    //       redirectUri: `${window.location.href}`,
    //     }),
    //   );
    // }

    // update location with organization while there is no code
    // if (cachedLocationSearch && !code) {
    //   if (invitation) return;
    //   return history.replace(
    //     `${history.location.pathname}?organization=${
    //       cachedLocationSearch || ''
    //     }`,
    //   );
    // }
    // eslint-disable-next-line
  }, [history, loginWithRedirect, invitation]);
}
