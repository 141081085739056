import { RedirectLoginOptions, useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';

export const InvitationAuth = () => {
  const { search } = useLocation();
  const { loginWithRedirect } = useAuth0();
  const params = new URLSearchParams(search);

  const invitation = params.get('invitation');
  const OrganizationId = params.get('OrganizationId');
  const organization = params.get('organization');
  const realOrgId = OrganizationId || organization || undefined;

  const obj: RedirectLoginOptions = {
    redirectUri: location.origin,
  };

  if (invitation) {
    Object.assign(obj, { invitation });
  }

  if (OrganizationId) {
    obj['OrganizationId'] = realOrgId;
  }

  if (organization) {
    obj['organization_id'] = realOrgId;
  }

  loginWithRedirect(obj);

  return null;
};
