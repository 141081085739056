const orgIdStorageKey = 'portal-ui:org_id';

/**

 * If user never used the app before, identity service determines what organization to use.
 *
 * @see https://community.auth0.com/t/how-do-i-use-organizations-with-the-react-sdk-and-maintain-user-sessions/69949#recipe-2-configuring-multiple-organizations-4
 */

export function fetchLastSuccessfulLoginOrganizationId(): string | undefined {
  return (
    sessionStorage.getItem(orgIdStorageKey) ||
    localStorage.getItem(orgIdStorageKey) ||
    undefined
  );
}

/**
 * Save the organization id when a user first logs in, later we pass it to the Auth0Provider when app re-loads.
 * Session storage (vs local storage) is used to allow user to change organization for a tab/window and keep the choice for that tab/window between page loads.
 */

export function storeLastSuccessfulLoginOrganizationId(
  organizationId?: string,
) {
  if (organizationId) {
    sessionStorage.setItem(orgIdStorageKey, organizationId);
    localStorage.setItem(orgIdStorageKey, organizationId);
  }
}

export function resetLastSuccessfulLoginOrganizationId() {
  sessionStorage.removeItem(orgIdStorageKey);
  localStorage.removeItem(orgIdStorageKey);
}
