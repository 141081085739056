/**
 * Inspired by @jsdevkr/react-multi-email https://github.com/jsdevkr/react-multi-email
 *
 * I converted it from a class component to a function component so we can use
 * ChakraUI hooks like useMultiStyleConfig() to make it use our theme. Very similar
 * to how the Select component works only this is completely custom.
 */
import {
  ForwardedRef,
  useState,
  useRef,
  useEffect,
  useCallback,
  SyntheticEvent,
  KeyboardEvent,
} from 'react';
import {
  chakra,
  Tag,
  TagCloseButton,
  TagLabel,
  useMultiStyleConfig,
  createStylesContext,
  forwardRef,
  ThemeTypings,
  Box,
} from '@chakra-ui/react';
import { css } from '@emotion/react';

const [StylesProvider] = createStylesContext('Component');
const inputTagCss = css`
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  cursor: text;
  height: auto;
  min-height: var(--input-height);
  overflow: hidden;

  &[data-disabled='true'] {
    pointer-events: none;
  }
  & > span[data-placeholder] {
    display: none;
    position: absolute;
    left: 1rem;
    top: 0.5rem;
  }
  &.empty > span[data-placeholder] {
    display: inline;
  }
  &.focused > span[data-placeholder] {
    display: none;
  }
  & > input {
    outline: none !important;
    border: 0 none !important;
    line-height: 1;
    vertical-align: baseline !important;
    padding: 0.125rem 0;
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    cursor: text;
  }
  & > input[disabled] {
    cursor: not-allowed;
  }
  & [data-tag] {
    line-height: 1;
    vertical-align: baseline;
    margin: 0.14285714em;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
  }
  & [data-tag] [data-tag-item] {
    max-width: 100%;
    overflow: hidden;
  }
  & [data-tag] [data-tag-handle] {
    margin-left: 0.833em;
    cursor: pointer;
  }
  & [data-tag] [data-tag-handle][disabled] {
    cursor: not-allowed;
  }

  & [data-input-wrapper] {
    display: inline-grid;
    vertical-align: top;
    align-items: center;
    position: relative;

    & input,
    &:after {
      width: auto;
      min-width: 1em;
      grid-area: 1 / 2;
      font: inherit;
      resize: none;
      background: none;
      appearance: none;
      border: none;
      box-shadow: none;
      outline: none;
    }

    &:after {
      content: attr(data-value) ' ';
      visibility: hidden;
      white-space: pre-wrap;
    }
  }

  &.focused {
    border-color: var(--colors-primary-500);
    box-shadow: 0 0 0 1px var(--colors-primary-500);
  }
`;

interface InputTagProps {
  name: string;
  tags: string[];
  invalidTags?: string[];
  onChange: (tags: string[]) => void;
  onBlur?: (e: any) => void;
  validateTag?: (tag: string) => boolean;
  isDisabled?: boolean;
  isInvalid?: boolean;
  placeholder?: string | React.ReactNode;
  tagColorScheme?:
    | ThemeTypings['colorSchemes']
    | (string & NonNullable<unknown>);
  dataTestId?: string;
}

export const InputTag = forwardRef(
  (
    {
      name,
      tags = [],
      invalidTags = [], // Erroneous tags defined by the consumer
      onChange,
      onBlur,
      validateTag = () => true, //An optional override for validating tags
      isDisabled,
      isInvalid,
      placeholder,
      tagColorScheme = 'neutral',
      dataTestId = 'inputTag',
    }: InputTagProps,
    forwardedRef?: ForwardedRef<HTMLInputElement>,
  ) => {
    const [focused, setFocused] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      if (!forwardedRef) return;

      if (typeof forwardedRef === 'function') forwardedRef(inputRef.current);
      else forwardedRef.current = inputRef.current;
    }, [forwardedRef]);

    const findTag = useCallback(
      (event: SyntheticEvent<HTMLInputElement>, isEnter?: boolean) => {
        const { value } = event.currentTarget;
        const validTags: string[] = [];
        let inputValue = '';
        const re = /[ ,;]/g;

        const addTags = (tag: string) => {
          if (!tags.includes(tag)) validTags.push(tag);
        };

        if (value !== '') {
          if (re.test(value)) {
            const splitData = value
              .split(re)
              .filter((n) => {
                return n !== '' && n !== undefined && n !== null;
              })
              .map((e) => e.replace(/>|</g, '')); //added this so that users can paste from lists (csv, email lists)

            const arr = Array.from(new Set(splitData));

            arr.forEach((tag) => {
              if (validateTag(tag)) {
                addTags(tag);
              } else if (arr.length === 1) {
                inputValue = tag;
              }
            });
            onBlur?.(event);
            setFocused(false);
          } else if (isEnter && validateTag(value)) addTags(value);
          else inputValue = value;
        }

        setInputValue(inputValue);
        onChange?.([...tags, ...validTags]);
      },
      [onBlur, onChange, tags, validateTag],
    );

    const onChangeInputValue = useCallback(
      (event: SyntheticEvent<HTMLInputElement>) => findTag(event),
      [findTag],
    );

    const removeTag = useCallback(
      (index: number) => {
        const updatedTags = tags.filter((_, i) => i !== index);
        onChange?.(updatedTags);
      },
      [tags, onChange],
    );

    const handleOnKeydown = useCallback(
      (event: KeyboardEvent<HTMLInputElement>) => {
        switch (event.code) {
          case 'Enter':
            event.preventDefault();
            break;
          case 'Backspace':
            if (!event.currentTarget.value) removeTag(tags.length - 1);
            break;
          default:
        }
      },
      [tags, removeTag],
    );

    const handleOnKeyup = useCallback(
      (event: KeyboardEvent<HTMLInputElement>) => {
        switch (event.code) {
          case 'Tab':
          case 'Enter':
            findTag(event, true);
            break;
          default:
        }
      },
      [findTag],
    );

    const handleOnChange = useCallback(
      (event: SyntheticEvent<HTMLInputElement>) => {
        onChangeInputValue(event);
      },
      [onChangeInputValue],
    );

    const handleOnBlur = useCallback(
      (event: SyntheticEvent<HTMLInputElement>) => {
        setFocused(false);
        findTag(event, true);
        onBlur?.(event);
      },
      [setFocused, findTag, onBlur],
    );

    const handleOnFocus = useCallback(() => setFocused(true), [setFocused]);

    //This will provide all of the theme styles/classes needed to style the container div.
    const inputStyles = useMultiStyleConfig('Input', { size: 'md' });

    const isInvalidTag = (tag: string) => invalidTags.includes(tag);
    const hasInvalidTag = tags.some?.((tag) => isInvalidTag(tag));

    return (
      <StylesProvider value={inputStyles}>
        <Box
          sx={{ ...inputStyles.field }}
          data-testid={dataTestId}
          css={inputTagCss}
          className={`${focused ? 'focused' : ''} ${
            inputValue === '' && tags.length === 0 ? 'empty' : ''
          }`}
          onClick={() => {
            if (inputRef?.current) {
              inputRef.current.focus();
            }
          }}
          data-focus={Boolean(focused) || undefined}
          data-disabled={Boolean(isDisabled) || undefined}
          data-invalid={Boolean(isInvalid || hasInvalidTag) || undefined}
        >
          {placeholder ? (
            <Box
              as='span'
              data-testid={`${dataTestId}_placeholder`}
              color='chakra-placeholder-color'
              data-placeholder
            >
              {placeholder}
            </Box>
          ) : null}
          {tags.map?.((tag: string, index: number) => (
            <Tag
              colorScheme={isInvalidTag(tag) ? 'danger' : tagColorScheme}
              key={index}
              data-testid={`${dataTestId}_tag${index}`}
              data-tag
              data-tag-invalid={isInvalidTag(tag) || undefined}
            >
              <TagLabel data-tag-item>{tag}</TagLabel>
              <TagCloseButton
                isDisabled={isDisabled}
                onClick={() => removeTag(index)}
                data-tag-handle
                aria-label={`Remove ${tag}`}
              />
            </Tag>
          ))}
          <Box data-input-wrapper data-value={inputValue}>
            <chakra.input
              data-testid={`${dataTestId}_input`}
              ref={inputRef}
              type='text'
              disabled={isDisabled}
              value={inputValue}
              //@ts-ignore
              inputId={name}
              name={name}
              key={name}
              onFocus={handleOnFocus}
              onBlur={handleOnBlur}
              onChange={handleOnChange}
              onKeyDown={handleOnKeydown}
              onKeyUp={handleOnKeyup}
            />
          </Box>
        </Box>
      </StylesProvider>
    );
  },
);
