import { useAuthMutation } from 'features/common/apiUtils/queryHelper';
import { edgePlatformAdminApi } from '../api/endpoints';

type createHostnamePayload = {
  hostname: string;
  defaultContextId?: string;
  defaultSiteId?: string;
};

export const useCreateHostname = () => {
  const { create_hostname } = edgePlatformAdminApi;
  const url = create_hostname.url();

  return useAuthMutation(
    (axiosInstance) => (payload: createHostnamePayload) =>
      axiosInstance?.post(url, payload),
    {
      onSuccess: () => void 0,
      onError: () => void 0,
    },
    create_hostname.scope,
  );
};
