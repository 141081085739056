import * as React from 'react';
import { LoadingScreenProps } from './model';
import { Box, CircularProgress, Text } from '@chakra-ui/react';

const LoadingScreen = ({
  message,
  withOverlay = false,
}: LoadingScreenProps) => {
  return (
    <Box
      height='full'
      width='full'
      alignItems='center'
      bgColor={withOverlay ? 'whiteAlpha.900' : 'white'}
      display='flex'
      justifyContent='center'
    >
      <Box textAlign='center'>
        <CircularProgress
          capIsRound
          color='primary'
          trackColor='neutral-color.200'
          isIndeterminate
        />
        {message && <Text mt={6}>{message}</Text>}
      </Box>
    </Box>
  );
};

export { LoadingScreen };
