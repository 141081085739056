import {
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  InputProps,
  Tooltip,
  FormErrorIcon,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react';
import { mdiTrashCanOutline } from '@mdi/js';

interface ClaimRow {
  nameProps: InputProps;
  nameErrors: string | undefined;
  valueProps: InputProps;
  valueErrors: string | undefined;
}

const ClaimRow = ({
  nameProps,
  valueProps,
  onDeleteRowHandler,
  isDeleteDisabled,
  nameErrors,
  valueErrors,
  helperText = undefined,
}: {
  nameProps: InputProps;
  valueProps: InputProps;
  onDeleteRowHandler: () => void;
  isDeleteDisabled: boolean;
  nameErrors: string | undefined;
  valueErrors: string | undefined;
  helperText?: string | undefined;
}) => {
  const hasNameErrors = Boolean(nameErrors);
  const hasValueErrors = Boolean(valueErrors);

  return (
    <HStack w='full' alignItems='start'>
      <FormControl isRequired={nameProps.isRequired} isInvalid={hasNameErrors}>
        <FormLabel>Name</FormLabel>
        <Input autoComplete='off' {...nameProps} />
        {hasNameErrors && (
          <FormErrorMessage>
            <FormErrorIcon />
            {nameErrors}
          </FormErrorMessage>
        )}
      </FormControl>
      <FormControl
        isRequired={valueProps.isRequired}
        isInvalid={hasValueErrors}
      >
        <FormLabel>Value</FormLabel>
        <Input autoComplete='off' {...valueProps} />
        {hasValueErrors ? (
          <FormErrorMessage>
            <FormErrorIcon />
            {valueErrors}
          </FormErrorMessage>
        ) : (
          <FormHelperText visibility={helperText ? 'visible' : 'hidden'}>
            {helperText ?? 'placeholder text'}
          </FormHelperText>
        )}
      </FormControl>
      <Tooltip label='Remove'>
        <IconButton
          data-testid='remove-claim-row'
          isDisabled={isDeleteDisabled}
          icon={
            <Icon>
              <path d={mdiTrashCanOutline} />
            </Icon>
          }
          variant='ghostColorOnHover'
          colorScheme='danger'
          aria-label={'Remove'}
          onClick={onDeleteRowHandler}
          mt={7}
        />
      </Tooltip>
    </HStack>
  );
};

export default ClaimRow;
