import { IResponse } from '@sitecore-ui/portal-singular';
import { useAuthQuery } from 'features/common/apiUtils/queryHelper';
import { edgePlatformAdminApi } from '../api/endpoints';
import { AxiosResponse } from 'axios';
import { Hostname } from '../types';
import { UseQueryOptions } from 'react-query';

const shouldEnablePolling = (
  hostnamesResponse?: AxiosResponse<IResponse<Hostname>>,
) => {
  const hostnames = hostnamesResponse?.data;
  const isHostnameInactive =
    Array.isArray(hostnames) &&
    hostnames.some(({ status }: { status: string }) => status !== 'active');

  return isHostnameInactive;
};

export const useGetHostnames = (
  queryOptions?:
    | Omit<
        UseQueryOptions<
          AxiosResponse<IResponse<Hostname>>,
          unknown,
          AxiosResponse<IResponse<Hostname>>,
          string[]
        >,
        'queryKey' | 'queryFn'
      >
    | undefined,
) => {
  const { get_hostnames } = edgePlatformAdminApi;
  const url = get_hostnames.url();

  return useAuthQuery(
    ['hostnames/list'],
    async (axiosInstance) => {
      return axiosInstance?.get<IResponse<Hostname>>(url);
    },
    {
      refetchInterval: (response) =>
        shouldEnablePolling(response) ? 10000 : false,
      ...queryOptions,
    },
    get_hostnames.scope,
  );
};
