import { t } from '@transifex/native';
import { BadgeByDates } from './shared/BadgeByDates';
import { history } from '../../../common/history/history';
import { openDrawer } from '../../../main/context/drawer/actionCreators';
import { DrawerAction } from 'features/main/context/drawer/model';
import { Text } from '@chakra-ui/react';
import { Invitation } from 'gql/graphql';
import { adminPath, invitesPath } from 'features/admin/components/admin-paths';

interface IInvitationDatatableData {
  expires: string;
  email: string;
  role: string;
  id: string;
}

export const getInvitationStatus = (status: boolean | null) =>
  status === null ? 'NA' : status ? 'Expired' : 'Pending';

export const getTableOptions = ({
  page,
  drawerDispatch,
  resendInvite,
}: {
  page: number;
  drawerDispatch?: ((arg0: DrawerAction) => void) | undefined;
  resendInvite?: (invitation: Invitation) => Promise<void>;
}) => ({
  rowActions: (inviteRow: { original: Invitation }) => {
    const inviteData = inviteRow.original;
    const inviteActions = inviteData.appearance.actions;

    const viewInvitationAction = inviteActions.find(
      ({ name }) => name === 'view',
    );
    const resendInvitationAction = inviteActions.find(
      ({ name }) => name === 'resend',
    );
    const removeInvitationAction = inviteActions.find(
      ({ name }) => name === 'delete',
    );
    return [
      {
        id: '0',
        label: viewInvitationAction?.displayName,
        onClick: () => {
          history.push(
            `${adminPath}${invitesPath}/page/${page}/${viewInvitationAction?.link?.to}${history.location.search}`,
          );
          if (drawerDispatch) {
            drawerDispatch(openDrawer('invite-details-drawer'));
          }
        },
        'data-behavior-analytics-id': 'view invitation',
      },
      {
        id: '1',
        label: resendInvitationAction?.displayName,
        onClick: () => {
          resendInvite?.(inviteData);
        },
        'data-behavior-analytics-id': 'resend invitation - submenu',
      },
      {
        id: '2',
        label: removeInvitationAction?.displayName,
        onClick: () => {
          history.push(
            `${adminPath}${invitesPath}/page/${page}/${removeInvitationAction?.link?.to}${history.location.search}`,
          );
        },
        'data-behavior-analytics-id': 'delete invitation - submenu',
      },
    ];
  },
  columns: [
    {
      Header: t('Email'),
      width: 232,
      disableSortBy: true,
      accessor: ({ inviteeEmail }: any) => (
        <Text size='sm'>{inviteeEmail}</Text>
      ),
    },

    {
      Header: t('Access'),
      accessor: ({ organizationRoles }: any) => (
        <Text size='sm'>{organizationRoles?.[0]?.name || ''}</Text>
      ),
      disableSortBy: true,
    },

    {
      Header: t('Status'),
      id: 'status',
      accessor: ({ expires }: IInvitationDatatableData) => (
        <BadgeByDates expirationDate={expires} />
      ),
      disableSortBy: true,
    },
  ],
});
