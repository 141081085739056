/**
 * Utility method to create a reducer from a map,
 * with initial state
 *
 * @param initialState
 * @param reducerMap
 * @returns {Function}
 */

function createReducer<State, Map>(initialState: State, reducerMap: Map) {
  return (state = initialState, action: { type: string; payload?: any }) => {
    // @ts-ignore
    const reducer = reducerMap[action.type];
    return reducer ? reducer(state, action.payload) : state;
  };
}

export default createReducer;
