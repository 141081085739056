import { t } from '@transifex/native';
import { useAppAccess } from './utils';
import {
  ITenant,
  TokenCustomClaimKeysEnum,
  getProduct,
} from '@sitecore-ui/portal-singular';
import { APP_ORGANIZATION } from './resource';
import { useMainState } from '../../../main/context';
import MediaCard from '../MediaCard/MediaCard';
import AppBoxEmptyState from './AppBoxEmptyState';
import { env } from 'features/common/config/envConfig';
import { VStack } from '@chakra-ui/react';

function AppAccess() {
  const state = useMainState();
  const { userRoles, claims } = useAppAccess();

  const tenants = state.env?.data.tenants;
  const organizationId = claims && claims[TokenCustomClaimKeysEnum.ORG_ID];
  if (state.env?.ORG_ADMIN_OR_OWNER) {
    return (
      <>
        {state.env?.WITH_APP_ACCESS ? (
          <AppBoxEmptyState
            dataTestId='no-appAccess--admin'
            text={t('You have access to all available apps.')}
          />
        ) : (
          <>
            <AppBoxEmptyState
              dataTestId='no-appAccess--admin'
              text={t('No apps available.')}
            />
          </>
        )}
      </>
    );
  }

  if (state.env?.WITH_NO_TENANTS && state.env?.ORG_USER) {
    return (
      <AppBoxEmptyState
        dataTestId='no-appAccess--user'
        text={t('No apps available. Contact your organization administrator.')}
      />
    );
  }

  if (!tenants || !userRoles || !organizationId) return null;

  const rolesWithTenantInfo = userRoles
    .filter((r) => r.scope !== APP_ORGANIZATION && r.tenantId)
    .map((role) => {
      const tenant = tenants.find((t: ITenant) => t.id === role.tenantId);
      return {
        ...role,
        tenantDetails: tenant,
      };
    });

  return (
    <VStack spacing={4}>
      {rolesWithTenantInfo.map((row, idx) => {
        if (!row.tenantDetails) return;

        const { viewModel, isOnboarded, isValid } = getProduct(
          row.tenantDetails,
          env,
        );

        if (!isOnboarded || !isValid) return null;

        return (
          <MediaCard
            key={idx}
            size={'option'}
            title={viewModel.title}
            label={viewModel.subTitle}
            imageUrl={viewModel.icon.iconUrl}
            iconColor='transparent'
          />
        );
      })}
    </VStack>
  );
}

export default AppAccess;
