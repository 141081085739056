import { Alert, AlertDescription, AlertIcon } from '@chakra-ui/react';

export const UserAppAccessLimitAlert = () => (
  <Alert status='warning' my={6}>
    <AlertIcon />
    <AlertDescription aria-label='Alert'>
      App limit reached. To add another app, you must remove one or change this
      team member's Organization role to Admin.
    </AlertDescription>
  </Alert>
);
