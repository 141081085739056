import {
  Environment,
  ITokenClaims,
  TokenCustomClaimKeysEnum,
} from '@sitecore-ui/portal-singular';
import { env } from 'features/common/config/envConfig';
import { useAuth0TokenClaims } from 'features/common/auth/claims';
import { useEffect } from 'react';
import {
  GetCurrentUserQuery,
  useGetCurrentUserQuery,
  useGetUserOrgDepsQuery,
} from 'gql/graphql';

const GainsightTagPerEnv = {
  dev: '',
  qa: '',
  staging: 'AP-BULS2UKDWQZP-2-2',
  'pre-production': '',
  production: 'AP-BULS2UKDWQZP-2',
};

const constructGainsightScript = (tag: string) =>
  tag
    ? `
(function(n,t,a,e,co){var i="aptrinsic";n[i]=n[i]||function(){
    (n[i].q=n[i].q||[]).push(arguments)},n[i].p=e;n[i].c=co;
  var r=t.createElement("script");r.async=!0,r.src=a+"?a="+e;
  var c=t.getElementsByTagName("script")[0];c.parentNode.insertBefore(r,c)
})(window,document,"https://web-sdk-eu.aptrinsic.com/api/aptrinsic.js","${tag}");
`
    : '';

/**
 * Get the Gainsight script based on the environment.
 * @returns The Gainsight script to be injected.
 */
export const getGainsightScript = () => {
  const tag = GainsightTagPerEnv[env as Environment];

  return constructGainsightScript(tag);
};

export const TokenCustomClaimOrgAccountIdKey =
  'https://auth.sitecorecloud.io/claims/org_account_id';

interface ITokenClaimsWithAccountOrgId extends ITokenClaims {
  [TokenCustomClaimOrgAccountIdKey]: string;
}

/**
 * Get the user and account fields for Gainsight integration.
 * @param userData - The user data received from the API.
 * @param claims - The token claims containing additional user information.
 * @returns The user fields and account fields for Gainsight.
 */
const getGainsightFields = ({
  userData,
  claims,
}: {
  userData?: GetCurrentUserQuery;
  claims?: ITokenClaimsWithAccountOrgId;
}) => {
  const { email, lastLogin = '', created = '' } = userData?.user || {};
  const org_account_id = claims && claims[TokenCustomClaimOrgAccountIdKey];

  const userFields = {
    id: email,
    email,
    lastLogin: new Date(lastLogin).valueOf(),
    signUpDate: new Date(created).valueOf(),
  };
  const accountFields = {
    id: org_account_id, // Salesforce Account ID
    sfdcId: org_account_id,
  };

  return { userFields, accountFields };
};

const setGlobalContext = (claims: ITokenClaims) => {
  const orgRoles = claims[TokenCustomClaimKeysEnum.ROLES]
    .filter((role) => role.includes('Organization'))
    .map((orgRole) => orgRole.split('\\')[1]);

  (window as any).aptrinsic('set', 'globalContext', {
    'Organization DisplayName':
      claims[TokenCustomClaimKeysEnum.ORG_DISPLAY_NAME],
    OrganizationID: claims[TokenCustomClaimKeysEnum.ORG_ID],
    'Organization Role': orgRoles.join(', '),
    'Organization Type':
      claims['https://auth.sitecorecloud.io/claims/org_type'] || '',
  });
};

const identifyCall = (claims: ITokenClaims, userData: GetCurrentUserQuery) => {
  const { userFields, accountFields } = getGainsightFields({
    userData,
    claims: claims as ITokenClaimsWithAccountOrgId,
  });

  (window as any).aptrinsic('identify', userFields, accountFields);
};

/**
 * Set the global context for Gainsight.
 * Initialize Gainsight with the user and account fields.
 */
export const useGainsight = () => {
  const { claims } = useAuth0TokenClaims();

  const { data: userData } = useGetCurrentUserQuery();

  const { data: orgs } = useGetUserOrgDepsQuery();
  const currentUserOrg = orgs?.organization ?? undefined;

  const { aptrinsic } = window as any;

  useEffect(() => {
    if (!userData || !currentUserOrg || !claims || !aptrinsic) return;

    setGlobalContext(claims);
    identifyCall(claims, userData);
  }, [aptrinsic, claims, currentUserOrg, userData]);
};
