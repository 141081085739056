import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Heading,
  ModalBody,
  Flex,
  ModalFooter,
  ButtonGroup,
  Button,
  FormControl,
  FormLabel,
  useDisclosure,
  Input,
  Skeleton,
} from '@chakra-ui/react';
import { FormikErrors, FormikTouched } from 'formik';
import { useCallback, useRef } from 'react';
import { useHistory, useParams } from 'react-router';
import { customHostnamesRoute } from './routes';
import { useHostnameEditForm } from './hooks/useHostnameEditForm';
import { CreateValues } from './hooks/useHostnameCreationForm';
import FieldError from 'features/common/components/Form/FieldError';

export const HostnameUpdateModal = () => {
  const { hostname } = useParams<{ hostname: string }>();
  const history = useHistory();
  const { onClose } = useDisclosure();
  const onCloseDrawer = useCallback(() => {
    history.push({
      pathname: customHostnamesRoute,
      search: history.location.search,
    });
    onClose();
  }, [history, onClose]);

  const {
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,
    handleBlur,
    isValid,
    isLoading,
    isFetching,
    dirty,
  } = useHostnameEditForm();
  const updateTouched = touched as FormikTouched<Partial<CreateValues>>;
  const updateErrors = errors as FormikErrors<Partial<CreateValues>>;
  const initialFocusRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <Modal
        isOpen={true}
        onClose={onCloseDrawer}
        data-onCloseDrawer='hostname-update-modal'
        size='lg'
        autoFocus={false}
      >
        <ModalOverlay />
        <ModalContent data-testid='hostname-update-modal'>
          <ModalHeader>
            <Heading
              as='h4'
              size='md'
              data-testid='hostname-update-modal-header'
            >
              Update custom hostname
            </Heading>
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>
            <form
              onSubmit={handleSubmit}
              data-testid='hostname-update-form'
              id='hostname-update-form'
            >
              <Flex direction='column' gap={6}>
                <FormControl>
                  <FormLabel data-testid='label-hostname'>Hostname</FormLabel>
                  <Input
                    ref={initialFocusRef}
                    name='hostname'
                    value={hostname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    data-testid='input-hostname'
                    disabled
                  />
                </FormControl>
                <FormControl
                  isInvalid={
                    !!updateErrors?.defaultContextId &&
                    updateTouched.defaultContextId
                  }
                >
                  <FormLabel data-testid='label-defaultContextId'>
                    Default Context ID
                  </FormLabel>
                  <Skeleton isLoaded={!isFetching}>
                    <Input
                      name='defaultContextId'
                      value={values.defaultContextId}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      data-testid='input-defaultContextId'
                    />
                  </Skeleton>
                  <FieldError
                    touched={updateTouched.defaultContextId}
                    error={updateErrors.defaultContextId}
                    testId='defaultContextId-error'
                  />
                </FormControl>
                <FormControl>
                  <FormLabel data-testid='label-defaultContextId'>
                    Default site ID
                  </FormLabel>
                  <Skeleton isLoaded={!isFetching}>
                    <Input
                      name='defaultSiteId'
                      value={values.defaultSiteId}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      data-testid='input-defaultSiteId'
                    />
                  </Skeleton>
                </FormControl>
              </Flex>
            </form>
          </ModalBody>
          <ModalFooter justifyContent='flex-end' alignItems='flex-end'>
            <ButtonGroup>
              <Button
                type='button'
                variant='ghost'
                data-testid='hostname-update-cancelButton'
                onClick={onCloseDrawer}
                isDisabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                type='submit'
                variant='solid'
                data-testid='hostname-update-submitButton'
                isLoading={isLoading}
                isDisabled={!isValid || !dirty}
                form='hostname-update-form'
              >
                Update
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
