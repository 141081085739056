import { Link } from '@chakra-ui/react';
import { GenericErrorTemplate } from 'features/common/components/GenericErrorTemplate';

export const NoAccessPage = () => (
  <GenericErrorTemplate
    title='Access denied'
    subtext='Error 403'
    text="You don't have permission to access this page"
    variant='access-denied'
    shortcuts={
      <Link
        onClick={() => (window.location.href = window.location.origin)}
        mb={4}
      >
        Go to Homepage
      </Link>
    }
  />
);
