import {
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
} from '@chakra-ui/react';
import { T } from '@transifex/react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ConnectionButtonTypes, ConnectionMenuItem } from './types';

const AddConnectionButton = ({
  label,
  menuItems,
  ...rest
}: ConnectionButtonTypes): JSX.Element => {
  const {
    push,
    location: { search },
  } = useHistory();
  const { url } = useRouteMatch();

  return (
    <Menu>
      <MenuButton
        as={Button}
        {...rest}
        variant='solid'
        data-testid='add-sso-connection-button'
        data-behavior-analytics-id='add sso connection'
      >
        {label}
      </MenuButton>
      <MenuList>
        {menuItems.map(({ label, value }: ConnectionMenuItem) => (
          <MenuItem
            key={label}
            data-testid={`${label}-menu-item`}
            onClick={() => push(`${url}/${value}/create${search}`)}
          >
            <Text data-testid={label}>
              <T _str={label} />
            </Text>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default AddConnectionButton;
