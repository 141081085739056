/* istanbul ignore file */
import {
  Box,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useDrawerState, useMainStateDispatch } from 'features/main/context';
import { T } from '@transifex/react';
import { MediaObjectLink } from 'features/common/components/MediaObjectLink';
import { closeDrawer, openDrawer } from '../context/drawer/actionCreators';
import { useGetHelpContentFromSchema } from 'features/domains/portal/content/helpLinks/useGetHelpContentFromSchema';
import { useSupportCase } from 'features/snow/hooks/useSupportCase';

export const HelpDrawerNoOrg = () => {
  const [, dispatch] = useMainStateDispatch();
  const drawerState = useDrawerState();
  const onCloseDrawer = () => dispatch(closeDrawer());

  return (
    <>
      <Drawer
        onClose={onCloseDrawer}
        isOpen={drawerState.drawerVisible}
        size='md'
      >
        <DrawerOverlay />
        <DrawerContent
          data-behavior-analytics-feature='Help drawer'
          data-testid='help-drawer'
        >
          <DrawerCloseButton
            data-testid='help-panel-close'
            _focus={{ border: 'none' }}
          />
          <DrawerHeader display='flex' alignItems='center'>
            <T _str='Help' />
          </DrawerHeader>
          <DrawerBody>
            {drawerState.helpDrawerVisible && <DrawerBodyContent />}
          </DrawerBody>
          <DrawerFooter pb='6' justifyContent='center'>
            <Text variant='subtle'>
              <Link
                data-testid='help-panel-legal'
                variant='ghost'
                href='https://www.sitecore.com/trust'
                target='_blank'
              >
                Legal
              </Link>
              &emsp;&middot;&emsp;
              <Link
                data-testid='help-panel-privacy-policy'
                variant='ghost'
                href=' https://www.sitecore.com/trust/privacy-policy'
                target='_blank'
              >
                Privacy policy
              </Link>
            </Text>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

const DrawerBodyContent = () => {
  const { helpMainQuickActions, helpSecondaryQuickActions } =
    useGetHelpContentFromSchema();
  const [, dispatch] = useMainStateDispatch();

  const onOpenDrawer = () => dispatch(openDrawer('create-support-case-drawer'));
  useSupportCase({
    componentName: 'create-help-case-drawer',
    buttonSelector: '[data-support-case="create-help-case-drawer"]',
  });

  return (
    <>
      <VStack>
        {helpMainQuickActions.map((quickAction) => (
          <MediaObjectLink
            {...quickAction}
            onClick={quickAction.action}
            title={quickAction.title}
            link={quickAction.link}
            key={quickAction.title}
            data-testid={`help-item-${quickAction.title}`}
            data-behavior-analytics-id={quickAction['analytics-id']}
            iconPath={quickAction.iconPath}
            iconColor={quickAction.iconColor}
          />
        ))}
      </VStack>
      <Box w='full' py='4'>
        <Divider />
      </Box>
      <VStack>
        {helpSecondaryQuickActions.map((quickAction) => (
          <MediaObjectLink
            {...quickAction}
            onClick={
              quickAction.title === 'Create support case'
                ? onOpenDrawer
                : quickAction.action
            }
            title={quickAction.title}
            link={quickAction.link}
            key={quickAction.title}
            data-testid={`help-item-${quickAction.title}`}
            data-behavior-analytics-id={quickAction['analytics-id']}
            iconPath={quickAction.iconPath}
            iconColor={quickAction.iconColor}
            {...(quickAction.title === 'Create support case'
              ? { 'data-support-case': 'create-help-case-drawer' }
              : {})}
          />
        ))}
      </VStack>
    </>
  );
};
