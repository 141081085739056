import { Flex, Text, Link, Tag } from '@chakra-ui/react';
import { Presentation } from 'features/marketplace/shared/types';
import { ReactNode } from 'react';

type SidebarProps = Pick<
  Presentation,
  | 'websiteUrl'
  | 'supportUrl'
  | 'privacyPolicyUrl'
  | 'termsOfServiceUrl'
  | 'categories'
> & {
  developerName?: string;
  worksWith?: string;
};

export const Sidebar = ({
  websiteUrl,
  supportUrl,
  privacyPolicyUrl,
  termsOfServiceUrl,
  categories,
  developerName = 'Unknown Developer',
  worksWith = 'Unknown',
}: SidebarProps) => (
  <Flex data-testid='app-details-body-sidebar' flexDir='column' gap={7}>
    <Section
      title='Developer'
      dataTestId='app-details-body-sidebar-developer-container'
    >
      <Text fontWeight='500' color='neutral-fg' size='small'>
        {developerName}
      </Text>
    </Section>
    <Section
      title='Works with'
      dataTestId='app-details-body-sidebar-works-container'
    >
      <Text fontWeight='500' color='neutral-fg' size='small'>
        {worksWith}
      </Text>
    </Section>
    <Section
      title='Resources'
      dataTestId='app-details-body-sidebar-resources-container'
    >
      <Link
        isExternal
        href={websiteUrl ?? '#'}
        data-testid='app-details-resources-websiteUrl-link'
      >
        Website
      </Link>
      <Link
        isExternal
        href={supportUrl ?? '#'}
        data-testid='app-details-resources-supportUrl-link'
      >
        Support
      </Link>
      <Link
        isExternal
        href={privacyPolicyUrl ?? '#'}
        data-testid='app-details-resources-privacyPolicyUrl-link'
      >
        Privacy Policy
      </Link>
      <Link
        isExternal
        href={termsOfServiceUrl ?? '#'}
        data-testid='app-details-resources-termsOfServiceUrl-link'
      >
        Terms of Service
      </Link>
    </Section>
    <Section
      title='Categories'
      dataTestId='app-details-body-sidebar-categories-container'
    >
      <Flex gap={2} wrap='wrap'>
        {categories?.map((category) => (
          <Tag key={category} size='md'>
            {category}
          </Tag>
        ))}
      </Flex>
    </Section>
  </Flex>
);

type SectionProps = {
  title: string;
  children: ReactNode;
  dataTestId?: string;
};

const Section = ({ title, children, dataTestId }: SectionProps) => (
  <Flex flexDir='column' gap={2} data-testid={dataTestId}>
    <Text fontWeight='500' color='neutral-fg' size='small'>
      {title}
    </Text>
    {children}
  </Flex>
);
