/* istanbul ignore file */
import 'core-js/full/promise/with-resolvers.js';

// @ts-ignore
if (typeof Promise.withResolvers === 'undefined') {
  if (window)
    // @ts-ignore
    window.Promise.withResolvers = function () {
      let resolve, reject;
      const promise = new Promise((res, rej) => {
        resolve = res;
        reject = rej;
      });
      return { promise, resolve, reject };
    };
}
