import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  Text,
  AlertDialogBody,
  AlertDialogFooter,
  ButtonGroup,
  Button,
  ModalCloseButton,
  useToast,
  SkeletonText,
} from '@chakra-ui/react';
import { t } from '@transifex/native';
import { history } from 'features/common/history/history';
import { useRef } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import { useDeleteConnection, useGetConnection } from '../api';
import { ssoRoute } from '../sso-routes';
import withConnectionIdGuard from '../with-connection-id-guard';
import { providerPerStrategy } from '../connections-table/connections-table-helpers';

const DeleteSSOConnectionModal = () => {
  const lessDestructiveRef = useRef(null);
  const { id } = useParams<{ id: string }>();
  const {
    data,
    isFetching: isFetchingConnection,
    isFetched,
  } = useGetConnection(id);
  const {
    mutate: deleteConnectionMutation,
    isLoading: isLoadingDeleteRequest,
  } = useDeleteConnection();
  const toast = useToast();
  const queryClient = useQueryClient();

  const { state, emailDomains, strategy } = data?.data || {};
  const [firstDomain, ...remainingDomains] = emailDomains ?? [];

  const onClose = () =>
    history.replace(`${ssoRoute}${history.location.search}`);

  const onDelete = () => {
    return deleteConnectionMutation(
      { id },
      {
        onSuccess: () => {
          queryClient.invalidateQueries('connections/list');
          onClose();
          toast({
            description: t('SSO connection deleted'),
            status: 'success',
          });
        },
      },
    );
  };

  return (
    <AlertDialog
      isOpen={true}
      onClose={onClose}
      leastDestructiveRef={lessDestructiveRef}
      size='xl'
      autoFocus={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent data-testid='delete-sso-connection-modal' gap={2}>
          <AlertDialogHeader>
            {/*//@ts-ignore*/}
            <SkeletonText isLoaded={isFetched} noOfLines={1} paddingRight={10}>
              <Text data-testid='deleteConnectionModal-title'>
                Delete {state === 'Active' ? 'enabled' : ''} SSO connection?
              </Text>
            </SkeletonText>
          </AlertDialogHeader>
          <ModalCloseButton />
          <AlertDialogBody>
            {/*//@ts-ignore*/}
            <SkeletonText isLoaded={isFetched} noOfLines={2}>
              {state === 'Active' ? (
                <Text>
                  If you delete this connection, team members can’t log in using
                  their accounts. To log in again, they’ll need to create a new
                  password by clicking Forgot password on the login screen. If
                  you have pending invitations for this connection, you’ll need
                  to send them again.
                </Text>
              ) : (
                <Text>
                  Delete{' '}
                  <strong>
                    {strategy
                      ? providerPerStrategy[
                          strategy as keyof typeof providerPerStrategy
                        ]
                      : ''}
                  </strong>{' '}
                  connection for{' '}
                  <strong>
                    {firstDomain}
                    {remainingDomains.length
                      ? ` and ${remainingDomains.length} more`
                      : ''}
                  </strong>
                  ? This action cannot be undone.
                </Text>
              )}
            </SkeletonText>
          </AlertDialogBody>
          <AlertDialogFooter>
            <ButtonGroup>
              <Button
                variant='ghost'
                data-testid='deleteConnectionModal-cancelButton'
                onClick={onClose}
                isDisabled={isFetchingConnection || isLoadingDeleteRequest}
              >
                Cancel
              </Button>
              <Button
                colorScheme='danger'
                variant='solid'
                data-testid='deleteConnectionModal-deleteButton'
                onClick={onDelete}
                isDisabled={isFetchingConnection}
                isLoading={isLoadingDeleteRequest}
              >
                Delete
              </Button>
            </ButtonGroup>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default withConnectionIdGuard(DeleteSSOConnectionModal);
