import { Flex, Skeleton, SkeletonCircle, SkeletonText } from '@chakra-ui/react';

export const HeaderSkeleton = () => (
  <Flex
    data-testid='app-details-header-skeleton'
    justifyContent='space-between'
  >
    <Flex gap={3} data-testid='app-details-header-icon-container-skeleton'>
      <Skeleton height='80px' width='80px' />
      <Flex
        flexDir='column'
        gap={1}
        data-testid='app-details-header-app-name-container-skeleton'
        justifyContent='center'
      >
        <Flex gap={1} alignItems='center'>
          <Skeleton height='30px' width='200px' />
          <SkeletonCircle size='30px' />
        </Flex>
        <SkeletonText noOfLines={1} width='150px' />
      </Flex>
    </Flex>
    <Skeleton height='40px' width='100px' />
  </Flex>
);
