/* eslint-disable no-console */
interface SupportCaseDrawer {
  componentName: string;
  buttonSelector: string;
  buttonRef?: React.MutableRefObject<any>;
  drawerState?: {
    selectedApp: string;
    title: string;
    description: string;
  };
  parentVisible?: boolean;
  rerun?: boolean;
}

import { useAuth0 } from '@auth0/auth0-react';
import { fetchLastSuccessfulLoginOrganizationId } from '@sitecore-ui/portal-singular';
import { resolveUserAgent } from 'features/common/apiUtils/user-agent';
import { env } from 'features/common/config/envConfig';
import { useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';

function useSupportCase(props: SupportCaseDrawer) {
  const { componentName, buttonSelector, drawerState } = props;
  const { getAccessTokenSilently, user } = useAuth0();
  const isSet = useRef(false);

  const { search } = useLocation() || {};
  const params = new URLSearchParams(search);
  const organization = params.get('organization');
  const organizationId =
    organization || fetchLastSuccessfulLoginOrganizationId() || '';
  const { userAgent } = resolveUserAgent();

  const runSupportCase = useCallback(() => {
    const component = document.querySelector(componentName);
    const button = document.querySelector(buttonSelector);

    if (!component || !button) {
      return;
    }

    // @ts-ignore
    component.componentName = componentName;
    // @ts-ignore
    component.buttonSelector = buttonSelector;

    // @ts-ignore
    component.clientContext = {
      organizationId,
      environment: env,
      email: user?.email,
      userAgent,
      drawerState,
      getAccessToken: getAccessTokenSilently,
    };

    isSet.current = true;
  }, [
    buttonSelector,
    componentName,
    drawerState,
    getAccessTokenSilently,
    organizationId,
    user?.email,
    userAgent,
  ]);

  useEffect(
    () => runSupportCase(),
    [props.parentVisible, props.rerun, runSupportCase],
  );

  return runSupportCase;
}

export { useSupportCase };
