import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Heading,
  ModalBody,
  Flex,
  ModalFooter,
  ButtonGroup,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import FieldError from 'features/common/components/Form/FieldError';
import { getIn } from 'formik';
import { useCallback, useRef } from 'react';
import { useHistory, useParams } from 'react-router';
import { ssoRoute } from '../sso-routes';
import { useCreateConnection, usePostConnection } from '../api';
import {
  connectionInputFields,
  constructSSOPayload,
  isDomain,
  isRequiredField,
  redirectToConfigureModal,
} from '../utils';
import { createConectionSchema } from '../validations/connectionSchema';
import { InputTag } from 'features/common/components/InputTag';
import { isEmpty } from 'lodash';
import { useQueryClient } from 'react-query';

export const SSOSaml = () => {
  const history = useHistory();
  const { onClose } = useDisclosure();
  const { ssoProvider } = useParams<{ ssoProvider: string }>();
  const queryClient = useQueryClient();
  const {
    touched,
    values,
    errors,
    setFieldValue,
    handleBlur,
    isValid,
    isValidating,
    isSubmitting,
    setSubmitting,
    dirty,
    isLoading,
    validateForm,
  } = useCreateConnection(ssoProvider);
  const { mutateAsync: postConnection } = usePostConnection();
  const toast = useToast();

  const onCloseDrawer = useCallback(() => {
    history.push({ pathname: ssoRoute, search: history.location.search });
    onClose();
  }, [history, onClose]);

  const { label, name, description, testId } = connectionInputFields[0];
  const initialFocusRef = useRef<HTMLInputElement>(null);
  const onChange = useCallback(
    (emails: string[]) => setFieldValue('emailDomains', emails),
    [setFieldValue],
  );

  const displayErrorAlert = (claimsData: { error: any; title: any }) => {
    if (claimsData.error) {
      toast({
        description: claimsData.title,
        status: 'error',
      });
    } else {
      toast({
        description: 'Something went wrong',
        status: 'error',
      });
    }
  };

  const onHandleSamlCreation = async () => {
    const errors = await validateForm();

    if (isEmpty(errors)) {
      const newValues = constructSSOPayload('samlp')(values);

      setSubmitting(true);
      const { data: postData, status: postStatus } = await postConnection(
        //@ts-ignore
        newValues,
        {},
      );
      setSubmitting(false);

      if (postStatus === 201) {
        queryClient.invalidateQueries('connections/list');
        redirectToConfigureModal('samlp', postData.id);
        toast({
          description: 'Connection saved',
          title: 'Success',
          status: 'success',
        });
      }

      if (postData.error) {
        displayErrorAlert(postData);
      }
    }
  };

  return (
    <>
      <Modal
        isOpen={true}
        onClose={onCloseDrawer}
        data-onCloseDrawer='sso-saml-modal'
        size='lg'
        autoFocus={false}
      >
        <ModalOverlay />
        <ModalContent data-testid='sso-saml-modal'>
          <ModalHeader>
            <Heading as='h4' size='md' data-testid='sso-saml-header'>
              Add SAML connection
            </Heading>
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>
            <form data-testid='sso-saml-form' id='sso-saml-form'>
              <Flex direction='column' gap={8}>
                <FormControl
                  isInvalid={getIn(errors, name) && getIn(touched, name)}
                  isRequired={isRequiredField(
                    name,
                    values,
                    createConectionSchema.samlp,
                  )}
                  key={name}
                >
                  <FormLabel data-testid={'label-' + name}>{label}</FormLabel>
                  <InputTag
                    name={name}
                    tags={values.emailDomains ?? []}
                    onChange={onChange}
                    onBlur={handleBlur}
                    validateTag={isDomain}
                    placeholder='example.com'
                    ref={initialFocusRef}
                    isInvalid={getIn(errors, name) && getIn(touched, name)}
                    dataTestId={testId}
                  />
                  <FormHelperText>{description}</FormHelperText>
                  <FieldError
                    touched={getIn(touched, name)}
                    error={getIn(errors, name)}
                    testId={testId}
                  />
                </FormControl>
              </Flex>
            </form>
          </ModalBody>
          <ModalFooter justifyContent='flex-end' alignItems='flex-end'>
            <ButtonGroup>
              <Button
                type='button'
                variant='ghost'
                data-testid='ssoSamlForm-cancelButton'
                onClick={onCloseDrawer}
                isDisabled={isValidating || isSubmitting}
              >
                Cancel
              </Button>
              <Button
                type='submit'
                variant='solid'
                data-testid='ssoSamlForm-submitButton'
                isLoading={isValidating || isSubmitting}
                isDisabled={!isValid || !dirty || isLoading}
                form='sso-saml-form'
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onHandleSamlCreation();
                }}
              >
                Add and configure
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
