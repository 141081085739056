import React from 'react';
import { useHistory } from 'react-router-dom';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { getAuthSettings } from '../config/envConfig';

export const Auth0ProviderWithHistory = ({
  children,
  initOrganizationId,
}: {
  initOrganizationId?: string;
  children?: React.ReactNode;
}) => {
  const { domain, clientId, audience } = getAuthSettings();

  const history = useHistory();

  const onRedirectCallback = (appState?: AppState) => {
    history.push(appState?.returnTo || window.location.pathname, {
      replace: true,
    });
  };

  if (!domain || !clientId || !audience) {
    throw new Error('Missing auth provider properties');
  }

  return (
    <Auth0Provider
      audience={audience}
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
      organization_id={initOrganizationId}
    >
      {children}
    </Auth0Provider>
  );
};
