import { useCallback, useMemo, useState } from 'react';

const useModal = (initialMode = false) => {
  const [isOpen, setIsOpen] = useState<boolean>(initialMode);
  const toggle = useCallback(() => setIsOpen((isOpen) => !isOpen), [setIsOpen]);
  const onCloseModal = useCallback(
    (options: { cb?: () => void } = {}) =>
      () => {
        const { cb } = options;
        cb?.();
        setIsOpen(false);
      },
    [setIsOpen],
  );

  return useMemo(
    () => ({
      isOpen,
      setIsOpen,
      toggle,
      onCloseModal,
    }),
    [isOpen, onCloseModal, toggle],
  );
};

export default useModal;
