import { FormErrorIcon, FormErrorMessage } from '@chakra-ui/react';
import { T } from '@transifex/react';

const ErrorMessage = ({
  msg,
  testId,
  whiteSpace = 'normal',
}: {
  msg: string | string[];
  testId: string;
  whiteSpace?:
    | 'normal'
    | 'nowrap'
    | 'pre'
    | 'pre-line'
    | 'pre-wrap'
    | 'break-spaces';
}) => {
  if (!msg.length) return null;
  return (
    <FormErrorMessage whiteSpace={whiteSpace} data-testid={testId}>
      <FormErrorIcon />
      <T _str={msg} />
    </FormErrorMessage>
  );
};

export default ErrorMessage;
