export type IMemberDetails = {
  jobRole?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
};

export enum EOrganizationAccessLevel {
  admin = 'admin',
  user = 'user',
  owner = 'owner',
}

export enum ESelectAccessOptions {
  owner = 'owner',
  admin = 'admin',
  user = 'user',
}

export interface ISelectOption {
  value: string;
  role: string;
  description: string;
}

export enum ETenantAccessLevel {
  'no-access' = 'no-access',
}

export type ITenantSelectOption = { value: string; label: string };

/**
 * Key is the tenant id
 */
export interface ITenantsAccessLevel {
  [key: string]: ETenantAccessLevel | string; // TODO: revisit this
}
