import { t } from '@transifex/native';
import { ConnectionType } from '../types';
import { mdiTrashCanOutline } from '@mdi/js';
import {
  Badge,
  ButtonGroup,
  Button,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Tag,
  Wrap,
  Text,
  Tooltip,
  PopoverCloseButton,
  PopoverHeader,
} from '@chakra-ui/react';
import { history } from 'features/common/history/history';
import { ssoRoute } from 'features/sso/sso-routes';
import { PortalIcon } from 'features/common/components/PortalIcon';
import { redirectToConfigureModal } from '../utils';

const callbackPerStrategy = {
  oidc: (step: string, id?: string, strategy?: string) => () =>
    history.replace(
      `${ssoRoute}/${strategy}/${id}/${step}${history.location.search}`,
    ),
  samlp: (step: string, id?: string, strategy?: string) => () =>
    history.replace(
      `${ssoRoute}/${strategy}/${id}/${step}${history.location.search}`,
    ),
};
export const providerPerStrategy = {
  oidc: 'OpenID Connect',
  samlp: 'SAML',
};

export const domainsAccesor = (domains: string[] = []) => {
  const [firstDomain, ...remainingDomains] = domains;
  return (
    <Wrap>
      <Tag maxW='3xs'>
        <Tooltip label={firstDomain}>
          <Text isTruncated>{firstDomain}</Text>
        </Tooltip>
      </Tag>
      {!!remainingDomains.length && (
        <Popover>
          <PopoverTrigger>
            <Tag maxW='3xs' layerStyle='interactive.fill'>
              {`+${remainingDomains.length}`}
            </Tag>
          </PopoverTrigger>
          <PopoverContent w='md'>
            <PopoverCloseButton />
            <PopoverHeader>Domains</PopoverHeader>
            <PopoverBody overflow='auto' maxH='3xs'>
              <Wrap>
                {remainingDomains.map((domain, i) => (
                  <Tag key={`${domain}_${i}`}>
                    <Text>{domain}</Text>
                  </Tag>
                ))}
              </Wrap>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )}
    </Wrap>
  );
};

export const domainVerifiedAccesor = (verified: boolean) => (
  <Badge
    data-testid={verified ? 'verifiedAccesorIcon' : 'nonVerifiedAccesorIcon'}
    {...(verified
      ? {
          children: 'Verified',
          colorScheme: 'success',
        }
      : {
          children: 'Unverified',
          colorScheme: 'danger',
        })}
  />
);

export const statusAccessor = (state: string) => {
  const badgeProps = {
    active: {
      color: 'success',
      text: 'Enabled',
    },
    inactive: {
      color: 'danger',
      text: 'Disabled',
    },
    activating: {
      color: 'danger',
      text: 'Disabled',
    },
  };

  const { color, text } =
    badgeProps[state.toLowerCase() as keyof typeof badgeProps];

  return <Badge colorScheme={color}>{text}</Badge>;
};

export const connectionTableButtonsAccessor = (
  verified: boolean,
  state: string,
  strategy: string,
  id?: string,
) => {
  return (
    <ButtonGroup flexDir='row' justifyContent='flex-end' size='sm' width='full'>
      {!verified && state === 'Inactive' && (
        <Button
          variant='outline'
          size='sm'
          onClick={callbackPerStrategy[
            strategy as keyof typeof callbackPerStrategy
          ]?.('verify-connection', id, strategy)}
        >
          Verify domains
        </Button>
      )}

      <Button
        variant='outline'
        size='sm'
        onClick={callbackPerStrategy[
          strategy as keyof typeof callbackPerStrategy
        ]?.('test-connection', id, strategy)}
      >
        Test
      </Button>

      {verified && state === 'Inactive' && (
        <Button
          variant='outline'
          size='sm'
          onClick={callbackPerStrategy[
            strategy as keyof typeof callbackPerStrategy
          ]?.('enable-connection', id, strategy)}
        >
          Enable
        </Button>
      )}
      {state === 'Activating' && (
        <Button
          isLoading
          loadingText='Enabling'
          variant='outline'
          colorScheme='success'
          isDisabled
        />
      )}
      <Button
        variant='outline'
        aria-label='edit connection'
        size='sm'
        onClick={() => redirectToConfigureModal(strategy, id)}
        isDisabled={state === 'Activating'}
      >
        Configure
      </Button>
      <IconButton
        variant='ghostColorOnHover'
        colorScheme='danger'
        aria-label='delete connection'
        icon={<PortalIcon path={mdiTrashCanOutline} />}
        isDisabled={state === 'Activating'}
        onClick={() =>
          history.push(
            `${ssoRoute}/${strategy}/${id}/delete-connection${history.location.search}`,
          )
        }
      />
    </ButtonGroup>
  );
};

export const getTableOptions = () => {
  return {
    columns: [
      {
        id: 'domain',
        Header: t('Email domains'),
        accessor: ({ emailDomains }: ConnectionType) =>
          domainsAccesor(emailDomains),
        disableSortBy: true,
      },
      {
        id: 'provider',
        Header: t('Provider type'),
        accessor: 'provider',
        disableSortBy: true,
      },
      {
        id: 'domain-verified',
        Header: t('Domains verified'),
        accessor: ({ verified }: ConnectionType) =>
          domainVerifiedAccesor(verified),
        disableSortBy: true,
      },
      {
        id: 'status',
        Header: t('Status'),
        accessor: ({ state }: ConnectionType) => statusAccessor(state),
        disableSortBy: true,
      },
      {
        id: 'connection-table-buttons',
        Header: '',
        accessor: ({ verified, state, id, strategy }: ConnectionType) =>
          connectionTableButtonsAccessor(verified, state, strategy, id),
        disableSortBy: true,
      },
    ],
  };
};

export const generateConnectionsTableData = (
  connectionData: ConnectionType[],
) => {
  return connectionData.map(
    ({ id, emailDomains, verified, state, strategy }) => ({
      id,
      provider:
        providerPerStrategy[strategy as keyof typeof providerPerStrategy],
      emailDomains,
      verified,
      state,
      strategy,
    }),
  );
};
