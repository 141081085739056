import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  Stack,
  useMediaQuery,
} from '@chakra-ui/react';
import sitecoreTheme from '@sitecore/blok-theme';
import { ReactNode } from 'react';

const ConnectionModalContainer = ({
  navigationButtons,
  children,
}: {
  navigationButtons: { title: string; goToAnchor: string }[];
  children: ReactNode;
}) => {
  const [isBiggerThanMd] = useMediaQuery(
    `(min-width: ${sitecoreTheme.breakpoints.lg})`,
  );

  const handleClickScroll = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <HStack spacing={6} alignItems='start'>
      {isBiggerThanMd && (
        <Box top={0} position='sticky'>
          <ButtonGroup variant='navigation' orientation='vertical'>
            {navigationButtons.map((b) => (
              <Button
                onClick={() => b.goToAnchor && handleClickScroll(b.goToAnchor)}
              >
                {b.title}
              </Button>
            ))}
          </ButtonGroup>
        </Box>
      )}
      <Stack maxW='container.lg' mx='auto' spacing='10'>
        {children}
      </Stack>
    </HStack>
  );
};

export default ConnectionModalContainer;
