import { IconButton } from '@chakra-ui/react';
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';
import { PortalIcon } from '../PortalIcon';

interface PreviousNextButtonProps {
  type: 'previous' | 'next';
  disabled?: boolean;
  onClick: any;
}
export function PreviousNextButton({
  type,
  onClick,
  disabled,
}: PreviousNextButtonProps) {
  return (
    <IconButton
      data-testid={`pagination_${type}Page`}
      variant='pagination'
      onClick={onClick}
      isDisabled={disabled}
      aria-label={`${type} page`}
      icon={
        <PortalIcon
          path={type == 'previous' ? mdiChevronLeft : mdiChevronRight}
        />
      }
    />
  );
}
