import { useAuthMutation } from '../../common/apiUtils/queryHelper';
import { getContextApiUrl } from '../utils';

const baseUrl = getContextApiUrl();

export const useDeleteSitecoreContext = () => {
  const url = (id: string) => `${baseUrl}/contexts/${id}`;

  return useAuthMutation((axiosInstance) => ({ id }: { id: string }) => {
    return axiosInstance?.delete(url(id));
  });
};
