import {
  mdiAccountCircleOutline,
  mdiAccountGroupOutline,
  mdiAccountKeyOutline,
  mdiAccountMultipleOutline,
  mdiAccountPlusOutline,
  mdiApplicationBracesOutline,
  mdiArrowDecision,
  mdiArrowDecisionOutline,
  mdiAutorenew,
  mdiBadgeAccountHorizontalOutline,
  mdiBadgeAccountOutline,
  mdiBellCogOutline,
  mdiBookOpenOutline,
  mdiBookOpenPageVariantOutline,
  mdiBullhornOutline,
  mdiButtonPointer,
  mdiCalendarOutline,
  mdiCardAccountMailOutline,
  mdiCellphoneLink,
  mdiChartBoxOutline,
  mdiChartTimeline,
  mdiClipboardFlowOutline,
  mdiCodeBraces,
  mdiCodeTags,
  mdiCommentAlertOutline,
  mdiCommentPlusOutline,
  mdiCreationOutline,
  mdiDatabaseImportOutline,
  mdiDatabaseSearchOutline,
  mdiEmailFastOutline,
  mdiEqualizerOutline,
  mdiFeather,
  mdiFileEditOutline,
  mdiFileSign,
  mdiFileTree,
  mdiFlaskOutline,
  mdiForumOutline,
  mdiHelpCircleOutline,
  mdiImageMultipleOutline,
  mdiImageOutline,
  mdiKeyPlus,
  mdiLayersTripleOutline,
  mdiLifebuoy,
  mdiListBoxOutline,
  mdiMessageAlertOutline,
  mdiMonitorArrowDown,
  mdiMonitorDashboard,
  mdiOpenInNew,
  mdiPencilRuler,
  mdiPencilRulerOutline,
  mdiSchoolOutline,
  mdiShieldKeyOutline,
  mdiSitemapOutline,
  mdiTelevisionPlay,
  mdiTestTube,
  mdiToolboxOutline,
  mdiTools,
  mdiToyBrickOutline,
  mdiYoutube,
} from '@mdi/js';
import { redirect } from '@sitecore-ui/portal-singular';
import { openDrawer } from 'features/main/context/drawer/actionCreators';
import { DrawerId } from 'features/main/context/drawer/model';
import { Action, Link } from 'gql/graphql';
import noop from 'lodash/noop';

const mdiIcons = {
  mdiAccountCircleOutline,
  mdiAccountGroupOutline,
  mdiAccountKeyOutline,
  mdiAccountMultipleOutline,
  mdiAccountPlusOutline,
  mdiApplicationBracesOutline,
  mdiArrowDecision,
  mdiArrowDecisionOutline,
  mdiAutorenew,
  mdiBadgeAccountHorizontalOutline,
  mdiBadgeAccountOutline,
  mdiBellCogOutline,
  mdiBookOpenOutline,
  mdiBookOpenPageVariantOutline,
  mdiBullhornOutline,
  mdiButtonPointer,
  mdiCalendarOutline,
  mdiCardAccountMailOutline,
  mdiCellphoneLink,
  mdiChartBoxOutline,
  mdiChartTimeline,
  mdiClipboardFlowOutline,
  mdiCodeBraces,
  mdiCodeTags,
  mdiCommentAlertOutline,
  mdiCommentPlusOutline,
  mdiCreationOutline,
  mdiDatabaseImportOutline,
  mdiDatabaseSearchOutline,
  mdiEmailFastOutline,
  mdiEqualizerOutline,
  mdiFeather,
  mdiFileEditOutline,
  mdiFileSign,
  mdiFileTree,
  mdiFlaskOutline,
  mdiForumOutline,
  mdiHelpCircleOutline,
  mdiImageMultipleOutline,
  mdiImageOutline,
  mdiKeyPlus,
  mdiLayersTripleOutline,
  mdiLifebuoy,
  mdiListBoxOutline,
  mdiMessageAlertOutline,
  mdiMonitorArrowDown,
  mdiMonitorDashboard,
  mdiOpenInNew,
  mdiPencilRuler,
  mdiPencilRulerOutline,
  mdiSchoolOutline,
  mdiShieldKeyOutline,
  mdiSitemapOutline,
  mdiTelevisionPlay,
  mdiTestTube,
  mdiToolboxOutline,
  mdiTools,
  mdiToyBrickOutline,
  mdiYoutube,
};

import { iconXmCloud } from '@sitecore/blok-theme';

const blokIcons = {
  iconXmCloud,
};

export const quickActionsMapper = (quickAction: any) => {
  const mdiIcon = quickAction.viewModel.icon.mdiIcon as string;
  const blokIcon = quickAction.viewModel.icon.blokIcon as string;
  const iconUrl = quickAction.viewModel.icon.iconUrl;

  return {
    iconColor: quickAction.viewModel?.color,
    title: quickAction.viewModel.title,
    action: quickAction.viewModel.ctaCallback,
    iconPath: mdiIcon
      ? mdiIcons[mdiIcon as keyof typeof mdiIcons]
      : blokIcon
      ? blokIcons[blokIcon as keyof typeof blokIcons]
      : null,
    svgIcon: iconUrl,
    link: quickAction.getCtaLink(),
    category: quickAction.viewModel.category ?? '',
    description: quickAction.viewModel.description,
    'analytics-id':
      quickAction.metadata.analyticsId ??
      quickAction.viewModel.title +
        (quickAction.viewModel.type !== 'ui'
          ? ` - ${quickAction.viewModel.description}`
          : ''),
    'data-url': quickAction.metadata.dataUrAcl,
  };
};

export function getCtaCallback({
  link,
  history,
  dispatch,
}: {
  link: Link;
  history: any;
  dispatch: any;
}) {
  try {
    // Resolve link url based on json data
    const ctaUrl = link.to;

    const callback = (() => {
      // Resolve the callback for external link
      if (link.type === 'external-link') {
        return redirect;
      }

      // Resolve the callback with a user's defined call to action (this.hostCta) function
      if (link.type === 'internal-link') {
        return (url = ctaUrl) => history && history.push(url);
      }

      if (link.type === 'internal-state' && dispatch) {
        const payload = link.to as DrawerId;
        return () => dispatch(openDrawer(payload));
      }

      return noop;
    })();

    return () => callback(ctaUrl);
  } catch {
    // fail with a no operation function
    return () => noop();
  }
}

export const quickActionAdapter = (
  quickAction: Action,
  { history, dispatch }: { history: any; dispatch: any },
) => {
  const icon = {
    iconUrl: '',
    mdiIcon: '',
    blokIcon: '',
  };
  const iconSrc = quickAction.icon?.src;

  switch (quickAction.icon?.type) {
    case 'url':
      icon.iconUrl = iconSrc;
      break;
    case 'mdi':
      icon.mdiIcon = iconSrc;
      break;
    case 'blok':
      icon.blokIcon = iconSrc;
      break;
    default:
      break;
  }

  return {
    iconColor: quickAction?.color,
    title: quickAction.name,
    action: getCtaCallback({ link: quickAction.link, history, dispatch }),

    iconPath: icon.mdiIcon
      ? mdiIcons[icon.mdiIcon as keyof typeof mdiIcons]
      : icon.blokIcon
      ? blokIcons[icon.blokIcon as keyof typeof blokIcons]
      : null,
    svgIcon: icon.iconUrl,
    link: quickAction.link?.to,
    category: quickAction.category ?? '',
    description: quickAction.displayName,
    'analytics-id': quickAction.name,
    'data-url':
      quickAction.link?.type === 'external-link' ? quickAction.link?.to : null,
  };
};

export const mediaCardAdapter = (quickAction: Action) => {
  const { color, name, displayName, icon } = quickAction;

  return {
    title: name,
    label: displayName,
    iconColor: color,
    ...(icon.type === 'url' && {
      imageUrl: icon.src,
    }),
    ...(icon.type === 'mdi' && {
      svgIcon: icon.src,
    }),
  };
};
