import { useFeatures } from 'lib/featureFlags/features';
import { useGetHelpQuickActionsQuery } from 'gql/graphql';

import {
  QuickActionSchema,
  sitecoreCloudDocumentationQuickaction,
  sitecoreDeveloperDocumentationQuickAction,
  sitecoreSupportQuickAction,
  sitecoreCollaborativeProgramQuickaction,
  sitecoreLearningQuickAction,
  discoverSitecoreQuickAction,
  sitecoreDocumentationQuickAction,
} from '@sitecore-ui/portal-singular';
import createSupportCase from '../generalActions/create-support-case.json';

const helpLinksMainSchemas = [
  sitecoreCloudDocumentationQuickaction,
  sitecoreDocumentationQuickAction,
  sitecoreSupportQuickAction,
  sitecoreLearningQuickAction,
  sitecoreDeveloperDocumentationQuickAction,
  discoverSitecoreQuickAction,
] as QuickActionSchema[];

const helpLinksSecondarySchemas = [
  sitecoreCollaborativeProgramQuickaction,
  createSupportCase,
] as QuickActionSchema[];

export { helpLinksMainSchemas, helpLinksSecondarySchemas };

/**
 * This function is used to check if the feature flag is enabled or not.
 * If the feature flag is not enabled, the quick action will be disabled.
 * This is done to avoid the user to click on the quick action while it is not enabled.
 * @param json
 * @param features
 */
function isFeatureFlagEnabled(
  featureFlagKey: string,
  features: Record<string, any>,
) {
  // TODO: we should probably add a feature flag id to the quick action schema for better azure feature flag mapping,
  //  but for now we can use the drawer id and the feature flag id to be the same
  // SNOW quick action condition
  // eslint-disable-next-line no-prototype-builtins
  return features.hasOwnProperty(featureFlagKey)
    ? features[featureFlagKey]
    : true;
}

const useGetHelpContent = () => {
  const features = useFeatures();
  const { data, loading } = useGetHelpQuickActionsQuery({
    variables: {
      first: 100,
    },
  });

  const quicActions = data?.organization?.appearance?.web?.actions?.nodes ?? [];
  const shouldShowSnow = isFeatureFlagEnabled('SNOW', features);
  const filteredActions = shouldShowSnow
    ? quicActions
    : quicActions.filter(({ link }) => link?.to !== 'create-ticket-drawer');

  const mainHelpLinks = filteredActions.filter(
    ({ location }) => location?.[0] === 'helpMainSection',
  );
  const secondaryHelpLinks = filteredActions.filter(
    ({ location }) => location?.[0] === 'helpSecondarySection',
  );

  return {
    mainHelpLinks,
    secondaryHelpLinks,
    isLoading: loading,
  };
};

export { useGetHelpContent, isFeatureFlagEnabled };
