/* eslint-disable no-console */
import { useEffect, useRef, useState } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  ButtonGroup,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Text,
  Button,
  ModalCloseButton,
  useToast,
} from '@chakra-ui/react';
import { useHistory, useParams } from 'react-router';
import { T } from '@transifex/react';
import { AxiosResponse } from 'axios';
import { t } from '@transifex/native';
import { redirect } from 'features/common/utils/utils';
import { useQueryClient } from 'react-query';
import {
  IOidcConnection,
  Verification,
  useActivateConnection,
  useGetConnection,
  useVerifyConnection,
} from '../api';
import VerifyConnectionSteps from './verify-connection-steps';
import { ssoRoute } from '../sso-routes';
import withConnectionIdGuard from '../with-connection-id-guard';
import VerifyAlertDescription from './verify-alert-description';
import { getLoginUrl } from '../utils';

const VerifyConnectionModal = ({ connectionId }: { connectionId?: string }) => {
  const lessDestructiveRef = useRef(null);
  const queryClient = useQueryClient();
  const {
    replace,
    location: { search },
  } = useHistory();
  const { id, ssoProvider } = useParams<{ id: string; ssoProvider: string }>();
  const { data: connection } = useGetConnection(id, {
    enabled: !!id || !!connectionId,
  });
  const { name, verification } = connection?.data ?? {};
  const testUrl = `${getLoginUrl()}/TestConnection?connectionName=${name}`;

  const [connectionVerification, setConnectionVerification] =
    useState('pending');
  const [errorMessage, setErrorMessage] = useState('');
  const [verificationState, setVerificationState] = useState<
    Verification | undefined
  >(verification);

  useEffect(() => setVerificationState(verification), [verification]);

  const isConnectionVerified = connectionVerification === 'success';
  const options = {
    onSuccess: (data: AxiosResponse<IOidcConnection>) => {
      const methods = data.data.verification?.methods ?? [];

      queryClient.invalidateQueries('connections/list');
      const isConnectionVerified = data.data.verified;

      isConnectionVerified
        ? setConnectionVerification('success')
        : setConnectionVerification('failed');

      if (!isConnectionVerified && methods.length === 0) {
        return setErrorMessage('Something went wrong. Try again later.');
      }

      if (!isConnectionVerified && methods.length > 0)
        setVerificationState(data.data.verification);
    },
    onError: (error: {
      response: { status: number; data: { detail: string } };
    }) => {
      setConnectionVerification('failed');
      const status = error?.response?.status ?? 0;

      if (status >= 400 && status < 500) {
        return setErrorMessage(error?.response.data.detail);
      }

      return setErrorMessage('Something went wrong');
    },
  };

  /*
   * Verify connection hooks
   */
  const { mutate: verifyConnection, isLoading } = useVerifyConnection({
    connectionId: id as string,
  });

  /*
   * Activate connection hooks
   */

  const { mutate: postConnection } = useActivateConnection();
  const toast = useToast();
  const onClose = () => replace(`${ssoRoute}${search}`);
  const onEnable = () => {
    postConnection(
      { id },
      {
        onSuccess: () => {
          queryClient.invalidateQueries('connections/list');
          onClose();
          toast({
            description: t(
              'Enabling SSO connection. This may take a few minutes.',
            ),
            status: 'info',
          });
        },
        onError: () => {
          toast({
            description: t('Couldn’t enable SSO connection'),
            status: 'error',
          });
        },
      },
    );
  };

  const onSubmit = isConnectionVerified
    ? () => {
        redirect(testUrl);
        replace(
          `${ssoRoute}/${ssoProvider}/${id}/restart-test-connection${search}`,
        );
      }
    : //@ts-ignore
      () => verifyConnection({}, options);

  return (
    <AlertDialog
      isOpen={true}
      onClose={onClose}
      leastDestructiveRef={lessDestructiveRef}
      size='2xl'
      autoFocus={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent data-testid='verifyConnectionModal' gap={2}>
          <AlertDialogHeader>
            <Text as='span' data-testid='verifyConnectionModal-title'>
              Verify domains
            </Text>
          </AlertDialogHeader>
          <ModalCloseButton />
          <AlertDialogBody maxH='2xl' overflow='auto'>
            {connectionVerification !== 'pending' && (
              <VerifyAlertDescription
                verified={isConnectionVerified}
                errorMessage={errorMessage}
              />
            )}
            <VerifyConnectionSteps
              verified={isConnectionVerified}
              verification={verificationState}
            />
          </AlertDialogBody>
          <AlertDialogFooter>
            <ButtonGroup>
              <Button
                variant='ghost'
                onClick={onClose}
                ref={lessDestructiveRef}
                isDisabled={isLoading}
                data-testid='verifyConnectionModal-closeButton'
              >
                <T _str='Close' />
              </Button>
              <Button
                variant={isConnectionVerified ? 'ghost' : 'solid'}
                onClick={onSubmit}
                isLoading={isLoading}
                data-testid={`verifyConnectionModal-${
                  isConnectionVerified ? 'testConnection' : 'verifyDomain'
                }Button`}
              >
                <T
                  _str={
                    isConnectionVerified
                      ? 'Test SSO connection'
                      : 'Verify domains'
                  }
                />
              </Button>
              {isConnectionVerified && (
                <Button
                  onClick={onEnable}
                  variant='solid'
                  data-testid='verifyConnectionModal-enableButton'
                >
                  Enable SSO connection
                </Button>
              )}
            </ButtonGroup>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default withConnectionIdGuard(VerifyConnectionModal);
