import { gql } from '@apollo/client';

export const GET_COUNT_OF_PRODUCT = gql`
  query GetCountOfProduct($productCode: String!) {
    organization {
      applications(where: { productCode: { value: { eq: $productCode } } }) {
        totalCount
      }
    }
  }
`;

export const GET_DEPLOYMENT_REGIONS = gql`
  query GetDeploymentRegions {
    products {
      code
      deploymentRegions {
        totalCount
        nodes {
          id
          description
        }
      }
    }
  }
`;

export const PROVISION_CONNECT = gql`
  mutation ProvisionConnect(
    $name: String!
    $displayName: String!
    $regiondId: String!
    $auditLogsEnabled: Boolean
  ) {
    provisionProduct(
      input: {
        connect: {
          name: $name
          displayName: $displayName
          regionId: $regiondId
          auditLogsEnabled: $auditLogsEnabled
        }
      }
    ) {
      errors {
        ... on ProductProvisioningValidationError {
          message
          errorDetails {
            key
            value
          }
        }
        ... on ProductProvisioningConflictError {
          message
        }
        ... on UnknownError {
          message
        }
      }
    }
  }
`;
