import { validateURL } from '@sitecore-ui/portal-singular';
import { History, Path } from 'history';

export const redirect = (
  url: string,
  history?: string[] | History<unknown>,
) => {
  if (!url) return;

  if (validateURL(url)) {
    window.open(encodeURI(url), '_blank', 'noopener');
    return;
  }

  if (url.includes('mailto')) {
    window.open(encodeURI(url), '_self');
    return;
  }

  history && history.push(url as Path);
};
