import * as envConfig from 'features/common/config/envConfig';

const getBaseUrl = (): string => {
  const env = envConfig.env.toLowerCase();

  switch (env) {
    case 'prod':
      return 'https://marketplace-api.sitecorecloud.io';
    case 'preprod':
      return 'https://marketplace-api-preprod.sitecorecloud.io';
    case 'qa':
      return 'https://marketplace-api-qa.sitecore-staging.cloud';
    case 'staging':
      return 'https://marketplace-api-staging.sitecore-staging.cloud';
    default:
      return 'https://marketplace-api-dev.sitecore-staging.cloud';
  }
};

export const env = {
  baseUrl: getBaseUrl(),
};
