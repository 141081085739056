import { T } from '@transifex/react';
import { MediaObjectLink } from 'features/common/components/MediaObjectLink';
import { Box, Heading, Skeleton, VStack } from '@chakra-ui/react';
import { useSupportCase } from 'features/snow/hooks/useSupportCase';
import { useDrawerStateDispatch, useMainState } from 'features/main/context';
import { useGetQuickLinksQuery } from 'gql/graphql';
import { quickActionAdapter } from 'features/domains/portal/content/utils';
import { useHistory } from 'react-router';
import { useFeatures } from 'lib/featureFlags/features';
import { isFeatureFlagEnabled } from 'features/domains/portal/content/helpLinks';
import { xmCloudDeployActionVar } from 'lib/@apollo/client/ApolloProvider';

const QuickLinksPanel: React.FC = () => {
  const state = useMainState();
  const { data, loading } = useGetQuickLinksQuery();
  const quickLinks = data?.organization?.appearance?.web?.actions?.nodes || [];
  const [, drawerDispatch] = useDrawerStateDispatch();
  const history = useHistory();
  const features = useFeatures();

  const xmCloudDeployAction = quickLinks.find(
    (quickAction) => quickAction.name === 'XM Cloud Deploy',
  );

  if (xmCloudDeployAction) {
    xmCloudDeployActionVar(xmCloudDeployAction);
  }

  const shouldShowSnow = isFeatureFlagEnabled('SNOW', features);
  const filteredLinks = shouldShowSnow
    ? quickLinks
    : quickLinks.filter(({ link }) => link?.to !== 'create-ticket-drawer');

  useSupportCase({
    componentName: 'create-support-case-drawer',
    buttonSelector: '[data-support-case="create-support-case-drawer"]',
  });

  return (
    <Box
      w='full'
      data-testid='quick-links-panel'
      data-behavior-analytics-feature='quick-links - home page'
    >
      <Heading variant='section' pb='4'>
        <T _str='Quick Links' />
      </Heading>
      {loading ? (
        <VStack>
          <Skeleton w='full' h={12} />
          <Skeleton w='full' h={12} />
          <Skeleton w='full' h={12} />
          <Skeleton w='full' h={12} />
        </VStack>
      ) : (
        <VStack>
          {filteredLinks.map((quickAction) => {
            const {
              iconColor,
              action,
              title,
              description,
              svgIcon,
              iconPath,
              link,
            } = quickActionAdapter(quickAction, {
              dispatch: drawerDispatch,
              history,
            });

            return (
              <MediaObjectLink
                onClick={() => {
                  if (link === 'create-ticket-drawer') {
                    return () => void 0;
                  }

                  return action();
                }}
                title={title}
                link={link}
                key={title}
                data-testid={`help-item-${title}`}
                iconPath={iconPath}
                iconColor={iconColor}
                svgIcon={svgIcon}
                description={description}
                {...(link === 'create-ticket-drawer'
                  ? {
                      'data-support-case': 'create-support-case-drawer',
                    }
                  : {})}
              />
            );
          })}
        </VStack>
      )}
      {state.env?.ORG_ADMIN_OR_OWNER && shouldShowSnow && (
        <create-support-case-drawer />
      )}
    </Box>
  );
};

export default QuickLinksPanel;
