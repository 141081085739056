import {
  Alert,
  AlertDescription,
  AlertIcon,
  Text,
  Box,
} from '@chakra-ui/react';
import { T } from '@transifex/react';

const VerifyAlertDescription = ({
  verified,
  errorMessage,
}: {
  verified: boolean;
  errorMessage: string;
}) =>
  !verified && !errorMessage ? null : (
    <Box mb={6}>
      <Alert status={verified ? 'success' : 'error'}>
        <AlertIcon />
        <AlertDescription>
          {verified ? (
            <Text data-testid='verifyConnectionModal-verified'>
              <T _str='Domains verified' />
            </Text>
          ) : (
            <Text data-testid='verifyConnectionModal-error'>
              <T _str={errorMessage} />
            </Text>
          )}
        </AlertDescription>
      </Alert>
    </Box>
  );

export default VerifyAlertDescription;
