import { gql } from '@apollo/client';

export const GET_MEMBERS = gql`
  query GetMembers($query: String, $take: Int, $skip: Int) {
    organization {
      id
      members(query: { nameOrEmail: $query }, take: $take, skip: $skip) {
        totalCount
        items {
          id
          emailVerified
          created
          updated
          lastLogin
          signUpCompleted
          isExternal
          givenName
          familyName
          nickName
          phoneNumber
          company
          jobRole
          preferredLanguage
          picture
          email
          organizationRoles(first: 25) {
            nodes {
              description
              name
              organizationId
            }
          }
          appearance {
            memberActions {
              totalCount
              nodes {
                link {
                  to
                  type
                }
                displayName
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ORGANIZATION_MEMBER = gql`
  query GetOrganizationMember($id: String!) {
    organization {
      id
      memberById(userId: $id) {
        id
        emailVerified
        created
        updated
        lastLogin
        signUpCompleted
        isExternal
        givenName
        familyName
        nickName
        phoneNumber
        company
        jobRole
        preferredLanguage
        picture
        email
        applications(first: 100) {
          totalCount
          nodes {
            id
            name
            displayName
            organizationId
            state
            systemId
            productCode
            assignedRoles(first: 100) {
              totalCount
              nodes {
                applicationId
                organizationId
                role
                productCode
              }
            }
          }
        }
        organizationRoles {
          totalCount
          nodes {
            organizationId
            name
            description
          }
        }
        appearance {
          memberActions {
            totalCount
            nodes {
              name
              displayName
              link {
                to
                type
              }
            }
          }
        }
      }
    }
  }
`;

// prettier-ignore
export const UPDATE_ORGANIZATION_MEMBER_ROLE = gql`
  mutation UpdateOrganizationMemberRoles(
      $userId: String!,
      $organizationRolesToAdd: [OrganizationMemberOrganizationRoleInput!],
      $organizationRolesToDelete: [OrganizationMemberOrganizationRoleInput!],
      $applicationRolesToAdd: [OrganizationMemberApplicationRoleInput!],
      $applicationRolesToDelete: [OrganizationMemberApplicationRoleInput!],
  ) {
    updateOrganizationMemberRoles(
      input: {
        applicationRolesToDelete: $applicationRolesToDelete
        applicationRolesToAdd: $applicationRolesToAdd
        organizationRolesToDelete: $organizationRolesToDelete
        organizationRolesToAdd: $organizationRolesToAdd
        userId: $userId
      }
    ) {
      userId
      errors {
        ... on UpdateOrgMemberRolesNotFound {
            message
            userId
        }
        ... on UpdateOrgMemberRolesBadRequest {
            message
            errorDetails {
                key
                value
            }
        }
        ... on UpdateOrgMemberRolesUnknownError {
            message
        }
    }
    }
  }
`;

export const GET_MEMBER_DETAILS_ORGANIZATION = gql`
  query GetMemberDetailsOrganization {
    organization {
      applications(first: 100) {
        totalCount
        nodes {
          id
          name
          displayName
          organizationId
          state
          systemId
          productCode
        }
      }
    }
  }
`;

export const GET_MEMBER_DETAILS_USER = gql`
  query GetMemberDetailsUser {
    user {
      applications(first: 100) {
        totalCount
        nodes {
          id
          name
          displayName
          organizationId
          state
          systemId
          productCode
          appearance {
            web {
              actions(first: 100) {
                nodes {
                  icon {
                    src
                    type
                  }
                  name
                  displayName
                  color
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_MEMBER_DETAILS_PRODUCTS = gql`
  query GetMemberDetailsProducts {
    products {
      roles(first: 100) {
        totalCount
        nodes {
          productCode
          name
        }
      }
    }
  }
`;

export const MEMBER_FRAGMENT = gql`
  fragment Member on OrganizationMember {
    familyName
    givenName
    email
    lastLogin
  }
`;
