import {
  Flex,
  Heading,
  Button,
  IconButton,
  Text,
  Link,
} from '@chakra-ui/react';
import { mdiLinkVariant } from '@mdi/js';
import { PortalIcon } from 'features/common/components/PortalIcon';
import { ImageWithSkeleton } from 'features/marketplace/shared/components';
import { Presentation, Application } from 'features/marketplace/shared/types';

type HeaderProps = Pick<Presentation, 'iconUrl'> & Pick<Application, 'name'>;

export const Header = ({ name, iconUrl }: HeaderProps) => (
  <Flex data-testid='app-details-header' justifyContent='space-between'>
    <Flex gap={3} data-testid='app-details-header-icon-container'>
      <ImageWithSkeleton
        src={iconUrl ?? ''}
        w='80px'
        minW='80px'
        alt='data.appName-icon'
      />
      <Flex
        flexDir='column'
        gap={1}
        data-testid='app-details-header-app-name-container'
        justifyContent='center'
      >
        <Flex gap={1} alignItems='center'>
          <Heading as='h1' data-testid='app-details-header-app-name'>
            {name}
          </Heading>
          <IconButton
            size='sm'
            variant='ghost'
            // eslint-disable-next-line no-empty-function
            onClick={() => {}}
            icon={<PortalIcon path={mdiLinkVariant} />}
            aria-label='Edit'
            data-testid='app-details-header-link-to-app-details'
          />
        </Flex>
        <Text
          fontSize='x-small'
          variant='subtle'
          data-testid='app-details-header-disclaimer'
        >
          by{' '}
          <Link
            isExternal
            href='https://doc.sitecore.com/'
            data-testid='app-details-header-disclaimer-link'
          >
            Sitecore
          </Link>
        </Text>
      </Flex>
    </Flex>
    <Button
      variant='solid'
      flexShrink={0}
      data-testid='install-application-button'
      data-behavior-analytics-id='install application'
    >
      Install
    </Button>
  </Flex>
);
