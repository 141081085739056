import { useToast } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { useFormik } from 'formik';
import { useQueryClient } from 'react-query';
import { ContextErrors, updateHostnameSchema } from '../utils';
import { EdgeApiError } from '../types';
import { useHistory, useParams } from 'react-router';
import { customHostnamesRoute } from '../routes';
import { useUpdateHostname } from './useUpdateHostname';
import { useGetHostname } from './useGetHostname';
import { useMemo } from 'react';

type UpdateValues = {
  defaultSiteId: string;
  defaultContextId: string;
};

const defaultValues: UpdateValues = {
  defaultSiteId: '',
  defaultContextId: '',
};

export const useHostnameEditForm = () => {
  const toast = useToast();
  const history = useHistory();
  const { hostname } = useParams<{ hostname: string }>();
  const queryClient = useQueryClient();

  const onError = (error: AxiosError<EdgeApiError>) => {
    toast({
      description: error?.response?.data?.detail ?? 'Something went wrong',
      title: 'Error',
      status: 'error',
    });
  };

  const {
    data,
    isLoading: isHostnameLoading,
    isFetching,
    //@ts-expect-error
  } = useGetHostname(hostname, { onError });
  const { defaultContextId, defaultSiteId } = useMemo(
    () => data?.data ?? defaultValues,
    [data?.data],
  );
  const { mutate: updateHostname, isLoading: isUpdateHostnameLoading } =
    useUpdateHostname(hostname);
  const {
    touched,
    values,
    errors,
    handleChange,
    handleSubmit,
    handleBlur,
    setSubmitting,
    isValid,
    isValidating,
    isSubmitting,
    dirty,
    initialValues,
  } = useFormik({
    initialValues: { defaultContextId, defaultSiteId },
    enableReinitialize: true,
    onSubmit: (values, { setFieldError }) => {
      const options = {
        onSuccess: () => {
          queryClient.invalidateQueries('hostnames/list');
          toast({
            description: 'Hostname has been updated',
            status: 'success',
          });
          setSubmitting(false);
          history.push({
            pathname: customHostnamesRoute,
            search: history.location.search,
          });
        },
        onError: (error: AxiosError<EdgeApiError>) => {
          const fieldError = ContextErrors.includes(
            error?.response?.data?.detail ?? '',
          )
            ? 'defaultContextId'
            : '';
          setFieldError(fieldError, error?.response?.data?.detail);
          toast({
            description: 'Something went wrong',
            title: 'Error',
            status: 'error',
          });
          setSubmitting(false);
        },
      };

      //@ts-ignore
      updateHostname(values, options);
    },
    validationSchema: updateHostnameSchema,
  });

  return {
    touched,
    values,
    errors,
    handleChange,
    handleSubmit,
    handleBlur,
    setSubmitting,
    isValid,
    isValidating,
    isSubmitting,
    isFetching,
    isLoading:
      isValidating ||
      isSubmitting ||
      isUpdateHostnameLoading ||
      isHostnameLoading,
    dirty,
    initialValues,
  };
};
