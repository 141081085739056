import { Box, BoxProps } from '@chakra-ui/react';

export const LayoutMain = ({ children, ...rest }: BoxProps) => {
  return (
    <Box
      as='main'
      order={2}
      background='chakra-body-bg'
      p={6}
      flexGrow={1}
      flexShrink={1}
      overflow='auto'
      {...rest}
    >
      {children}
    </Box>
  );
};
