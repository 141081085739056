import { handleGuestIdVerificationAndIdentityDispatch } from './personalize-sdk';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { env } from 'features/common/config/envConfig';
import { Environment } from '@sitecore-ui/portal-singular';

export const PersonalizeTracking = () => {
  const location = useLocation();

  useEffect(() => {
    const initializeEngage = async () => {
      const settingsProd = {
        pointOfSale: 'default',
        cookieDomain: '.sitecorecloud.io',
      };
      // Personalize settings
      const settings = {
        clientKey: '1cc5231e2655eea6c724058e362f8e2a',
        targetURL: 'https://api-engage-eu.sitecorecloud.io',
        pointOfSale: 'portal-staging.sitecore-staging.cloud',
        cookieDomain: '.sitecore-staging.cloud',
        web_flow_target: 'https://d35vb5cccm4xzp.cloudfront.net',
        javascriptLibraryVersion: '1.4.9',
        cookieExpiryDays: 365,
        forceServerCookieMode: false,
        includeUTMParameters: true,
        webPersonalization: true,
      };

      if (env.toLowerCase() === Environment.production) {
        settings.pointOfSale = settingsProd.pointOfSale;
        settings.cookieDomain = settingsProd.cookieDomain;
      }

      // Load Engage SDK script
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src =
        'https://d1mj578wat5n4o.cloudfront.net/sitecore-engage-v.1.4.1.min.js';
      script.async = true;
      script.onload = async () => {
        window.engage = await (window as any).Engage.init(settings);

        handleGuestIdVerificationAndIdentityDispatch({
          page: location.pathname,
        });
      };

      document.head.appendChild(script);

      return () => {
        document.head.removeChild(script);
      };
    };

    initializeEngage();
  }, []);

  /**
   * Track page view
   */
  useEffect(() => {
    const event = {
      channel: 'WEB',
      language: 'EN',
      currency: 'EUR',
      page: location.pathname,
    };

    if ((window as any).engage) {
      (window as any).engage.pageView(event);
    }
  }, [location]);

  return null;
};
