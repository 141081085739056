import { useToast } from '@chakra-ui/react';
import { history } from 'features/common/history/history';
import { ComponentType } from 'react';
import { useParams } from 'react-router';
import { useGetConnection } from './api';
import { ssoRoute } from './sso-routes';

const withConnectionIdGuard =
  (Component: ComponentType<any>) => (props: any) => {
    const { id } = useParams<{ id: string }>();
    const toast = useToast();
    const onError = (req: any) => {
      const {
        response: { data: error },
      } = req;

      toast({
        status: 'error',
        title: `Error ${error.status}`,
        description: error.detail,
      });
      history.replace(`${ssoRoute}${history.location.search}`);
    };
    const { isLoading, isError } = useGetConnection(id, {
      enabled: !!id,
      onError,
    });

    if (isLoading || isError) return null;

    return <Component {...props} />;
  };

export default withConnectionIdGuard;
