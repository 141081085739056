import {
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Button,
  ModalContent,
  ModalOverlay,
  Heading,
  Image,
  Flex,
  Box,
  useMediaQuery,
} from '@chakra-ui/react';
import sitecoreTheme from '@sitecore/blok-theme';

interface INewFeatureAnnouncementModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const NewFeatureAnnouncementModal = ({
  isOpen,
  onClose,
}: INewFeatureAnnouncementModalProps) => {
  const [isBiggerThanSM] = useMediaQuery(
    `(min-width: ${sitecoreTheme.breakpoints.sm})`,
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      data-testid='new-feature-announcement-modal'
      size='2xl'
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton data-behavior-analytics-id='New-Feature-Announcement-Modal-Close' />
        <ModalHeader>
          <Heading variant='section'>New feature</Heading>
          Sitecore Support access
        </ModalHeader>
        <ModalBody>
          <Flex
            alignItems={'center'}
            flexDirection={isBiggerThanSM ? 'row' : 'column-reverse'}
          >
            <Box>
              We've made it easier and faster to get help from the Sitecore
              Support team. With one click, you can now grant Sitecore support
              engineers access to your organization and all its apps to resolve
              technical issues.
            </Box>
            <Image
              boxSize='28'
              mx='16'
              src='https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-lifebuoy'
              alt='keys'
              mb={isBiggerThanSM ? 0 : '2'}
            />
          </Flex>
        </ModalBody>
        <ModalFooter justifyContent={'flex-start'}>
          <ButtonGroup>
            <Button
              data-testid='learn-more-btn'
              variant='solid'
              onClick={() => {
                window.open(
                  'https://doc.sitecore.com/portal/en/developers/sitecore-cloud-portal/index-en.html?contextId=sitecore-support-access',
                  '_blank',
                );
                onClose();
              }}
              data-behavior-analytics-id='New-Feature-Announcement-Modal-LearnMore'
            >
              Learn More
            </Button>
            <Button
              variant='outline'
              onClick={onClose}
              data-testid='dismiss-btn'
              data-behavior-analytics-id='New-Feature-Announcement-Modal-Dismiss'
            >
              Dismiss
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
