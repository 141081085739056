import { getContextApiUrl } from '../utils';
import { useAuthQuery } from '../../common/apiUtils/queryHelper';
import { SitecoreContextType } from '../types/types';

export const useGetSitecoreContext = (sitecoreContextId: string) => {
  const url = `${getContextApiUrl()}/contexts/${sitecoreContextId}`;
  return useAuthQuery<SitecoreContextType>(
    [`contexts/context/${sitecoreContextId}`],
    async (axiosInstance) => {
      const response = await axiosInstance?.get(url);
      return response?.data;
    },
  );
};
