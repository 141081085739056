import { format } from 'date-fns';
import {
  DomainStatusMap,
  Hostname,
  HostnameStatusKeys,
  SslStatusKeys,
  TransformedHostname,
} from './types';
import { Badge, Button, ButtonGroup, IconButton } from '@chakra-ui/react';
import { mdiTrashCanOutline } from '@mdi/js';
import { PortalIcon } from 'features/common/components/PortalIcon';
import * as Yup from 'yup';
import { env } from 'features/common/config/envConfig';
import { history } from 'features/common/history/history';
import { customHostnamesRoute } from './routes';

export const ContextErrors = ['Context not found'];
export const getTableData = (hostnames?: Hostname[]): TransformedHostname[] =>
  hostnames?.map(
    ({
      hostname,
      defaultContextId,
      defaultSiteId,
      created_at: createdAt,
      status: hostnameStatus,
      ssl: { status: sslStatus } = { status: '' },
      id,
    }) => ({
      id,
      hostname,
      defaultContextId,
      defaultSiteId,
      createdAt,
      sslStatus: sslStatus as TransformedHostname['sslStatus'],
      hostnameStatus: hostnameStatus as TransformedHostname['hostnameStatus'],
    }),
  ) ?? [];

export const tableOptions = {
  columns: [
    {
      id: 'hostname',
      width: '14%',
      Header: 'Custom hostname',
      accessor: 'hostname',
      disableSortBy: true,
    },
    {
      id: 'defaultContextId',
      width: '14%',
      Header: 'Default Context ID',
      accessor: ({ defaultContextId }: Partial<TransformedHostname>) =>
        defaultContextId || <Badge colorScheme='neutral'>NOT SPECIFIED</Badge>,
      disableSortBy: true,
    },
    {
      id: 'defaultSiteId',
      width: '14%',
      Header: 'Default site ID',
      accessor: ({ defaultSiteId }: Partial<TransformedHostname>) =>
        defaultSiteId || <Badge colorScheme='neutral'>NOT SPECIFIED</Badge>,
      disableSortBy: true,
    },
    {
      id: 'loginTime',
      width: '14%',
      Header: 'Created',
      accessor: ({ createdAt }: Partial<TransformedHostname>) => {
        if (!createdAt) return '';

        const formattedDate = format(
          new Date(createdAt).getTime(),
          'MMM d, yyyy, h:mm a',
        );
        return formattedDate;
      },
      disableSortBy: true,
    },
    {
      id: 'sslStatus',
      width: '14%',
      Header: 'SSL status',
      accessor: ({ sslStatus }: Partial<TransformedHostname>) => {
        const { text, color } = getStatusByDomain(sslStatus, 'ssl') ?? {
          text: 'NOT SPECIFIED',
          color: 'neutral',
        };
        return <Badge colorScheme={color}>{text}</Badge>;
      },
      disableSortBy: true,
    },
    {
      id: 'hostnameStatus',
      width: '14%',
      Header: 'Hostname status',
      accessor: ({ hostnameStatus }: Partial<TransformedHostname>) => {
        const { text, color } = getStatusByDomain(
          hostnameStatus,
          'hostname',
        ) ?? { text: 'NOT SPECIFIED', color: 'neutral' };
        return <Badge colorScheme={color}>{text}</Badge>;
      },
      disableSortBy: true,
    },
    {
      id: 'hostname-table-buttons',
      Header: '',
      accessor: ({
        hostname,
        hostnameStatus,
        sslStatus,
      }: Partial<TransformedHostname>) => {
        return (
          <ButtonGroup
            flexDir='row'
            justifyContent='flex-end'
            size='sm'
            width='full'
          >
            <Button
              variant='outline'
              aria-label='add records'
              size='sm'
              data-testid='add-records-button'
              display={
                hostnameStatus === 'active' && sslStatus === 'active'
                  ? 'none'
                  : 'inline-flex'
              }
              onClick={() =>
                history.push({
                  pathname: `${customHostnamesRoute}/${hostname}/records`,
                  search: history.location.search,
                })
              }
            >
              Add records
            </Button>
            <Button
              variant='outline'
              aria-label='update hostname'
              size='sm'
              data-testid='update-hostname-button'
              onClick={() =>
                history.push({
                  pathname: `${customHostnamesRoute}/${hostname}/update`,
                  search: history.location.search,
                })
              }
            >
              Update
            </Button>
            <IconButton
              variant='ghostColorOnHover'
              colorScheme='danger'
              aria-label='delete hostname'
              icon={<PortalIcon path={mdiTrashCanOutline} />}
              data-testid='delete-hostname-button'
              onClick={() =>
                history.push({
                  pathname: `${customHostnamesRoute}/${hostname}/delete`,
                  search: history.location.search,
                })
              }
            />
          </ButtonGroup>
        );
      },
      disableSortBy: true,
    },
  ],
};

export const sslStatus = {
  active: {
    text: 'active',
    color: 'success',
  },
  backup_issued: {
    text: 'backup issued',
    color: 'success',
  },
  initializing: {
    text: 'initializing',
    color: 'warning',
  },
  pending_validation: {
    text: 'pending validation',
    color: 'warning',
  },
  pending_issuance: {
    text: 'pending issuance',
    color: 'warning',
  },
  pending_deployment: {
    text: 'pending deployment',
    color: 'warning',
  },
  pending_deletion: {
    text: 'pending deletion',
    color: 'warning',
  },
  pending_expiration: {
    text: 'pending expiration',
    color: 'warning',
  },
  pending_cleanup: {
    text: 'pending cleanup',
    color: 'warning',
  },
  deactivating: {
    text: 'deactivating',
    color: 'warning',
  },
  deleted: {
    text: 'deleted',
    color: 'danger',
  },
  expired: {
    text: 'expired',
    color: 'danger',
  },
  initializing_timed_out: {
    text: 'initializing timed out',
    color: 'danger',
  },
  validation_timed_out: {
    text: 'validation timed out',
    color: 'danger',
  },
  issuance_timed_out: {
    text: 'issuance timed out',
    color: 'danger',
  },
  deployment_timed_out: {
    text: 'deployment timed out',
    color: 'danger',
  },
  deletion_timed_out: {
    text: 'deletion timed out',
    color: 'danger',
  },
  holding_deployment: {
    text: 'holding deployment',
    color: 'danger',
  },
  inactive: {
    text: 'inactive',
    color: 'danger',
  },
};

export const hostnameStatus = {
  active: {
    text: 'active',
    color: 'success',
  },
  active_redeploying: {
    text: 'active redeploying',
    color: 'success',
  },
  provisioned: {
    text: 'provisioned',
    color: 'success',
  },
  pending: {
    text: 'pending',
    color: 'warning',
  },
  pending_deletion: {
    text: 'pending deletion',
    color: 'warning',
  },
  pending_blocked: {
    text: 'pending blocked',
    color: 'warning',
  },
  pending_migration: {
    text: 'pending migration',
    color: 'warning',
  },
  moved: {
    text: 'moved',
    color: 'danger',
  },
  deleted: {
    text: 'deleted',
    color: 'danger',
  },
  blocked: {
    text: 'blocked',
    color: 'danger',
  },
};

const statusPerDomain: DomainStatusMap = {
  ssl: sslStatus,
  hostname: hostnameStatus,
};

type DomainKey = keyof typeof statusPerDomain;

export const getStatusByDomain = (
  text: SslStatusKeys | HostnameStatusKeys | undefined,
  domain: DomainKey,
) => {
  const status = statusPerDomain[domain];
  return status[text as keyof typeof status];
};

const hostnameRegex =
  /^(?![0-9.]+$)(?!-)(?!.*--)(?!.*\.$)(?!.*-$)(?!.*\.\.)[A-Za-z0-9]+(-[A-Za-z0-9]+)*(\.[A-Za-z0-9]+(-[A-Za-z0-9]+)*)*$/;

const isValidHostname = (hostname: string = '') =>
  hostname.length <= 255 && hostnameRegex.test(hostname);

export const createHostnameSchema = Yup.object({
  hostname: Yup.string()
    .label('Hostname')
    .required()
    .test('hostname', 'Hostname is not valid', isValidHostname),
  defaultSitetId: Yup.string().label('Default site id'),
  defaultContextId: Yup.string().label('Default context id'),
});

export const updateHostnameSchema = Yup.object({
  defaultSitetId: Yup.string().label('Default site id'),
  defaultContextId: Yup.string().label('Default context id'),
});

const cnameEnvironmentMap = {
  dev: 'dev.envoy.sitecore-staging.cloud',
  staging: 'staging.envoy.sitecore-staging.cloud',
  'pre-production': 'sitecoreproxy-staging.io',
  production: ' sitecoreproxy.io',
};

export const getCnamePerEnvironment = () => {
  const lowerCaseEnv = env?.toLowerCase() as keyof typeof cnameEnvironmentMap;
  return cnameEnvironmentMap[lowerCaseEnv];
};
