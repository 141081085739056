import { Heading, Stack, Image, Text } from '@chakra-ui/react';

interface IEmptyTablePlaceholder {
  title: string;
  description?: string;
}

export const EmptyTablePlaceholder = ({
  title,
  description,
}: IEmptyTablePlaceholder) => (
  <Stack pt='40' align='center' spacing='4'>
    <Image
      boxSize='24'
      src='https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-magnify-close-neutral'
      alt='Image of magnifier'
    />
    <Heading size='md'>{title}</Heading>
    <Text variant='subtle'>{description}</Text>
  </Stack>
);
