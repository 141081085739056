import packageInfo from '../../../../package.json';

function resolveUserAgent() {
  const userAgent = {
    'X-Sitecore-User-Agent': `Sitecore Portal/${packageInfo.version}`,
  };

  return {
    userAgent,
  };
}

export { resolveUserAgent };
