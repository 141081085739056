/* eslint-disable no-console */
import { Redirect, useHistory, useRouteMatch } from 'react-router';
import { Switch } from 'react-router-dom';
import { MembersTableWithErrorHandling } from './table/MembersTable';
import { PageNotFound } from 'features/main/components/CustomErrorPages';
import { ApmRoute } from '@elastic/apm-rum-react';
import { MemberDetails } from './table/MemberDetails';
import { MemberRemoveConfirmation } from './table/components/MemberRemoveConfirmation';
import { useRef, useState } from 'react';

const MembersContainer = () => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const [, setMutationCompleted] = useState(false);
  const dataTableRef = useRef(null);

  return (
    <>
      <Switch>
        <ApmRoute
          exact
          path={path}
          component={() => (
            <Redirect to={`${path}/page/1${history.location.search}`} />
          )}
        />
        <ApmRoute
          exact
          path={`${path}/page/:page/details/:id`}
          render={(props) => <MemberDetails {...props} />}
        />
        <ApmRoute
          path={`${path}/page/:page`}
          render={(props) => <MembersTableWithErrorHandling {...props} />}
        />
        <ApmRoute component={PageNotFound} />
      </Switch>
      <ApmRoute
        exact
        path={`${path}/page/:page/remove/:id`}
        render={(props) => (
          <MemberRemoveConfirmation
            {...props}
            isOpen
            onClose={() => {
              setMutationCompleted(true);
              if (dataTableRef.current as any) {
                (dataTableRef.current as any)?.setPage(1);
              }
              setTimeout(() => {
                history.push(`${path}/page/1${history.location.search}`);
              }, 0);
            }}
          />
        )}
      />
    </>
  );
};

export default MembersContainer;
