import { rest } from 'msw';
import {
  generateMockApplicationListResponse,
  generateMockApplications,
} from './data/Application';
import { Application } from '../../shared/types';

const baseUrl = 'https://marketplace-api-staging.sitecore-staging.cloud';
const baseApiUrl = `${baseUrl}/api/v1`;
const DELAY = 800;

export const handlers = [
  rest.get(`${baseApiUrl}/Applications`, async (req, res, ctx) => {
    const code = parseInt(req.url.searchParams.get('errorCode') || '200');

    await new Promise((resolve) => setTimeout(resolve, DELAY));

    if (code !== 200) {
      return res(ctx.status(code));
    }

    return res(
      ctx.delay(DELAY),
      ctx.json(generateMockApplicationListResponse(7)),
    );
  }),

  rest.get(`${baseApiUrl}/Applications/:id`, async (req, res, ctx) => {
    const code = parseInt(req.url.searchParams.get('errorCode') || '200');

    await new Promise((resolve) => setTimeout(resolve, DELAY));

    if (code !== 200) {
      return res(ctx.status(code));
    }

    const app = generateMockApplications(1)[0];
    app.id = req.params.id as string;
    return res(ctx.delay(DELAY), ctx.json(app));
  }),

  rest.post<Application>(
    `${baseApiUrl}/Applications`,
    async (req, res, ctx) => {
      const code = parseInt(req.url.searchParams.get('errorCode') || '200');

      await new Promise((resolve) => setTimeout(resolve, DELAY));

      if (code !== 200) {
        return res(ctx.status(code));
      }

      const newApp = {
        ...generateMockApplications(1)[0],
        ...req.body,
        id: crypto.randomUUID(),
      };
      return res(ctx.delay(DELAY), ctx.status(201), ctx.json(newApp));
    },
  ),

  rest.put<Application>(
    `${baseApiUrl}/Applications/:id`,
    async (req, res, ctx) => {
      const code = parseInt(req.url.searchParams.get('errorCode') || '200');

      await new Promise((resolve) => setTimeout(resolve, DELAY));

      if (code !== 200) {
        return res(ctx.status(code));
      }

      return res(
        ctx.delay(DELAY),
        ctx.json({
          ...req.body,
          id: req.params.id,
        }),
      );
    },
  ),

  rest.delete(`${baseApiUrl}/Applications/:id`, async (req, res, ctx) => {
    const code = parseInt(req.url.searchParams.get('errorCode') || '200');

    await new Promise((resolve) => setTimeout(resolve, DELAY));

    if (code !== 200) {
      return res(ctx.status(code));
    }

    return res(ctx.delay(DELAY), ctx.status(204));
  }),
];
