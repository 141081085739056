import { Switch, useRouteMatch } from 'react-router-dom';
import { PageNotFound } from 'features/main/components/CustomErrorPages';
import MultifactorAuthentication from 'features/multifactor-authentication/MultifactorAuthentication';
import { SSO } from 'features/sso';
import { ApmRoute } from '@elastic/apm-rum-react';
import { LayoutMain, LayoutWrapper } from 'features/common/components/Layouts';
import { useFeatures } from 'lib/featureFlags/features';
import AdminSideNav from './AdminSideNav';
import MembersContainer from 'features/members/components/MembersContainer';
import {
  contextMappingPath,
  customHostnamesPath,
  invitesPath,
  membersPath,
  mfaPath,
  ssoPath,
  supportAccessPath,
} from './admin-paths';
import InvitesContainer from 'features/members/components/InvitesContainer';
import { useMediaQuery } from '@chakra-ui/react';
import sitecoreTheme from '@sitecore/blok-theme';
import SupportAccess from 'features/supportAccess/SupportAccess';
import ContextMappingContainer from 'features/context-mapping/components/ContextMappingContainer';
import { CustomHostnamesContainer } from 'features/custom-hostnames';

const AdminContainer = () => {
  const { path } = useRouteMatch() || {};
  const features = useFeatures();

  const [isBiggerThanMd] = useMediaQuery(
    `(min-width: ${sitecoreTheme.breakpoints.md})`,
  );

  return (
    <LayoutWrapper flexDirection={'row'}>
      {isBiggerThanMd && <AdminSideNav />}
      <LayoutMain layerStyle='section.main'>
        <Switch>
          <ApmRoute path={`${path}${membersPath}`}>
            <MembersContainer />
          </ApmRoute>
          <ApmRoute path={`${path}${invitesPath}`}>
            <InvitesContainer />
          </ApmRoute>
          <ApmRoute
            path={`${path}${mfaPath}`}
            render={() =>
              features.MFA ? <MultifactorAuthentication /> : <PageNotFound />
            }
          />
          <ApmRoute
            path={`${path}${ssoPath}`}
            render={() => (features.SSO ? <SSO /> : <PageNotFound />)}
          />
          <ApmRoute
            path={`${path}${supportAccessPath}`}
            render={() =>
              features.SUPPORT_ACCESS ? <SupportAccess /> : <PageNotFound />
            }
          />
          <ApmRoute
            path={`${path}${contextMappingPath}`}
            render={() =>
              features.CONTEXT_MAPPING ? (
                <ContextMappingContainer />
              ) : (
                <PageNotFound />
              )
            }
          />
          <ApmRoute
            path={`${path}${customHostnamesPath}`}
            render={() =>
              features.CUSTOM_HOSTNAMES ? (
                <CustomHostnamesContainer />
              ) : (
                <PageNotFound />
              )
            }
          />
          <ApmRoute component={PageNotFound} />
        </Switch>
      </LayoutMain>
    </LayoutWrapper>
  );
};

export default AdminContainer;
