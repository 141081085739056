import { Link } from '@chakra-ui/react';
import { GenericErrorTemplate } from 'features/common/components/GenericErrorTemplate';

export const PageNotFound = () => (
  <GenericErrorTemplate
    title='Page not found'
    subtext='Error 404'
    text='The page you are looking for cannot be found'
    variant='not-found'
    shortcuts={
      <>
        <Link
          onClick={() => (window.location.href = window.location.origin)}
          mb={4}
        >
          Go to Homepage
        </Link>
        <Link mb={4} href='https://support.sitecore.com/kb' isExternal>
          Visit Knowledge Base
        </Link>
      </>
    }
  />
);
