import { useState } from 'react';
import { mdiArrowLeft } from '@mdi/js';
import {
  Box,
  Text,
  Button,
  Heading,
  Skeleton,
  CircularProgress,
  HStack,
  Flex,
  useToast,
} from '@chakra-ui/react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import {
  filterRolesForOrganization,
  selectAccessGlobalDropdownOptions,
} from './utils/memberDetailsUtils';
import { MemberDetailsHeader } from './components/MemberDetailsHeader';
import {
  ESelectAccessOptions,
  ISelectOption,
} from './utils/memberDetailsModel';
import { MemberDetailsProductAccess } from './components/MemberDetailsProductAccess';
import { MemberRemoveConfirmation } from './components/MemberRemoveConfirmation';
import OrganizationAccessCard from 'features/common/components/Access/UserAccessCard';
import { useAppAccess } from 'features/common/components/OrganizationAppAccess';
import { PortalIcon } from 'features/common/components/PortalIcon';
import { Select as ReactSelect, SingleValue } from 'chakra-react-select';
import {
  useGetOrganizationMemberQuery,
  useUpdateOrganizationMemberRolesMutation,
} from 'gql/graphql';
import { flatten } from 'lodash';
import { IMemberRole } from 'features/members/services/identity/membersModel';
import { GET_ORGANIZATION_MEMBER } from './members-queries';
import { ORG_ROLES } from 'features/common/components/OrganizationAppAccess/resource';

interface RouteParamProps {
  id: string;
  page: string;
}

export interface IMemberDetailsProps
  extends RouteComponentProps<RouteParamProps> {}

export const MemberDetails = (props: IMemberDetailsProps) => {
  const userId = props.match.params.id;
  const history = useHistory();
  const toast = useToast();
  const [isUpdating, setIsUpdating] = useState(false);

  const [updateOrganizationMemberRolesMutation] =
    useUpdateOrganizationMemberRolesMutation();

  const {
    data: memberData,
    loading: singleMemberLoading,
    error: isError,
  } = useGetOrganizationMemberQuery({ variables: { id: userId } });

  const goBack = (meta?: { from: 'member-details' }) => {
    history.push(
      `/admin/members/page/${props.match.params.page}${history.location.search}`,
      meta,
    );
  };

  if (isError) {
    goBack();
  }

  const { hasOrgOwnerAccess } = useAppAccess();
  const organizationId = memberData?.organization?.id || '';
  const { memberById } = memberData?.organization || { organization: {} };
  const { memberActions } = memberById?.appearance || {
    memberActions: undefined,
  };
  const {
    givenName,
    familyName,
    jobRole,
    email,
    phoneNumber,
    lastLogin,
    picture,
    organizationRoles,
    applications,
  } = memberById || {};
  const applicationNodes = applications?.nodes || [];
  const orgRole = organizationRoles?.nodes?.filter(
    (o) => o.organizationId === organizationId,
  )[0];

  const selectedOrgRole = orgRole
    ? selectAccessGlobalDropdownOptions[orgRole.name]
    : selectAccessGlobalDropdownOptions['Organization User'];

  const allRoles = flatten(
    applicationNodes.map(
      (node) =>
        node.assignedRoles?.nodes &&
        node.assignedRoles?.nodes?.map(
          (n) =>
            ({
              organizationId: n.organizationId,
              scope: n.productCode,
              tenantId: n.applicationId,
              role: n.role,
            } || []),
        ),
    ),
  ) as IMemberRole[];

  const memberName = `${givenName} ${familyName}`;
  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);

  const accessSelectOptions = hasOrgOwnerAccess
    ? Object.values(selectAccessGlobalDropdownOptions)
    : Object.values(selectAccessGlobalDropdownOptions).filter(
        (r) => r.value !== ESelectAccessOptions.owner,
      );

  /**
   * on change organization handler
   * @param newAccess
   * @returns void
   */
  const changeOrganizationAccess = async (
    newAccess: SingleValue<ISelectOption>,
  ) => {
    if (orgRole) {
      setIsUpdating(true);

      try {
        const { data } = await updateOrganizationMemberRolesMutation({
          variables: {
            organizationRolesToDelete: [
              {
                roleName: orgRole.name,
              },
            ],
            organizationRolesToAdd: [
              { roleName: `Organization ${newAccess?.role}` },
            ],
            userId: userId,
          },
          refetchQueries: [
            { query: GET_ORGANIZATION_MEMBER, variables: { id: userId } },
          ],
        });
        setIsUpdating(false);

        const error = data?.updateOrganizationMemberRoles.errors?.[0];
        if (error) {
          return toast({
            description: error?.message,
            title: 'Error',
            status: 'error',
          });
        }
      } catch (error: any) {
        const { networkError } = error ?? {};
        toast({
          description: networkError.message,
          title: `Error ${networkError.statusCode}`,
          status: 'error',
        });
      }
    }
  };

  const getOrganisationAccessReusedProps = () => ({
    formatOptionLabel: ({ role, description }: ISelectOption) => (
      <span data-testid={`option-${role}`}>
        <OrganizationAccessCard role={role} description={description} />
      </span>
    ),
    name: 'react-select',
  });

  const isSkeletonLoaded = !singleMemberLoading || Boolean(isError);

  const memberActionsList =
    memberActions?.nodes && memberActions?.nodes.map((node) => node.name);

  const hasRemoveAccess = memberActionsList?.includes('Remove access');

  return (
    <Box data-behavior-analytics-feature='member details page'>
      {/* Top Row */}
      <Flex w='100%' justifyContent='space-between'>
        <Button
          variant='ghost'
          alignItems='center'
          onClick={() => goBack()}
          data-testid='back-button'
          size='sm'
        >
          <PortalIcon path={mdiArrowLeft} mr='2' />
          Back to team members
        </Button>
        <Skeleton isLoaded={isSkeletonLoaded}>
          {hasRemoveAccess && (
            <Button
              variant='outline'
              colorScheme='danger'
              onClick={() => {
                setShowRemoveModal(true);
              }}
              data-testid='remove-user-button'
              size='sm'
            >
              Remove access
            </Button>
          )}
        </Skeleton>
      </Flex>
      {/* header */}
      <MemberDetailsHeader
        isLoading={singleMemberLoading}
        memberName={memberName}
        memberDetails={{
          jobRole,
          email,
          phoneNumber,
          picture,
        }}
        lastLogin={lastLogin}
      />
      {/* organization access */}
      <Heading size='md' mb='4'>
        Organization access
      </Heading>
      <HStack mb='8' w='100%'>
        <Box width='sm' data-testid='org-access-box'>
          <Skeleton isLoaded={isSkeletonLoaded}>
            <ReactSelect
              selectedOptionColorScheme='primary'
              useBasicStyles
              value={selectedOrgRole}
              onChange={changeOrganizationAccess}
              options={accessSelectOptions}
              isDisabled={isUpdating || !hasRemoveAccess}
              {...getOrganisationAccessReusedProps()}
            />
          </Skeleton>
        </Box>
        <Box width='36' ml='4'>
          {isUpdating && <CircularProgress isIndeterminate size='5' />}
        </Box>
      </HStack>
      <Box>
        <Heading size='md' mb='4'>
          App access
        </Heading>
        {!isSkeletonLoaded || isUpdating ? (
          <Skeleton maxWidth='sm' height='10' />
        ) : (
          <>
            {orgRole?.name === ORG_ROLES.ORG_OWNER && (
              <Text variant='subtle' data-testid='owner-access-wrapper'>
                You are granting ownership rights to this organization. <br />
                This includes access to all apps, the admin section and
                management of all owners.
              </Text>
            )}
            {orgRole?.name === ORG_ROLES.ORG_ADMIN && (
              <Text variant='subtle' data-testid='admin-access-wrapper'>
                You are granting admin rights to this organization. <br />
                This includes access to all apps and the admin section.
              </Text>
            )}
            {orgRole?.name === ORG_ROLES.ORG_USER && (
              <MemberDetailsProductAccess
                roles={filterRolesForOrganization(organizationId, allRoles)}
                userId={userId}
              />
            )}
          </>
        )}
      </Box>
      {/* Delete confirmation modal */}
      <MemberRemoveConfirmation
        isOpen={!!hasRemoveAccess && showRemoveModal}
        userId={userId}
        name={givenName || ''}
        onClose={() => setShowRemoveModal(false)}
        onCompleted={() => {
          setShowRemoveModal(false);
          setTimeout(() => {
            history.push(`/admin/members/page/1${history.location.search}`);
          }, 0);
        }}
      />
    </Box>
  );
};
