/* eslint-disable no-console */
export class LocalStorageValue<T> {
  instances: Map<string, LocalStorageValue<any>> = new Map();
  key: string;

  constructor(key: string) {
    this.key = key;
  }

  public static instances: Map<string, LocalStorageValue<any>> = new Map();

  public static getInstance<T>(key: string): LocalStorageValue<T> {
    if (!LocalStorageValue.instances.has(key)) {
      LocalStorageValue.instances.set(key, new LocalStorageValue<T>(key));
    }

    return LocalStorageValue.instances.get(key) as LocalStorageValue<T>;
  }

  get(label: string): T | null {
    const retrievedValue = localStorage.getItem(this.key);

    if (retrievedValue) {
      try {
        const localStorageEntry = JSON.parse(retrievedValue);
        return localStorageEntry[label] as T;
      } catch (e) {
        //if there is an issue with reading the value return null
        console.error('Cannot parse ', retrievedValue, ' from localStorage');
        return null;
      }
    }

    return null;
  }

  set(label: string, value: T) {
    const retrievedValue = localStorage.getItem(this.key) || null;
    try {
      const localStorageEntry =
        (retrievedValue && JSON.parse(retrievedValue)) || {};
      const newLocalStorageEntry = { ...localStorageEntry, [label]: value };
      localStorage.setItem(this.key, JSON.stringify(newLocalStorageEntry));
    } catch (e) {
      //if there is an issue with setting the value return null
      console.error('Cannot parse ', retrievedValue, ' from localStorage');
      return null;
    }
  }

  remove() {
    localStorage.removeItem(this.key);
  }
}
