import { useAuthMutation } from 'features/common/apiUtils/queryHelper';
import { connectionsApi } from './endpoints';
import { getIdentityBaseUrl } from '@sitecore-ui/portal-singular';
import { env } from 'features/common/config/envConfig';

const baseUrl = getIdentityBaseUrl(env.toLowerCase());

export const useActivateConnection = () => {
  const { activate_connection } = connectionsApi;
  const url = (id: string) => `${baseUrl}${activate_connection.url(id)}`;

  return useAuthMutation(
    (axiosInstance) =>
      ({ id }: { id: string }) =>
        axiosInstance?.post(url(id)),
    {},
    activate_connection.scope,
  );
};
