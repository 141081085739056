import { TenantsDrawer } from '../../../home/components/homepage/TenantsDrawer/TenantsDrawer';
import { InviteDetailsDrawer } from '../../../members/components/table/InviteDetailsDrawer';
import { InviteDrawer } from '../../../members/components/table/InviteDrawer';
import AddConnectionDrawer from 'features/sso/add-connection-drawer';
import { HelpDrawerController } from 'features/main/components/HelpDrawerController';
import SSOSamlConnectionModal from 'features/sso/saml/sso-saml-connection-modal';
import { TryDiscoverDrawer } from 'features/try-apps/TryConnectDrawer';

type DrawerId = keyof typeof DrawerMap;
// TODO We need to decouple feature state from the drawer, as we do with SNOW
type DrawerState = {
  drawerVisible: boolean;
  drawerId: string;
  data?: {
    connectionId?: string;
    ssoProvider?: string;
    [key: string]: any;
  } | null;
};
type DrawerAction = { type: string; payload?: any };

const DrawerMap = {
  'tenant-drawer': TenantsDrawer,
  'help-drawer': HelpDrawerController,
  'invite-details-drawer': InviteDetailsDrawer,
  'invite-drawer': InviteDrawer,
  'add-sso-connection-drawer': AddConnectionDrawer,
  'create-support-case-drawer': () => null,
  'sso-saml-connection-modal': SSOSamlConnectionModal,
  'try-connect': TryDiscoverDrawer,
  default: () => null,
  null: () => null,
};

export { DrawerId, DrawerState, DrawerAction, DrawerMap };
