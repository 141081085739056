import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Heading,
  ModalBody,
  Flex,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  useDisclosure,
  Input,
  Skeleton,
  Text,
  InputGroup,
  InputRightElement,
  useToast,
  FormHelperText,
} from '@chakra-ui/react';
import { useCallback, useMemo, useRef } from 'react';
import { useHistory, useParams } from 'react-router';
import { customHostnamesRoute } from './routes';
import { useGetHostname } from './hooks/useGetHostname';
import { EdgeApiError, Hostname } from './types';
import { CopyClipboardIconTooltip } from 'features/common/components/CopyClipboardIconTooltip';
import { getCnamePerEnvironment } from './utils';
import { AxiosError } from 'axios';

export const HostnameRecordsModal = () => {
  const { hostname } = useParams<{ hostname: string }>();
  const history = useHistory();

  const toast = useToast();
  const onError = (error: AxiosError<EdgeApiError>) => {
    toast({
      description: error?.response?.data?.detail ?? 'Something went wrong',
      title: 'Error',
      status: 'error',
    });
  };
  //@ts-expect-error
  const { data, isFetching } = useGetHostname(hostname, { onError });

  const { onClose } = useDisclosure();
  const onCloseDrawer = useCallback(() => {
    history.push({
      pathname: customHostnamesRoute,
      search: history.location.search,
    });
    onClose();
  }, [history, onClose]);

  const { ssl, ownership_verification } = useMemo(
    () => (data?.data as Hostname) || { ssl: {}, ownership_verification: {} },
    [data?.data],
  );

  const { name: hostnameValidationTXTName, value: hostnameValidationTXTValue } =
    ownership_verification ?? {};
  const { txt_name: sslValidationTXTName, txt_value: sslValidationTXTValue } =
    ssl?.validation_records?.[0] ?? {};
  const cnameValue = getCnamePerEnvironment();

  const initialFocusRef = useRef<HTMLInputElement>(null);

  return (
    <Modal
      isOpen={true}
      onClose={onCloseDrawer}
      data-onCloseDrawer='hostname-records-modal'
      size='2xl'
      autoFocus={false}
      initialFocusRef={initialFocusRef}
    >
      <ModalOverlay />
      <ModalContent data-testid='hostname-records-modal'>
        <ModalHeader>
          <Heading
            as='h4'
            size='md'
            data-testid='hostname-records-modal-header'
          >
            Hostname Records
          </Heading>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <Text data-testid='hostname-records-details' mb={8}>
            Copy the TXT record and SSL record and paste them in to your DNS
            records. After adding the DNS records, hostname will activate
            shortly.
          </Text>
          <form data-testid='hostname-records-form' id='hostname-records-form'>
            <Flex direction='column' gap={8}>
              {hostnameValidationTXTValue || isFetching ? (
                <Flex direction='column'>
                  <Text fontWeight='bold' mb={2}>
                    TXT hostname validation
                  </Text>
                  <Flex gap={4}>
                    <ValidationInput
                      label='Name'
                      value={hostnameValidationTXTName}
                      isLoading={isFetching}
                      dataTestId='hostname-txt-name-input'
                    />
                    <ValidationInput
                      label='Value'
                      value={hostnameValidationTXTValue}
                      isLoading={isFetching}
                      dataTestId='hostname-txt-value-input'
                    />
                  </Flex>
                </Flex>
              ) : null}
              {sslValidationTXTValue || isFetching ? (
                <Flex direction='column'>
                  <Text fontWeight='bold' mb={2}>
                    TXT SSL validation
                  </Text>
                  <Flex gap={4}>
                    <ValidationInput
                      label='Name'
                      value={sslValidationTXTName}
                      isLoading={isFetching}
                      dataTestId='label-ssl-txt-name'
                    />
                    <ValidationInput
                      label='Value'
                      value={sslValidationTXTValue}
                      isLoading={isFetching}
                      dataTestId='label-ssl-txt-value'
                    />
                  </Flex>
                </Flex>
              ) : null}
              <Flex direction='column'>
                <Text fontWeight='bold' mb={2}>
                  CNAME
                </Text>
                <Flex gap={4}>
                  <ValidationInput
                    label='Name'
                    value={hostname}
                    helperText='(also known as alias, host or label)'
                    dataTestId='label-cname-name'
                  />
                  <ValidationInput
                    label='Value'
                    value={cnameValue}
                    helperText='(also known as target, destination or answer)'
                    dataTestId='label-cname-value'
                  />
                </Flex>
              </Flex>
            </Flex>
          </form>
        </ModalBody>
        <ModalFooter justifyContent='flex-end' alignItems='flex-end'>
          <Button
            type='button'
            variant='solid'
            onClick={onCloseDrawer}
            isDisabled={isFetching}
            data-testid='hostname-records-cancelButton'
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

type ValidationInputProps = {
  label: string;
  value: string;
  isLoading?: boolean;
  helperText?: string;
  dataTestId?: string;
};
export const ValidationInput = ({
  label,
  value,
  isLoading = false,
  helperText = '',
  dataTestId = 'validation-input',
}: ValidationInputProps) => (
  <FormControl>
    <FormLabel data-testid={`${dataTestId}-label`}>{label}</FormLabel>
    <Skeleton isLoaded={!isLoading}>
      <InputGroup>
        <Input
          name='hostname-txt-name'
          value={value}
          isReadOnly
          data-testid={dataTestId}
        />
        <InputRightElement>
          <CopyClipboardIconTooltip
            inputValue={value}
            dataTestId={`${dataTestId}-tooltip`}
          />
        </InputRightElement>
      </InputGroup>
      <FormHelperText>{helperText}</FormHelperText>
    </Skeleton>
  </FormControl>
);
