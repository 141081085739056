import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, ReactNode, useMemo } from 'react';
import { history } from '../../common/history/history';

type ErrorControllerProps = {
  children?: ReactNode;
};

const ErrorController = ({ children }: ErrorControllerProps) => {
  const urlParams = useMemo(
    () => new URLSearchParams(window.location.search),
    [],
  );
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    const error = urlParams.get('error');
    const errorDescription = urlParams.get('error_description')?.toLowerCase();
    const redirectToErrorScreen = ({
      pathname,
      descriptionMap,
    }: {
      pathname: string;
      descriptionMap: string[];
    }) => {
      const shouldRedirectToCustomPath = descriptionMap.find((description) =>
        errorDescription?.includes(description),
      );
      return history.push({
        pathname: shouldRedirectToCustomPath
          ? pathname
          : '/something-went-wrong',
        search: '',
      });
    };

    switch (error) {
      case 'unauthorized':
        redirectToErrorScreen({
          pathname: '/no-organization-access',
          descriptionMap: [
            'not_member_of_org',
            'user is blocked',
            'Support access is not enabled for the organization.',
            'Support access is not enabled for the organization',
            'support access is not enabled for the organization',
          ],
        });
        break;
      case 'invalid_request':
        redirectToErrorScreen({
          pathname: '/no-valid-invitation',
          descriptionMap: ['invitation not found or already used'],
        });
        break;
      default:
        if (error)
          history.push({ pathname: '/something-went-wrong', search: '' });
        break;
    }
  }, [loginWithRedirect, urlParams]);

  return <>{children}</>;
};

export default ErrorController;
