import { useAuth0 } from '@auth0/auth0-react';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

interface AuthQueryOptions<TData, TError>
  extends Omit<UseQueryOptions<TData, TError>, 'queryFn'> {
  url: string;
  method?: 'GET' | 'DELETE';
}

export function useAuthQuery<TData = unknown, TError = unknown>(
  options: AuthQueryOptions<TData, TError>,
): UseQueryResult<TData, TError> {
  const { getAccessTokenSilently } = useAuth0();

  const queryFn = async (): Promise<TData> => {
    const token = await getAccessTokenSilently();

    const { url, method = 'GET' } = options;

    const response = await fetch(url, {
      method,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      const error = new Error(errorData?.message || 'An error occurred');
      (error as any).status = response.status; // Add status to the error object
      throw error;
    }

    return response.json();
  };

  return useQuery<TData, TError>({
    ...options,
    queryFn,
  });
}
