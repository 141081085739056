import { useGetSitecoreContext } from '../api/useGetSitecoreContext';
import { useToast } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { SitecoreContextResourceType } from '../types/types';
import { useMainState } from '../../main/context';
import { useCreateSitecoreContextResource } from '../api/useCreateSitecoreContextResource';
import { useCreateSitecoreContext } from '../api/useCreateSitecoreContext';
import { useHistory, useParams } from 'react-router';
import { PRODUCT_CODES } from '../utils';
import { useQueryClient } from 'react-query';
import { useGetListSitecoreContexts } from '../api/useGetListSitecoreContexts';
import {
  findContextResourceDataByTenantIdAndResourceType,
  findEdgeTenantId,
  findTenantDataByTenantId,
  findTenantId,
  getContextCdpPersonalizeResource,
  getContextSearchResource,
  getSelectEdgeEndpointOptions,
  getSelectOptions,
  mutateOptions,
} from './utils/utils';
import { useRouteMatch } from 'react-router-dom';
import { getCtxMappingEditRoute } from '../routes/ContextMappingDrawerRoute';
import { useFetchTenantsData } from './useFetchTenantsData';

function useAddEditContextMapping() {
  const history = useHistory();
  const toast = useToast();
  const queryClient = useQueryClient();
  const { withXmCloudContext } = useMainState();
  const isWithXmCloudContext = withXmCloudContext || false;

  // if path matches /admin/context-mapping/:id/edit, then isInEditMode is true
  const isInEditMode = Boolean(useRouteMatch(getCtxMappingEditRoute()));
  const { sitecoreContextId: sitecoreContextId } = useParams<{
    sitecoreContextId: string;
  }>();

  const { tenantsData, isLoading } = useFetchTenantsData();

  const {
    data: contextsData,
    isLoading: isGetListSitecoreContextsLoading,
    isFetched: isGetListSitecoreContextsFetched,
  } = useGetListSitecoreContexts();

  const {
    data: selectedSitecoreContext,
    isLoading: isGetSitecoreContextLoading,
    isFetched: isGetSitecoreContextFetched,
  } = useGetSitecoreContext(sitecoreContextId);

  const {
    mutate: createSitecoreContextResource,
    isLoading: isCreateSitecoreContextResourceLoading,
  } = useCreateSitecoreContextResource(sitecoreContextId);

  const {
    mutate: createSitecoreContext,
    isLoading: isCreateSitecoreContextLoading,
  } = useCreateSitecoreContext();

  const edgeEndpointSelectOptions = getSelectEdgeEndpointOptions(
    contextsData || [],
    tenantsData,
  );
  const cdpPersonalizeTenantSelectOptions = getSelectOptions(tenantsData, [
    PRODUCT_CODES.CDP,
    PRODUCT_CODES.PERSONALIZE,
    PRODUCT_CODES.PERSONALIZE_EMBEDDED,
  ]);
  const searchTenantSelectOptions = getSelectOptions(tenantsData, [
    PRODUCT_CODES.SEARCH,
  ]);

  const isSelectOptionDisabled = (
    selectedValue: string,
    selectOptions: any[],
  ) =>
    selectOptions.length !== 0
      ? isInEditMode
        ? !!selectedValue
        : false
      : true;
  const isSubmitButtonDisabled = () =>
    isInEditMode
      ? values.isEdgeEndpointDisabled &&
        values.isCdpPersonalizeTenantDisabled &&
        values.isSearchTenantDisabled
      : isWithXmCloudContext
      ? edgeEndpointSelectOptions.length === 0
      : false;

  const getInitialValues = () => {
    if (
      !isLoading &&
      isGetSitecoreContextFetched &&
      isGetListSitecoreContextsFetched
    ) {
      const edgeEndpointInitialValue = findEdgeTenantId(
        [PRODUCT_CODES.EDGE],
        selectedSitecoreContext,
        tenantsData,
      );
      const cdpPersonalizeInitialValue = findTenantId(
        [
          PRODUCT_CODES.CDP,
          PRODUCT_CODES.PERSONALIZE,
          PRODUCT_CODES.PERSONALIZE_EMBEDDED,
        ],
        selectedSitecoreContext,
        tenantsData,
      );
      const searchInitialValue = findTenantId(
        [PRODUCT_CODES.SEARCH],
        selectedSitecoreContext,
        tenantsData,
      );

      return {
        selectedEdgeEndpointValue: edgeEndpointInitialValue,
        selectedCdpPersonalizeTenantValue: cdpPersonalizeInitialValue,
        selectedSearchTenantValue: searchInitialValue,
        isEdgeEndpointDisabled:
          !isWithXmCloudContext ||
          isSelectOptionDisabled(
            edgeEndpointInitialValue,
            edgeEndpointSelectOptions,
          ),
        isCdpPersonalizeTenantDisabled: isSelectOptionDisabled(
          cdpPersonalizeInitialValue,
          cdpPersonalizeTenantSelectOptions,
        ),
        isSearchTenantDisabled: isSelectOptionDisabled(
          searchInitialValue,
          searchTenantSelectOptions,
        ),
      };
    }

    return {
      selectedEdgeEndpointValue: '',
      selectedCdpPersonalizeTenantValue: '',
      selectedSearchTenantValue: '',
      isEdgeEndpointDisabled: true,
      isCdpPersonalizeTenantDisabled: true,
      isSearchTenantDisabled: true,
    };
  };

  const handleAddContextMapping = () => {
    const options = mutateOptions(
      queryClient,
      history,
      toast,
      'Context ID {sitecoreContextId} added.',
      'Context ID adding failed.',
    );
    const contextToCreate = {
      resources: [] as SitecoreContextResourceType[],
    };
    if (values.selectedEdgeEndpointValue) {
      const resourceData = findContextResourceDataByTenantIdAndResourceType(
        values.selectedEdgeEndpointValue,
        PRODUCT_CODES.EDGE,
        contextsData || [],
      );

      if (resourceData) {
        contextToCreate.resources.push(resourceData);
      }
    }
    if (values.selectedCdpPersonalizeTenantValue) {
      const tenantData = findTenantDataByTenantId(
        values.selectedCdpPersonalizeTenantValue,
        tenantsData,
      );
      contextToCreate.resources.push(
        getContextCdpPersonalizeResource(
          values.selectedCdpPersonalizeTenantValue,
          tenantData,
        ),
      );
    }
    if (values.selectedSearchTenantValue) {
      const tenantData = findTenantDataByTenantId(
        values.selectedSearchTenantValue,
        tenantsData,
      );
      contextToCreate.resources.push(
        getContextSearchResource(values.selectedSearchTenantValue, tenantData),
      );
    }
    createSitecoreContext(contextToCreate, options);
  };

  const handleEditContextMapping = () => {
    const options = mutateOptions(
      queryClient,
      history,
      toast,
      `Context ID ${sitecoreContextId} updated.`,
      `Context ID ${sitecoreContextId} update failed.`,
    );
    if (
      isWithXmCloudContext &&
      values.selectedEdgeEndpointValue &&
      !initialValues.selectedEdgeEndpointValue &&
      values.selectedEdgeEndpointValue !==
        initialValues.selectedEdgeEndpointValue
    ) {
      const resourceData = findContextResourceDataByTenantIdAndResourceType(
        values.selectedEdgeEndpointValue,
        PRODUCT_CODES.EDGE,
        contextsData || [],
      );

      if (resourceData) {
        createSitecoreContextResource(resourceData, options);
      }
    }

    if (
      values.selectedCdpPersonalizeTenantValue &&
      !initialValues.selectedCdpPersonalizeTenantValue &&
      values.selectedCdpPersonalizeTenantValue !==
        initialValues.selectedCdpPersonalizeTenantValue
    ) {
      const tenantData = findTenantDataByTenantId(
        values.selectedCdpPersonalizeTenantValue,
        tenantsData,
      );
      const resourceToCreate = getContextCdpPersonalizeResource(
        values.selectedCdpPersonalizeTenantValue,
        tenantData,
      );
      createSitecoreContextResource(resourceToCreate, options);
    }

    if (
      values.selectedSearchTenantValue &&
      !initialValues.selectedSearchTenantValue &&
      values.selectedSearchTenantValue !==
        initialValues.selectedSearchTenantValue
    ) {
      const tenantData = findTenantDataByTenantId(
        values.selectedSearchTenantValue,
        tenantsData,
      );
      const resourceToCreate = getContextSearchResource(
        values.selectedSearchTenantValue,
        tenantData,
      );
      createSitecoreContextResource(resourceToCreate, options);
    }
  };

  const { initialValues, values, handleSubmit, setFieldValue, isValid, dirty } =
    useFormik({
      initialValues: getInitialValues(),
      enableReinitialize: true,
      validate: (values) => {
        const errors: { [key: string]: string } = {};
        if (
          !isInEditMode &&
          !values.selectedEdgeEndpointValue &&
          !values.selectedCdpPersonalizeTenantValue &&
          !values.selectedSearchTenantValue
        ) {
          errors.noValueSelected = 'Please select at least one tenant.';
          toast({
            description: 'Please select at least one tenant.',
            status: 'error',
          });
        }
        if (
          isInEditMode &&
          values.isEdgeEndpointDisabled &&
          values.isCdpPersonalizeTenantDisabled &&
          values.isSearchTenantDisabled
        ) {
          errors.invalidOperation = 'Invalid operation.';
          toast({
            description: 'Invalid operation.',
            status: 'error',
          });
        }
        return errors;
      },
      onSubmit: () => {
        if (isValid) {
          isInEditMode ? handleEditContextMapping() : handleAddContextMapping();
        }
      },
    });

  return {
    isInEditMode,
    isWithXmCloudContext,
    values,
    edgeEndpointSelectOptions,
    cdpPersonalizeTenantSelectOptions,
    searchTenantSelectOptions,
    handleSubmit,
    setFieldValue,
    isValid,
    dirty,
    isLoading:
      isCreateSitecoreContextLoading ||
      isCreateSitecoreContextResourceLoading ||
      isLoading ||
      isGetSitecoreContextLoading ||
      isGetListSitecoreContextsLoading,
    isSubmitButtonDisabled,
  };
}

export { useAddEditContextMapping };
