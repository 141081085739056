import { FormErrorIcon, FormErrorMessage } from '@chakra-ui/react';

function FieldError({
  error,
  touched,
  testId = 'input',
}: {
  error: string | undefined;
  touched: boolean | undefined;
  testId?: string;
}) {
  if (!touched || !error) return null;
  return (
    <FormErrorMessage data-testid={`${testId}-field-error`}>
      <FormErrorIcon />
      {error}
    </FormErrorMessage>
  );
}

export default FieldError;
