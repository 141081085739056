import {
  Box,
  CloseButton,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Skeleton,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { useSearchOnList } from 'features/common/hooks/search/useSearchOnList';
import debounce from 'lodash/debounce';
import { useEffect, useState, useMemo } from 'react';
import { IMemberRole } from 'features/members/services/identity/membersModel';
import MemberDetailsProductAccessRow from './MemberDetailsProductAccessRow';
import { mdiMagnify } from '@mdi/js';
import { UserAppAccessLimitAlert } from '../shared/UserAppAccessLimitAlert';
import { userAccessLimit } from 'features/members/utils/lib';
import {
  useGetMemberDetailsOrganizationQuery,
  useGetMemberDetailsUserQuery,
  useGetMemberDetailsProductsQuery,
} from 'gql/graphql';
import { flatten } from 'lodash';

interface IMemberDetailsProductAccess {
  roles: IMemberRole[];
  userId: string;
  usersLimit?: number;
}

export const MemberDetailsProductAccess = ({
  roles,
  userId,
  usersLimit = userAccessLimit,
}: IMemberDetailsProductAccess) => {
  const { data: organizationData, loading: isOrganizationLoading } =
    useGetMemberDetailsOrganizationQuery();
  const { data: userData, loading: isUserLoading } =
    useGetMemberDetailsUserQuery();
  const { data: productData, loading: isProductLoading } =
    useGetMemberDetailsProductsQuery();

  const isLoading = isOrganizationLoading || isUserLoading || isProductLoading;

  const hasTenants =
    organizationData?.organization?.applications?.totalCount &&
    organizationData.organization.applications.totalCount > 0;

  /**
   * Transforms the roles data to be used in MemberDetailsProductAccessRow
   */
  const getTransformedRoles = () => {
    const allRoles = flatten(
      productData?.products.map((product) => product?.roles?.nodes || []),
    );

    return allRoles.map((role) => ({
      scope: role.productCode,
      name: role.name,
      id: `${role.productCode}-${role.name}`,
      description: '',
    }));
  };

  const getTenantsAccessObject = () => {
    const tenantsList =
      organizationData?.organization?.applications?.nodes && hasTenants
        ? organizationData?.organization?.applications?.nodes.map((node) => ({
            [node.id]: 'no-access',
          }))
        : [];

    const tenantsObject = Object.assign({}, ...tenantsList);

    roles.forEach((role) => {
      if (role.tenantId) {
        tenantsObject[role.tenantId] = role.role;
      }
    });

    return tenantsObject;
  };

  const getTenantIconsObject = () => {
    const icons = {} as any;
    if (userData?.user?.applications?.nodes) {
      userData.user.applications.nodes.forEach((node) => {
        if (node.appearance?.web.actions?.nodes) {
          const { icon, name } = node.appearance.web.actions.nodes[0];
          icons[node.productCode] = { ...icon, name: name };
        }
      });
    }

    return icons;
  };

  const tenantsIcons = getTenantIconsObject();

  /**
   * Returns the tenants view model that is used in MemberDetailsProductAccessRow
   */
  const tenantsViewModel = useMemo(
    () =>
      organizationData?.organization?.applications?.nodes
        ? organizationData.organization.applications.nodes.map((node) => {
            return {
              id: node.id,
              label: node.displayName,
              productCode: node.productCode,
              title:
                (node.productCode &&
                  tenantsIcons[node.productCode] &&
                  tenantsIcons[node.productCode].name) ||
                '',
              iconUrl:
                (node.productCode &&
                  tenantsIcons[node.productCode] &&
                  tenantsIcons[node.productCode].src) ||
                '',
            };
          })
        : [],
    [tenantsIcons, organizationData],
  );

  const [searchValue, setSearchValue] = useState<string>('');

  const tenantsAccessObject = getTenantsAccessObject();

  const filteredTenantList = useSearchOnList(
    tenantsViewModel,
    ['label', 'productCode'],
    searchValue,
  );

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchInputValue = event.target.value;
    setSearchValue(searchInputValue);
  };

  const onClearHandler = () => {
    setSearchValue('');
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(onChangeHandler, 300),
    [],
  );

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * @description returns selected tenant option, if nothing is selected return no-access
   * @param selected
   */
  const getSelectedTenantOption = (selected: string) => {
    if (selected === 'no-access') {
      return { value: 'no-access', label: 'No access' };
    }
    return { value: selected, label: selected };
  };

  const getTenantData = (id: string) => {
    const tenantAccessValue = tenantsAccessObject[id];
    const selectedValue = getSelectedTenantOption(tenantAccessValue);
    const tenant = tenantsViewModel.find((tenant) => tenant.id == id);

    return { tenant, selectedValue };
  };

  const tenantsWithAccess = useMemo(
    () =>
      tenantsViewModel.filter(
        ({ id }) => tenantsAccessObject[id] !== 'no-access',
      ),
    [tenantsViewModel, tenantsAccessObject],
  );

  const accessRowRoles = getTransformedRoles();
  const userHasReachedTheLimit = tenantsWithAccess.length >= usersLimit;

  return isLoading ? (
    <Stack gap={5}>
      <Skeleton maxWidth='sm' height='10' />
      <Skeleton maxWidth='lg' height='10' />
      <Skeleton maxWidth='lg' height='10' />
      <Skeleton maxWidth='lg' height='10' />
    </Stack>
  ) : (
    <Box>
      {hasTenants ? (
        <>
          <InputGroup mb='4' maxWidth='sm'>
            <InputLeftElement
              pointerEvents='none'
              // TODO change this after we open Input, InputElement props
              children={
                <Icon>
                  <path d={mdiMagnify} />
                </Icon>
              }
            />
            <Input
              data-testid='tenant-search-input'
              value={searchValue}
              onChange={onChangeHandler}
              placeholder='Search apps'
            />
            {searchValue.length > 0 && (
              <InputRightElement>
                <Tooltip label='Clear search'>
                  <CloseButton
                    onClick={onClearHandler}
                    data-testid='tenant-search-clear-button'
                  />
                </Tooltip>
              </InputRightElement>
            )}
          </InputGroup>
          {userHasReachedTheLimit ? <UserAppAccessLimitAlert /> : null}
          {filteredTenantList.map(({ id }) => {
            const { tenant, selectedValue } = getTenantData(id);

            if (!tenant || !accessRowRoles) return null;

            const shouldDisableAccessRow =
              userHasReachedTheLimit && selectedValue.value === 'no-access';
            return (
              <MemberDetailsProductAccessRow
                tenant={tenant}
                selectedValue={selectedValue}
                userId={userId}
                isDisabled={shouldDisableAccessRow}
                key={id}
                roles={accessRowRoles}
              />
            );
          })}
        </>
      ) : (
        <Text data-testid='no-app-access'>
          There are no apps set up yet. You will need to update the product
          access after provisioning.
        </Text>
      )}
    </Box>
  );
};
