import { TokenCustomClaimKeysEnum } from '@sitecore-ui/portal-singular';
import { useAuth0TokenClaims } from 'features/common/auth/claims';

export const useHomepageUtils = () => {
  const { claims } = useAuth0TokenClaims();

  const generateKeyForBannerDismissal = (bannerName: string) => {
    const organization =
      (claims && claims[TokenCustomClaimKeysEnum.ORG_ID]) || '';

    return `${organization}_${bannerName}Banner_dismissed`;
  };

  return { generateKeyForBannerDismissal };
};

export const retrieveElementStyleProperty = (
  element: Element,
  property: string,
) => {
  if (element) {
    const styles = window.getComputedStyle(element);
    return styles.getPropertyValue(property);
  }
};
