import {
  FormControl,
  FormLabel,
  InputGroup,
  Input,
  InputRightElement,
} from '@chakra-ui/react';
import { CopyClipboardInputProps } from './model';
import { useState } from 'react';
import { CopyClipboardIconTooltip } from '../CopyClipboardIconTooltip';

const CopyClipboardInput = ({
  inputLabel,
  inputValueProp,
  isReadOnly = false,
  dataTestId,
}: CopyClipboardInputProps) => {
  const [inputValue, setInputValue] = useState(inputValueProp ?? '');

  const handleChange = (event: any) => setInputValue(event.target.value);

  return (
    <FormControl>
      <FormLabel>{inputLabel}</FormLabel>
      <InputGroup>
        <Input
          isReadOnly={isReadOnly}
          value={inputValue}
          onChange={handleChange}
          data-testid={`${dataTestId}-input`}
        />
        <InputRightElement>
          <CopyClipboardIconTooltip
            inputValue={inputValue}
            dataTestId={dataTestId}
          />
        </InputRightElement>
      </InputGroup>
    </FormControl>
  );
};

export { CopyClipboardInput };
