import { useAuthQuery } from 'features/common/apiUtils/queryHelper';
import { edgePlatformAdminApi } from '../api/endpoints';
import { Hostname } from '../types';
import { UseQueryOptions } from 'react-query';
import { AxiosResponse } from 'axios';

export const useGetHostname = (
  hostname: string,
  queryOptions?:
    | Omit<
        UseQueryOptions<
          AxiosResponse<Hostname>,
          unknown,
          AxiosResponse<Hostname>,
          string[]
        >,
        'queryKey' | 'queryFn'
      >
    | undefined,
) => {
  const { get_hostname } = edgePlatformAdminApi;
  const url = get_hostname.url(hostname);

  return useAuthQuery(
    [`hostnames/hostname/${hostname}`],
    //@ts-expect-error
    async (axiosInstance) => {
      return axiosInstance?.get<AxiosResponse<Hostname>>(url);
    },
    {
      ...queryOptions,
    },
    get_hostname.scope,
  );
};
