import { memo, useEffect } from 'react';

const ScriptInjector = ({
  src = '',
  code = '',
  id = '',
}: {
  src?: string;
  code?: string;
  id?: string;
}) => {
  useEffect(() => {
    if (!code && !src) return;

    const script = document.createElement('script');
    if (src) {
      script.src = src;
      script.async = true;
    } else if (code) {
      script.innerHTML = code;
    }
    script.id = id;

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [src, code, id]);

  return null;
};

export default memo(ScriptInjector);
