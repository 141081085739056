import { useRef } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  ButtonGroup,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Text,
  Button,
  useToast,
} from '@chakra-ui/react';
import { T } from '@transifex/react';
import { useHistory } from 'react-router';
import {
  GetInvitationsDocument,
  useDeleteInvitationMutation,
} from 'gql/graphql';

export interface IInvitationDialogProps {
  isOpen: boolean;
  invitationId?: string;
  onSuccess?: () => void;
  onClose?: () => void;
}

export const InvitationRemoveConfirmation = ({
  invitationId,
  onClose,
  onSuccess,
  isOpen,
  ...rest
}: IInvitationDialogProps) => {
  const lessDestructiveRef = useRef(null);
  const toast = useToast();
  const { goBack } = useHistory();

  const [deleteInviteMutation, { loading }] = useDeleteInvitationMutation();
  //@ts-ignore
  const id = invitationId ?? rest?.match?.params.id;

  const onSuccessCb = onSuccess
    ? onSuccess
    : () => {
        toast({
          description: 'The invitation has been deleted.',
          status: 'success',
        });
      };
  const onError = (error: Omit<Error, 'name'>) => {
    toast({
      description: error?.message,
      title: 'Error',
      status: 'error',
    });
  };

  const deleteInvite = async () => {
    try {
      const { data } = await deleteInviteMutation({
        variables: {
          invitationId: id,
        },
        refetchQueries: [GetInvitationsDocument],
      });

      const error = data?.deleteInvitation.errors?.[0];
      if (error) return onError(error);

      onSuccessCb();
    } catch (error: any) {
      const { networkError } = error ?? {};

      toast({
        description: networkError.message,
        title: `Error ${networkError.statusCode}`,
        status: 'error',
      });
    } finally {
      const onSettled = onClose ?? goBack;
      onSettled();
    }
  };

  if (!id) return null;
  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={lessDestructiveRef}
      data-testid='delete-member-modal'
      onClose={onClose ?? goBack}
      autoFocus={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent data-testid='delete-invitation-modal-content'>
          <AlertDialogHeader>
            <T _str='Delete invitation' />
          </AlertDialogHeader>
          <AlertDialogBody>
            <Text>
              <T _str='You are about to delete the invitation. This action cannot be undone.' />
            </Text>
          </AlertDialogBody>
          <AlertDialogFooter>
            <ButtonGroup>
              <Button
                variant='ghost'
                onClick={onClose ?? goBack}
                isDisabled={loading}
                ref={lessDestructiveRef}
                data-testid='invitation-delete-conf-close-btn'
              >
                <T _str='Cancel' />
              </Button>
              <Button
                colorScheme='danger'
                variant='solid'
                onClick={deleteInvite}
                isLoading={loading}
                data-testid='invitation-delete-conf-delete-btn'
              >
                <T _str='Delete' />
              </Button>
            </ButtonGroup>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
