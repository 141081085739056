import { Flex, Image, Heading } from '@chakra-ui/react';
import { IconLinks } from 'features/common/utils/icons';
import AddConnectionButton from './add-connection-button-component';
import { noConnectionTypes } from './types';

export const NoConnections = ({ ssoProviderOptions }: noConnectionTypes) => (
  <Flex
    height='100%'
    justifyContent='center'
    alignItems='center'
    mb={40}
    data-testid='no-sso-connections-container'
  >
    <Flex direction='column' alignItems='center' gap={5}>
      <Image
        data-testid='no-sso-connections-icon'
        boxSize='24'
        src={IconLinks.noConnectionsIcon}
        alt='no SSO connections icon'
      />
      <Heading size='md'>No SSO connections set up</Heading>
      <AddConnectionButton
        label='Add SSO connection'
        menuItems={ssoProviderOptions}
        variant='solid'
      />
    </Flex>
  </Flex>
);
