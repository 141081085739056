import { useAuth0TokenClaims } from '../../common/auth/claims';
import { Text } from '@chakra-ui/react';
import { TokenCustomClaimKeysEnum } from '@sitecore-ui/portal-singular';

function OrgName() {
  const { claims } = useAuth0TokenClaims();

  if (!claims || !claims[TokenCustomClaimKeysEnum.ORG_DISPLAY_NAME])
    return null;

  return (
    <Text
      variant='subtle'
      lineHeight='8'
      noOfLines={1}
      data-testid='org-name'
      mr='4'
    >
      {claims[TokenCustomClaimKeysEnum.ORG_DISPLAY_NAME]}
    </Text>
  );
}

export default OrgName;
