import { hostnames } from 'features/common/config/envConfig';
import { history } from 'features/common/history/history';
import { RecommendationCard } from 'gql/graphql';

export const baseDeployUrl = {
  [hostnames.internal]: 'https://deploy-staging.sitecore-staging.cloud',
  [hostnames.localhost]: 'https://deploy-staging.sitecore-staging.cloud',
  [hostnames.dev]: 'https://deploy-dev.sitecore-staging.cloud',
  [hostnames.qa]: 'https://deploy-qa.sitecore-staging.cloud',
  [hostnames.staging]: 'https://deploy-staging.sitecore-staging.cloud',
  [hostnames.beta]: 'https://deploy-preprod.sitecorecloud.io',
  [hostnames['pre-production']]: 'https://deploy-preprod.sitecorecloud.io',
  [hostnames.production]: 'https://deploy.sitecorecloud.io',
};

export const XmcCloudDeployCardId = 'XMCloudDeploy';

export const transformCardData = (
  cards: RecommendationCard[],
): RecommendationCard[] =>
  cards.map((card) =>
    card.cardId === XmcCloudDeployCardId
      ? {
          ...card,
          link: {
            type: card.link?.type ?? '',
            to: `${baseDeployUrl[window.location.hostname]}${
              history.location.search
            }`,
          },
        }
      : card,
  );
