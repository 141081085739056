/* eslint-disable no-console */
import {
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Flex,
  ButtonGroup,
  InputRightElement,
  Tooltip,
  CloseButton,
  FormErrorIcon,
} from '@chakra-ui/react';
import { t } from '@transifex/native';
import { T } from '@transifex/react';
import {
  useMemo,
  useCallback,
  ReactNode,
  useState,
  ChangeEvent,
  useEffect,
} from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { getTableOptions, transformDataForTable } from './membersTableHelpers';
import { useMemberGuards } from 'features/members/utils/lib';
import { allowedSearchCharactersRegex } from 'features/common/validations/matchers';
import { EmptyTablePlaceholder } from './components/EmptyTablePlaceHolder';
import { useExportMembers } from 'features/members/hooks';
import { mdiAccountPlusOutline, mdiTrayArrowDown, mdiMagnify } from '@mdi/js';
import { useDrawerStateDispatch } from '../../../main/context';
import { openDrawer } from '../../../main/context/drawer/actionCreators';
import { useQueryErrorResetBoundary } from 'react-query';
import { ErrorBoundary } from 'react-error-boundary';
import { MembersErrorPage } from 'features/main/components/CustomErrorPages/MembersErrorPage';
import styled from '@emotion/styled';
import { Feature } from 'lib/featureFlags/features';
import {
  DataTable,
  DataTableListOptions,
  DataTableSortingRule,
} from 'features/common/components/DataTable';
import { PortalIcon } from 'features/common/components/PortalIcon';
import { OrganizationMember, useGetMembersQuery } from 'gql/graphql';
import { adminPath, membersPath } from 'features/admin/components/admin-paths';
import { gql, useApolloClient } from '@apollo/client';

export const GET_REMOVED_MEMBER_ID = gql`
  query GetRemovedMemberId {
    removedMemberId @client
  }
`;

const TableDiv = styled.div`
  table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
`;

export type IMembersTableProps = RouteComponentProps<{ page: string }>;

const Header = ({ children }: { children: ReactNode }) => (
  <Flex
    flexDirection={['column', 'column', 'row']}
    justifyContent='space-between'
    marginBottom={12}
    gap={[6, 6, 0]}
  >
    {children}
  </Flex>
);

export const MembersTableWithErrorHandling = (props: IMembersTableProps) => {
  const { reset } = useQueryErrorResetBoundary();
  return (
    <ErrorBoundary FallbackComponent={MembersErrorPage} onReset={reset}>
      <MembersTable {...props} />
    </ErrorBoundary>
  );
};

const sortBy: DataTableSortingRule[] | undefined = [];

function useDebounce(value: string) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, 400);
    return () => {
      clearTimeout(handler);
    };
  }, [value]);
  return debouncedValue;
}

export const MembersTable = (props: IMembersTableProps) => {
  const history = useHistory();
  const { exportMembers, isLoading: isExportLoading } = useExportMembers();

  const client = useApolloClient();

  // Use the query in your component
  const removedMemberData = client.readQuery({
    query: GET_REMOVED_MEMBER_ID,
  });

  // Access the removedMemberId from the query result
  let removedMemberId = removedMemberData?.removedMemberId;
  if (removedMemberId) {
    removedMemberId = removedMemberId.replace('OrganizationMember:', '');
  }

  const [searchQueryPagingConfig, setSearchQueryPagingConfig] = useState({
    page: parseInt(props.match.params.page ?? 1),
    pageSize: 10,
  });

  const simpleQueryPagingConfig = {
    page: parseInt(props.match.params.page ?? 1),
    pageSize: 10,
  };

  const [searchFilter, setSearchFilter] = useState<string>('');

  const debouncedSearchFilter = useDebounce(searchFilter);

  const { page, pageSize } = debouncedSearchFilter
    ? searchQueryPagingConfig
    : simpleQueryPagingConfig;

  const [, drawerDispatch] = useDrawerStateDispatch();

  const [searchStringIsValid, setSearchStringIsValid] = useState<boolean>(true);

  const state = history.location.state as { from?: string };
  // The fetchPolicy needs to be set based on the UX, if it's coming from member details we need to fetch without caching, else we need the cache in order to modify it and perform a secondary refetch after mutation.
  const fetchPolicy =
    state && state.from === 'member-details'
      ? 'cache-first'
      : 'cache-and-network';

  const { data: membersData, loading: membersLoading } = useGetMembersQuery({
    variables: {
      skip: pageSize * (page - 1),
      take: pageSize,
      query: searchStringIsValid ? debouncedSearchFilter : '',
    },
    fetchPolicy,
  });

  const [resetPagination, setResetPagination] = useState(false);

  useEffect(() => {
    if (debouncedSearchFilter) {
      setResetPagination(true);
    }
  }, [debouncedSearchFilter]);

  useEffect(() => {
    if (resetPagination) {
      setSearchQueryPagingConfig((prevConfig) => ({
        ...prevConfig,
        page: 1,
      }));
      setResetPagination(false);
    }
  }, [resetPagination]);

  useEffect(() => {
    if (debouncedSearchFilter) {
      const newPage = props.match.params.page
        ? parseInt(props.match.params.page)
        : 1;
      setSearchQueryPagingConfig((prevConfig) => ({
        ...prevConfig,
        page: newPage,
      }));
    }
  }, [debouncedSearchFilter, props.match.params.page]);

  const { items, totalCount } = membersData?.organization?.members || {
    items: [],
    totalCount: 0,
  };

  const cachedFilteredItems = items?.filter(
    (member) => member.id !== removedMemberId,
  );

  const memberToShow = transformDataForTable(
    cachedFilteredItems as OrganizationMember[],
  );

  const { allowMemberAccess } = useMemberGuards();

  const options = useMemo(
    () =>
      getTableOptions({
        page,
        allowMemberAccess,
      }),

    [allowMemberAccess, page],
  );

  const onChange = useCallback(
    (options: DataTableListOptions) => {
      setResetPagination(false);
      history.push({
        pathname: `${adminPath}${membersPath}/page/${options.page}`,
        search: history.location.search,
      });
    },
    [history],
  );

  const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const searchInputValue = event.target.value;

    if (allowedSearchCharactersRegex.test(searchInputValue)) {
      setSearchFilter(searchInputValue);
      setSearchStringIsValid(true);
    } else setSearchStringIsValid(false);
  };

  const onClearHandler = () => {
    setSearchFilter('');
    setSearchStringIsValid(true);
  };

  const pagination = totalCount < pageSize ? 'hidden' : 'complex';

  return (
    <>
      <Flex direction='column'>
        <Header>
          <Heading as='h1'>
            <T _str='Team members' />
          </Heading>
          <ButtonGroup
            display='flex'
            flexDirection={['column', 'row']}
            gap={2}
            spacing={0}
          >
            <Feature name={'EXPORT_MEMBERS'}>
              <Button
                data-testid='export'
                variant='outline'
                onClick={async () => await exportMembers()}
                isLoading={isExportLoading}
                loadingText='Export'
                leftIcon={<PortalIcon path={mdiTrayArrowDown} />}
                data-behavior-analytics-id='export members'
              >
                <T _str='Export' />
              </Button>
            </Feature>
            <Feature name='INVITATIONS'>
              <Button
                variant='solid'
                data-testid='create_invite_toggle'
                onClick={() => drawerDispatch(openDrawer('invite-drawer'))}
                leftIcon={<PortalIcon path={mdiAccountPlusOutline} />}
                data-behavior-analytics-id='Invite members button - Members page'
              >
                <T _str='Invite' />
              </Button>
            </Feature>
          </ButtonGroup>
        </Header>

        <Flex justifyContent='space-between' marginBottom={12} maxW='xs'>
          <FormControl isInvalid={!searchStringIsValid}>
            <InputGroup mb='4' maxWidth='sm'>
              <InputLeftElement
                pointerEvents='none'
                // TODO change this after we open Input, InputElement props
                children={<PortalIcon path={mdiMagnify} />}
              />
              <Input
                placeholder='Search'
                data-testid='member_search_input'
                data-behavior-analytics-feature='members search'
                onChange={onSearch}
                value={searchFilter}
              />
              {searchFilter.length > 0 && (
                <InputRightElement>
                  <Tooltip label='Clear search'>
                    <CloseButton
                      onClick={onClearHandler}
                      data-testid='member_search_clear_button'
                    />
                  </Tooltip>
                </InputRightElement>
              )}
            </InputGroup>
            <FormErrorMessage>
              <FormErrorIcon />
              <T _str='Currently, the search is only available in alphanumeric characters, at signs (@), dots, dashes, and underscores.' />
            </FormErrorMessage>
          </FormControl>
        </Flex>

        {memberToShow.length === 0 && !membersLoading ? (
          <EmptyTablePlaceholder
            description={t('Change your search parameters and try again.')}
            title={t('No team members found')}
          />
        ) : (
          <TableDiv>
            <DataTable
              columns={options.columns}
              //@ts-ignore
              rowActions={options.rowActions}
              pagination={pagination}
              totalItems={totalCount}
              data={memberToShow}
              onChange={onChange}
              page={page}
              pageSize={pageSize}
              isLoading={membersLoading}
              sortBy={sortBy}
              theaderProps={{ 'data-behavior-analytics-id': 'members sorting' }}
              paginationProps={{
                'data-behavior-analytics-feature': ' members pagination',
              }}
              data-behavior-analytics-feature='members table'
            />
          </TableDiv>
        )}
      </Flex>
    </>
  );
};
