import { useMemo } from 'react';

/**
 *
 * @param list an array of objects to search at fields with string
 * @param fields the field of the object to search
 * @returns search function
 */
export function useSearchOnList<T>(
  list: T[],
  fields: Array<keyof T>,
  input: string,
) {
  const filteredList = useMemo(() => {
    if (!input) return list;

    const lowerCaseInput = input.toLocaleLowerCase();

    return list.filter((listItem: T) => {
      return fields.some((field) => {
        const element = listItem[field];

        return (
          typeof element === 'string' &&
          element.toLowerCase().includes(lowerCaseInput)
        );
      });
    });
  }, [list, fields, input]);

  return filteredList;
}
