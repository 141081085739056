import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  forwardRef,
} from '@chakra-ui/react';
import { t } from '@transifex/native';
import { env } from 'features/common/config/envConfig';
import { GET_TENANTS } from 'features/home/home-queries';
import { useDrawerStateDispatch } from 'features/main/context';
import { closeDrawer } from 'features/main/context/drawer/actionCreators';
import { useProvisionConnectMutation, Error } from 'gql/graphql';
import { useState, useEffect, useRef } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import './assets/AnnotationLayer.css';
import './assets/TextLayer.css';
import { GET_COUNT_OF_PRODUCT } from './queries';

let workerSrc;
if (process.env.NODE_ENV === 'test') {
  workerSrc = './node_modules/pdfjs-dist/build/pdf.worker.min.js';
} else {
  workerSrc = '/portal-ui/pdf.worker.min.mjs';
}

pdfjs.GlobalWorkerOptions.workerSrc = workerSrc;

export const ConnectTermsModal = ({
  isOpen,
  onCloseHandler,
  regionId,
}: {
  isOpen: boolean;
  onCloseHandler: (value: boolean) => void;
  regionId: string;
}) => {
  const node = useRef<HTMLDivElement | null>(null);
  const toast = useToast();
  const [isEndOfText, setIsEndOfText] = useState(false);
  const [, drawerDispatch] = useDrawerStateDispatch();

  const [provisionConnectMutation, { loading: mutationLoading }] =
    useProvisionConnectMutation({
      variables: {
        name: 'tier0',
        displayName: 'tier0',
        regiondId: regionId,
        auditLogsEnabled: true,
      },
      refetchQueries: [
        {
          query: GET_TENANTS,
          variables: {
            first: 20,
            env: env,
          },
        },
        {
          query: GET_COUNT_OF_PRODUCT,
          variables: {
            productCode: 'Connect',
          },
        },
      ],
    });

  const modalCloseDrawer = () => {
    setIsEndOfText(false);
    onCloseHandler(false);
  };

  const onError = (error: Error) => {
    toast({
      description: error?.message,
      title: t('Error'),
      status: 'error',
    });
  };

  const onSuccess = async () => {
    toast({
      title: t('Connect tier0 provisioned successfully'),
      status: 'success',
    });
    drawerDispatch(closeDrawer());
    onCloseHandler(false);
  };

  const handleSubmit = async () => {
    const { data } = await provisionConnectMutation();
    const error = data?.provisionProduct.errors?.[0];

    if (error) return onError(error);
    onSuccess();
  };

  useEffect(() => {
    const handleScroll = () => {
      const { scrollHeight, scrollTop, clientHeight } = node?.current || {};
      const isEnd =
        scrollHeight !== undefined &&
        Math.abs((scrollTop ?? 0) + (clientHeight ?? 0) - scrollHeight) < 1;

      setIsEndOfText(isEnd);
    };

    setTimeout(() => {
      if (node.current) {
        node.current.addEventListener('scroll', handleScroll);
      }
    }, 200);

    return () => {
      if (node.current) {
        node.current.removeEventListener('scroll', handleScroll);
      }
    };
  });

  return (
    <Modal
      onClose={modalCloseDrawer}
      isOpen={isOpen}
      size='4xl'
      scrollBehavior='inside'
      blockScrollOnMount={false}
    >
      <ModalOverlay />
      <ModalContent aria-label='trial consent'>
        <ModalHeader>Connect Terms and Conditions</ModalHeader>
        <ModalCloseButton />
        <ForwardedModalBody aria-label='consent body' ref={node}>
          <Document
            externalLinkTarget='_blank'
            file='/portal-ui/Connect Trial EULA.pdf'
          >
            <Page pageNumber={1} />
            <br />
            <Page pageNumber={2} />
            <br />
            <Page pageNumber={3} />
            <br />
            <Page pageNumber={4} />
            <br />
          </Document>
        </ForwardedModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button
              data-testid='cancel-btn'
              type='button'
              variant='ghost'
              onClick={modalCloseDrawer}
              isDisabled={mutationLoading}
            >
              Cancel
            </Button>
            <Button
              variant='solid'
              onClick={handleSubmit}
              isDisabled={!isEndOfText}
              isLoading={mutationLoading}
              data-testid='connect-accept-enable'
              aria-label='accept and enable'
            >
              Accept and enable
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const ForwardedModalBody = forwardRef<any, 'div'>((props, ref) => {
  const { 'aria-label': ariaLabel } = props;
  return (
    <div
      ref={ref}
      aria-label={ariaLabel}
      style={{
        flex: '1 1 0%',
        overflow: 'auto',
        zoom: 1.3,
      }}
    >
      <ModalBody ref={ref} {...props} />
    </div>
  );
});
