import { useAuthMutation } from 'features/common/apiUtils/queryHelper';
import { connectionsApi } from './endpoints';
import { getIdentityBaseUrl } from '@sitecore-ui/portal-singular';
import { env } from 'features/common/config/envConfig';
import { IOidcConnection } from 'features/sso/api/types';

type UsePatchConnectionProps = { connectionId?: string };
const baseUrl = getIdentityBaseUrl(env.toLowerCase());

export const usePatchConnection = ({
  connectionId = '',
}: UsePatchConnectionProps) => {
  const { patch_connection } = connectionsApi;
  const url = `${baseUrl}${patch_connection.url(connectionId)}`;

  return useAuthMutation(
    (axiosInstance) => (payload) =>
      axiosInstance?.patch<IOidcConnection>(
        url,
        payload as unknown as IOidcConnection,
      ),
    {
      onError: () => {
        return;
      },
      onSuccess: () => {
        return;
      },
    },
    patch_connection.scope,
  );
};
