import { useAuthMutation } from 'features/common/apiUtils/queryHelper';
import { edgePlatformAdminApi } from '../api/endpoints';

type updateHostnamePayload = {
  defaultContextId?: string;
  defaultSiteId?: string;
};

export const useUpdateHostname = (hostname: string) => {
  const { update_hostname } = edgePlatformAdminApi;
  const url = update_hostname.url(hostname);

  return useAuthMutation(
    //@ts-ignore
    (axiosInstance) => (payload: updateHostnamePayload) =>
      axiosInstance?.patch(url, payload),
    {
      enabled: !!hostname,
    },
    update_hostname.scope,
  );
};
