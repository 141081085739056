import { Tooltip, IconButton, Icon, useClipboard } from '@chakra-ui/react';
import { mdiContentCopy } from '@mdi/js';
import { CopyClipboardIconTooltipProps } from './model';

const CopyClipboardIconTooltip = ({
  inputValue,
  dataTestId,
}: CopyClipboardIconTooltipProps) => {
  const { onCopy, hasCopied } = useClipboard(inputValue);

  const handleCopy = () => {
    if (!inputValue) return;
    onCopy();
  };

  return (
    <Tooltip
      label={hasCopied ? 'Copied' : 'Copy to clipboard'}
      aria-label={hasCopied ? 'Copied' : 'Copy'}
      closeOnClick={false}
    >
      <IconButton
        size='sm'
        variant='ghost'
        aria-label={hasCopied ? 'Copied' : 'Copy to clipboard'}
        data-testid={`${dataTestId}-copyButton`}
        icon={
          <Icon>
            <path d={mdiContentCopy} />
          </Icon>
        }
        onClick={handleCopy}
      />
    </Tooltip>
  );
};

export { CopyClipboardIconTooltip };
