import { hostnames } from 'features/common/config/envConfig';

const baseCalUrl = {
  [hostnames.internal]: 'https://audit-api-staging.sitecore-staging.cloud',
  [hostnames.localhost]: 'https://audit-api-staging.sitecore-staging.cloud',
  [hostnames.dev]: 'https://audit-api-dev.sitecore-staging.cloud',
  [hostnames.qa]: 'https://audit-api-qa.sitecore-staging.cloud',
  [hostnames.staging]: 'https://audit-api-staging.sitecore-staging.cloud',
  [hostnames.beta]: 'https://audit-api-beta.sitecorecloud.io',
  [hostnames['pre-production']]: 'https://audit-api-beta.sitecorecloud.io',
  [hostnames.production]: 'https://audit-api.sitecorecloud.io',
};

export const auditLogApi = {
  get_logs: {
    url: ({
      page,
      pageSize,
      action,
      entityType,
    }: {
      page: number;
      pageSize: number;
      action: string;
      entityType: string;
    }) =>
      `${
        baseCalUrl[window.location.hostname]
      }/api/v1/logs?pagenumber=${page}&pagesize=${pageSize}&action=${action}&entity.type=${entityType}`,
    scope: 'audit.logs:r',
  },
};
