import { Text, VStack } from '@chakra-ui/react';
import {
  UserWrapper,
  AdminOwnerWrapper,
} from 'features/common/components/OrganizationAppAccess';

const NoTenantsPanelMessage = () => (
  <>
    <UserWrapper>
      <VStack spacing='4' my='5'>
        <Text variant='subtle' data-testid='no-tenants-message'>
          No apps available. Contact your organization administrator.
        </Text>
      </VStack>
    </UserWrapper>
    <AdminOwnerWrapper>
      <VStack spacing='4' my='5'>
        <Text variant='subtle' data-testid='no-tenants-message'>
          No apps available.
        </Text>
      </VStack>
    </AdminOwnerWrapper>
  </>
);

export default NoTenantsPanelMessage;
