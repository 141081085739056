import React from 'react';

import { Text, Box } from '@chakra-ui/react';

interface IAppBoxEmptyState {
  dataTestId: string;

  text: string;
}

const AppBoxEmptyState = ({ dataTestId, text }: IAppBoxEmptyState) => (
  <Box data-testid={dataTestId}>
    <Text as='span' size='md' lineHeight='6' color='chakra-subtle-text'>
      {text}
    </Text>
  </Box>
);

export default AppBoxEmptyState;
