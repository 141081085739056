import { UserHasOrgs } from 'features/common/components/OrganizationAppAccess';
import { UserHasNoOrgs } from 'features/common/components/OrganizationAppAccess/utils';
import { HelpDrawer } from 'features/main/components/HelpDrawer';
import { HelpDrawerNoOrg } from 'features/main/components/HelpDrawerNoOrg';

export function HelpDrawerController() {
  return (
    <>
      <UserHasOrgs>
        <HelpDrawer />
      </UserHasOrgs>
      <UserHasNoOrgs>
        {/*istanbul ignore next */}
        <HelpDrawerNoOrg />
      </UserHasNoOrgs>
    </>
  );
}
