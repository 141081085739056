/* eslint-disable no-console */
import { useFormik } from 'formik';
import {
  useGetConnection,
  convertToIConnectionViewModel,
  IOidcConnection,
} from '.';
import { useParams } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import { createConectionSchema } from 'features/sso/validations/connectionSchema';

import { ssoEditRoute } from 'features/sso/sso-routes';

const defaultValues = {
  oidc: {
    options: {
      oidc: {
        type: 'FrontChannel',
        clientId: '',
        clientSecret: '',
        scope: 'openid profile email',
        discoveryUrl: '',
      },
    },
    strategy: 'oidc',
    emailDomains: [],
  },
  samlp: {
    strategy: 'samlp',
    emailDomains: [],
    options: {
      samlp: {},
    },
  },
};
export const useCreateConnection = (strategy: string) => {
  // get the connection id from uri path via useParams
  const { id: connectionId } = useParams<{ id: string }>();
  // if path matches /admin/sso/:ssoProvider/:id/edit, then isInEditMode is true
  const isInEditMode = Boolean(useRouteMatch(ssoEditRoute));

  // if it's on edit mode, fetch the connection data
  const {
    data: connection,
    isLoading,
    isFetched,
    error: connectionError,
  } = useGetConnection(connectionId, {
    enabled: isInEditMode,
  });

  /**
   * Samlp will always returns the default values
   */
  const getInitialValues = () => {
    if (isFetched && connection?.data && 'oidc' in connection.data.options) {
      return convertToIConnectionViewModel(
        connection.data as IOidcConnection,
        isInEditMode,
      );
    }

    return defaultValues[strategy as keyof typeof defaultValues];
  };

  const {
    touched,
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    isValidating,
    isSubmitting,
    setSubmitting,
    dirty,
    setErrors,
    setFieldValue,
    setFieldTouched,
    initialValues,
    submitForm,
    validateForm,
  } = useFormik({
    initialValues: getInitialValues(),
    validateOnMount: true,
    enableReinitialize: true,
    // eslint-disable-next-line no-empty-function
    onSubmit: () => {},
    validationSchema:
      createConectionSchema[strategy as keyof typeof createConectionSchema],
  });

  return {
    connectionData: connection?.data,
    touched,
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setErrors,
    setFieldTouched,
    isValid,
    isValidating,
    isSubmitting,
    dirty,
    isInEditMode,
    isLoading: isLoading,
    isFetched,
    connectionError,
    initialValues,
    submitForm,
    validateForm,
    setSubmitting,
  };
};
