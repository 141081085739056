import { Box, BoxProps } from '@chakra-ui/react';

export const LayoutAside = ({ children, ...rest }: BoxProps) => {
  return (
    <Box
      layerStyle='section.sidebar'
      as='aside'
      overflow='auto'
      zIndex={2}
      maxW='xs'
      minW='2xs'
      {...rest}
    >
      {children}
    </Box>
  );
};
