import { ITenantSelectOption } from '../utils/memberDetailsModel';
import { Box, CircularProgress, HStack, useToast } from '@chakra-ui/react';
import MediaCard from 'features/common/components/MediaCard/MediaCard';
import getAccessOptions from '../utils/getAccessOptions';
import { Select as ReactSelect } from 'chakra-react-select';
import { useUpdateOrganizationMemberRolesMutation } from 'gql/graphql';
import { GET_ORGANIZATION_MEMBER } from '../members-queries';
import { useState } from 'react';

interface MemberDetailsProductAccessRowProps {
  tenant: {
    id: string;
    title: string;
    label: string;
    iconUrl: string;
    productCode: string;
  };
  selectedValue: ITenantSelectOption;
  isDisabled: boolean;
  userId: string;
  roles: { scope: string; name: string; id: string; description: string }[];
}

const MemberDetailsProductAccessRow = ({
  tenant,
  selectedValue,
  userId,
  isDisabled,
  roles,
}: MemberDetailsProductAccessRowProps) => {
  const toast = useToast();
  const [isUpdating, setIsUpdating] = useState(false);
  const { title, id, label, productCode, iconUrl } = tenant;
  const [updateOrganizationMemberRolesMutation] =
    useUpdateOrganizationMemberRolesMutation();
  const noAccessValue = 'no-access';

  const accessOptions = () => [
    ...getAccessOptions(roles, productCode),
    {
      value: noAccessValue,
      label: 'No access',
    } as ITenantSelectOption,
  ];

  const changeSelectAccess: (accessLevel: string) => void = async (
    currentOption,
  ) => {
    const previousOption = selectedValue.value;

    if (previousOption === currentOption) return;

    setIsUpdating(true);

    const roleToAdd =
      currentOption === noAccessValue
        ? []
        : [
            {
              productCode: productCode,
              applicationId: id,
              roleName: currentOption,
            },
          ];

    const roleToDelete = [
      {
        productCode: productCode,
        applicationId: id,
        roleName: previousOption,
      },
    ];

    try {
      const { data } = await updateOrganizationMemberRolesMutation({
        variables: {
          applicationRolesToAdd: roleToAdd,
          applicationRolesToDelete: roleToDelete,
          userId: userId,
        },
        refetchQueries: [
          { query: GET_ORGANIZATION_MEMBER, variables: { id: userId } },
        ],
      });

      setIsUpdating(false);
      const error = data?.updateOrganizationMemberRoles.errors?.[0];

      if (error) {
        toast({
          description: error?.message,
          title: 'Error',
          status: 'error',
        });
      }
    } catch (error: any) {
      const { networkError } = error ?? {};
      toast({
        description: networkError.message,
        title: `Error ${networkError.statusCode}`,
        status: 'error',
      });
    }
  };

  return (
    <HStack w='100%' mb='4' data-testid={id}>
      <Box mr='4' width='sm'>
        <MediaCard
          size='option'
          title={title}
          label={label}
          imageUrl={iconUrl}
        />
      </Box>
      <Box w='44' data-testid='select-tenant-access'>
        <ReactSelect
          selectedOptionColorScheme='primary'
          useBasicStyles
          isDisabled={isUpdating || isDisabled}
          value={selectedValue}
          onChange={(data) => {
            //@ts-ignore will always have value
            changeSelectAccess(data?.value);
          }}
          options={accessOptions()}
        />
      </Box>
      <Box width='36' ml='4' data-testid='test'>
        {isUpdating && (
          <CircularProgress
            data-testid='loading-icon'
            isIndeterminate
            size='5'
          />
        )}
      </Box>
    </HStack>
  );
};

export default MemberDetailsProductAccessRow;
