import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { T } from '@transifex/react';
import { useCallback, useRef, useState } from 'react';
import SSOConnectionForm from './sso-connection-form';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ssoRoute, ssoEditRoute } from './sso-routes';
import DiscardChangesModal from 'features/common/components/DiscardModal/DiscardChangesModal';
import { useQueryClient } from 'react-query';

const AddConnectionDrawer = () => {
  const history = useHistory();
  // if path matches /admin/sso/:ssoProvider/:id/edit, then isInEditMode is true
  const isInEditMode = Boolean(useRouteMatch(ssoEditRoute));
  const { isOpen, onClose, onOpen: onOpenDiscard } = useDisclosure();
  const [isDirty, setIsDirty] = useState(false);
  const queryClient = useQueryClient();

  const onCloseDrawer = useCallback(() => {
    if (isDirty) return onOpenDiscard();
    history.push({ pathname: ssoRoute, search: history.location.search });
    queryClient.removeQueries('claims-mappings');
    onClose();
  }, [history, isDirty, onClose, onOpenDiscard, queryClient]);

  const onDiscard = useCallback(() => {
    onClose();
    history.push({ pathname: ssoRoute, search: history.location.search });
  }, [history, onClose]);

  const initialFocusRef = useRef(null);

  return (
    <>
      <Modal
        size='full'
        isOpen={true}
        onClose={onCloseDrawer}
        initialFocusRef={initialFocusRef}
        scrollBehavior='inside'
        data-testid={'add-sso-connection-drawer'}
        id={'add-sso-connection-drawer'}
      >
        <ModalOverlay />
        <ModalContent
          data-testid='add-sso-connection-drawer'
          data-behavior-analytics-feature='Add SSO Connections drawer'
        >
          <ModalCloseButton
            data-testid='add-sso-connection-drawer-close-button'
            _focus={{ border: 'none' }}
          />
          <ModalHeader display='flex' alignItems='center'>
            <T _str={`${isInEditMode ? 'Edit' : 'Add'} SSO connection`} />
          </ModalHeader>
          <SSOConnectionForm
            initialFocusRef={initialFocusRef}
            setIsDirty={setIsDirty}
            onCloseDrawer={onCloseDrawer}
          />
        </ModalContent>
      </Modal>
      <DiscardChangesModal
        isOpen={isOpen}
        onClose={onClose}
        onDiscard={onDiscard}
      />
    </>
  );
};
export default AddConnectionDrawer;
