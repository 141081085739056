import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { useToast } from '@chakra-ui/react';
import { ORG_ROLES } from '../../../common/components/OrganizationAppAccess/resource';
import {
  getMemberValidationError,
  getRestrictedEmailsValidationError,
  validateSSOConnectionEmails,
  handleValidate,
  hasRestrictedEmail,
  getEmailsPerMessage,
} from './utils/utils';
import { env } from 'features/common/config/envConfig';
import {
  GetInvitationsDocument,
  InviteOrganizationMemberMutationVariables,
  useGetCurrentUserQuery,
  useGetMembersQuery,
  useGetOrganizationTenantsQuery,
  useInviteOrganizationMemberMutation,
} from 'gql/graphql';
import { iconByProduct } from 'features/subscriptions/utils';
import { ProductAccessRowStateType } from 'features/common/components/ProductAccessOptions/ProductAccessOptions';

export interface IMemberInviteValues {
  emails: string[];
  orgRole: typeof ORG_ROLES.ORG_ADMIN | typeof ORG_ROLES.ORG_USER;
}

const initialValues: IMemberInviteValues = {
  emails: [],
  orgRole: ORG_ROLES.ORG_USER,
};

const getUniqueEmails = (emails: string[]) =>
  Array.from(new Set(emails.map((email) => email.toLowerCase())));

function useInviteMember({ onClose }: { onClose: () => void }) {
  const [invitationErrors, setInvitationErrors] = useState<
    { email: string; message: string }[]
  >([]);
  const [inviteOrganizationMemberMutation, { loading }] =
    useInviteOrganizationMemberMutation();

  const { data: tenantsData, loading: tenantsLoading } =
    useGetOrganizationTenantsQuery({
      variables: {
        first: 100,
        env,
      },
    });

  const transformedTenants = useMemo(
    () =>
      tenantsData?.organization?.applications?.nodes?.map((tenant) => {
        const { name, iconUrl } =
          iconByProduct[tenant.productCode.toLowerCase()] ?? {};
        return {
          title: name,
          subTitle: tenant.displayName,
          icon: {
            iconUrl: iconUrl,
          },
          productCode: tenant.productCode,
          id: tenant.id,
          organizationId: tenant.organizationId,
          value: tenant.id,
          roles: tenant.product?.roles?.nodes?.map((role) => ({
            value: role.name,
            label: role.name,
          })),
        };
      }),
    [tenantsData?.organization?.applications?.nodes],
  );

  const toast = useToast();

  /**
   * Members email check
   */
  const { data: membersData } = useGetMembersQuery({
    variables: {
      skip: 0,
      take: 100,
    },
  });

  const { data } = useGetCurrentUserQuery();
  const inviterName = data?.user
    ? `${data.user.givenName} ${data.user.familyName}`
    : 'Unknown';

  const members = membersData?.organization?.members?.items ?? [];
  const membersEmailsPool = members.map((m) => m.email);
  const memberEmailExists = (email: string) =>
    membersEmailsPool.some(
      (memberEmail) => memberEmail.toLowerCase() === email.toLowerCase(),
    );

  const getValidEmails = (emails: string[]) => {
    const validEmails = emails.filter(
      (email) => !memberEmailExists(email) && !hasRestrictedEmail(email),
    );

    return getUniqueEmails(validEmails);
  };

  const [productAccessState, setProductAccessState] =
    useState<ProductAccessRowStateType>([]);

  const handleSubmit = useCallback(
    // @ts-ignore
    async (values) => {
      if (!values.emails?.length) {
        return;
      }

      const emailsToBeSent = getValidEmails(values.emails);

      // if all emails are invalid, stop the submission
      // TODO: improve this logic
      if (!emailsToBeSent.length) {
        return;
      }

      const invitations = emailsToBeSent.map((email) => ({
        inviteeEmail: email,
        inviterName,
        applicationRoles: productAccessState.map(({ product, accessType }) => ({
          roleName: accessType.value,
          applicationId: product.id,
          productCode: product.productCode,
        })),
        organizationRoles: [
          {
            roleName: values.orgRole,
          },
        ],
      }));

      inviteOrganizationMember(invitations);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getValidEmails, inviterName, productAccessState],
  );

  const formik = useFormik<IMemberInviteValues>({
    initialValues,
    onSubmit: handleSubmit,
    validate: handleValidate,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
  });
  const setProductAccessStateCb = useCallback(
    (data: any) => setProductAccessState(data),
    [],
  );

  const inviteOrganizationMember = useCallback(
    async (invitations: InviteOrganizationMemberMutationVariables[]) => {
      try {
        const invitePromises = await Promise.allSettled(
          invitations.map((invitation, i) =>
            inviteOrganizationMemberMutation({
              variables: invitation,
              ...(i === invitations.length - 1 && {
                refetchQueries: [GetInvitationsDocument],
              }),
            }),
          ),
        );

        const serverInvitationErrors = invitePromises
          //@ts-ignore
          .map(({ value }) => ({
            email:
              value?.data.inviteOrganizationMember.invitation?.inviteeEmail,
            message: value?.data.inviteOrganizationMember.errors?.[0].message,
          }))
          .filter((serverError) => !!serverError.message);

        setInvitationErrors(serverInvitationErrors);
        if (serverInvitationErrors.length > 0) {
          const invitationsWording =
            serverInvitationErrors.length === 1
              ? 'An invitation has'
              : 'Some invitations have';

          toast({
            description: `${invitationsWording} not been sent.`,
            status: 'warning',
          });
        } else {
          const invitationsWording =
            invitations.length === 1 ? 'invitation has' : 'invitations have';

          toast({
            description: `The ${invitationsWording} been sent.`,
            status: 'success',
          });

          onClose();
          // queryClient.invalidateQueries('organization/invitations');
          formik.resetForm();
          setProductAccessStateCb([]);
        }
      } catch (error: any) {
        const { networkError } = error ?? {};

        toast({
          description: networkError.message,
          title: `Error ${networkError.statusCode}`,
          status: 'error',
        });
      }
    },
    [
      formik,
      inviteOrganizationMemberMutation,
      onClose,
      setProductAccessStateCb,
      toast,
    ],
  );

  useEffect(() => {
    if (invitationErrors?.length > 0) {
      const message =
        invitationErrors.length > 1
          ? `${getEmailsPerMessage(invitationErrors)
              .map(
                ({ message }: { emails: string[]; message: string }) => message,
              )
              .join('\r\n')}`
          : invitationErrors[0]?.message;
      formik.setFieldError('emails', message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.setFieldError, invitationErrors]);

  const getAvailableOptions = useCallback(() => {
    const productIds = productAccessState.map((p) => p.product.id);
    const filteredProductIds = transformedTenants?.filter(
      (p) => !productIds.includes(p.id),
    );

    return filteredProductIds;
  }, [transformedTenants, productAccessState]);

  const ssoRestrictedEmails = useMemo(
    () =>
      validateSSOConnectionEmails({
        userEmails: formik.values.emails,
      }),
    [formik.values.emails],
  );

  const invalidEmails = [
    ...(formik.errors.emails ? invitationErrors.map(({ email }) => email) : []),
    ...formik.values.emails.filter(memberEmailExists),
    ...formik.values.emails.filter(hasRestrictedEmail),
    ...ssoRestrictedEmails.invalidSSOEmails,
  ];

  return {
    getValidEmails,
    formik,
    memberEmailExists,
    hasTenants: !!transformedTenants?.length,
    tenantsLoading,
    setProductAccessStateCb,
    getAvailableOptions,
    productAccessState,
    invalidEmails,
    loading,
    memberValidationError: getMemberValidationError(
      formik.values,
      membersEmailsPool,
      invitationErrors,
    ),
    restrictedEmailsValidationError: getRestrictedEmailsValidationError(
      formik.values,
    ),
    restrictedSSOEmailsError: ssoRestrictedEmails.error,
  };
}

export default useInviteMember;
