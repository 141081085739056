import { Flex, Heading, Image } from '@chakra-ui/react';

type EmptyStateProps = {
  text: string;
  dataTestId: string;
};

const EmptyState = ({ text, dataTestId }: EmptyStateProps) => (
  <Flex
    flexDir='column'
    justifyContent='center'
    alignItems='center'
    m='auto'
    data-testid={dataTestId}
  >
    <Image
      boxSize='24'
      src='https://sitecorecontenthub.stylelabs.cloud/api/public/content/5d2c4616a0304589818f265edc4471ce'
      alt='cactus'
    />
    <Flex justifyContent='center'>
      <Heading size='md'>{text}</Heading>
    </Flex>
  </Flex>
);

export default EmptyState;
