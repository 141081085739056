import { useFormik } from 'formik';
import {
  useGetConnection,
  IConnectionSaml,
  convertToIConnectionSamlViewModel,
} from '../api';
import { useParams } from 'react-router';
import * as Yup from 'yup';
import { useAuthMutation } from 'features/common/apiUtils/queryHelper';
import { connectionsApi } from '../api/endpoints';
import { getIdentityBaseUrl } from '@sitecore-ui/portal-singular';
import { env } from 'features/common/config/envConfig';

const defaultValues = {
  strategy: 'samlp',
  emailDomains: [],
  displayName: '',
  metadataSelection: 'url',
  metadataUrl: '',
  metadataXml: '',
  fieldsMap: undefined,
};

const baseUrl = getIdentityBaseUrl(env.toLowerCase());

const usePatchConnection = ({
  connectionId = '',
}: {
  connectionId?: string;
}) => {
  const { patch_connection } = connectionsApi;
  const url = `${baseUrl}${patch_connection.url(connectionId)}`;

  return useAuthMutation(
    (axiosInstance) => (payload) =>
      axiosInstance?.patch<IConnectionSaml>(
        url,
        payload as unknown as IConnectionSaml,
      ),
    {
      onError: () => {
        return;
      },
      onSuccess: () => {
        return;
      },
    },
    patch_connection.scope,
  );
};

export const usePatchSamlConnection = () => {
  const { id: connectionId } = useParams<{ id: string }>();

  const {
    data: connection,
    isLoading,
    isFetched,
    error: connectionError,
  } = useGetConnection(connectionId, {
    enabled: true,
  });

  const { isLoading: isEditLoading } = usePatchConnection({
    connectionId,
  });
  const { metadataUrl, signInEndpoint } =
    (connection?.data?.options as Record<string, any>)?.[
      connection?.data?.strategy ?? ''
    ] ?? {};
  const isMetadataXMLOptional = Boolean(signInEndpoint && !metadataUrl);

  const {
    touched,
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    isValidating,
    isSubmitting,
    dirty,
    setFieldValue,
    setFieldTouched,
    initialValues,
    validateForm,
    submitForm,
    setSubmitting,
  } = useFormik({
    initialValues:
      (isFetched &&
        connection?.data &&
        convertToIConnectionSamlViewModel(
          connection.data as unknown as IConnectionSaml,
        )) ||
      defaultValues,
    validateOnChange: false,
    enableReinitialize: true,
    // eslint-disable-next-line no-empty-function
    onSubmit: () => {},
    validationSchema: Yup.object({
      metadataSelection: Yup.string().oneOf(['url', 'xml']),
      metadataUrl: Yup.string().when('metadataSelection', {
        is: 'url',
        then: Yup.string().required('Required'),
        otherwise: Yup.string().optional(),
      }),
      metadataXml: Yup.string().when('metadataSelection', {
        is: (selection: string) =>
          selection === 'xml' && !isMetadataXMLOptional,
        then: Yup.string().required('Required'),
        otherwise: Yup.string().optional(),
      }),
      fieldsMap: Yup.array().optional(),
    }),
  });

  return {
    connectionData: connection?.data,
    touched,
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    isValid,
    isValidating,
    isSubmitting,
    dirty,
    isLoading: isEditLoading || isLoading,
    isConnectionLoading: isLoading,
    isFetched,
    connectionError,
    initialValues,
    validateForm,
    submitForm,
    setSubmitting,
  };
};
