import { gql } from '@apollo/client';

export const GET_TENANTS = gql`
  query GetTenants($first: Int, $after: String, $env: String) {
    user {
      applications(
        first: $first
        after: $after
        where: {
          labels: { some: { key: { eq: "Environment" }, value: { eq: $env } } }
        }
      ) {
        totalCount
        edges {
          cursor
          node {
            id
            appearance {
              web {
                actions(
                  first: 1
                  where: { category: { value: { eq: "Direct Links" } } }
                ) {
                  nodes {
                    name
                    icon {
                      src
                      type
                    }
                    displayName
                  }
                }
              }
            }
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        nodes {
          id
          name
          displayName
          organizationId
          state
          systemId
          productCode
          labels {
            key
            value
          }
          appearance {
            web {
              actions {
                nodes {
                  name
                  color
                  displayName
                  location
                  category
                  description
                  link {
                    type
                    to
                  }
                  icon {
                    src
                    type
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
