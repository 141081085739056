import { useAuthQuery } from '../../common/apiUtils/queryHelper';
import { getContextApiUrl } from '../utils';

export const useGetListSitecoreContexts = () => {
  const url = `${getContextApiUrl()}/contexts`;
  return useAuthQuery(['contexts/list'], async (axiosInstance) => {
    const response = await axiosInstance?.get(url);
    return response?.data;
  });
};
