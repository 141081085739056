import { IResponse } from '@sitecore-ui/portal-singular';
import { useAuthQuery } from 'features/common/apiUtils/queryHelper';
import { auditLogApi } from '../api/endpoints';
import { AxiosResponse } from 'axios';
import { Log } from '../types';

export const useGetAuditLogs = ({
  page,
  pageSize,
  action = 'user_login',
  entityType = 'support_user',
  enabled = true,
}: {
  page: number;
  pageSize: number;
  action?: string;
  entityType?: string;
  enabled?: boolean;
  notifyOnChangeProps?: string[];
}) => {
  const { get_logs } = auditLogApi;
  const url = get_logs.url({
    page,
    pageSize,
    action,
    entityType,
  });

  return useAuthQuery(
    ['logs/read', page],
    async (axiosInstance) => {
      return axiosInstance?.get<AxiosResponse<IResponse<Log>>>(url);
    },
    { enabled },
    get_logs.scope,
  );
};
