import { Flex, Heading, Text, Button, Link } from '@chakra-ui/react';
import { CustomHostnamesTable } from './CustomHostnamesTable';
import { useHistory } from 'react-router';
import { customHostnamesCreateRoute } from './routes';

export const CustomHostnames = () => {
  const history = useHistory();
  return (
    <Flex flexDir='column' height='100%'>
      <Flex justifyContent='space-between' width='100%'>
        <Flex direction='column' alignSelf='start'>
          <Heading as='h1' data-testid='customHostnames-heading'>
            Custom hostnames
          </Heading>
          <Text maxW='xl' my='8' data-testid='customHostnames-description'>
            Custom hostnames allow organizations to align their domain names
            with their brand, improving SEO, ensuring first-party tracking,
            reducing CORS and blocklisting issues, and simplifying API access.
            For more details, see{' '}
            <Link
              isExternal
              href='https://doc.sitecore.com/portal/en/developers/sitecore-cloud-portal/custom-hostnames.html'
            >
              custom hostnames documentation
            </Link>
            .
          </Text>
        </Flex>
        <Button
          variant='solid'
          flexShrink={0}
          data-testid='create-custom-hostname-button'
          data-behavior-analytics-id='create custom hostname'
          onClick={() =>
            history.push({
              pathname: customHostnamesCreateRoute,
              search: history.location.search,
            })
          }
        >
          Create custom hostname
        </Button>
      </Flex>
      <CustomHostnamesTable />
    </Flex>
  );
};
