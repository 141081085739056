import { useAppAccess } from 'features/common/components/OrganizationAppAccess';
import { ORG_ROLES } from 'features/common/components/OrganizationAppAccess/resource';

export function useMemberGuards() {
  const { hasOrgAdminAccess, hasOrgOwnerAccess, claims } = useAppAccess();

  const _isSameMemberCb = (userId: string | undefined) =>
    claims?.sub === userId;

  // Grant access to members to perform diferrent actions.
  // For example used to reveal the remove access button, or to disable the app access select on member details
  // TODO: differentiate the actions example: edit, delete etc
  const allowMemberAccess = (
    userId: string | undefined,
    organizationRole: string,
  ) => {
    if (_isSameMemberCb(userId)) {
      return false;
    }

    // Admin has only access on other users and admins
    if (
      hasOrgAdminAccess &&
      [ORG_ROLES.ORG_ADMIN, ORG_ROLES.ORG_USER].includes(organizationRole)
    ) {
      return true;
    }

    // Owner has access on everyone except from himself
    if (hasOrgOwnerAccess) {
      return true;
    }

    return false;
  };

  return {
    allowMemberAccess,
  };
}

export const userAccessLimit = 25;
