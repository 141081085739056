import { env } from 'features/common/config/envConfig';
import { getIn } from 'formik';
import { Code, FormHelperText } from '@chakra-ui/react';
import { IOidcConnection, IConnectionViewModel } from './api';
import { ssoRoute } from './sso-routes';
import { history } from 'features/common/history/history';
import { isValidDomain } from 'features/common/validations/matchers';

export const isDomain = (input: string) => isValidDomain.test(input);

const fakeMaskedValue = 'abcdefghijklmnop';

export const connectionInputFields = [
  {
    label: 'Email domains',
    name: 'emailDomains',
    description:
      "A connection can include up to 50 email domains. When a connection is created, you can't add, delete, or modify its associated domains.",
    testId: 'domainInputControl',
    isEditable: false,
    isVisible: () => true,
  },
  {
    label: 'Connection type',
    name: 'options.oidc.type',
    description: (
      <>
        <FormHelperText data-testid='front-channel-input-helper'>
          Front Channel (Implicit Flow) uses{' '}
          <Code color='chara-subtle-text'>response_mode=form_post</Code> and
          <Code color='chara-subtle-text'>response_type=id_token</Code>.
        </FormHelperText>
        <FormHelperText data-testid='back-channel-input-helper'>
          Back Channel (Authorization Code Flow) uses{' '}
          <Code color='chara-subtle-text'>response_type=code</Code>.
        </FormHelperText>
      </>
    ),
    testId: 'connectionTypeInputControl',
    isEditable: true,
    isVisible: () => true,
    options: [
      { label: 'Front Channel', value: 'FrontChannel' },
      { label: 'Back Channel', value: 'BackChannel' },
    ],
  },
  {
    label: 'Issuer URL',
    name: 'options.oidc.discoveryUrl',
    description:
      'The URL of the discovery document of the OpenID Connect provider you want to connect with.',
    testId: 'issuerUrlInputControl',
    isEditable: true,
    isVisible: () => true,
  },
  {
    label: 'Scopes',
    name: 'options.oidc.scope',
    description: 'Scopes to be requested during authentication.',
    testId: 'scopesInputControl',
    isEditable: true,
    isVisible: () => true,
  },
  {
    label: 'Client ID',
    name: 'options.oidc.clientId',
    description: (
      <FormHelperText data-testid='client-id-input-helper'>
        The <Code color='chara-subtle-text'>client ID</Code> of the app created
        with your identity provider.
      </FormHelperText>
    ),
    testId: 'clientIdInputControl',
    isEditable: true,
    isVisible: () => true,
  },
  {
    label: 'Client secret',
    name: 'options.oidc.clientSecret',
    description: (
      <FormHelperText data-testid='client-secret-input-helper'>
        The <Code color='chara-subtle-text'>client secret</Code> of the app
        created with your identity provider. Required if using Back Channel.
      </FormHelperText>
    ),
    testId: 'clientSecretInputControl',
    isMasked: true,
    isEditable: true,
    isVisible: (values: any) => values?.options?.oidc?.type !== 'FrontChannel',
    fakeMaskedValue: fakeMaskedValue,
  },
];

export const getLoginUrl = () => {
  switch (env) {
    case 'dev':
      return 'https://login-staging.sitecore-staging.cloud';
    case 'staging':
      return 'https://login-staging.sitecore-staging.cloud';
    case 'pre-production':
      return 'https://login-beta.sitecorecloud.io';
    case 'production':
      return 'https://login.sitecorecloud.io';
    default:
      console.error('Unexpected environment value.');
      return '';
  }
};

/**
 * Checks if the field is required, based on the validation schema
 * @param name
 * @param values - formik values
 */
export function isRequiredField(name: string, values: any, schema: any) {
  const validationSchema = schema;

  const accessor = name.split('.').join('.fields.');
  const field = getIn(validationSchema.describe().fields, accessor);

  if (!field) {
    return false;
  }
  // This is a special case for the OIDC connection clientSecret field because it has conditional validation and cannont be calculated directly from the yup schema description
  const isClientSecretRequired =
    name === 'options.oidc.clientSecret' &&
    getIn(values, 'options.oidc.type') === 'BackChannel';

  if (isClientSecretRequired) {
    return true;
  }

  const isRequired = field.tests.some(
    (test: { name: string }) => test.name === 'required',
  );
  return isRequired;
}

const constructPayloadPerStrategy = {
  oidc: (values: IConnectionViewModel) => {
    const {
      clientSecret,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      typeInitial,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      isEditMode,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      clientSecretInteractionMode,
      ...restOidc
    } = values.options.oidc;

    return {
      ...values,
      options: {
        ...values.options,
        oidc: {
          ...restOidc,
          ...(restOidc.type !== 'FrontChannel'
            ? { clientSecret: clientSecret }
            : {}),
        },
      },
    } as IConnectionViewModel;
  },
  samlp: (values: IOidcConnection) => values,
};

export const constructSSOPayload = (strategy: string) => (values: any) =>
  constructPayloadPerStrategy[
    strategy as keyof typeof constructPayloadPerStrategy
  ](values);

export const redirectToConfigureModal = (
  strategy: string | undefined,
  id: string | undefined,
) => {
  if (strategy && id) {
    history.push({
      pathname: `${ssoRoute}/${strategy}/${id}/edit`,
      search: history.location.search,
    });
  }
};
