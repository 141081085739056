import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  Text,
  AlertDialogBody,
  AlertDialogFooter,
  ButtonGroup,
  Button,
  ModalCloseButton,
  useToast,
  FormControl,
  FormLabel,
  Input,
  Flex,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router';
import { customHostnamesRoute } from './routes';
import { useDeleteHostname } from './hooks/useDeleteHostname';
import { AxiosError } from 'axios';
import { EdgeApiError } from './types';
import FieldError from 'features/common/components/Form/FieldError';

export const HostnameDeletionModal = () => {
  const [value, setValue] = useState('');
  const [touched, setTouched] = useState(false);

  const lessDestructiveRef = useRef(null);
  const history = useHistory();
  const { hostname } = useParams<{ hostname: string }>();

  const { mutate: deleteHostnameMutation, isLoading } =
    useDeleteHostname(hostname);
  const toast = useToast();
  const queryClient = useQueryClient();

  const onClose = () =>
    history.replace({
      pathname: customHostnamesRoute,
      search: history.location.search,
    });

  const onDelete = () =>
    deleteHostnameMutation(undefined, {
      onSuccess: () => {
        queryClient.invalidateQueries('hostnames/list');
        onClose();
        toast({
          description: 'Hostname successfully deleted',
          status: 'success',
        });
      },
      //@ts-ignore
      onError: (error: AxiosError<EdgeApiError>) => {
        toast({
          description: error?.response?.data?.detail ?? 'Something went wrong',
          title: 'Error',
          status: 'error',
        });
      },
    });

  const isInputInvalid = value !== hostname;
  return (
    <AlertDialog
      isOpen={true}
      onClose={onClose}
      leastDestructiveRef={lessDestructiveRef}
      size='xl'
      autoFocus={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent data-testid='hostname-deletion-modal' gap={2}>
          <AlertDialogHeader>
            <Text data-testid='hostname-deletion-modal-title'>
              Delete custom hostname
            </Text>
          </AlertDialogHeader>
          <ModalCloseButton />
          <AlertDialogBody>
            <Flex direction='column' gap={4}>
              <Text data-testid='hostname-deletion-name'>
                Are you sure you want to delete <strong>{hostname}</strong>?
              </Text>
              <Text data-testid='hostname-deletion-details'>
                All websites and services relying on this hostname will lose
                their connection to the domain, making them inaccessible to
                users.
              </Text>
              <FormControl isInvalid={isInputInvalid && touched}>
                <FormLabel data-testid='label-hostname'>
                  Type "{hostname}" to delete
                </FormLabel>
                <Input
                  name='hostname'
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  onBlur={() => setTouched(true)}
                  data-testid='hostname-deletion-input'
                />
                <FieldError
                  touched={touched}
                  error='The input value doesn’t match the hostname'
                  testId='hostnam-input-error'
                />
              </FormControl>
            </Flex>
          </AlertDialogBody>
          <AlertDialogFooter>
            <ButtonGroup>
              <Button
                variant='ghost'
                data-testid='hostname-deletion-modal-cancel-button'
                onClick={onClose}
                isDisabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                colorScheme='danger'
                variant='solid'
                data-testid='hostname-deletion-modal-delete-button'
                onClick={onDelete}
                isDisabled={isLoading || isInputInvalid}
                isLoading={isLoading}
              >
                Delete
              </Button>
            </ButtonGroup>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
