import { ApmRoute } from '@elastic/apm-rum-react';
import * as React from 'react';
import { Redirect, match } from 'react-router';
import { useFeatures } from 'lib/featureFlags/features';
import {
  adminPath,
  contextMappingPath,
} from '../../admin/components/admin-paths';

interface Props {
  component: React.ComponentType<any>;
  path: string;
  exact?: boolean;
  match?: match<{ [p: string]: string | undefined }>;
}
export const ContextMappingDrawerRoute = ({
  component: Component,
  ...rest
}: Props) => {
  const { CONTEXT_MAPPING: isCtxMappingEnabled } = useFeatures();
  return (
    <ApmRoute
      {...rest}
      render={({ match, ...restProps }) => {
        return !isCtxMappingEnabled ? (
          <div>
            <Redirect to='/no-access' />
          </div>
        ) : (
          <Component match={match} {...restProps} />
        );
      }}
    />
  );
};

export const ctxMappingRoute = `${adminPath}${contextMappingPath}`;
export const ctxMappingCreateRoute = `${ctxMappingRoute}/create`;
export const getCtxMappingEditRoute = (sitecoreContextId?: string) =>
  `${ctxMappingRoute}/${
    sitecoreContextId ? sitecoreContextId : ':sitecoreContextId'
  }/edit`;
export const getCtxMappingDeleteRoute = (sitecoreContextId?: string) =>
  `${ctxMappingRoute}/${
    sitecoreContextId ? sitecoreContextId : ':sitecoreContextId'
  }/delete`;
