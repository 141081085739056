import { ORG_ROLES } from 'features/common/components/OrganizationAppAccess/resource';

export const ORG_ROLE_LABEL = {
  OWNER: 'Owner',
  ADMIN: 'Admin',
  USER: 'User',
};

export const ORG_ROLE_OPTIONS = [
  {
    value: ORG_ROLES.ORG_OWNER,
    label: ORG_ROLE_LABEL.OWNER,
    desc: 'Can access everything',
  },
  {
    value: ORG_ROLES.ORG_ADMIN,
    label: ORG_ROLE_LABEL.ADMIN,
    desc: 'Can access all apps',
  },
  {
    value: ORG_ROLES.ORG_USER,
    label: ORG_ROLE_LABEL.USER,
    desc: 'Can only access the apps you specify',
  },
];
