import {
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputRightElement,
  Stack,
  Tooltip,
  useDisclosure,
  Text,
  InputGroup,
  IconButton,
  Icon,
  FormHelperText,
  Radio,
  HStack,
  Divider,
  Tag,
  Select,
  Button,
  RadioGroup,
  FormErrorMessage,
  FormErrorIcon,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalHeader,
  ModalFooter,
  ButtonGroup,
  Textarea,
  Alert,
  AlertDescription,
  AlertIcon,
  Skeleton,
  useToast,
} from '@chakra-ui/react';
import { useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { ssoRoute } from '../sso-routes';
import { mdiArrowLeftRight, mdiPlus, mdiTrashCanOutline } from '@mdi/js';
import { getAuthSettings } from 'features/common/config/envConfig';
import {
  addNewAttributeLine,
  constructSamlPatchPayload,
  editAttributeLine,
  getMetadata,
  getSavedMetadataTypeSelection,
  removeAttributeLine,
  userAttributeOptions,
} from './saml-utils';
import { usePatchSamlConnection } from './use-patch-saml-connection';
import { CopyClipboardIconTooltip } from '../../common/components/CopyClipboardIconTooltip';
import DiscardChangesModal from '../../common/components/DiscardModal/DiscardChangesModal';
import ConnectionModalContainer from '../components/connection-modal-container';
import ClaimsMapping from '../claimsMapping/claims-mapping';
import useClaimMappings from '../claimsMapping/use-claims-mapping';
import {
  useGetClaimsMapping,
  usePutClaimsMapping,
} from '../claimsMapping/use-claims-mapping-data';
import isEmpty from 'lodash/isEmpty';
import { useQueryClient } from 'react-query';
import {
  convertToIConnectionPatchDTO,
  displayConnectionErrorAlert,
  usePatchConnection,
} from '../api';
import { useFeatures } from 'lib/featureFlags/features';
import { handleSuccess, handleError } from '../utils/form-handlers';
import { useMainStateDispatch } from 'features/main/context';

const SSOSamlConnectionModal = () => {
  const { domain } = getAuthSettings();
  const history = useHistory();
  const { isOpen, onClose, onOpen: onOpenDiscard } = useDisclosure();
  const queryClient = useQueryClient();
  const toast = useToast();
  const { CLAIMS_MAPPING: isClaimsMappingFlagEnabled } = useFeatures();
  const [state] = useMainStateDispatch();

  const isClaimsMappingEnabled =
    isClaimsMappingFlagEnabled &&
    state.env?.WITH_XM_TENANT &&
    state?.env?.WITH_XM_CLOUD_CONTEXT;

  const {
    touched: samlTouched,
    values,
    errors,
    handleChange,
    isValidating,
    isSubmitting,
    dirty: samlDirty,
    isLoading,
    setFieldValue,
    connectionData,
    isConnectionLoading,
    validateForm: validateSaml,
    setSubmitting: setPatchConnectionSubmitting,
  } = usePatchSamlConnection();
  const { data: claimsMappingsData } = useGetClaimsMapping({
    connectionId: connectionData?.id,
  });
  const { mutateAsync: patchConnection } = usePatchConnection({
    connectionId: connectionData?.id,
  });
  const { mutateAsync: putClaimsMapping } = usePutClaimsMapping();
  const claimsMappings = claimsMappingsData?.data.configuration;
  const {
    values: mappings,
    options: mappingRequestOptions,
    dirty: mappingsDirty,
    errors: mappingsErrors,
    validateForm: validateMappings,
    addClaimMappingHandler,
    removeClaimMappingHandler,
    addSourceClaimHandler,
    onDeleteSourceRowHandler,
    addTargetClaimHandler,
    onDeleteTargetRowHandler,
    onSourceInputChange,
    onTargetInputChange,
    setSubmitting: setClaimsMappingSubmitting,
  } = useClaimMappings(claimsMappings ?? []);

  const onCloseDrawer = useCallback(async () => {
    if (samlDirty || mappingsDirty) return onOpenDiscard();
    history.push({ pathname: ssoRoute, search: history.location.search });
    queryClient.removeQueries('claims-mappings');
    onClose();
  }, [samlDirty, mappingsDirty, onOpenDiscard, history, queryClient, onClose]);

  const initialFocusRef = useRef(null);

  const { metadataUrl, entityId, assertionConsumerService } = getMetadata(
    domain,
    values.displayName || '',
  );

  const isMetadataXMLInvalid = Boolean(
    errors.metadataXml && samlTouched.metadataXml,
  );

  const { signInEndpoint, metadataUrl: metadataUrlResponse } = connectionData
    ? 'samlp' in connectionData.options
      ? connectionData.options.samlp
      : { signInEndpoint: null, metadataUrl: null }
    : { signInEndpoint: null, metadataUrl: null };

  const metadataTypeSaved = getSavedMetadataTypeSelection(
    signInEndpoint,
    metadataUrlResponse,
  );

  const addAttributeMappingHandler = () => {
    const newAttributes = addNewAttributeLine(values.fieldsMap || []);
    setFieldValue('fieldsMap', newAttributes);
  };

  const onAttributeRowHandler = (
    newMapping: string | undefined,
    newValue: string | undefined,
    key: number,
  ) => {
    const newAttributes = editAttributeLine(
      values.fieldsMap || [],
      newMapping,
      newValue,
      key,
    );
    setFieldValue('fieldsMap', newAttributes);
  };

  const onRemoveAttributeHandler = (position: number) => {
    const newAttributes = removeAttributeLine(values.fieldsMap || [], position);

    setFieldValue('fieldsMap', newAttributes);
  };

  const onDiscard = useCallback(() => {
    onCloseDrawer();
    history.push({ pathname: ssoRoute, search: history.location.search });
  }, [history, onCloseDrawer]);

  const hasSubmittedMetadataUrl = Boolean(
    values.metadataSelection === 'url' &&
      connectionData &&
      'samlp' in connectionData.options &&
      connectionData.options.samlp.metadataUrl &&
      connectionData.options.samlp.metadataUrl?.length > 0,
  );

  const isMetadataUrlInvalid = Boolean(
    (errors.metadataUrl && !hasSubmittedMetadataUrl) ||
      (errors.metadataUrl && samlTouched.metadataUrl),
  );

  /**
   * Disable the save button on these cases:
   * 1. isLoading is true
   * 2. metadataSelection is url and connection data for the metadata url hase been already configured and is   not empty
   * 3. the form is not dirty
   */
  const isSaveDisabled =
    isLoading ||
    ((hasSubmittedMetadataUrl ? false : !samlDirty) && !mappingsDirty);

  const shouldDisplaySkeleton = !isConnectionLoading;

  const onHandleFormSubmit = async () => {
    const mappingsErrors = isClaimsMappingEnabled
      ? await validateMappings()
      : {};
    const samlErrors = await validateSaml();

    if (isEmpty(samlErrors) && isEmpty(mappingsErrors)) {
      const newValues = constructSamlPatchPayload(values);
      setPatchConnectionSubmitting(true);

      try {
        const { data: patchData, status: patchStatus } = await patchConnection(
          // @ts-ignore
          convertToIConnectionPatchDTO(newValues, connectionData),
          {},
        );

        if (patchStatus === 200) {
          if (isClaimsMappingEnabled) {
            setClaimsMappingSubmitting(true);
            const { data: claimsData, status: claimsStatus } =
              await putClaimsMapping(
                {
                  payload: { configuration: mappings },
                  connectionId: patchData.id,
                },
                mappingRequestOptions,
              );

            if ([201, 200].includes(claimsStatus)) {
              handleSuccess(history, toast, queryClient);
            } else {
              displayConnectionErrorAlert(toast, claimsData);
            }
          } else {
            handleSuccess(history, toast, queryClient);
          }
        } else {
          // @ts-ignore
          displayConnectionErrorAlert(toast, patchData);
        }
      } catch (error) {
        handleError(error, toast);
      } finally {
        setPatchConnectionSubmitting(false);
        setClaimsMappingSubmitting(false);
      }
    }
  };

  return (
    <>
      <Modal
        size='full'
        isOpen={true}
        onClose={onCloseDrawer}
        initialFocusRef={initialFocusRef}
        scrollBehavior='inside'
        id={'sso-saml-connection-modal'}
      >
        <form
          data-testid='sso-saml-connection-form'
          id='sso-saml-connection-form'
        >
          <ModalOverlay />
          <ModalContent data-testid='sso-saml-connection-modal'>
            <ModalHeader>Configure SAML connection</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <ConnectionModalContainer
                navigationButtons={[
                  {
                    title: 'Metadata',
                    goToAnchor: 'metadata-heading',
                  },
                  {
                    title: 'Attribute mapping',
                    goToAnchor: 'attribute-mapping-heading',
                  },
                  ...(isClaimsMappingEnabled
                    ? [
                        {
                          title: 'Claims mapping',
                          goToAnchor: 'claims-mapping',
                        },
                      ]
                    : []),
                ]}
              >
                <Stack spacing='10'>
                  <Heading id='metadata-heading' size='lg'>
                    Metadata
                  </Heading>
                  <Stack spacing='6'>
                    <Heading data-testid='metadata-step-one' size='md' as='h3'>
                      Step 1: Register Sitecore Cloud Portal with your identity
                      provider
                    </Heading>
                    <Text>
                      Create a SAML app (also called SAML connection) in your
                      identity provider using following Sitecore metadata.
                      Depending on your identity provider, you'll need the SP
                      metadata URL or both the ACS URL and Audience URI.
                    </Text>
                    <FormControl>
                      <FormLabel>Service provider (SP) metadata URL</FormLabel>
                      <Skeleton isLoaded={shouldDisplaySkeleton}>
                        <InputGroup>
                          <Input isReadOnly value={metadataUrl} />
                          <InputRightElement>
                            <CopyClipboardIconTooltip
                              inputValue={metadataUrl}
                              dataTestId='service-provider'
                            />
                          </InputRightElement>
                        </InputGroup>
                      </Skeleton>
                    </FormControl>
                    <FormControl>
                      <FormLabel>Audience URI</FormLabel>
                      <Skeleton isLoaded={shouldDisplaySkeleton}>
                        <InputGroup>
                          <Input isReadOnly value={entityId} />
                          <InputRightElement>
                            <CopyClipboardIconTooltip
                              inputValue={entityId}
                              dataTestId='audience-url'
                            />
                          </InputRightElement>
                        </InputGroup>
                        <FormHelperText>
                          (also called service provider entity ID or audience
                          restriction)
                        </FormHelperText>
                      </Skeleton>
                    </FormControl>
                    <FormControl>
                      <FormLabel>
                        Assertion Consumer Service (ACS) URL
                      </FormLabel>
                      <Skeleton isLoaded={shouldDisplaySkeleton}>
                        <InputGroup>
                          <Input isReadOnly value={assertionConsumerService} />
                          <InputRightElement>
                            <CopyClipboardIconTooltip
                              inputValue={assertionConsumerService}
                              dataTestId='assertion-consumer'
                            />
                          </InputRightElement>
                        </InputGroup>
                      </Skeleton>
                      <FormHelperText>
                        (also called service provider sign-on callback,
                        post-back URL, or callback URL)
                      </FormHelperText>
                    </FormControl>
                  </Stack>
                  <Stack spacing='6'>
                    <Heading data-testid='metadata-step-two' size='md' as='h3'>
                      Step 2: Add the identity provider metadata
                    </Heading>
                    <RadioGroup
                      id='metadataSelection'
                      name='metadataSelection'
                      onChange={(value) =>
                        setFieldValue('metadataSelection', value)
                      }
                      value={values.metadataSelection}
                    >
                      <HStack spacing='6'>
                        <Skeleton isLoaded={shouldDisplaySkeleton}>
                          <Radio
                            {...(values.metadataSelection === 'url' && {
                              'data-testid': 'metadata-selected',
                            })}
                            value='url'
                          >
                            Metadata URL
                          </Radio>
                        </Skeleton>
                        <Skeleton isLoaded={shouldDisplaySkeleton}>
                          <Radio
                            {...(values.metadataSelection === 'xml' && {
                              'data-testid': 'metadata-selected',
                            })}
                            value='xml'
                          >
                            Metadata XML
                          </Radio>
                        </Skeleton>
                      </HStack>
                    </RadioGroup>
                    {values.metadataSelection === 'url' && (
                      <Skeleton isLoaded={shouldDisplaySkeleton}>
                        <FormControl
                          isRequired
                          isInvalid={isMetadataUrlInvalid}
                        >
                          <FormLabel>Identity provider metadata URL</FormLabel>
                          <Input
                            id='metadataUrl'
                            name='metadataUrl'
                            onChange={handleChange}
                            value={values.metadataUrl}
                            isInvalid={isMetadataUrlInvalid}
                          />
                          <FormErrorMessage data-testid='metadata-url-required'>
                            <FormErrorIcon />
                            {errors.metadataUrl}
                          </FormErrorMessage>
                        </FormControl>
                      </Skeleton>
                    )}
                    {values.metadataSelection === 'xml' && (
                      <>
                        {metadataTypeSaved === 'xml' && (
                          <Alert data-testid='metadata-xml-alert'>
                            <AlertIcon />
                            <AlertDescription>
                              XML metadata file content was already added. If
                              you add new content, it will override the existing
                              XML metadata file content.
                            </AlertDescription>
                          </Alert>
                        )}
                        <FormControl
                          isRequired={!(signInEndpoint && !metadataUrlResponse)}
                          isInvalid={isMetadataXMLInvalid}
                        >
                          <FormLabel>
                            Identity provider metadata XML file content
                          </FormLabel>
                          <Textarea
                            data-testid='metadata-xml-textarea'
                            name='metadataXml'
                            onChange={handleChange}
                            value={values.metadataXml}
                            isInvalid={isMetadataXMLInvalid}
                            placeholder={
                              metadataTypeSaved === 'xml'
                                ? 'To override the existing XML metadata file content, add new content here'
                                : ''
                            }
                          />
                          <FormErrorMessage data-testid='metadata-xml-required'>
                            <FormErrorIcon />
                            {errors.metadataXml}
                          </FormErrorMessage>
                        </FormControl>
                      </>
                    )}
                  </Stack>
                  <Divider my='4' />
                  <Heading id='attribute-mapping-heading' size='lg'>
                    Attribute mapping
                  </Heading>
                  <Stack spacing='6'>
                    <Text>
                      Sitecore expects the following attributes in your identity
                      provider’s SAML response:
                    </Text>
                    <HStack>
                      {userAttributeOptions.map((attribute) => (
                        <Tag key={attribute}>{attribute}</Tag>
                      ))}
                    </HStack>
                    <Link
                      data-testid='attribute-mapping-link'
                      fontSize='sm'
                      href='https://doc.sitecore.com/portal/en/developers/sitecore-cloud-portal/introduction-to-the-sitecore-cloud-portal.html?contextId=attribute-mapping-saml'
                      isExternal
                    >
                      Attribute mapping information
                    </Link>
                    <Text>
                      If your identity provider’s SAML response includes these
                      attributes, no further configuration is required and you
                      can test your connection now.
                    </Text>
                    <Text>
                      If not, you need to modify your identity provider’s SAML
                      response to include these attributes by configuring
                      attribute mapping within your identity provider.
                    </Text>
                    <Text>
                      If your identity provider does not support attribute
                      mapping, you can map the attributes below. Choose the
                      Sitecore attribute to map, then enter the corresponding
                      identity provider attribute name.
                    </Text>
                    {values.fieldsMap &&
                      values.fieldsMap.map((attribute, key) => (
                        <HStack key={key} align='end'>
                          <FormControl>
                            <FormLabel>Sitecore attribute</FormLabel>
                            <Select
                              data-testid={`sitecore-attribute-select-${key}`}
                              onChange={(event) => {
                                onAttributeRowHandler(
                                  event.target.value,
                                  undefined,
                                  key,
                                );
                              }}
                              value={attribute.mapping}
                            >
                              {userAttributeOptions.map((οption) => (
                                <option key={οption} value={οption}>
                                  {οption}
                                </option>
                              ))}
                            </Select>
                          </FormControl>
                          <Icon
                            color='chakra-placeholder-color'
                            boxSize='10'
                            p='2'
                          >
                            <path d={mdiArrowLeftRight} />
                          </Icon>
                          <FormControl>
                            <FormLabel>
                              Identity provider attribute name
                            </FormLabel>
                            <Input
                              data-testid={`identity-provider-input-${key}`}
                              value={attribute.value}
                              onChange={(event) =>
                                onAttributeRowHandler(
                                  undefined,
                                  event.target.value,
                                  key,
                                )
                              }
                            />
                          </FormControl>
                          <Tooltip label='Remove'>
                            <IconButton
                              data-testid={`remove-attribute-icon-${key}`}
                              icon={
                                <Icon>
                                  <path d={mdiTrashCanOutline} />
                                </Icon>
                              }
                              variant='ghostColorOnHover'
                              colorScheme='danger'
                              aria-label={'Remove'}
                              onClick={() => onRemoveAttributeHandler(key)}
                            />
                          </Tooltip>
                        </HStack>
                      ))}
                    <Skeleton isLoaded={shouldDisplaySkeleton}>
                      <Button
                        data-testid='add-attribute-mapping'
                        alignSelf='start'
                        variant='outline'
                        colorScheme='primary'
                        onClick={addAttributeMappingHandler}
                        leftIcon={
                          <Icon>
                            <path d={mdiPlus} />
                          </Icon>
                        }
                      >
                        Add attribute mapping
                      </Button>
                    </Skeleton>
                  </Stack>

                  {isClaimsMappingEnabled && (
                    <>
                      <Divider my='4' />
                      <Stack spacing='6'>
                        <ClaimsMapping
                          connectionId={connectionData?.id}
                          mappings={mappings}
                          errors={mappingsErrors}
                          addClaimMappingHandler={addClaimMappingHandler}
                          removeClaimMappingHandler={removeClaimMappingHandler}
                          addSourceClaimHandler={addSourceClaimHandler}
                          onDeleteSourceRowHandler={onDeleteSourceRowHandler}
                          addTargetClaimHandler={addTargetClaimHandler}
                          onDeleteTargetRowHandler={onDeleteTargetRowHandler}
                          onSourceInputChange={onSourceInputChange}
                          onTargetInputChange={onTargetInputChange}
                        />
                      </Stack>
                    </>
                  )}
                </Stack>
              </ConnectionModalContainer>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  onClick={onCloseDrawer}
                  isDisabled={isValidating || isSubmitting}
                  variant='ghost'
                >
                  Cancel
                </Button>
                <Button
                  type='submit'
                  isLoading={isValidating || isSubmitting}
                  isDisabled={isSaveDisabled}
                  variant='solid'
                  data-behavior-analytics-id='SAML-Save-button'
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onHandleFormSubmit();
                  }}
                >
                  Save
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
      <DiscardChangesModal
        isOpen={isOpen}
        onClose={onClose}
        onDiscard={onDiscard}
      />
    </>
  );
};

export default SSOSamlConnectionModal;
