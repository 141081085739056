import { claimsMappingSchema } from './claims-mapping-validator';
import { useFormik } from 'formik';
import {
  AddSourceClaimHandler,
  AddTargetClaimHandler,
  Claim,
  Mapping,
  OnDeleteSourceRowHandler,
  OnDeleteTargetRowHandler,
  OnSourceInputChange,
  OnTargetInputChange,
  RemoveClaimMappingHandler,
} from './claim-mapping-types';
import { useToast } from '@chakra-ui/react';
import axios from 'axios';

const useClaimMappings = (mappings: Mapping[]) => {
  const toast = useToast();

  const {
    touched,
    values,
    errors,
    handleChange,
    setValues,
    dirty,
    setFieldValue,
    validateForm,
    handleSubmit,
    submitForm,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    initialValues: { mappings: mappings },
    validateOnChange: false,
    enableReinitialize: true,
    // eslint-disable-next-line no-empty-function
    onSubmit: () => {},
    validationSchema: claimsMappingSchema,
  });

  const getNewClaim = (): Claim => ({
    name: '',
    value: '',
  });

  const addClaimMappingHandler = () => {
    const newClaim = getNewClaim();
    const newMapping = [
      ...values.mappings,
      { sourceClaims: [], targetClaims: [newClaim] },
    ];
    setValues({ mappings: newMapping });
  };

  const removeClaimMappingHandler = ({
    indexToRemove,
  }: RemoveClaimMappingHandler) => {
    const newMappings = values.mappings.filter(
      (_, index) => index !== indexToRemove,
    );
    setFieldValue('mappings', newMappings);
  };

  const addSourceClaimHandler = ({ mappingIndex }: AddSourceClaimHandler) => {
    const newMappings = values.mappings.map((mapping, index) => {
      if (index === mappingIndex) {
        const newClaim = getNewClaim();

        return {
          sourceClaims: [...mapping.sourceClaims, newClaim],
          targetClaims: mapping.targetClaims,
        };
      }
      return mapping;
    });
    setFieldValue('mappings', newMappings);
  };

  const onDeleteSourceRowHandler = ({
    mappingIndex,
    sourceIndexToRemove,
  }: OnDeleteSourceRowHandler) => {
    const newMappings = values.mappings.map((mapping, index) => {
      if (index === mappingIndex) {
        return {
          sourceClaims: mapping.sourceClaims.filter(
            (_, index) => index !== sourceIndexToRemove,
          ),
          targetClaims: mapping.targetClaims,
        };
      }
      return mapping;
    });
    setFieldValue('mappings', newMappings);
  };

  const addTargetClaimHandler = ({ mappingIndex }: AddTargetClaimHandler) => {
    const newMappings = values.mappings.map((mapping, index) => {
      if (index === mappingIndex) {
        const newClaim = getNewClaim();

        return {
          sourceClaims: mapping.sourceClaims,
          targetClaims: [...mapping.targetClaims, newClaim],
        };
      }
      return mapping;
    });
    setFieldValue('mappings', newMappings);
  };

  const onDeleteTargetRowHandler = ({
    mappingIndex,
    targetIndexToRemove,
  }: OnDeleteTargetRowHandler) => {
    const newMappings = values.mappings.map((mapping, index) => {
      if (index === mappingIndex) {
        return {
          sourceClaims: mapping.sourceClaims,
          targetClaims: mapping.targetClaims.filter(
            (_, index) => index !== targetIndexToRemove,
          ),
        };
      }
      return mapping;
    });
    setFieldValue('mappings', newMappings);
  };

  const onSourceInputChange = ({
    mappingIndex,
    indexToChange,
    value,
    type,
  }: OnSourceInputChange) => {
    const newMappings = values.mappings.map((mapping, index) => {
      if (index === mappingIndex) {
        return {
          sourceClaims: mapping.sourceClaims.map((claim, claimsIndex) =>
            claimsIndex === indexToChange ? { ...claim, [type]: value } : claim,
          ),
          targetClaims: mapping.targetClaims,
        };
      }
      return mapping;
    });
    setFieldValue('mappings', newMappings);
  };

  const onTargetInputChange = ({
    mappingIndex,
    indexToChange,
    value,
    type,
  }: OnTargetInputChange) => {
    const newMappings = values.mappings.map((mapping, index) => {
      if (index === mappingIndex) {
        return {
          sourceClaims: mapping.sourceClaims,
          targetClaims: mapping.targetClaims.map((claim, claimsIndex) =>
            claimsIndex === indexToChange ? { ...claim, [type]: value } : claim,
          ),
        };
      }

      return mapping;
    });

    setFieldValue('mappings', newMappings);
  };

  const options = {
    onError: (error: unknown) => {
      if (axios.isAxiosError(error) && error.response) {
        const response = error.response as {
          data: { title: any; detail: any };
        };
        toast({
          title: response.data.title,
          description: response.data.detail,
          status: 'error',
        });
      } else {
        toast({
          title: 'An unexpected error occurred',
          description: 'Please try again later.',
          status: 'error',
        });
      }

      setSubmitting(false);
    },
  };

  return {
    values: values.mappings,
    options,
    errors,
    touched,
    dirty,
    handleChange,
    validateForm,
    handleSubmit,
    submitForm,
    addClaimMappingHandler,
    removeClaimMappingHandler,
    addSourceClaimHandler,
    onDeleteSourceRowHandler,
    addTargetClaimHandler,
    onDeleteTargetRowHandler,
    onSourceInputChange,
    onTargetInputChange,
    isSubmitting,
    setSubmitting,
  };
};

export default useClaimMappings;
