import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Divider,
  Flex,
} from '@chakra-ui/react';
import { t } from 'features/marketplace/shared/utils/i18n';
import { useHistory, useParams } from 'react-router';
import { StatusView } from 'features/marketplace/shared/components';
import { Header, Body, Sidebar } from './components';
import { useGetApplication } from './hooks';
import { AppDetailsSkeleton } from './AppDetailsSkeleton';

const defaultValues = {
  name: '',
  presentation: {
    iconUrl: null,
    categories: null,
    description: null,
    screenshots: null,
    websiteUrl: null,
    supportUrl: null,
    privacyPolicyUrl: null,
    termsOfServiceUrl: null,
  },
};

export const AppDetails = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const { data, isLoading, error } = useGetApplication(id);

  if (error) {
    return (
      <StatusView
        icon='https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-alert-circle'
        iconAlt='alert icon'
        title={t('Failed to fetch this application')}
      />
    );
  }

  const { name, presentation } = data ?? defaultValues;
  const {
    categories,
    description,
    screenshots,
    websiteUrl,
    supportUrl,
    privacyPolicyUrl,
    termsOfServiceUrl,
    iconUrl,
  } = presentation;

  if (isLoading) return <AppDetailsSkeleton />;
  return (
    <Flex
      flexDir='column'
      px={40}
      py={6}
      gap={6}
      overflowY='auto'
      css={{
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        scrollbarWidth: 'none', // Firefox
      }}
    >
      <Breadcrumb data-testid='app-details-breadcrumb'>
        <BreadcrumbItem>
          <BreadcrumbLink
            onClick={() =>
              history.push({
                pathname: '/marketplace',
                search: history.location.search,
              })
            }
          >
            Marketplace
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href='#'>{name}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Header name={name} iconUrl={iconUrl} />
      <Divider px={4} />
      <Flex
        data-testid='app-details-body'
        justifyContent='space-between'
        flexWrap='wrap'
      >
        <Body description={description} screenshots={screenshots} />
        <Sidebar
          websiteUrl={websiteUrl}
          supportUrl={supportUrl}
          privacyPolicyUrl={privacyPolicyUrl}
          termsOfServiceUrl={termsOfServiceUrl}
          categories={categories}
        />
      </Flex>
    </Flex>
  );
};
