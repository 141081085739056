export enum EOrganizationAccessRole {
  'Organization Admin' = 'Organization Admin',
  'Organization User' = 'Organization User',
  'Organization Owner' = 'Organization Owner',
}

export enum ETenantAccessRole {
  'Admin' = 'Admin',
  'User' = 'User',
  'No-Access' = 'No-access',
}

export type IMemberRole = {
  organizationId: string;
  scope: string;
  tenantId: string | null;
  role: string;
};

export interface IGetMembersResponse {
  totalRecords: number;
  pageLength: number;
  pageNumber: number;
  data: IGetMembersDataItem[];
}

export interface IGetMembersDataItem {
  id: string;
  email: string;
  emailVerified: boolean;
  created: string;
  updated: string;
  lastLogin: string;
  signUpCompleted: boolean;
  givenName: string | null;
  familyName: string | null;
  nickName: string;
  phoneNumber: string;
  company: string;
  jobRole: string;
  preferredLanguage: string;
  region: string;
  roles: IMemberRole[];
  picture: string;
}
