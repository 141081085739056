import { Icon } from '@chakra-ui/icon';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  MenuOptionGroup,
  Button,
} from '@chakra-ui/react';
import { mdiChevronDown } from '@mdi/js';
import {
  useGetMainMenuItems,
  useGetUserManagementMenuItems,
  useGetorganizationManagementMenuItems,
} from 'features/common/hooks/navigationMenu/useGetNavigationMenuItems';
import { useHistory } from 'react-router';

const MobileMenu = () => {
  const history = useHistory();
  const { mainMenuItems } = useGetMainMenuItems();
  const { userManagementMenuItems } = useGetUserManagementMenuItems();
  const { organizationManagementMenuItems } =
    useGetorganizationManagementMenuItems();
  const adminItems = [
    ...userManagementMenuItems,
    ...organizationManagementMenuItems,
  ];

  return (
    <Menu>
      <MenuButton
        as={Button}
        variant='navigation'
        size='sm'
        rightIcon={
          <Icon layerStyle='menuButtonIcon'>
            <path d={mdiChevronDown} />
          </Icon>
        }
      >
        Menu
      </MenuButton>
      <MenuList>
        {mainMenuItems &&
          mainMenuItems
            .filter((item) => item.label !== 'Admin')
            .map((item) => (
              <MenuItem
                onClick={() => item.href && history.push(item.href)}
                icon={item.mobileMenuIcon}
              >
                {item.label}
              </MenuItem>
            ))}

        {adminItems && adminItems.length > 0 && (
          <>
            <MenuDivider />
            <MenuOptionGroup title='Admin'>
              {adminItems.map((item) => (
                <MenuItem
                  onClick={() => item.href && history.push(item.href)}
                  icon={item.icon}
                >
                  {item.label}
                </MenuItem>
              ))}
            </MenuOptionGroup>
          </>
        )}
      </MenuList>
    </Menu>
  );
};

export default MobileMenu;
