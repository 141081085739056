import React, { ErrorInfo, ReactNode } from 'react';
import {
  Center,
  Stack,
  Image,
  Heading,
  Text,
  Button,
  Link as ChakraLink,
} from '@chakra-ui/react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';

interface ErrorBoundaryProps extends RouteComponentProps {
  children: ReactNode;
  customErrorMessage?: string;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorDisplay
          onRetry={() => this.setState({ hasError: false, error: null })}
          customErrorMessage={this.props.customErrorMessage}
          history={this.props.history}
        />
      );
    }

    return this.props.children;
  }
}

interface ErrorDisplayProps {
  onRetry: () => void;
  customErrorMessage?: string;
  history: RouteComponentProps['history'];
}

const ErrorDisplay: React.FC<ErrorDisplayProps> = ({
  onRetry,
  customErrorMessage,
  history,
}) => {
  return (
    <Center layerStyle='section.main' h='full' backgroundColor={'transparent'}>
      <Stack align='center' textAlign='center' spacing='6' maxW='sm'>
        <Image
          boxSize='32'
          src='https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-alert'
          alt='alert'
        />
        <Heading as='h1'>Something went wrong</Heading>
        <Text>
          {customErrorMessage ||
            'Please try again. If the issue persists, try visiting the '}
          {!customErrorMessage && (
            <ChakraLink
              as={Link}
              href='https://support.sitecore.com/kb'
              isExternal
            >
              Knowledge Base
            </ChakraLink>
          )}
          {!customErrorMessage && ' for assistance.'}
        </Text>
        <Stack>
          <Button variant='link' onClick={onRetry}>
            Retry
          </Button>
          <Button variant='link' onClick={() => history.push('/')}>
            Go to homepage
          </Button>
        </Stack>
      </Stack>
    </Center>
  );
};

export default withRouter(ErrorBoundary);
