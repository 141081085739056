import { AxiosResponse } from 'axios';
import { UseQueryOptions } from 'react-query';
import { useAuthQuery } from '../../../common/apiUtils/queryHelper';
import {
  IGetUserOrgResponse,
  IUser,
  identity as identityConfig,
} from '@sitecore-ui/portal-singular';
import { env } from 'features/common/config/envConfig';

const identity = identityConfig(env);

export const useGetCurrentUser = ({
  enabled = true,
}: {
  enabled?: boolean;
} = {}) => {
  return useAuthQuery(
    ['user'],
    async (axiosInstance) => {
      return axiosInstance?.get<IUser>(identity.get_user.url);
    },
    { enabled },
    identity.get_user.scope,
  );
};

export const useGetCurrentUserOrgs = (
  {
    pagenumber,
    pagesize,
  }: {
    pagenumber: number;
    pagesize: number;
  },
  queryOptions?:
    | Omit<
        UseQueryOptions<
          AxiosResponse<IGetUserOrgResponse>,
          unknown,
          AxiosResponse<IGetUserOrgResponse>,
          string[]
        >,
        'queryKey' | 'queryFn'
      >
    | undefined,
) => {
  return useAuthQuery(
    ['user', 'orgs'],
    async (axiosInstance) => {
      return axiosInstance?.get<IGetUserOrgResponse>(
        identity.get_user_organizations.url(pagenumber, pagesize),
      );
    },
    {
      ...queryOptions,
    },
    identity.get_user_organizations.scope,
  );
};
