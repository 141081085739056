export const jobRolesOptions = [
  { value: 'Alliance Manager', label: 'Alliance Manager' },
  { value: 'Architect', label: 'Architect' },
  { value: 'Brand/Creative', label: 'Brand/Creative' },
  { value: 'Business Analyst', label: 'Business Analyst' },
  { value: 'Chief Digital Officer', label: 'Chief Digital Officer' },
  { value: 'Chief Executive Officer', label: 'Chief Executive Officer' },
  { value: 'Chief Information Officer', label: 'Chief Information Officer' },
  { value: 'Chief Marketing Officer', label: 'Chief Marketing Officer' },
  { value: 'Chief Revenue Officer', label: 'Chief Revenue Officer' },
  { value: 'Chief Technology Officer', label: 'Chief Technology Officer' },
  { value: 'Communications/Event', label: 'Communications/Event' },
  { value: 'Consulting', label: 'Consulting' },
  { value: 'Content Marketer', label: 'Content Marketer' },
  { value: 'Customer Experience', label: 'Customer Experience' },
  { value: 'Developer', label: 'Developer' },
  { value: 'Digital Marketer', label: 'Digital Marketer' },
  { value: 'Digital Merchandiser', label: 'Digital Merchandiser' },
  { value: 'Ecommerce', label: 'Ecommerce' },
  { value: 'Finance', label: 'Finance' },
  { value: 'IT', label: 'IT' },
  { value: 'Marketing Developer', label: 'Marketing Developer' },
  { value: 'Marketing Operations', label: 'Marketing Operations' },
  { value: 'Marketing Technologist', label: 'Marketing Technologist' },
  { value: 'Product Marketing', label: 'Product Marketing' },
  { value: 'Program Manager', label: 'Program Manager' },
  { value: 'Project Manager', label: 'Project Manager' },
  { value: 'Sales Operations', label: 'Sales Operations' },
  { value: 'Sales/Business Development', label: 'Sales/Business Development' },
  { value: 'Security', label: 'Security' },
  { value: 'Tech Lead', label: 'Tech Lead' },
  { value: 'UX Designer', label: 'UX Designer' },
  { value: 'Web Strategy', label: 'Web Strategy' },
  { value: 'Web Technical', label: 'Web Technical' },
];
