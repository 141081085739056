import {
  Card,
  CardBody,
  HStack,
  Heading,
  VStack,
  Image,
  Text,
  CloseButton,
} from '@chakra-ui/react';
import { RecommendationCard as GraphQlRecommendationCard } from 'gql/graphql';

type RecommendationCard = GraphQlRecommendationCard & {
  onClick: () => void;
  onClose: () => void;
};

const RecommendationsCard = ({
  cardId,
  icon,
  title,
  description,
  onClick,
  onClose,
}: RecommendationCard) => {
  return (
    <Card
      data-testid={`recommendation-card-for ${cardId}`}
      variant='flat'
      size='md'
      cursor='pointer'
      onClick={onClick}
      layerStyle='interactive.raise'
      height='full'
    >
      <CardBody>
        <HStack alignItems='center' gap='4'>
          <Image
            width='14'
            height='14'
            src={icon?.src}
            alt='recommendation icon'
          />
          <VStack w='full' alignItems='flex-start'>
            <HStack w='full' justifyContent='space-between'>
              <Heading lineHeight='1.3' noOfLines={2} size='sm'>
                {title}
              </Heading>
              <CloseButton
                data-testid={`${title}-close-button`}
                position='relative'
                size='sm'
                top='-0.7rem'
                left='0.5rem'
                onClick={(e) => {
                  e.stopPropagation();
                  onClose();
                }}
              />
            </HStack>
            <Text noOfLines={2} variant='subtle'>
              {description}
            </Text>
          </VStack>
        </HStack>
      </CardBody>
    </Card>
  );
};

export default RecommendationsCard;
